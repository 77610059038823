import autocomplete from "autocomplete.js"
import { Controller } from "stimulus"

// Note: For Autosuggests when multiple options may be selected.
// For single autosuggest, use the AutosuggestController

// When a user selects an option, a defined multiselect (multiselectTarget) is kept in sync.
// This covers 90% of cases.
// You can optionally set the 'checkbox-sync' data attribute to additionally keep a checkbox
// set (in a completely different form) in sync. This is used in the onboarding/profile-setup wizard
// in the Skills step, where both an autosuggest and checkbox view is available and need to be kept in sync.

export default class extends Controller {
  static targets = ["input", "selectionsContainer", "multiselect"]

  connect() {
    this.setupAutosuggest()

    if (this.data.get("checkboxSync")) {
      this.checkboxSyncTarget = $(this.data.get("checkboxSync"))
    } else {
      this.checkboxSyncTarget = false
    }
  }

  setupAutosuggest = () => {
    const controller = this

    autocomplete(
      `#${controller.inputTarget.id}`,
      {
        hint: false,
        clearOnSelected: true
      },
      [
        {
          source: (query, callback) => {
            const pattern = new RegExp(query, "gi")
            const results = App[controller.data.get("source")]
              .filter((item) => item.name.toLowerCase().includes(query.toLowerCase()))
              .slice(0, 10)
              .map((item) => {
                item.highlight = item.name.replace(pattern, "<em>$&</em>")
                return item
              })
            callback(results)
          },
          displayKey: "name",
          templates: {
            empty: function (context) {
              return "<span class='no-results'>No results found.</span>"
            },
            suggestion: function (suggestion) {
              return suggestion.highlight
            }
          }
        }
      ]
    ).on("autocomplete:selected", function (event, suggestion, dataset, context) {
      const container = controller.selectionsContainerTarget
      const item = $(
        `<span>${suggestion.name} <i class='icon-close' data-action='click->multi-autosuggest#removeItem'></i></span>`
      )
      item.attr("data-value", suggestion.value)
      item.attr("data-id", suggestion.id)

      item.appendTo(container)
      container.classList.add("with-items")
      container.classList.remove("no-items")

      const allItems = [...controller.multiselectTarget.options]
      const selectItem = allItems.find((option) => option.value == suggestion.id)
      selectItem.selected = true

      if (controller.checkboxSyncTarget) {
        controller.checkboxSyncTarget.find(`input[value='${suggestion.id}']`).attr("checked", "checked")
      }
    })
  }

  removeItem = (e) => {
    const id = $(e.target).closest("span").attr("data-id")
    const allItems = [...this.multiselectTarget.options]
    const selectItem = allItems.find((option) => option.value == id)
    selectItem.selected = false

    $(e.target)
      .closest("span")
      .fadeOut(function () {
        $(this).remove()
      })

    if (this.checkboxSyncTarget) {
      this.checkboxSyncTarget.find(`input[value='${id}']`).attr("checked", false)
    }
  }
}
