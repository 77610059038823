import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["filterInput", "checkboxesToFilter"]

  // Hide mode: never shows full checkbox list and only shows results when searching.
  // Standard mode: shows full list, and filters them when searching.

  connect() {
    this.initialDisplayMode = "block"
    this.checkboxes = this.checkboxesToFilterTarget.querySelectorAll("span.checkbox")
    this.hideModeEnabled = this.data.get("hide-mode") == "true"
    this.requiredTermLength = this.hideModeEnabled ? 3 : 1
    this.reset()
  }

  currentTerm = () => {
    return this.filterInputTarget.value.toLowerCase()
  }

  filter = () => {
    if (this.currentTerm().length >= this.requiredTermLength) {
      this.filterCheckboxes()
    } else {
      this.reset()
    }
  }

  filterCheckboxes = () => {
    this.checkboxesToFilterTarget.classList.add("single_column")

    const term = this.filterInputTarget.value.toLowerCase()

    this.checkboxes.forEach((item) => {
      if (item.querySelector("label").textContent.toLowerCase().indexOf(term) != -1) {
        item.style.display = this.initialDisplayMode
      } else {
        item.style.display = "none"
      }
    })
  }

  reset = () => {
    const displayMode = this.hideModeEnabled ? "none" : this.initialDisplayMode

    this.checkboxesToFilterTarget.classList.remove("single_column")

    this.checkboxes.forEach((item) => {
      item.style.display = displayMode
    })
  }
}
