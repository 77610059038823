
export const OFFICE_HOUR_QUERY = `
  query OfficeHour($id:ID!){
    officeHour(id:$id) {
      id
      informationId
      openTime
      closeTime
      day
      information {
        id
        structureType
        property {
          id
          name
        }
      }
      daysOfTheWeek {
        value
        name
      }
      columns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_OFFICE_HOUR_MUTATION = `
  mutation CreateOfficeHourMutation($informationId: Int!, $openTime: String, $closeTime: String, $day: String) {
    createOfficeHourMutation(
      informationId: $informationId
      openTime: $openTime
      closeTime: $closeTime
      day: $day
    ) {
      id
      informationId
      openTime
      closeTime
      day
    }
  }
`

export const UPDATE_OFFICE_HOUR_MUTATION = `
  mutation UpdateOfficeHourMutation($id: ID!, $openTime: String, $closeTime: String, $day: String) {
    updateOfficeHourMutation(
      id: $id
      openTime: $openTime
      closeTime: $closeTime
      day: $day
    ) {
      id
      informationId
      openTime
      closeTime
      day
    }
  }
`

export const DELETE_OFFICE_HOUR_MUTATION = `
  mutation DeleteOfficeHourMutation($id: ID!) {
    deleteOfficeHourMutation(id: $id) {
      result
      errors
    }
  }
`
