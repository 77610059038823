import React, { useEffect } from "react"
import OfficeHoursProvider from "../../context/OfficeHoursContext"
import OfficeHourShow from "../../shared/OfficeHourShow"
import OfficeHourNew from "../../shared/OfficeHourNew"
import OfficeHourEdit from "../../shared/OfficeHourEdit"

export default function OfficeHour({ params, page }) {
  return (
    <OfficeHoursProvider>
      {page === "show" &&
        <OfficeHourShow params={params} />
      }
      {page === "new" &&
        <OfficeHourNew params={params} />
      }
      {page === "edit" &&
        <OfficeHourEdit params={params} />
      }
    </OfficeHoursProvider>
  )
}
