import React from "react"
const comingsoon = require("../../../../../public/samples/coming_soon.gif")

export default function ComingSoon() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Coming Soon</h1>
			<p className="text-lg font-medium">At Brynsights, we're constantly developing new features. This is on the way!</p>
			<div className="flex justify-center align-center mt-8">
				<img src={comingsoon} />
			</div>
		</div>
	)
}