import PropTypes from "prop-types"
import React, { useRef, useEffect, useCallback } from "react"
import { XMarkIcon } from '@heroicons/react/24/solid'

const Sidebar = ({ header: Header, visible, children, closeSidebar, backgroundColor, iconName = "close" }) => {
  const iconClass = `icon-${iconName}`
  const closeRef = useRef(null)

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeRef.current.click()
    }
  }, [])

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false)

    return () => {
      document.removeEventListener("keydown", escFunction, false)
    }
  }, [])

  return (
    <div
      className={`fixed top-0 z-9998 h-screen w-45vw min-w-400 max-w-2xl
        overflow-x-hidden overflow-y-scroll pb-125 shadow duration-300
        md:w-screen md:min-w-unset md:pb-300
        ${visible ? "right-0 md:right-0" : "-right-45vw md:-right-100vw"}`}
      style={{ overscrollBehaviorBlock: "contain", backgroundColor: `${backgroundColor}` }}>


      <div
        className="flex items-center justify-between fixed top-0 z-10 w-45vw relative min-w-400 max-w-2xl px-0 py-6 text-center md:w-screen md:min-w-unset md:max-w-unset md:pt-40">
        <button
          ref={closeRef}
          className="flex cursor-pointer rounded-full border border-geyser p-1.5 ml-4"
          onClick={() => {
            closeSidebar();
          }}>
          <XMarkIcon width={16} height={16} />
        </button>
        {Header ? <Header /> : null}
      </div>
      <div className="SidebarBody px-6 pb-0 pt-6">{children}</div>
    </div>
  )
}

Sidebar.propTypes = {
  header: PropTypes.elementType,
  visible: PropTypes.bool,
  children: PropTypes.node,
  closeSidebar: PropTypes.func,
  backgroundColor: PropTypes.string,
  iconName: PropTypes.oneOf(["close", "back"])
}

export default Sidebar
