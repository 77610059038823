import React, { createContext } from "react"
import { useMutation } from "urql"

import {
  BULK_UPDATE_MEDIA_MUTATION,
  CREATE_MEDIUM_MUTATION,
  DELETE_MEDIUM_MUTATION,
  SORT_MEDIA_MUTATION,
  UPDATE_MEDIUM_MUTATION
} from "../queries/MediaMutations"

export const MediaContext = createContext(null)

// eslint-disable-next-line no-unused-vars
const MediaProvider = ({ children, ...props }) => {
  const [, createMedium] = useMutation(CREATE_MEDIUM_MUTATION)
  const [, deleteMedium] = useMutation(DELETE_MEDIUM_MUTATION)
  const [, updateMedium] = useMutation(UPDATE_MEDIUM_MUTATION)
  const [, sortMedia] = useMutation(SORT_MEDIA_MUTATION)
  const [, bulkUpdateMediaMutation] = useMutation(BULK_UPDATE_MEDIA_MUTATION)

  return (
    <MediaContext.Provider value={{ createMedium, deleteMedium, updateMedium, sortMedia, bulkUpdateMediaMutation }}>
      {children}
    </MediaContext.Provider>
  )
}

export default MediaProvider
