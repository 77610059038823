import React from "react"
const marketdata = require("../../../../../public/samples/marketdata.png")

export default function MarketData() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Market Data Summary</h1>
			<p className="text-lg font-medium">A page to show high level stats reflecting the local and regional markets (# of listings, package levels, etc.)</p>
			<div className="flex justify-center align-center mt-8">
				<img src={marketdata} />
			</div>
		</div>
	)
}