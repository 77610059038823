import React, { useContext } from "react";
import { GlobalContext } from "../../context/GlobalContext";

const sourcetracking = require("../../../../../public/samples/sourcetracking");

export default function SourceTrackingDetails() {
  const { currentUserData } = useContext(GlobalContext);

  const about = currentUserData?.currentUser?.currentCompany?.about;

  const sourceTrackingUrls = {
    'Olympus Property': "https://docs.google.com/spreadsheets/d/1Okn_6VHfmnHbRkGDJV6UUJENO16UD92YcfabRjjRBTE/edit?usp=sharing",
    'Goldfarb Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTv5y9t0Z96Wy_7AXOCNi43ItNUiAampCOJRtS-Gm-qF5KK4i3D1QAw8wD2S42H_g/pubhtml?gid=1256845755&amp;single=true&amp;widget=true&amp;headers=false",
    'Tri City Rentals': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTOTYeYwn6J2Ju574SVrjnegcFomuhVXrZ7pnLPt4KFuaX9jsf8tBeCzCl5hV29nZIYQ8S3UtooC53W/pubhtml?widget=true&amp;headers=false",
    'Prime Management LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTIoFgWXix7UKrTbmefCpfxmSM4klBOfSrumJCPWN2a-Bt-8AstZA5dZVzG7vsrKQ/pubhtml?widget=true&amp;headers=false",
    'REM Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vR27jjoiSPpM4tubHgiJWcHHYAvYEsR3Vue5eoedh632-YBfUtkDBtxxJbk65zTWg/pubhtml?widget=true&amp;headers=false",
    'M3 Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRXn8LAmIArO2wJVPkRXfh9FszsUsugIxpW0JJLt86YHqogxhY64LXCw3Dg95fiOw/pubhtml?widget=true&amp;headers=false",
    'Olive Tree Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSa_vL4qlDvaQFOKQuxj1rX8AeHI8Cy250l8RRWaSR2_8SL3xI2irWIe9A9C1BHvA/pubhtml?widget=true&amp;headers=false",
    'Dawn Homes Management Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQaUadYsz3KkTmf1_gIQyCBbZn0chTPD5QU9Bt3b7z-V_Tys0VhVizRWt_rW2DrKw/pubhtml?widget=true&amp;headers=false",
    'Judge Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT_pSL1i6VMLeUQ2aW2dzOcepYHujaF78jq1FtqGl6TRX90syVhHm0oAnToW9linQ/pubhtml?widget=true&amp;headers=false",
    'The Sterling Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTUkRlgX-bTzYn0AUCe57T1KwE1N0A2ImGMcp8qT3A6sYV4Wr-caIMt2imnTT6Aug/pubhtml?widget=true&amp;headers=false",
    'Aberly Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTkT6xMvnf-k8mh8u1Ma_l59T9GvVJjPmiQhdFVg-gbGGERigjmdESxPflGgMuJqg/pubhtml?widget=true&amp;headers=false",
    'Bellrock Real Estate Partners LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTAkQdHQpVChyHlTe4k8tBLoBAsb-zV0duZ4f0Yq8ImiG28j3egCYTOAx8Xn_ziqQ/pubhtml?widget=true&amp;headers=false",
    'AMBO Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSOOcLBp1o1VaeblkYfYRD1w5FB2y_v1YECreYJbT0EXxtcCsFIK_xEvjG6n2IRjA/pubhtml?widget=true&amp;headers=false",
    'Chelsea Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vR8h6GT9TdTF8l7rcEQtYyfLwGNLP3N4xzR9d7KWg3e1ZMbYNnMhgcHcIxeyFq0kg/pubhtml?widget=true&amp;headers=false",
    'WPHS Venture Partners': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT8z7zGuzkSEWVbN2WeHb_rFyhgTcVHFnVl1WFfpeWWIZ90rHAjLEs7iVS0N_wsqQ/pubhtml?widget=true&amp;headers=false",
    'Skytian Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTijdc4obU27W0UnX4zRuOYz7QDSYzCpC_emhAGG1zxz0AQkdYZ7bm8sEqBco-g6Q/pubhtml?widget=true&amp;headers=false",
    'Bruns Realty Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRQ85Hx9jntZ31p4sqWId4E0RFf1xgCkQPynIIpwLV8iC2kl0LWAuUzHg46-BREqQ/pubhtml?widget=true&amp;headers=false",
    'Velo Residential': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTzdwk6UMO1Vj75Za7-DtS1ekzJjGJ9OZpp_7zXiDLzgupo73Z-u1_W5epbDKd33w/pubhtml?widget=true&amp;headers=false",
    'default': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQsAIrcI0uv1-QxtBfxsYQeVJwFJBS5mwRGffD94VpQ1g7PyJ9TZrdrKFWsL4qKIw/pubhtml?widget=true&amp;headers=false"
  };

  const trackingDetailsUrl = sourceTrackingUrls[about] || sourceTrackingUrls['default'];

  return (
    <div className="p-8">
      <h1 className="font-semibold text-xl">Source Tracking Details for {about || "Demo"}</h1>
      <div className="w-full">
        <iframe
          src={trackingDetailsUrl}
          frameBorder="0"
          width="100%"
          height="1000"
        />
      </div>
    </div>
  );
}
