import React, { useEffect } from "react"
import ConcessionsProvider from "../../context/ConcessionsContext"
import ConcessionNew from "../../shared/ConcessionNew"
import ConcessionEdit from "../../shared/ConcessionEdit"

export default function Concession({ params, page }) {
  return (
    <ConcessionsProvider>
      {page === "new" &&
        <ConcessionNew params={params} />
      }
      {page === "edit" &&
        <ConcessionEdit params={params} />
      }
    </ConcessionsProvider>
  )
}
