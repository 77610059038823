import React, { createContext } from "react"
import { useMutation } from "urql"

import {
  CREATE_UNIT_MUTATION,
  UPDATE_UNIT_MUTATION,
  DELETE_UNIT_MUTATION,
  UPLOAD_UNIT_MUTATION
} from "../queries/UnitsMutations"

export const UnitsContext = createContext(null)

const UnitsProvider = ({ children, ...props }) => {
  const [, createUnit] = useMutation(CREATE_UNIT_MUTATION)
  const [, updateUnit] = useMutation(UPDATE_UNIT_MUTATION)
  const [, deleteUnit] = useMutation(DELETE_UNIT_MUTATION)
  const [, uploadUnit] = useMutation(UPLOAD_UNIT_MUTATION)

  return (
    <UnitsContext.Provider value={{ createUnit, updateUnit, deleteUnit, uploadUnit }}>{children}</UnitsContext.Provider>
  )
}

export default UnitsProvider
