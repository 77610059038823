import Inputmask from "inputmask"

document.addEventListener("turbolinks:load", function () {
  // Submit icon
  $("body").on("click", "form button[type='submit']", function (e) {
    $(this).addClass("working")
  })

  // Input icons
  $("input[data-icon]").each(function () {
    const wrapper = $(this).closest(".input-wrapper")
    $(`<i class="icon-${$(this).attr("data-icon")}"></i>`).prependTo(wrapper)
    wrapper.addClass("with-icon")
  })

  // Tel input formats
  document.querySelectorAll("input[type='tel']").forEach((input) => {
    const mask = new Inputmask("(999) 999-9999")
    mask.mask(input)
  })

  // Money input formats
  document.querySelectorAll(".money input").forEach((input) => {
    const mask = new Inputmask({ alias: "currency" })
    mask.mask(input)
  })

  // Range Slider - move bullet
  const slider = $(".range-slider input")
  if (slider.length) {
    const getRgbColorFromGradient = function (beginColor, endColor, ratio) {
      return (
        "rgb(" +
        Math.round(endColor[0] * ratio + beginColor[0] * (1 - ratio)) +
        "," +
        Math.round(endColor[1] * ratio + beginColor[1] * (1 - ratio)) +
        "," +
        Math.round(endColor[2] * ratio + beginColor[2] * (1 - ratio)) +
        ")"
      )
    }

    const getGradientColor = function (percent) {
      // Gradient is #0B3B56 0%, #1B9D9F 33.4%, #F4B765 68.89%, #F39881 100%
      if (percent <= 33.4) {
        return getRgbColorFromGradient([11, 59, 86], [27, 157, 159], percent / 33.4)
      }
      if (percent <= 68.89) {
        return getRgbColorFromGradient([27, 157, 159], [244, 183, 101], percent / 68.89)
      }
      if (percent <= 100) {
        return getRgbColorFromGradient([244, 183, 101], [243, 152, 129], percent / 100)
      }
    }

    const moveSliderBullet = function (sliderInputElement) {
      const inputElement = sliderInputElement.length ? sliderInputElement : $(this)
      const value = inputElement.val()
      const max = inputElement.attr("max")
      const percent = Math.round(((value - 1) / (max - 1)) * 10000) / 100
      const bullet = inputElement.parent().parent().find(".bullet")
      // restet line and number colors
      const lines = inputElement.parent().parent().find(".line")
      const numbers = inputElement.parent().parent().find(".tick-number")
      lines.css("color", "#C8D6DF")
      numbers.css("color", "#55778A")
      // Set current chosen line and number color
      lines[value - 1].style.color = "#0B3954"
      numbers[value - 1].style.color = "#0B3954"
      // Move bullet and set its background color
      bullet.css("left", percent + "%")
      bullet.css("background-color", getGradientColor(percent))
      // Size bullet according to length of text to be shown in it
      const values = inputElement.data("values")
      if (values[value - 1].length < 6) {
        bullet.css("width", "77px")
        bullet.css("height", "40px")
        bullet.css("top", "-93px")
      } else if (values[value - 1].length < 15) {
        bullet.css("width", "119px")
        bullet.css("height", "40px")
        bullet.css("top", "-93px")
      } else if (values[value - 1].length < 32) {
        bullet.css("width", "250px")
        bullet.css("height", "40px")
        bullet.css("top", "-93px")
      } else {
        bullet.css("width", "250px")
        bullet.css("height", "60px")
        bullet.css("top", "-113px")
      }
      bullet.children(".text").text(values[value - 1])
    }

    moveSliderBullet(slider)
    slider.on("input", moveSliderBullet)
  }

  // Word count
  if ($("#char-count-1").length > 0) {
    $("#char-count-1").html($("#counted-1").val().length)
    $("#counted-1").keyup(function () {
      $("#char-count-1").html($(this).val().length)
    })
  }

  if ($("#char-count-2").length > 0) {
    $("#char-count-2").html($("#counted-2").val().length)

    $("#counted-2").keyup(function () {
      $("#char-count-2").html($(this).val().length)
    })
  }

  if ($("#char-count-3").length > 0) {
    $("#char-count-3").html($("#counted-3").val().length)

    $("#counted-3").keyup(function () {
      $("#char-count-3").html($(this).val().length)
    })
  }

  if ($("#char-count-4").length > 0) {
    $("#char-count-4").html($("#counted-4").val().length)

    $("#counted-4").keyup(function () {
      $("#char-count-4").html($(this).val().length)
    })
  }

  if ($("#char-count-5").length > 0) {
    $("#char-count-5").html($("#counted-5").val().length)

    $("#counted-5").keyup(function () {
      $("#char-count-5").html($(this).val().length)
    })
  }

  // if ($('#title-char-count').length > 0 ){
  //   $('#title-char-count').html($('#title-id').val().length);

  //   $('#title-id').keyup(function() {
  //     $('#title-char-count').html($(this).val().length);
  //   });
  // }

  if ($("#headline-char-count").length > 0) {
    $("#headline-char-count").html($("#headline-id").val().length)

    $("#headline-id").keyup(function () {
      $("#headline-char-count").html($(this).val().length)
    })
  }

  $(".select").change(function (e) {
    $(this).addClass("touched")
  })
})
