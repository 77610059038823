import clsx from "clsx"
import { Table } from "flowbite-react"
import React, { useEffect, useState } from "react"

export function PricingRecommendations({ properties = [], className = "" }) {
  return (
    <div className={clsx("my-8 overflow-hidden rounded-md border", className)}>
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Pricing Recommendations</p>
          <p className="text-xs font-medium text-gray-500">
            Maximize revenue with unit-specific rent recommendations based on recently signed leases.
          </p>
        </div>
      </div>
      <Tables properties={properties} />
    </div>
  )
}

export function Tables({ properties = [] }) {
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const [units, setUnits] = React.useState([])
  const getEffPsf = (price, sqft) => {
    if (price > 0 && sqft > 0) {
      return parseFloat((price / sqft).toFixed(1))
    }
    return 0
  }
  const formatsUnits = (properties) => {
    const units = []
    properties.forEach((property) => {
      property.building_availability.forEach((unit) => {
        if (unit.history.length > 0) {
          const maxEffectivePrice = Math.max(...unit.history.map((item) => item.price))
          if (maxEffectivePrice) {
            const recommendation = unit.history.find((item) => item.price === maxEffectivePrice)
            const comparables = unit.history.filter((item) => item.price !== maxEffectivePrice)
            const unitData = {
              property: property.building_name,
              effPsf: getEffPsf(recommendation.price, unit.sqft),
              effRent: recommendation.price,
              recommendation: recommendation,
              comparables: comparables,
              ...unit
            }
            units.push(unitData)
          }
        }
      })
    })
    return units
  }
  const renderPagination = () => {
    const pageNumbers = []
    const maxButtons = 7
    const halfMaxButtons = Math.floor(maxButtons / 2)

    // Calculate the position where the ellipsis should appear
    let startPage = Math.max(1, currentPage - halfMaxButtons)
    let endPage = Math.min(startPage + maxButtons - 1, totalPages)

    // If the current page is close to the beginning, adjust endPage
    if (currentPage <= halfMaxButtons) {
      endPage = Math.min(maxButtons, totalPages)
    }

    // If the current page is close to the end, adjust startPage
    if (currentPage >= totalPages - halfMaxButtons) {
      startPage = Math.max(totalPages - maxButtons + 1, 1)
    }

    pageNumbers.push(
      <button
        key="prev"
        onClick={() => {
          if (currentPage > 1) {
            handlePageChange(currentPage - 1)
          }
        }}
        className={clsx(
          "m-1 rounded px-2 py-1",
          currentPage > 1 ? "cursor-pointer bg-gray-100 text-gray-600" : "cursor-not-allowed bg-gray-200 text-gray-400"
        )}>
        &lt;
      </button>
    )

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="m-1 rounded bg-gray-100 px-2 py-1 text-gray-600">
          1
        </button>
      )
      if (startPage > 2) {
        pageNumbers.push(
          <div key="ellipsis-start" className="m-1 px-2 py-1">
            ...
          </div>
        )
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={clsx("m-1 rounded px-2 py-1", i === currentPage ? "bg-blue-400" : "bg-gray-100 text-gray-600")}>
          {i}
        </button>
      )
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <div key="ellipsis-end" className="m-1 px-2 py-1">
          ...
        </div>
      )
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="m-1 rounded bg-gray-100 px-2 py-1 text-gray-600">
          {totalPages}
        </button>
      )
    }

    pageNumbers.push(
      <button
        key="next"
        onClick={() => {
          if (currentPage < totalPages) {
            handlePageChange(currentPage + 1)
          }
        }}
        className={clsx(
          "m-1 rounded px-2 py-1",
          currentPage < totalPages
            ? "cursor-pointer bg-gray-100 text-gray-600"
            : "cursor-not-allowed bg-gray-200 text-gray-400"
        )}>
        &gt;
      </button>
    )

    return <div className="flex justify-center">{pageNumbers}</div>
  }
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const totalPages = Math.ceil(units.length / itemsPerPage)
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentUnits = units.slice(indexOfFirstItem, indexOfLastItem)
  useEffect(() => {
    setUnits(formatsUnits(properties))
  }, [properties])

  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>Building Name</Table.HeadCell>
          <Table.HeadCell>Floorplan</Table.HeadCell>
          <Table.HeadCell>Type</Table.HeadCell>
          <Table.HeadCell>Sf</Table.HeadCell>
          <Table.HeadCell>Days On Mkt</Table.HeadCell>
          <Table.HeadCell>Last Eff Rent</Table.HeadCell>
          <Table.HeadCell>Rent Psf</Table.HeadCell>
          <Table.HeadCell>Recommended Rent</Table.HeadCell>
        </Table.Head>
        <Table.Body className="border-t text-center text-sm">
          {currentUnits.map((item, index) => (
            <Table.Row key={index} className={clsx("border-b font-medium text-gray-800 hover:bg-blue-100")}>
              <Table.Cell className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2 pl-4">
                {item.property}
              </Table.Cell>
              <Table.Cell>{item.floorplan_name}</Table.Cell>
              <Table.Cell>
                <div
                  style={{
                    backgroundColor:
                      item.bed === 3
                        ? "rgb(255, 87, 34)"
                        : item.bed === 2
                        ? "rgb(76, 175, 80)"
                        : item.bed === 1
                        ? "#6365f1"
                        : item.bed === 0
                        ? "rgb(255, 193, 7)"
                        : "transparent",
                    display: "inline-block",
                    borderRadius: "10px",
                    width: "36px",
                    height: "18px",
                    lineHeight: "12px",
                    paddingTop: "2px",
                    color: "white",
                    textAlign: "center"
                  }}>
                  {item.bed}/{item.bath}
                </div>
              </Table.Cell>
              <Table.Cell>{item.sqft}</Table.Cell>
              <Table.Cell>{item.days_on_market}</Table.Cell>
              <Table.Cell>${item.effRent}</Table.Cell>
              <Table.Cell>${item.effPsf}</Table.Cell>
              <Table.Cell>$0</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {renderPagination()}
    </div>
  )
}
