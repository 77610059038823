import React from "react"

const colorStart = { r: 0xef, g: 0xef, b: 0xef } // #efefef
const colorEnd = { r: 0x30, g: 0xa1, b: 0x4e } // #30a14e

const rgbToHex = (r, g, b) => {
  const componentToHex = (c) => {
    const hex = c.toString(16)
    return hex.length === 1 ? "0" + hex : hex
  }
  return `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`
}

const RenderQuality = ({ item }) => {
  const normalizedScore = typeof item === "number" && !isNaN(item) ? item : 0
  const clampedScore = Math.max(0, Math.min(10, normalizedScore))

  const r = Math.round(colorStart.r + (colorEnd.r - colorStart.r) * (clampedScore / 10))
  const g = Math.round(colorStart.g + (colorEnd.g - colorStart.g) * (clampedScore / 10))
  const b = Math.round(colorStart.b + (colorEnd.b - colorStart.b) * (clampedScore / 10))

  const color = rgbToHex(r, g, b)

  return (
    <div
      className="mx-auto flex h-6 w-6 items-center justify-center rounded p-2"
      style={{ backgroundColor: color, color: "#fff" }}>
      {clampedScore}
    </div>
  )
}

export default RenderQuality
