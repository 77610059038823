import { TextInput } from "flowbite-react"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { useQuery } from "urql"
import CurrencyInput from "react-currency-input-field"
import { HiSearch } from "react-icons/hi"
import styled from "styled-components"

import { GlobalContext } from "../context/GlobalContext"
import { PropertiesContext } from "../context/PropertiesContext"
import { SearchResultList, useFetch } from "../portal/comp_analysis/CompAnalysisReportsNew"
import { propertyColumnsCurrent } from "../queries/PropertiesColumns"
import { PROPERTY_QUERY } from "../queries/PropertiesMutations"

import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import DesktopH5 from "./DesktopH5"
import DesktopTitle3 from "./DesktopTitle3"
import GreyLabel from "./GreyLabel"
import InputField from "./InputField"
import LoadingButton from "./LoadingButton"
import Modal from "./Modal"
import Selectbox from "./Selectbox"
import SidebarFooter from "./SidebarFooter"
import SlideInAlert from "./SlideInAlert"
import Loading from "./Loading"
import PropertyDetailAmenitiesSidebar from "./PropertyDetailAmenitiesSidebar"

const BoxWrapper = styled.div`
  background-color: #fff;
  padding: 24px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  margin-bottom: 24px;
  position: relative;

  .info-row {
    border-bottom: 1px solid #c8d6df;
    padding-bottom: 16px;
    margin-bottom: 16px;
  }
  .green {
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    color: #0d9da4;
  }

  .subtitle {
    font-family: Lato;
    font-weight: 700;
    font-size: 16px;
    color: #0b3954;
    margin-bottom: 16px;
  }
`

const EditPropertyForm = styled.div`
  padding-bottom: 50px;

  .row {
    margin-top: 16px;
  }
`

export default function PropertyDetail({ propertyId, refreshProperty, propertyEdited, propertyDeleted }) {
  const [showEditProperty, setShowEditProperty] = useState(false)
  const [showDeleteProperty, setShowDeleteProperty] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent] = useState("")
  const [error, setError] = useState(null)
  const [showAmenitiesSidebar, setShowAmenitiesSidebar] = useState(false)
  const [propertyInput, setPropertyInput] = useState()
  const [helloDataPropertyId, setHelloDataPropertyId] = useState("")
  const [property, setProperty] = useState()
  const [fetchDataLoading, setFetchDataLoading] = useState(false)
  const [fetchDcaaLoading, setFetchDcaaLoading] = useState(false)
  const [search1, setSearch1] = useState("")
  const [showResultList, setShowResultList] = useState(false)
  const { editProperty, deleteProperty } = useContext(PropertiesContext)
  const { statesData, currentUserData } = useContext(GlobalContext)
  const csrfToken = window.document.querySelector('[name="csrf-token"]').getAttribute("content")

  const {
    data: results1,
    error: error1,
    isLoading: isLoading1
  } = useFetch({
    url: `/hello_data/search?q=${search1}`,
    enabled: Boolean(search1?.trim()),
    options: {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    }
  })

  const columns = propertyColumnsCurrent

  const addValue = (item, value) => {
    const current = {}
    current[`${item.accessor}`] = value
    setPropertyInput({ ...propertyInput, ...current })
  }

  const fetchHelloData = async () => {
    if (fetchDataLoading) return
    const HELLO_DATA_PROPERTY_DETAILS_ENDPOINT = "/hello_data/fetch_remote_data"
    const helloDataId = property.helloDataPropertyId
    const propertyId = property.id
    if (helloDataId && propertyId) {
      const url = `${HELLO_DATA_PROPERTY_DETAILS_ENDPOINT}/${helloDataId}/${propertyId}`
      try {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        const options = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          }
        }
        const response = await fetch(url, options)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        await response.json()
        setFetchDataLoading(false)
        alert("Fetch remote data done")
      } catch (error) {
        setFetchDataLoading(false)
        throw error
      }
    } else {
      setFetchDataLoading(false)
      alert("Please input hello_data_property_id before fetch remote data")
    }
  }

  const fetchDcaaData = async () => {
    if (fetchDataLoading) return
    const helloDataId = property.helloDataPropertyId
    const propertyId = property.id
    const HELLO_DATA_PROPERTY_DETAILS_ENDPOINT = "/hello_data/fetch_dcaa_data"
    if (helloDataId && propertyId) {
      const url = `${HELLO_DATA_PROPERTY_DETAILS_ENDPOINT}/${propertyId}`
      try {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute("content")
        const options = {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            "X-CSRF-Token": csrfToken
          }
        }
        const response = await fetch(url, options)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        await response.json()
        setFetchDcaaLoading(false)
        alert("Fetch Dcaa data done")
      } catch (error) {
        setFetchDcaaLoading(false)
        throw error
      }
    } else {
      setFetchDcaaLoading(false)
      alert("Please input hello_data_property_id before fetch remote data")
    }
  }

  async function onSelectSubject(result = {}) {
    setSearch1("")

    setSearch1(result.building_name)
    setHelloDataPropertyId(result.id)
    setShowResultList(false)
  }

  const identificationTypeOptions = [
    "High Rise",
    "Garden Style",
    "Mid Rise",
    "Mixed Use",
    "Standard",
    "Unspecified",
    "Low Rise",
    "House for Rent",
    "Condo for Rent",
    "Walkup",
    "Coach House",
    "Townhouse",
    "Courtyard"
  ].map((value) => ({
    value: value,
    label: value
  }))

  const rentalTypeOptions = [
    "Subsidized",
    "Assisted Senior",
    "Assisted Living",
    "Market Rate",
    "Unspecified",
    "Broker",
    "Student",
    "Temporary"
  ].map((value) => ({
    value: value,
    label: value
  }))

  const [{ data: propertyData, fetching: fetchingProperty }, propReload] = useQuery({
    query: PROPERTY_QUERY,
    requestPolicy: "network-only",
    variables: {
      id: propertyId
    },
    pause: propertyId ? false : true
  })

  useEffect(() => {
    if (propertyData) {
      setProperty(propertyData.property);
    }
  }, [propertyData]);

  const fetchUpdateProperties = (propertyInput) => {
    const propertiesValues = {}
    propertiesValues["id"] = property.id
    propertiesValues["companyId"] = parseInt(currentUserData?.currentUser?.currentCompany?.id)
    columns.forEach((item) => {
      if (propertyInput[item.accessor]) {
        if (item.accessor_value === "state_id") {
          propertiesValues[item.accessor_value] = parseInt(propertyInput[item.accessor]?.id)
        } else {
          let value = null
          if (item.type === "number") {
            value = parseInt(propertyInput[item.accessor])
          } else if (item.type === "decimal") {
            value = parseFloat(propertyInput[item.accessor])
          } else {
            value = propertyInput[item.accessor]
          }

          propertiesValues[item.accessor_value || item.accessor] = value
        }
      } else {
        propertiesValues[item.accessor_value || item.accessor] = null
      }
      propertiesValues.helloDataPropertyId = helloDataPropertyId
      propertiesValues.helloDataPropertyName = search1
    })
    return propertiesValues
  }

  const isUser = property && property.__typename === "User"

  useMemo(() => {
    if (property) {
      const propertiesValues = {}
      columns.map((item) => {
        if (item.accessor === "state") {
          propertiesValues[item.accessor] = parseInt(property[item.accessor]?.id)
        } else {
          propertiesValues[item.accessor] = property[item.accessor]
        }
      })
      setPropertyInput(propertiesValues)
    }
  }, [property])

  useEffect(() => {
    if (property) {
      setHelloDataPropertyId(property.helloDataPropertyId)
      setSearch1(property.helloDataPropertyName)
    }
  }, [property])

  return (
    <div>
      {property ? 
        <div>
          <BoxWrapper>
            <div className="row flex w-full justify-between">
              <p className="subtitle">Property</p>
            </div>
            <div className="info-row">
              <GreyLabel content="Name" />
              <p>{property.name}</p>
            </div>
            <div className="info-row">
              <GreyLabel content="Address" />
              <p>{property.fullAddressWithFallback}</p>
            </div>
            <div className="info-row">
              <GreyLabel content="Unit Count" />
              <p>{property.unitCount}</p>
            </div>
    
            <GreyLabel content="Add/Edit Property Syndication Info" />
            {property.information ? (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/informations/${property.information.id}/edit`}>
                  Information
                </a>
              </div>
            ) : (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/informations/new?id=${property.id}`}>
                  Information
                </a>
              </div>
            )}
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/floorplans/${property.id}`}>
                Floorplans
              </a>
            </div>
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/units/${property.id}`}>
                Units
              </a>
            </div>
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                onClick={() => {
                  setShowAmenitiesSidebar(true)
                }}>
                Amenities
              </a>
            </div>
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/pet_policies/${property.id}`}>
                Pet Policy
              </a>
            </div>
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/media/all?id=${property.id}&source=Property`}>
                All Media
              </a>
            </div>
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/media?id=${property.id}&source=Property`}>
                Property Specific Media
              </a>
            </div>
            <div>
              <a
                className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/trackings/${property.id}/edit`}>
                Tracking Links/Scripts
              </a>
            </div>
            {property.fee ? (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/fees/${property.id}/edit?source=Property`}>
                  Fees
                </a>
              </div>
            ) : (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/fees/new?id=${property.id}&source=Property`}>
                  Fees
                </a>
              </div>
            )}
            {property.payment ? (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/payments/${property.payment.id}/edit`}>
                  Payment details
                </a>
              </div>
            ) : (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/payments/new?id=${property.id}`}>
                  Payment details
                </a>
              </div>
            )}
            {property.account ? (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/accounts/${property.account.id}/edit`}>
                  Payment Account
                </a>
              </div>
            ) : (
              <div>
                <a
                  className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                  href={`/portal/accounts/new?id=${property.id}`}>
                  Payment Account
                </a>
              </div>
            )}
    
            <div>
              {property.concession ? (
                <div>
                  <a
                    className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                    href={`/portal/concessions/${property.id}/edit?source=Property`}>
                    Concessions
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                    href={`/portal/concessions/new?id=${property.id}&source=Property`}>
                    Concessions
                  </a>
                </div>
              )}
              {property.deposit ? (
                <div>
                  <a
                    className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                    href={`/portal/deposits/${property.id}/edit?source=Property`}>
                    Deposit Information
                  </a>
                </div>
              ) : (
                <div>
                  <a
                    className="mb-4 inline-block border-b-2 border-blue-600 text-sm font-semibold text-blue-600"
                    href={`/portal/deposits/new?id=${property.id}&source=Property`}>
                    Deposit Information
                  </a>
                </div>
              )}
            </div>
          </BoxWrapper>
          <SidebarFooter>
            <div className="columns is-gapless">
              <div className={`column ${isUser ? "is-one-fourth" : ""}`}>
                <a
                  onClick={() => {
                    setShowDeleteProperty(true)
                  }}>
                  Delete
                </a>
              </div>
              {!isUser && (
                <div className="column is-one-fourth">
                  <a onClick={() => setShowEditProperty(true)}>Edit</a>
                </div>
              )}
              <div className={`column ${isUser ? "is-one-fourth" : ""}`}>
                {!fetchDataLoading ? (
                  <a
                    onClick={() => {
                      setFetchDataLoading(true)
                      fetchHelloData()
                    }}>
                    Fetch Data
                  </a>
                ) : (
                  <LoadingButton />
                )}
              </div>
              <div className={`column ${isUser ? "is-one-fourth" : ""}`}>
                {!fetchDcaaLoading ? (
                  <a
                    onClick={() => {
                      setFetchDcaaLoading(true)
                      fetchDcaaData()
                    }}>
                    General DCAA
                  </a>
                ) : (
                  <LoadingButton />
                )}
              </div>
            </div>
          </SidebarFooter>
          <Modal
            open={showEditProperty}
            closeModal={() => {
              setShowEditProperty(false)
              refreshProperty()
            }}
            size="small"
            header={
              <div>
                <DesktopH5 content="Edit Property" />
              </div>
            }
            footer={
              <>
                <BrynsonSquareButton
                  type="white"
                  onClick={() => {
                    setShowEditProperty(false)
                  }}
                  label="Cancel"
                />
                <BrynsonSquareButton
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    setLoading(true)
                    editProperty(fetchUpdateProperties(propertyInput)).then((result) => {
                      if (result?.error || result?.data?.editProperty?.errors) {
                        setError(`Error updating property: ${result?.error || result?.data?.editProperty?.errors}`)
                      } else {
                        setError(null)
                        propertyEdited()
                        setShowEditProperty(false)
                        refreshProperty()
                      }
                      setLoading(false)
                    })
                  }}
                  label="Save"
                />
              </>
            }>
            <EditPropertyForm>
              {error && <p className="text-red-600">{error}</p>}
              {columns.map((item) => (
                <div className="row px-4" key={`property-insert-label-${item.accessor}`}>
                  {item.type === "boolean" && (
                    <div className="my-4 w-full">
                      <CheckBoxExtended
                        name={item.Header}
                        checked={property[item.accessor]}
                        handleClick={(value) => {
                          addValue(item, value)
                        }}
                      />
                    </div>
                  )}
                  {item.accessor === "identificationType" && (
                    <div className="my-4 w-full">
                      <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                      <Selectbox
                        id={`property-${item.accessor}-edit`}
                        defaultValue={
                          property[item.accessor]
                            ? { label: property[item.accessor], value: property[item.accessor] }
                            : null
                        }
                        options={identificationTypeOptions}
                        onChange={(value) => {
                          addValue(item, value)
                        }}
                        update={false}
                      />
                    </div>
                  )}
                  {item.accessor === "rentalType" && (
                    <div className="my-4 w-full">
                      <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                      <Selectbox
                        id={`property-${item.accessor}-edit`}
                        defaultValue={
                          property[item.accessor]
                            ? { label: property[item.accessor], value: property[item.accessor] }
                            : null
                        }
                        options={rentalTypeOptions}
                        onChange={(value) => {
                          addValue(item, value)
                        }}
                        update={false}
                      />
                    </div>
                  )}
                  {item.accessor === "state" && (
                    <>
                      <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                      <Selectbox
                        defaultValue={
                          property[item.accessor]
                            ? { label: property[item.accessor]?.abbreviation, value: parseInt(property[item.accessor]?.id) }
                            : null
                        }
                        options={statesData?.states?.map((i) => ({ value: i.id, label: i.abbreviation }))}
                        onChange={(value) => {
                          const property = {}
                          property[`${item.accessor}`] = parseInt(value)
                          setPropertyInput({ ...propertyInput, ...property })
                        }}
                      />
                    </>
                  )}
                  {item.accessor === "helloDataPropertyId" && (
                    <>
                      <label htmlFor="property-name">{item.Header}</label>
                      <div className="relative max-w-lg">
                        <TextInput
                          icon={HiSearch}
                          value={search1}
                          className="lg:max-w-lg"
                          placeholder="Enter an address or building name"
                          onChange={(e) => {
                            setSearch1(e.target.value)
                            setShowResultList(true)
                          }}
                        />
                        <SearchResultList
                          show={Boolean(search1?.trim()) && (isLoading1 || results1?.length) && showResultList}
                          error={error1}
                          results={results1}
                          isLoading={isLoading1}
                          onSelectResult={onSelectSubject}
                        />
                      </div>
                    </>
                  )}
                  {item.type === "decimal" && (
                    <>
                      <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                      <CurrencyInput
                        className="m-0 w-full rounded border border-geyser bg-white px-4 py-3.5 text-navy shadow-none outline-0"
                        name={item.accessor}
                        placeholder={item.Header}
                        defaultValue={property[item.accessor]}
                        decimalsLimit={2}
                        id={`property-${item.accessor}-edit`}
                        onValueChange={(value) => {
                          addValue(item, value)
                        }}
                      />
                    </>
                  )}
                  {item.type !== "boolean" &&
                    item.accessor !== "state" &&
                    item.accessor !== "helloDataPropertyId" &&
                    item.accessor !== "helloDataPropertyName" &&
                    item.accessor !== "structureType" &&
                    item.accessor !== "identificationType" &&
                    item.accessor !== "rentalType" &&
                    item.type !== "decimal" && (
                      <>
                        <label htmlFor="property-name">{item.Header}</label>
                        <InputField
                          id={`property-${item.accessor}-edit`}
                          placeholder={item.Header}
                          defaultValue={property[item.accessor]}
                          onChange={(input) => {
                            const property = {}
                            property[`${item.accessor}`] = input.target.value
                            setPropertyInput({ ...propertyInput, ...property })
                          }}
                        />
                      </>
                    )}
                </div>
              ))}
            </EditPropertyForm>
          </Modal>
          <Modal
            open={showDeleteProperty}
            closeModal={() => {
              setShowDeleteProperty(false)
            }}
            size="small"
            header={
              <div>
                <DesktopH5 content="Delete Property" />
              </div>
            }
            footer={
              <>
                <BrynsonSquareButton
                  type="link"
                  size="medium"
                  onClick={() => {
                    setShowDeleteProperty(false)
                    refreshProperty()
                  }}
                  label="Go Back"
                />
                <BrynsonSquareButton
                  type="warning"
                  size="medium"
                  loading={loading}
                  className={"flex items-center justify-center"}
                  onClick={() => {
                    setLoading(true)
                    deleteProperty({ id: property.id }).then((result) => {
                      if (result?.error || result?.data?.deleteProperty?.errors) {
                        setError(`Error deleting property: ${result?.error || result?.data?.editProperty?.errors}`)
                        setLoading(false)
                      } else {
                        setError(null)
                        propertyDeleted()
                      }
                      setLoading(false)
                      setShowDeleteProperty(false)
                    })
                  }}
                  label="Delete Property"
                />
              </>
            }>
            <DesktopTitle3 content="Are you sure you want to delete this property? This action can't be undone." />
          </Modal>
          <SlideInAlert visible={showAlert} setVisible={setShowAlert}>
            {alertContent}
          </SlideInAlert>
          <PropertyDetailAmenitiesSidebar 
            visible={showAmenitiesSidebar}
            property={property}
            propReload={propReload}
            onClose={() => {
              setShowAmenitiesSidebar(false)
              refreshProperty()
            }}
          />
        </div>: <Loading />
      }
    </div>
  )
}
