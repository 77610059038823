import React from "react"

import AmenitiesTable from "./AmenitiesTable"

export function Amenities({ properties = [] }) {
  const firstBuildingAmenities =
    properties.length > 0 && properties[0].building_amenities
      ? properties[0].building_amenities.slice(0, 7).map((amenity) => amenity.replace(/_/g, " ").toUpperCase())
      : []

  const firstUnitAmenities =
    properties.length > 0 && properties[0].unit_amenities
      ? properties[0].unit_amenities.slice(0, 7).map((amenity) => amenity.replace(/_/g, " ").toUpperCase())
      : []

  const formatBuildingAmenities = (properties, firstBuildingAmenities) => {
    const data = []
    properties.forEach((property) => {
      if (property.building_amenities) {
        const amenities = {
          property: property.building_name,
          ...firstBuildingAmenities.reduce((acc, amenity) => {
            const originalAmenity = amenity.toLowerCase().replace(/ /g, "_")
            acc[amenity] = property.building_amenities.includes(originalAmenity)
            return acc
          }, {})
        }
        data.push(amenities)
      }
    })
    return data
  }

  const formatUnitAmenities = (properties, firstUnitAmenities) => {
    const data = []
    properties.forEach((property) => {
      if (property.unit_amenities) {
        const amenities = {
          property: property.building_name,
          ...firstUnitAmenities.reduce((acc, amenity) => {
            const originalAmenity = amenity.toLowerCase().replace(/ /g, "_")
            acc[amenity] = property.unit_amenities.includes(originalAmenity)
            return acc
          }, {})
        }
        data.push(amenities)
      }
    })
    return data
  }

  const buildingAmenities = formatBuildingAmenities(properties, firstBuildingAmenities)
  const unitAmenities = formatUnitAmenities(properties, firstUnitAmenities)

  return (
    <>
      <AmenitiesTable amenities={buildingAmenities} firstAmenities={firstBuildingAmenities} amenitiesTye="Building" />
      <AmenitiesTable amenities={unitAmenities} firstAmenities={firstUnitAmenities} amenitiesTye="Unit" />
    </>
  )
}
