import React from "react"
const recommendations = require("../../../../../public/samples/airecommendations")

export default function AIRecommendations() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Recommendations from Brynsights</h1>
			<p className="text-lg font-medium">A page to show all the recommendations we've generated with data from the connected PMS, DCAAs, and market data.</p>
			<div className="flex justify-center align-center mt-8">
				<img src={recommendations} />
			</div>
		</div>
	)
}