import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import InputField from "./InputField"
import DesktopTitle1 from "./DesktopH5"
import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import CurrencyInput from 'react-currency-input-field';
import BrynsonDatePicker from "./BrynsonDatePicker"
import { FeesContext } from "../context/FeesContext"
import BreadCrumbs from "./BreadCrumbs"
import Loading from "./Loading"
import moment from "moment-timezone"
import Selectbox from "./Selectbox"

export default function FeeEdit({ params }) {
  const prefix = "$"
  const page = "Fee"
  const source = JSON.parse(params)["source"] || "Property"
  const { propertyData, reloadProperty, unitData, reloadUnit, floorplanData, reloadFloorplan } = useContext(GlobalContext)
  const { updateFee: update } = useContext(FeesContext)
  const [error, setError] = useState()
  const [currentInput, setCurrentInput] = useState()
  const [columns, setColumns] = useState([])
  const [currentData, setCurrentData] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchUpdate = (currentInput) => {
    const currentValues = {}
    currentValues['id'] = currentData?.id
    columns.forEach((item) => {
      if (currentInput[item.accessor]) {
        if (item.type === "integer") {
          currentValues[item.accessor] = parseInt(currentInput[item.accessor])
        } else if(item.type === "decimal") {
          currentValues[item.accessor] = parseFloat(currentInput[item.accessor])
        } else {
          currentValues[item.accessor] = currentInput[item.accessor]
        }
      } else {
        currentValues[item.accessor] = null;
      }
    })

    return currentValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      const source = JSON.parse(params)["source"] || "Property"
      if (source === "Property") reloadProperty(parseInt(id))
      if (source === "Floorplan") reloadFloorplan(parseInt(id))
      if (source === "Unit") reloadUnit(parseInt(id))
    }
  }, [params])

  const addProperty = (data) => {
    const currentValues = {}
    data?.property?.feeColumns.map((item) => {
      currentValues[item.accessor] = data?.property?.fee[item.accessor]
    })
    setCurrentInput(currentValues)
    setCurrentData(data?.property?.fee)
    setColumns(data?.property?.feeColumns)
  }

  const addFloorplan = (data) => {
    const currentValues = {}
    data?.floorplan?.feeColumns.map((item) => {
      currentValues[item.accessor] = data?.floorplan?.fee[item.accessor]
    })
    setCurrentInput(currentValues)
    setCurrentData(data?.floorplan?.fee)
    setColumns(data?.floorplan?.feeColumns)
  }

  const addUnit = (data) => {
    const currentValues = {}
    data?.unit?.feeColumns.map((item) => {
      currentValues[item.accessor] = data?.unit?.fee[item.accessor]
    })
    setCurrentInput(currentValues)
    setCurrentData(data?.unit?.fee)
    setColumns(data?.unit?.feeColumns)
  }

  const addValue = (item, value) => {
    const current = {}
    current[`${item.accessor}`] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  const getLinkFromSource = () => {
    if (source === "Property") {
      return "/portal/listings"
    } else if(source === "Floorplan") {
      return `/portal/floorplans/${floorplanData.floorplan.propertyId}`
    } else if(source === "Unit") {
      return `/portal/units/${unitData.unit.propertyId}`
    }
  }

  const getNameFromSource = () => {
    if (source === "Property") {
      return `Property ${propertyData.property.name || ""}`
    } else if(source === "Floorplan") {
      return `Floorplan ${floorplanData.floorplan.name || ""}`
    } else if(source === "Unit") {
      return `Unit ${unitData.unit.name || ""}`
    }
  }

  useMemo(() => {
    const source = JSON.parse(params)["source"] || "Property"
    if (propertyData && source === "Property") {
      addProperty(propertyData)
    } else if (floorplanData && source === "Floorplan") {
      addFloorplan(floorplanData)
    } else if (unitData && source === "Unit") {
      addUnit(unitData)
    }
    if(propertyData || floorplanData || unitData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: getLinkFromSource(), title: getNameFromSource()},
        { link: null, title: page }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData, floorplanData, unitData])

  const prorateTypeOptions = [
    {value: 'Standard', label: 'Standard'},
    {value: 'Annualized', label: 'Annualized'},
    {value: 'DaysInMonth', label: 'Days in month'},
  ]

  return (
    <>
      { currentData ?
        <div className="p-8">
          {breadCrumbs &&
            <BreadCrumbs items={breadCrumbs} />
          }
          <DesktopTitle1 content={`Edit ${page}`} />
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex row flex-wrap grid grid-cols-2 gap-3">
            {columns.map((item) => (
              <div className="row flex items-center w-full" key={`current-insert-${item.accessor}`}>
                {item.type === "decimal" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="current-name">{item.Header}</label>
                    <CurrencyInput
                      className="m-0 w-full rounded border border-geyser bg-white text-navy shadow-none outline-0 focus:border-teal active:border-teal px-4 py-3.5"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={currentData[item.accessor]}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                }
                {item.type === "boolean" &&
                  <div className="my-4 w-full">
                    <CheckBoxExtended name={item.Header} checked={currentData[item.accessor]} handleClick={(value) => {
                      addValue(item, value)
                    }} />
                  </div>
                }
                {item.type === "date" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="current-date">{item.Header}</label>
                    <BrynsonDatePicker selectedDate={currentData[item.accessor] ? moment(currentData[item.accessor]).toDate() : ""} onChange={(date) => {
                      addValue(item, date)
                    }} />
                  </div>
                }
                {item.accessor === "prorateType" &&
                  <div className="mb-2 w-full">
                    <label htmlFor={`fee-${item.accessor}-edit`}>{item.Header}</label>
                    <Selectbox
                      id={`fee-${item.accessor}-edit`}
                      defaultValue={
                        currentData[item.accessor]
                          ? { label: currentData[item.accessor], value: currentData[item.accessor] }
                          : null
                      }
                      options={prorateTypeOptions}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                    />
                  </div>
                }
                {(item.accessor !== "prorateType" && item.type !== "boolean" && item.type !== "decimal" && item.type !== "date" && item.accessor !== "petType") &&
                  <div className="mb-2 w-full">
                    <label htmlFor="current">{item.Header}</label>
                    <InputField
                      id={`current-${item.accessor}-edit`}
                      placeholder={item.Header}
                      defaultValue={currentData[item.accessor]}
                      type={item.type === "integer" ? "number" : "text" }
                      onChange={(input) => {
                        addValue(item, input.target.value)
                      }}
                    />
                  </div>
                }
              </div>
            ))}
          </div>
          {columns.length > 0 &&
            <div className="flex justify-center items-center w-full">
              <div>
                <BrynsonSquareButton
                  type="primary"
                  testId="add-new-button"
                  loading={currentLoading}
                  onClick={() => {
                    setLoading(true)
                    const updateCurrent = fetchUpdate(currentInput)
                    update(updateCurrent).then((result) => {
                      if (result?.error) {
                        setError(`Error updating: ${result?.error }`)
                      } else {
                        const id = parseInt(JSON.parse(params)["id"])
                        window.location.href=`/portal/fees/${id}/edit?source=${source}`
                      }
                      setTimeout(() => {
                        setLoading(false)
                      }, 1000)
                    })
                  }}
                  label={`Edit ${page}`}
                />
              </div>
            </div>
          }
        </div>
      :
        <Loading />
      }
    </>
  )
}
