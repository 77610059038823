import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_PAYMENT_MUTATION, UPDATE_PAYMENT_MUTATION } from "../queries/PaymentsMutations";

export const PaymentsContext = createContext(null);

const PaymentsProvider = ({ children, ...props }) => {
  const [, createPayment] = useMutation(CREATE_PAYMENT_MUTATION)
  const [, updatePayment] = useMutation(UPDATE_PAYMENT_MUTATION)

  return(
    <PaymentsContext.Provider value={{createPayment, updatePayment}}>
      {children}
    </PaymentsContext.Provider>
  );
}

export default PaymentsProvider;
