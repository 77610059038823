import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import LoaderIcon from "./LoaderIcon"

const Button = styled.a`
  border: none;
  padding: 12px 16px;
  background: #c89a2c;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0.03em;
  height: fit-content;
  border-radius: 4px;

  &:hover {
    cursor: pointer;
    color: #fff;
  }

  &:disabled {
    opacity: 50%;

    &:hover {
      cursor: not-allowed;
    }
  }
  ${({ fullWidth }) => fullWidth && `width: 100%;`}

  ${({ fitContent }) => fitContent && `width: fit-content;`}

  ${({ size }) => size === "slim" && `padding: 10px 37px; font-size: 14px; font-weight: 500; border-radius: 4px;`}

  ${({ size }) =>
    size === "large" && `padding: 24px 48px; font-size: 14px; font-weight: 700; border-radius: 4px; min-width: 110px;`}

  ${({ size }) =>
    size === "medium" && `padding: 16px 24px; font-size: 16px; font-weight: 700; border-radius: 4px; min-width: 110px;`}

  ${({ type }) =>
    type === "primary" &&
    `&:hover:enabled { background: linear-gradient(0deg, rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)), #0D9DA4;}`}

  ${({ type }) => type === "tertiary" && `&:hover:enabled { background-color: #E4EFF6;}`}

  ${({ type }) => type === "white" && `&:hover:enabled { border: 1px solid #889FAD !important;}`}
`
const customStyling = {
  secondary: {},
  tertiary: {
    background: "#F3F8FB",
    color: "#0D9DA4",
    border: "1px solid #F3F8FB"
  },
  link: {
    color: "#0D9DA4",
    background: "transparent",
    border: "1px solid #0D9DA4"
  },
  redLink: {
    color: "#EB5757",
    background: "transparent"
  },
  quaternary: {
    background: "#fff",
    color: "#0B3954",
    border: "1px solid #C8D6DF",
    padding: "8px 24px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px"
  },
  warning: {
    background: "#EB5757",
    color: "#FFF",
    letterSpacing: "3%",
    lineHeight: "16px",
    border: "1px solid #EB5757"
  },
  white: {
    background: "#fff",
    color: "#0B3954",
    border: "1px solid #C8D6DF"
  }
}

const BrynsonSquareLink = ({
  type,
  icon,
  label,
  disabled = false,
  onClick,
  fullWidth = false,
  fitContent = false,
  size = "full",
  loading = false,
  className,
  testId,
  href
}) => {
  if (disabled) {
    onClick = undefined
  }

  return (
    <Button
      href={href}
      style={customStyling[type]}
      onClick={onClick}
      disabled={!!disabled}
      fullWidth={fullWidth}
      fitContent={fitContent}
      size={size}
      data-test-id={testId}
      className={className}>
      {loading ? (
        <LoaderIcon />
      ) : (
        <>
          {icon && <i className={`icon-${icon}`} />} {label}
        </>
      )}
    </Button>
  )
}

BrynsonSquareLink.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "tertiary", "link", "redLink", "quaternary", "warning", "white"]),
  icon: PropTypes.string,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  fullWidth: PropTypes.bool,
  fitContent: PropTypes.bool,
  size: PropTypes.oneOf(["slim", "medium", "large", "full"]),
  loading: PropTypes.bool,
  className: PropTypes.string,
  testId: PropTypes.string
}

export default BrynsonSquareLink
