
export const PAYMENT_QUERY = `
  query Payment($id:ID!){
    payment(id:$id) {
      id
      propertyId
      property {
        id
        name
      }
      remitTo
      checkPayable
      paymentMethod
      columns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_PAYMENT_MUTATION = `
  mutation CreatePaymentMutation($propertyId: Int!, $remitTo: String, $checkPayable: String, $paymentMethod: String) {
    createPaymentMutation(
      propertyId: $propertyId
      remitTo: $remitTo
      checkPayable: $checkPayable
      paymentMethod: $paymentMethod
    ) {
      id
      propertyId
      remitTo
      checkPayable
      paymentMethod
    }
  }
`

export const UPDATE_PAYMENT_MUTATION = `
  mutation UpdatePaymentMutation($id: ID!, $remitTo: String, $checkPayable: String, $paymentMethod: String) {
    updatePaymentMutation(
      id: $id
      remitTo: $remitTo
      checkPayable: $checkPayable
      paymentMethod: $paymentMethod
    ) {
      id
      propertyId
      remitTo
      checkPayable
      paymentMethod
    }
  }
`
