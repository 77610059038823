import moment from "moment-timezone"
import React, { useContext, useEffect, useMemo, useState } from "react"
import CurrencyInput from "react-currency-input-field"

import { GlobalContext } from "../context/GlobalContext"
import { UnitsContext } from "../context/UnitsContext"

import BreadCrumbs from "./BreadCrumbs"
import BrynsonDatePicker from "./BrynsonDatePicker"
import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import DesktopTitle1 from "./DesktopH5"
import InputField from "./InputField"
import Loading from "./Loading"
import Selectbox from "./Selectbox"
import {occupancyStatusOptions, leasedStatusOptions}  from "./enum"

export default function UnitEdit({ params }) {
  const prefix = "$"
  const page = "unit"
  const { unitData, reloadUnit } = useContext(GlobalContext)
  const { updateUnit } = useContext(UnitsContext)
  const [error, setError] = useState()
  const [unitInput, setUnitInput] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchUnitsToCreate = (unitInput) => {
    const unitValues = {}
    unitValues["id"] = JSON.parse(params)["id"]
    if (unitInput["floorplanId"]) unitValues["floorplanId"] = parseInt(unitInput["floorplanId"])
    unitData?.unit?.columns.forEach((item) => {
      if (item.accessor !== "floorplanColumn") {
        if (unitInput[item.accessor]) {
          unitValues[item.accessor] =
            item.type === "decimal" || item.type === "integer"
              ? parseInt(unitInput[item.accessor])
              : unitInput[item.accessor]
        } else {
          unitValues[item.accessor_value || item.accessor] = null
        }
      }
    })

    return unitValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadUnit(parseInt(id))
    }
  }, [params])

  useEffect(() => {
    if (unitData) {
      const unitValues = {}
      unitData?.unit?.columns.map((item) => {
        unitValues[item.accessor] = unitData?.unit[item.accessor]
      })
      setUnitInput(unitValues)
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: "/portal/listings", title: `Property ${unitData?.unit?.property?.name || ""}` },
        { link: `/portal/units/${unitData?.unit?.property?.id}`, title: "Unit" },
        { link: null, title: "Edit" }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [unitData])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  const addValue = (item, value) => {
    const unit = {}
    unit[`${item.accessor}`] = value
    setUnitInput({ ...unitInput, ...unit })
  }

  return (
    <>
      {unitData ? (
        <div className="p-8">
          {breadCrumbs && breadCrumbHeader()}
          <DesktopTitle1 content={`Edit unit ${unitData?.unit["name"] || ""}`} />
          {error && <p className="text-red-600">{error}</p>}
          <div className="row flex grid grid-cols-2 flex-wrap gap-3">
            {unitData?.unit?.columns.map((item) => (
              <div className="row flex w-full items-center" key={`unit-insert-${item.accessor}`}>
                {item.type === "decimal" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <CurrencyInput
                      className="focus:border-teal active:border-teal m-0 w-full rounded border border-geyser bg-white px-4 py-3.5 text-navy shadow-none outline-0"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={unitData?.unit[item.accessor]}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                )}
                {item.accessor === "leasedStatus" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="edit-unit-leased-status">{item.Header}</label>
                    <Selectbox
                      defaultValue={
                        unitData?.unit?.leasedStatus
                          ? {
                              label: leasedStatusOptions.find(x => x.value === unitData?.unit.leasedStatus)?.label,
                              value: unitData?.unit.leasedStatus
                            }
                          : null
                      }
                      options={leasedStatusOptions}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                      id="edit-unit-leased-status"
                    />
                  </div>
                )}
                {item.accessor === "occupancyStatus" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="edit-unit-occupancy-status">{item.Header}</label>
                    <Selectbox
                      defaultValue={
                        unitData?.unit?.occupancyStatus
                          ? {
                              label: occupancyStatusOptions.find(x => x.value === unitData?.unit.occupancyStatus)?.label,
                              value: unitData?.unit.occupancyStatus
                            }
                          : null
                      }
                      options={occupancyStatusOptions}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                      id="edit-unit-occupancy-status"
                    />
                  </div>
                )}
                {item.accessor === "floorplanColumn" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <Selectbox
                      defaultValue={
                        unitData?.unit?.floorplan
                          ? { label: unitData?.unit.floorplan.name, value: unitData?.unit.floorplan.name.id }
                          : null
                      }
                      options={unitData?.unit?.floorplansOptions?.map((i) => ({ value: i.value, label: i.name }))}
                      onChange={(value) => {
                        addValue(
                          {
                            accessor: "floorplanId"
                          },
                          value
                        )
                      }}
                      update={false}
                    />
                  </div>
                )}
                {item.type === "boolean" && (
                  <div className="my-4 w-full">
                    <CheckBoxExtended
                      name={item.Header}
                      checked={unitData?.unit[item.accessor]}
                      handleClick={(value) => {
                        addValue(item, value)
                      }}
                    />
                  </div>
                )}
                {item.type === "date" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <BrynsonDatePicker
                      selectedDate={unitData?.unit[item.accessor] ? moment(unitData?.unit[item.accessor]).toDate() : ""}
                      onChange={(date) => {
                        addValue(item, date)
                      }}
                    />
                  </div>
                )}
                {item.type !== "boolean" &&
                  item.type !== "decimal" &&
                  item.type !== "date" &&
                  item.accessor !== "floorplanColumn" &&
                  item.accessor !== "leasedStatus" &&
                  item.accessor !== "occupancyStatus" && (
                    <div className="mb-2 w-full">
                      <label htmlFor="property-name">{item.Header}</label>
                      <InputField
                        id={`property-${item.accessor}-edit`}
                        placeholder={item.Header}
                        defaultValue={unitData?.unit[item.accessor]}
                        type={item.type === "integer" ? "number" : "text"}
                        onChange={(input) => {
                          addValue(item, input.target.value)
                        }}
                      />
                    </div>
                  )}
              </div>
            ))}
          </div>
          {unitData?.unit?.columns && (
            <div className="flex w-full items-center justify-center">
              <div>
                <BrynsonSquareButton
                  type="primary"
                  testId="add-new-property-button"
                  loading={currentLoading}
                  onClick={() => {
                    setLoading(true)
                    const createUnits = fetchUnitsToCreate(unitInput)
                    updateUnit(createUnits).then((result) => {
                      if (result?.error || result?.data?.createUnits?.errors) {
                        setError(`Error updating property: ${result?.error || result?.data?.createUnits?.errors}`)
                      } else {
                        window.location.href = `/portal/units/${unitData?.unit["propertyId"]}`
                      }
                      setTimeout(() => {
                        setLoading(false)
                      }, 1000)
                    })
                  }}
                  label="Edit unit"
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
