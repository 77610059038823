import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["button", "error", "field", "dr_rev", "first_name", "middle_name", "last_name", "suffixes"]
  static values = {
    allSlugs: Array,
    vanitySlugs: Array,
    currentSlug: String
  }

  connect() {
    this.change()
    this.nameChange()
  }

  change() {
    const newSlug = this.fieldTarget.value.replace(/\s+/g, "-").toLowerCase()
    if (this.vanitySlugsValue.includes(newSlug) || this.allSlugsValue.includes(newSlug)) {
      if (newSlug !== this.currentSlugValue) {
        this.fieldTarget.classList.add("is-danger")
        this.errorTarget.classList.remove("hidden")
        this.buttonTarget.classList.add("hidden")
      } else {
        this.fieldTarget.classList.remove("is-danger")
        this.errorTarget.classList.add("hidden")
        this.buttonTarget.classList.remove("hidden")
      }
    } else {
      this.fieldTarget.classList.remove("is-danger")
      this.errorTarget.classList.add("hidden")
      this.buttonTarget.classList.remove("hidden")
    }
  }

  nameChange() {
    document.getElementById("split-name").innerHTML =
      this.dr_revTarget.value +
      " " +
      this.first_nameTarget.value +
      " " +
      this.middle_nameTarget.value +
      " " +
      this.last_nameTarget.value +
      " " +
      this.suffixesTarget.value
  }
}
