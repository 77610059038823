import { Controller } from "stimulus"

export default class extends Controller {
  toggle = (e) => {
    e.preventDefault()
    const name = this.data.get("name")
    const modal = document.querySelector(`.modal[data-name='${name}']`)
    modal.classList.add("is-active")
  }
}
