export const CREATE_CONCESSION_MUTATION = `
  mutation CreateConcessionMutation($id:ID!, $sourceType:String!, $value: Float, $term: String, $dateStart: ISO8601Date, $dateEnd: ISO8601Date, $descriptionHeader: String, $descriptionBody: String, $descriptionFooter: String) {
    createConcessionMutation(
      id: $id
      sourceType: $sourceType
      value: $value
      term: $term
      dateStart: $dateStart
      dateEnd: $dateEnd
      descriptionHeader: $descriptionHeader
      descriptionBody: $descriptionBody
      descriptionFooter: $descriptionFooter
    ) {
      result
    }
  }
`

export const UPDATE_CONCESSION_MUTATION = `
  mutation UpdateConcessionMutation($id: ID!$value: Float, $term: String, $dateStart: ISO8601Date, $dateEnd: ISO8601Date, $descriptionHeader: String, $descriptionBody: String, $descriptionFooter: String) {
    updateConcessionMutation(
      id: $id
      value: $value
      term: $term
      dateStart: $dateStart
      dateEnd: $dateEnd
      descriptionHeader: $descriptionHeader
      descriptionBody: $descriptionBody
      descriptionFooter: $descriptionFooter
    ) {
      result
    }
  }
`
