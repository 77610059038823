export const IFRAME_INTEGRATION_QUERY = `
  query ($id:ID!){
    iframeIntegration(id: $id) {
      id
      name
      description
      iframeSource
    }
  }
`
