import { XMarkIcon } from "@heroicons/react/24/solid"
import React, { useState, useEffect } from "react"
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { useTable, usePagination, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table"
import styled from "styled-components"

import Selectbox from "./Selectbox"

const MainWrapper = styled.div`
  overflow: hidden;

  .table-header {
    justify-content: flex-start;
    align-items: center;

    .table-actions {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 0.75em;
        text-wrap: nowrap;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .table-actions {
      flex-direction: column;
    }

    .table-actions .export,
    .table-actions .client-upload {
      display: none;
    }

    .table-header {
      display: flex;
      flex-direction: column-reverse;
    }

    .table-header .column {
      width: 100%;
    }

    .table-header .table-actions button {
      width: 100%;
      margin-bottom: 20px;
      margin-left: 0px;
    }
  }

  .pagination {
    overflow: hidden;
    border-top: 1px solid #e4eff6;
    justify-content: center;
    margin-bottom: 0;
    margin-top: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    background-color: #fff;

    li {
      margin-left: 36px;
      margin-right: 36px;
    }
  }
`

const TableWrapper = styled.div`
  background-color: #fff;
  min-height: 300px;
  overflow-x: scroll;

  table {
    width: 100%;
  }

  thead tr th {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: #0b3954;
  }

  thead tr:first-child th {
    padding-top: 0;
    padding-bottom: 32px;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 700;
    font-size: 21px;
    line-height: 32px;
    letter-spacing: -0.02em;
    color: #0b3954;
    margin-bottom: 24px;
    border-bottom: 1px solid #e4eff6;
  }

  thead tr:nth-child(2) th {
    background: var(--color-offwhite);
  }

  table td,
  thead tr th {
    border-bottom: 1px solid #e4eff6;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 10px;
  }

  thead tr th p {
    display: none;
  }

  tr:hover {
    cursor: pointer;

    td {
      background-color: #f0f6fa;
    }
  }

  tr.no-results {
    text-align: center;

    &:hover {
      cursor: initial;
    }

    td {
      background-color: #fff;
    }

    p {
      font-size: 16px;
      color: #55778a;
      font-family: Montserrat;
    }
  }

  th.align-right {
    width: 175px;
    text-align: right;
    padding-right: 16px;
  }

  td.align-right {
    width: 175px;
    text-align: right;
  }

  th.align-center,
  td.align-center {
    text-align: center;
  }

  td.full-width {
    white-space: nowrap;
  }

  @media only screen and (max-width: 768px) {
    overflow-x: scroll;

    table {
      min-width: 1000px;

      thead tr:first-child th {
        padding-bottom: 16px;
      }

      thead tr:first-child th p {
        font-size: 16px;
        font-weight: 300;
        font-family: "Lato", sans-serif;
        display: block;
        color: #363636;
        letter-spacing: normal;
      }
    }
  }
`

const SearchWrapper = styled.div`
  position: relative;
  i {
    &:before {
      color: #55778a;
    }
  }
  input {
    padding-left: 42px;
    color: #55778a;
    &:active,
    &:focus {
      border: 1px solid #0d9da4;
    }
  }
`

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const currency = value => value ? currencyFormat.format(value) : ""
const percent = value => value ? `${parseFloat(value).toFixed(2)}%` : ""

function renderCell(cell) {
  if (cell.column.type === "currency") {
    return <>{currency(cell.value)}</>
  } else if (cell.column.type === "percent") {
    return <>{percent(cell.value)}</>
  } else {
    return <>{cell.render("Cell")}</>
  }
}

function GlobalFilter({ globalFilter, setGlobalFilter, data }) {
  const [value, setValue] = useState(globalFilter)
  const [globalFilterOptions, setGlobalFilterOptions] = useState([])
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined)
  }, 200)

  useEffect(() => {
    if (data) {
      const options = [...data].sort((a, b) => (a.name > b.name ? 1 : -1))?.map((i) => ({ value: i.name, label: i.name }))
      setGlobalFilterOptions(options)
    }
  }, [data])

  return (
    <SearchWrapper>
      <div className="relative">
        <Selectbox
          options={globalFilterOptions}
          defaultValue={value}
          onChange={(value) => {
            setValue(value)
            onChange(value)
          }}
        />
        {value?.length > 1 && (
          <XMarkIcon
            onClick={() => {
              setValue("")
              onChange("")
            }}
            className="absolute right-12 top-4 h-5 w-5 cursor-pointer"
          />
        )}
      </div>
    </SearchWrapper>
  )
}

function Table({ columns, data, header: Header, rowClick, hideFilter = false }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    nextPage,
    previousPage
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 30 }
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  return (
    <MainWrapper>
      <div className="columns table-header">
        {hideFilter ? null : (
          <div className="column is-3">
            <GlobalFilter
              preGlobalFilteredRows={preGlobalFilteredRows}
              globalFilter={state.globalFilter}
              data={data}
              setGlobalFilter={setGlobalFilter}
            />
          </div>
        )}
        <Header />
      </div>
      <TableWrapper>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, i) =>
                  column.hideHeader ? null : (
                    <th
                      className={column.className}
                      {...column.getHeaderProps()}
                      onClick={() => {
                        if (column.canSort) {
                          column.toggleSortBy(!column.isSortedDesc)
                        }
                      }}
                      key={i}>
                      {column.render("Header")}
                      <span className="ml-2 mt-px inline-block">
                        {column.isSorted ? column.isSortedDesc ? <FaChevronDown /> : <FaChevronUp /> : ""}
                      </span>
                      <p className="mobile-helper">Table scrolls left and right</p>
                    </th>
                  )
                )}
              </tr>
            ))}
          </thead>
          {page.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row)
                return (
                  <tr key={row.original.id} onClick={() => rowClick(row.original)}>
                    {row.cells.map((cell) => (
                      <td key={cell.id} className={cell.column.className} {...cell.getCellProps()}>
                        {renderCell(cell)}
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          ) : (
            <tbody>
              <tr className="no-results">
                <td colSpan="100%">
                  <p>No results</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
      </TableWrapper>
      {data.length > 30 && (
        <ul className="pagination">
          <li className="page-item" onClick={() => previousPage()} disabled={!canPreviousPage}>
            <a className="page-link">{"<"} Previous</a>
          </li>

          <li className="page-item" onClick={() => nextPage()} disabled={!canNextPage}>
            <a className="page-link">Next {">"}</a>
          </li>
        </ul>
      )}
    </MainWrapper>
  )
}

export default function BrynsonTable({ data, columns, countTitle, header, hideTitle, rowClick, hideFilter = false }) {
  const headerColumns = [
    {
      Header: typeof countTitle === "function" ? countTitle() : `${data?.length} ${countTitle}`,
      hideHeader: hideTitle,
      columns: columns
    }
  ]

  return (
    <>
      {data && (
        <Table columns={headerColumns} data={data} hideFilter={hideFilter} header={header} rowClick={rowClick} />
      )}
    </>
  )
}
