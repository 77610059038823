import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["preview", "error", "spinner", "input"]

  connect() {}

  openPicker = (event) => {
    event.preventDefault()
    const pickerOptions = JSON.parse(this.data.get("picker-options"))
    const action = this.data.get("action")
    if (action) {
      pickerOptions.exposeOriginalFile = true
      if (
        action === "/profile_setup/photo" ||
        action === "/profile_setup/gallery_images" ||
        action.includes("/profile_setup/service_photos")
      ) {
        pickerOptions.transformations = {
          crop: {
            aspectRatio: 1,
            force: true
          }
        }
        pickerOptions.imageMin = [400, 400]
        pickerOptions.onFileSelected = this.checkImgSize
      }
      pickerOptions.onUploadDone = this.persistData
    } else {
      pickerOptions.onUploadDone = this.setupDocumentUpload
    }
    filestack_client.picker(pickerOptions).open()
  }

  persistData = (data) => {
    this.spinnerTargets.forEach(function (spinner) {
      spinner.style.display = "flex"
    })
    $.ajax({
      url: this.data.get("action"),
      data: {
        handles: data.filesUploaded.map((file) => file.handle),
        id: this.data.get("resource-id")
      },
      method: "post",
      dataType: "json",
      success: (response) => {
        if (response.url) {
          this.previewTarget.querySelector("img").src = response.url
          this.element.classList.remove("without-photo")
          this.element.classList.add("with-photo")
          this.spinnerTarget.style.display = "none"
          this.errorTarget.textContent = ""
          // Update User Avatars in page
          if (response.updateAvatar) {
            if (document.querySelector("body.profiles.media")) {
              document.querySelector('span.image-wrapper picture source[type="image/webp"]').srcset = response.url
            } else if (document.querySelector("body.admin.settings.show")) {
              document.querySelector('div.actions a[data-action="profile"] picture source[type="image/webp"]').srcset =
                response.url
              document.querySelector('header div.image picture source[type="image/webp"]').srcset = response.url
            } else if (document.querySelector("body.settings.show")) {
              document.querySelector('section.heading a.navbar-link picture source[type="image/webp"]').srcset =
                response.url
            } else if (document.querySelector("body.admin.articles.edit")) {
              document.querySelector('div.cover div.image picture source[type="image/webp"]').srcset = response.url
            }
          }
        } else if (response.imagesData) {
          // Uploaded multiple gallery images
          const spinnerPath = this.previewTarget.dataset.spinnerPath
          const addImagesPlaceholder = $(this.previewTarget).children().last()
          response.imagesData.forEach(function (image) {
            $(`
              <div class="image-container">
                <div class="image-preview">
                  <img src="${image.url}">
                </div>
                <div class="delete-overlay">
                  <a data-action="click->filestack#removeGalleryImage" data-remote="true" rel="nofollow" data-method="delete" href="/profile_setup/gallery_images/${image.id}">
                    <i class="icon-close"></i>
                  </a>
                </div>
                <div class="spinner-container" data-filestack-target="spinner" style="display: none;">
                  <img src="${spinnerPath}">
                </div>
              </div>
            `).insertBefore(addImagesPlaceholder)
          })
          this.spinnerTargets.forEach(function (spinner) {
            spinner.style.display = "none"
          })
        } else if (response.videoUrl) {
          const videoElement = this.previewTarget.querySelector("video")
          if (videoElement) {
            videoElement.src = response.videoUrl
          } else {
            $(this.previewTarget).find("img").replaceWith(`<video src="${response.videoUrl}" controls></video>`)
            this.element.classList.remove("without-photo")
            this.element.classList.add("with-photo")
          }
          this.spinnerTarget.style.display = "none"
          this.errorTarget.textContent = ""
        }
      },
      error: (response) => {
        this.errorTarget.textContent = "Error, file was not uploaded."
        this.spinnerTargets.forEach(function (spinner) {
          spinner.style.display = "none"
        })
      }
    })
  }

  setupDocumentUpload = (data) => {
    this.spinnerTarget.style.display = "flex"
    if (this.element.querySelector(".image-container").style.display == "block") {
      // A document had already been uploaded, delete it and replace with new
      this.removeUploadedDocument(null, this.element.querySelector("img").src)
    }
    this.element.querySelector("input").value = data.filesUploaded.map((file) => file.url)
    this.element.querySelector("img").src =
      "https://cdn.filestackcontent.com/output=f:webp,q:80/resize=w:80,h:80,f:crop/" +
      data.filesUploaded.map((file) => file.handle)
    this.element.querySelector(".image-container").style.display = "block"
    setTimeout(() => {
      this.spinnerTarget.style.display = "none"
      this.dispatch("uploaded")
    }, 500)
  }

  removeGalleryImage = (event) => {
    $(event.target).closest(".image-container").remove()
  }

  removeUploadedDocument = (event, filestackUrl) => {
    $.ajax({
      url: "/profile_setup/pro_credentials/remove_upload",
      data: { url: filestackUrl || this.element.querySelector("img").src },
      method: "post",
      dataType: "json",
      success: (response) => {
        if (!filestackUrl) {
          // upload is being deleted, not replaced
          this.element.querySelector(".image-container").style.display = "none"
          this.element.querySelector("input").value = null
        }
      },
      error: (response) => {
        this.errorTarget.textContent = "Document could not be removed."
      }
    })
  }

  checkImgSize = (data) => {
    return new Promise(function (resolve, reject) {
      const minWidth = 400
      const minHeight = 400
      const blob = data.originalFile
      if (!blob) {
        resolve()
      } // ignore this check for Google Drive uploads since it doesn't work and breaks Drive uploads

      // Get an object URL for the local file
      const url = URL.createObjectURL(blob)

      // Create an image and load the object URL
      const img = new Image()
      img.src = url

      img.onload = function () {
        URL.revokeObjectURL(url)
        if (this.naturalWidth < minWidth || this.naturalHeight < minHeight) {
          reject("Image too small. Upload a photo that is at least 400 x 400 pixels in size.")
        }

        // If we made it here then the file was approved
        resolve()
      }
    })
  }
}
