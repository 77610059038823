import { sumBy } from "lodash"

export class Property {
  constructor(data = {}, options = {}) {
    this.data = data
    this.options = options
    this.similarity_score = options.similarity_score || null

    this.id = data.id
    this.street_address = data.street_address
    this.city = data.city
    this.state = data.state
    this.zip_code = data.zip_code
    this.year_built = data.year_built
    this.building_name = data.building_name
    this.number_units = data.number_units
    this.number_stories = data.number_stories
    this.management_company = data.management_company
    this.estimated_occupancy = data.estimated_occupancy
    this.building_quality = data.building_quality
    this.building_website = data.building_website
    this.building_availability = data.building_availability || []

    this.groups_by_bed = Object.groupBy(this.building_availability, ({ bed }) => bed)
    this.bed_options = [0, 1, 2, 3].map((bed) => {
      const group = this.groups_by_bed[bed] || []
      const name = bed === 0 ? "Studio" : `${bed} BR`
      const sqft = group.length ? (sumBy(group, "sqft") / group.length).toFixed(0) : null
      const ask_price = group.length ? (sumBy(group, "price") / group.length).toFixed(0) : null
      const ask_price_psf = group.length && sqft > 0 ? (ask_price / sqft).toFixed(2) : null
      const eff_price = group.length ? (sumBy(group, "effective_price") / group.length).toFixed(0) : null
      const eff_price_psf = group.length && sqft > 0 ? (eff_price / sqft).toFixed(2) : null
      return { bed, name, sqft, ask_price, ask_price_psf, eff_price, eff_price_psf }
    })
  }

  get isSubject() {
    return this.options.isSubject || false
  }

  get similarity_percent() {
    return this.similarity_score == null ? null : `${(this.similarity_score.overall * 100).toFixed(1)} %`
  }

  get number_available() {
    return this.building_availability.filter((item) => !item.exit_market).length
  }

  get leased_percent() {
    return this.estimated_occupancy === null ? null : `${(this.estimated_occupancy * 100).toFixed(1)} %`
  }

  get exposure() {
    return this.number_units == null ? null : this.number_available / this.number_units
  }

  get exposure_percent() {
    return this.exposure == null ? null : `${(this.exposure * 100).toFixed(2)} %`
  }
}
