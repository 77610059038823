import React from "react"

import MediaProvider from "../../context/MediaContext"
import MediaAllIndex from "../../shared/MediaAll"

export default function MediaAll({ params, page, filestack_api_key }) {
  return (
    <MediaProvider>
      {page === "all" && <MediaAllIndex params={params} filestackApiKey={filestack_api_key} />}
    </MediaProvider>
  )
}
