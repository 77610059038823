import React, { useContext, useEffect, useState, useRef } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { TextInput } from "flowbite-react"
import { HiSearch, HiOutlineTrash } from "react-icons/hi"

import CheckBoxExtended from "./CheckboxExtended"
import Sidebar from "./Sidebar"
import styled from "styled-components"

const AmenitiesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  .amenity-item .remove-amenity {
    visibility: hidden;
  }

  .amenity-item:hover .remove-amenity {
    visibility: visible;
  }
`

export default function PropertyDetailAmenitiesSidebar({ property, visible, onClose, propReload }) {
  const [search, setSearch] = useState()
  const [newAmenity, setNewAmenity] = useState('')
  const [showAmenityInput, setShowAmenityInput] = useState(false)
  const [amenities, setAmenities] = useState([])
  const inputRef = useRef(null);
  const { propertyAmenitiesData, currentUserData, createPropertyAmenities, deletePropertyAmenities, createAmenities, deleteAmenities, propertyAmenitiesReload } =
    useContext(GlobalContext)

  const showActions = currentUserData?.currentUser?.email?.endsWith('@brynson.com')


  useEffect(() => {
    setAmenities(propertyAmenitiesData.propertyAmenities)
  }, [propertyAmenitiesData])

  useEffect(() => {
    filterAmenities()
  }, [search])

  useEffect(() => {
    if (showAmenityInput && inputRef.current) {
      inputRef.current.focus();
    }
  }, [showAmenityInput]);

  
  const filterAmenities = () => {
    let amenities = propertyAmenitiesData.propertyAmenities
    if (search) {
      amenities = amenities.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    }
    setAmenities(amenities)
  }

  const checked = (item) => {
    const amenity = property?.amenitiesRecords?.find((i) => parseInt(i.amenityId) === parseInt(item.id))
    return amenity ? true : false
  }

  const createAmenity = async () => {
    if (newAmenity == '')
      return
    
    try {
      const amenityResult = await createAmenities({
        name: newAmenity,
        resourceType: "property",
        amenityType: "Other",
      })

      await createPropertyAmenities({
        propertyId: parseInt(property?.id),
        amenityId: parseInt(amenityResult.data.createAmenityMutation.result)
      })

      await propertyAmenitiesReload()
    } finally {
      setNewAmenity('')
      setShowAmenityInput(false)
    }
  }

  const deleteAmenity = async (id) => {
    await deleteAmenities({
      id: id,
    })
    await propertyAmenitiesReload()
  }

  return (
    <Sidebar
      visible={visible}
      header={() => (
        <h5 className="grow text-center text-lg font-bold">{property?.name ? property?.name : ""} Amenities</h5>
      )}
      backgroundColor="#FFF"
      iconName="close"
      closeSidebar={onClose}
    >
      <TextInput
        icon={HiSearch}
        className="lg:max-w-lg mb-4"
        placeholder="Search"
        onChange={(e) => {
          setSearch(e.target.value)
        }}
      />
      {
        showActions && <div>
          {
            showAmenityInput ?
            <div>
              <input
                className="m-0 mb-4 w-full rounded border border-geyser bg-white px-3 py-2 text-navy shadow-none outline-0"
                placeholder="Add new amenity"
                value={newAmenity}
                ref={inputRef}
                onChange={(e) => {
                  setNewAmenity(e.target.value)
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    createAmenity();
                  }
                  if (e.key === 'ESC') {
                    setShowAmenityInput(false)
                  }
                }}
              />
            </div> :
            <a 
              class="block font-medium text-bblue underline mb-4"
              onClick={() => {
                setShowAmenityInput(true);
              }}
            >
              + Add new amenity
            </a>
          }
        </div>
      }

      <AmenitiesWrapper>
        {amenities.map((item) => (
          <div className="amenity-item flex justify-center items-center p-2 hover:bg-[var(--color-offwhite)]" key={`amenities-${item.id}`}>
            <div className="flex-1">
              <CheckBoxExtended
                name={item.name}
                checked={checked(item)}
                handleClick={(value) => {
                  if (value) {
                    createPropertyAmenities({
                      propertyId: parseInt(property?.id),
                      amenityId: parseInt(item?.id)
                    }).then(() => {
                      propertyAmenitiesReload()
                    })
                  } else {
                    deletePropertyAmenities({
                      propertyId: parseInt(property?.id),
                      id: parseInt(item?.id)
                    }).then(() => {
                      propertyAmenitiesReload()
                    })
                  }
                }}
              />
            </div>
            { showActions && 
              <a 
                className="remove-amenity"
                onClick={() => {
                  deleteAmenity(item?.id)
                }}
              >
                <HiOutlineTrash></HiOutlineTrash>
              </a>
            }
          </div>
        ))}
      </AmenitiesWrapper>
    </Sidebar>
  )
}
