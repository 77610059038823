import React, { useState } from "react"
import DatePicker from "react-datepicker"
import styled from "styled-components"
import "react-datepicker/dist/react-datepicker.css"

const DatePickerWrapper = styled.div`
  input {
    border: 1px solid #c8d6df;
    background: white;
    padding: 8px 12px;
    width: 100%;
    margin: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0.25rem;
    height: auto;
    font-size: 16px;
  }

  input:hover,
  input:focus {
    box-shadow: 0 0 0 1px #0d9da4;
  }

  input[id*="react-select"]:hover,
  input[id*="react-select"]:focus {
    box-shadow: none;
  }

  input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;

    .react-datepicker__current-month {
      font-family: "Montserrat";
      font-size: 14px;
      font-weight: 400;
      color: #0b3954;
    }

    .react-datepicker__day-name {
      font-family: Lato;
      color: #55778a;
      font-size: 10px;
    }
  }

  .react-datepicker__day {
    border-radius: 100px;
    color: #0b3954;

    &.past {
      color: #c8d6df;
    }
  }

  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    background-color: #c89a2c !important;
    color: #fff !important;
    font-weight: 500 !important;
  }

  .react-datepicker__day--today {
    color: #0b3954 !important;
    font-weight: 700;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-width: 1px 1px 0 0;
    top: 10px;
  }

  .react-datepicker__triangle {
    display: none;
  }

  .react-datepicker-popper[data-placement^="bottom"] {
    padding-top: 4px;
  }
`

export default function BrynsonDatePicker({ selectedDate, onChange, maxDate = new Date() }) {
  const [selected, setSelected] = useState(selectedDate)
  const today = new Date()
  return (
    <DatePickerWrapper>
      <DatePicker
        selected={selected}
        onChange={(date) => {
          setSelected(date)
          onChange(date)
        }}
        formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 1)}
      />
    </DatePickerWrapper>
  )
}
