import React, {useContext, useState, useEffect} from "react"
import BrynsonTable from "../../shared/BrynsonTable"
import { GlobalContext } from "../../context/GlobalContext"
import Loading from "../../shared/Loading"
import styled from "styled-components"
import SelectField from "../../shared/SelectField"
const geofencing = require("../../../../../public/samples/geofencing.png")

const TableWrapper = styled.div`
	width: 100%;
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

const currencyFormat = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

const currency = value => value ? currencyFormat.format(value) : ""
const percent = value => value ? `${parseFloat(0).toFixed(2)}%` : ""

export default function Geofencing() {
	const {
		geofencingDetailsData: detailsData,
		geofencingCampaignsData: campaignsData,
		geofencingTvCampaignsData: campaignsTvData,
		geofencingPerformanceData: performanceData,
		geofencingGeofencePerformacesData: geofencePerformacesData,
		geofencingGeofenceAdPerformacesData: geofenceAdPerformacesData
 	} = useContext(GlobalContext)

	const [detailsOptions, setDetailsOptions] = useState([])
	const [selected, setSelected] = useState(null)

	const [performance, setPerformance] = useState()
	useEffect(() => {
		if (performanceData) {
			const data = selected ? performanceData.geofencingPerformance.filter(x => x.geofencingDetailsId == selected.value)[0] : performanceData.geofencingPerformance[0]
			setPerformance(data)
		}
	}, [performanceData, selected])

	const [campaigns, setCampaigns] = useState()
	useEffect(() => {
		if (campaignsData) {
			const data = selected ? campaignsData.geofencingCampaigns.filter(x => x.geofencingDetailsId == selected.value) : campaignsData.geofencingCampaigns
			setCampaigns(data)
		}
	}, [campaignsData, selected])

	const [tvCampaigns, setTvCampaigns] = useState()
	useEffect(() => {
		if (campaignsTvData) {
			const data = selected ? campaignsTvData.geofencingTvCampaigns.filter(x => x.geofencingDetailsId == selected.value) : campaignsTvData.geofencingTvCampaigns
			setTvCampaigns(data)
		}
	}, [campaignsTvData, selected])

	const [geofencePerformaces, setGeofencePerformaces] = useState()
	useEffect(() => {
		if (campaignsTvData) {
			const data = selected ? geofencePerformacesData.geofencingGeofencePerformances.filter(x => x.geofencingDetailsId == selected.value) : geofencePerformacesData.geofencingGeofencePerformances
			setGeofencePerformaces(data)
		}
	}, [geofencePerformacesData, selected])

	const [geofenceAdPerformaces, setGeofenceAdPerformaces] = useState()
	useEffect(() => {
		if (campaignsTvData) {
			const data = selected ? geofenceAdPerformacesData.geofencingGeofenceAdPerformances.filter(x => x.geofencingDetailsId == selected.value) : geofenceAdPerformacesData.geofencingGeofenceAdPerformances
			setGeofencePerformaces(data)
		}
	}, [geofenceAdPerformacesData, selected])

	useEffect(() => {
		if (detailsData) {
			const options = detailsData.geofencingDetails.map(item => ({
				value: item.id,
				label: item.importDate
			}));
			setDetailsOptions(options);
			setSelected(options[0])
		}
	}, [detailsData]);

	// useEffect(() => {
	// 	if (selected) {
	// 		const options = detailsOptions.filter(x => x.value === selected.value)
	// 		setDetailsOptions(options);
	// 	}
	// }, [selected]);

	const tableHeader = () => (
    <div></div>
  )


	return (
		<div className="w-full p-8">
			<>
				{ detailsData && campaignsData && campaignsTvData && performanceData && performanceData?.geofencingPerformance?.length > 0 && geofencePerformacesData && geofenceAdPerformacesData ? (
					<div>
						<h1 className="font-semibold text-xl">Geofencing</h1>
						<p className="text-lg font-medium">Integrate with your geofencing advertising provider</p>
						<div className="w-64 my-4 ">
							<SelectField
								defaultValue={selected}
								placeholder='Date'
								options={detailsOptions}
								onChange={(value) => {
									setSelected(detailsOptions.find(x => x.value === value))
								}}
								id="property-directory-historic-property-directory"
							/>
						</div>
						<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
							<h2 className="text-md font-semibold mb-4">Geofencing Overall Performance</h2>
							<div className="flex justify-center space-x-4">
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ performance?.impressions	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">Impressions</p>
								</div>
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ performance?.views	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">Commercial Views</p>
								</div>
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ currency(performance?.cpm)	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">CPM</p>
								</div>
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ performance?.clicks	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">Clicks</p>
								</div>
								{/*<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ percent(performance?.crt)	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">CTR</p>
								</div>*/}
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ currency(performance?.cpc)	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">CPC</p>
								</div>
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ currency(performance?.total)	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">Total Cost</p>
								</div>
								<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ performance?.walkIns	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">Walk Ins</p>
								</div>
								{/*<div className="flex flex-col items-center">
									<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
										{ currency(performance?.costPerWalkIn)	}
									</div>
									<p className="mt-2 text-gray-700 text-sm">Cost Per Walk In</p>
								</div>*/}
							</div>
						</div>
						{/* <div className="flex w-full">
							<div className="w-1/4">
								<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
									<h2 className="text-md font-semibold mb-4">Unique Walk Ins</h2>
									<div className="flex flex-col items-center">
										<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
											0
										</div>
										<p className="mt-2 text-gray-700 text-sm">Averange # of Days Walk In</p>
									</div>
								</div>
							</div>

							<div className="w-1/4 mx-4">
								<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
									<h2 className="text-md font-semibold mb-4">Perfomance Trend</h2>
									<div className="flex flex-col items-center">
										<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
											0
										</div>
										<p className="mt-2 text-gray-700 text-sm">Unique Walk Ins</p>
									</div>
								</div>
							</div>

							<div className="w-1/2">
								<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
									<h2 className="text-md font-semibold mb-4">Unique Walk Ins</h2>
									<div className="flex flex-col items-center">
										<div className="bg-green-500 text-white font-bold rounded-full h-24 w-24 flex items-center justify-center">
											0
										</div>
										<p className="mt-2 text-gray-700 text-sm">Unique Walk Ins</p>
									</div>
								</div>
							</div>
						</div> */}
						<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
							<h2 className="text-md font-semibold mb-4">Geofencing Display Campaign Performance</h2>
							<TableWrapper>
								<BrynsonTable
									hideTitle={true}
									data={campaigns}
									columns={[
										{
											accessor: "name",
											Header: "Name",
											type: "text",
											className: "font-medium"
										},
										{
											accessor: "impressions",
											Header: "Impressions",
											type: "text",
										},
										{
											accessor: "cpm",
											Header: "CPM",
											type: "currency",
										},
										{
											accessor: "clicks",
											Header: "Clicks",
											type: "text",
										},
										{
											accessor: "ctr",
											Header: "CTR",
											type: "percent",
										},
										{
											accessor: "cpc",
											Header: "CPC",
											type: "currency",
										},
										{
											accessor: "totalCost",
											Header: "Total Cost",
											type: "currency",
										},
										{
											accessor: "walkIns",
											Header: "Walk Ins",
											type: "text",
										},
										{
											accessor: "costPerWalkIn",
											Header: "Cost Per Walk In",
											type: "text",
										},
									]}
									countTitle={campaigns.length > 1 ? "total campaigns" : "campaign"}
									header={tableHeader}
									rowClick={() => {}}
								/>
							</TableWrapper>
						</div>
						<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
							<h2 className="text-md font-semibold mb-4">Streaming TV Campaign Performance</h2>
							<TableWrapper>
								<BrynsonTable
									hideTitle={true}
									data={tvCampaigns}
									columns={[
										{
											accessor: "name",
											Header: "Campaign",
											type: "text",
											className: "font-medium"
										},
										{
											accessor: "views",
											Header: "Views",
											type: "text",
										},
										{
											accessor: "cpm",
											Header: "CPM",
											type: "currency",
										},
										{
											accessor: "completeRate",
											Header: "Complete Rate",
											type: "percent",
										},
										{
											accessor: "muteRate",
											Header: "Mute Rate",
											type: "percent",
										},
										{
											accessor: "pauseRate",
											Header: "Pause Rate",
											type: "percent",
										},
										{
											accessor: "inStreamPreRoll",
											Header: "In Stream Pre Roll",
											type: "text",
										},
										{
											accessor: "inStreamMidRoll",
											Header: "In Stream Mid Roll",
											type: "text",
										},
										{
											accessor: "totalCost",
											Header: "Total Cost",
											type: "currency",
										},
										{
											accessor: "walkIns",
											Header: "Walk Ins",
											type: "text",
										},
										{
											accessor: "costPerWalkIn",
											Header: "Cost Per Walk In",
											type: "text",
										},
									]}
									header={tableHeader}
									rowClick={() => {}}
								/>
							</TableWrapper>
						</div>
						<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
							<h2 className="text-md font-semibold mb-4">Geofence Performance</h2>
							<TableWrapper>
								<BrynsonTable
									hideTitle={true}
									data={geofencePerformaces}
									columns={[
										{
											accessor: "name",
											Header: "Geo Fence",
											type: "text",
											className: "font-medium"
										},
										{
											accessor: "campaign",
											Header: "Campaign",
											type: "text",
										},
										{
											accessor: "impressions",
											Header: "Impressions",
											type: "text",
										},
										{
											accessor: "clicks",
											Header: "Clicks",
											type: "text",
										},
										{
											accessor: "totalCost",
											Header: "Total Cost",
											type: "currency",
										},
										{
											accessor: "cpc",
											Header: "CPC",
											type: "currency",
										},
										{
											accessor: "viewthroughWalkIns",
											Header: "Viewthrough Walk Ins",
											type: "text",
										},
										{
											accessor: "clickthroughWalkIns",
											Header: "Clickthrough Walk Ins",
											type: "text",
										},
										{
											accessor: "walkIns",
											Header: "Walk Ins",
											type: "text",
										},
									]}
									header={tableHeader}
									rowClick={() => {}}
								/>
							</TableWrapper>
						</div>
						<div className="w-full p-6 bg-white rounded-lg shadow-lg my-4">
							<h2 className="text-md font-semibold mb-4">Geofence Ad Performance</h2>
							<TableWrapper>
								<BrynsonTable
									hideTitle={true}
									data={geofenceAdPerformaces}
									columns={[
										{
											accessor: "name",
											Header: "Ad",
											type: "text",
											className: "font-medium"
										},
										{
											accessor: "impressions",
											Header: "Impressions",
											type: "text",
										},
										{
											accessor: "clicks",
											Header: "Clicks",
											type: "text",
										},
										{
											accessor: "ctr",
											Header: "CTR",
											type: "percent",
										},
										{
											accessor: "walkIns",
											Header: "Walk Ins",
											type: "text",
										},
										{
											accessor: "preview",
											Header: "Preview",
											type: "text",
										},
									]}
									header={tableHeader}
									rowClick={() => {}}
								/>
							</TableWrapper>
						</div>
					</div>

				) : (
					<div className="p-8">
						<h1 className="font-semibold text-xl">Geofencing</h1>
						<p className="text-lg font-medium">Integrate with your geofencing advertising provider</p>
						<div className="flex justify-center align-center mt-8">
							<img src={geofencing} />
						</div>
					</div>
				)}
			</>
		</div>
	)
}