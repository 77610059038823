import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_AVAILABILITY_MUTATION, UPDATE_AVAILABILITY_MUTATION } from "../queries/AvailabilitiesMutations";

export const AvailabilitiesContext = createContext(null);

const AvailabilityProvider = ({ children, ...props }) => {
  const [, createAvailability] = useMutation(CREATE_AVAILABILITY_MUTATION)
  const [, updateAvailability] = useMutation(UPDATE_AVAILABILITY_MUTATION)

  return(
    <AvailabilitiesContext.Provider value={{createAvailability, updateAvailability}}>
      {children}
    </AvailabilitiesContext.Provider>
  );
}

export default AvailabilityProvider;
