import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import InputField from "./InputField"
import DesktopTitle1 from "./DesktopH5"
import BrynsonSquareButton from "./BrynsonSquareButton"
import BrynsonDatePicker from "./BrynsonDatePicker"
import CheckBoxExtended from "./CheckboxExtended"
import CurrencyInput from 'react-currency-input-field';
import { PetPoliciesContext } from "../context/PetPoliciesContext"
import Selectbox from "./Selectbox"
import Loading from "./Loading"
import BreadCrumbs from "./BreadCrumbs"

export default function PetPolicyNew({ params }) {
  const prefix = "$"
  const page = "pet policy"
  const { propertyData, reloadProperty } = useContext(GlobalContext)
  const { createPetPolicy } = useContext(PetPoliciesContext)
  const [error, setError] = useState()
  const [petPolicyInput, setPetPolicyInput] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchCreatePetPolicy = (petPolicyInput) => {
    const petPolicyValues = {}
    petPolicyValues['propertyId'] = parseInt(JSON.parse(params)["id"]);
    propertyData?.property?.petPolicyColumns.forEach((item) => {
      if (petPolicyInput[item.accessor]) {
        petPolicyValues[item.accessor] = item.type === "decimal" || item.type === "integer" ? parseInt(petPolicyInput[item.accessor]) : petPolicyInput[item.accessor]
      } else {
        petPolicyValues[item.accessor_value || item.accessor] = null;
      }
    })

    return petPolicyValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadProperty(parseInt(id))
    }
  }, [params])

  useMemo(() => {
    if (propertyData?.property?.petPolicyColumns) {
      const petPolicyValues = {}
      propertyData?.property?.petPolicyColumns.map((item) => {
        petPolicyValues[item.accessor] = null
      })
      setPetPolicyInput(petPolicyValues)
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: "/portal/listings", title: `Property ${propertyData?.property?.name || ""}`},
        { link: `/portal/pet_policies/${propertyData?.property?.id}`, title: "Pet Policies"},
        { link: null, title: "New" }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData?.property?.petPolicyColumns])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  const addValue = (item, value) => {
    const petPolicy = {}
    petPolicy[`${item.accessor}`] = value
    setPetPolicyInput({ ...petPolicyInput, ...petPolicy })
  }

  const sizeOptions = ['Huge', 'Large', 'Medium', 'Small', 'Tiny'].map((value) => ({
    value: value,
    label: value
  }))

  return (
    <>
      { propertyData ?
        <div className="p-8">
          { breadCrumbs &&
            breadCrumbHeader()
          }
          <DesktopTitle1 content="Add new pet policy" />
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex row flex-wrap grid grid-cols-2 gap-3">
            {propertyData?.property?.petPolicyColumns.map((item) => (
              <div className="row flex items-center w-full" key={`pet-policy-insert-${item.accessor}`}>
                {item.accessor === "petType" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <Selectbox
                      defaultValue={null}
                      options={propertyData?.property?.petPolicyTypes?.map((i) => ({ value: i.value, label: i.name }))}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                    />
                  </div>
                }

                {item.type === "decimal" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <CurrencyInput
                      className="m-0 w-full rounded border border-geyser bg-white text-navy shadow-none outline-0 focus:border-teal active:border-teal px-4 py-3.5"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={""}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                }
                {item.type === "boolean" &&
                  <div className="my-4 w-full">
                    <CheckBoxExtended name={item.Header} checked={false} handleClick={(value) => {
                      addValue(item, value)
                    }} />
                  </div>
                }
                {item.type === "date" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <BrynsonDatePicker selectedDate={""} onChange={(date) => {
                      addValue(item, date)
                    }} />
                  </div>
                }
                {item.accessor === "size" &&
                  <div className="mb-2 w-full">
                    <label htmlFor={`pet-policy-${item.accessor}-new`}>{item.Header}</label>
                    <Selectbox
                      id={`pet-policy-${item.accessor}-new`}
                      options={sizeOptions}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                    />
                  </div>
                }
                {(item.type !== "boolean" && item.type !== "decimal" && item.type !== "date" && item.accessor !== "petType") &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <InputField
                      id={`property-${item.accessor}-edit`}
                      placeholder={item.Header}
                      defaultValue={""}
                      type={item.type === "integer" ? "number" : "text" }
                      onChange={(input) => {
                        addValue(item, input.target.value)
                      }}
                    />
                  </div>
                }
              </div>
            ))}
          </div>
          <div className="flex justify-center items-center w-full m-3">
            {propertyData?.property?.petPolicyColumns && 
              <BrynsonSquareButton
                type="primary"
                testId="add-new-property-button"
                loading={currentLoading}
                onClick={() => {
                  setLoading(true)
                  
                  const createPetPolicies = fetchCreatePetPolicy(petPolicyInput)

                  createPetPolicy(createPetPolicies).then((result) => {
                    if (result?.error || result?.data?.createPetPolicy?.errors) {
                      setError(`Error updating pet policies: ${result?.error || result?.data?.createPetPolicy?.errors}`)
                    } else {
                      window.location.href=`/portal/pet_policies/${JSON.parse(params)["id"]}`
                    }
                    setTimeout(() => {
                      setLoading(false)  
                    }, 1000)                  
                  })
                }}
                label="Add new pet policy"
              />
            }
          </div>
        </div>
      :
        <Loading />
      }
    </>
  )
}
