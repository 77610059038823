import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["input", "button"]

  connect() {
    this.updateStatus()
  }

  updateStatus = () => {
    const inputs = $(this.inputTargets)
    const button = $(this.buttonTarget)

    const withValues = inputs.filter(function () {
      return $.trim($(this).val()).length
    })

    const otherField = document.getElementById("quiz_answer_other")

    if (inputs.length == withValues.length || (otherField && otherField.value.trim() != "")) {
      button.attr("disabled", false)
    } else {
      button.attr("disabled", true)
    }
  }
}
