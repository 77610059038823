import React from "react"
import ILSRatesTable from "./ILSRatesTable"

export default function ILSRatesTab({rate = null}) {

  return (
    <>
      <div className="bg-white p-4 shadow">
        <ILSRatesTable entries={rate?.entries || []}></ILSRatesTable>
      </div>
    </>
  )
}

