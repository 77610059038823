import React from 'react';
import { ExportToCsv } from 'export-to-csv';

export const exportCSV = (data, filenamePrefix) => {
  if (!data || data.length === 0) {
    console.error("No data available to export.");
    return;
  }

  const columnKeys = Object.keys(data[0]);
  const firstColumnName = columnKeys.length > 0 ? columnKeys[0] : "";
  const secondColumnName = columnKeys.length > 1 ? columnKeys[1] : "";

  const filename = `${filenamePrefix} - ${firstColumnName} ${secondColumnName}`;

  const csvExporter = new ExportToCsv({
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: true,
    title: 'Data Export',
    filename: filename,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  });

  csvExporter.generateCsv(data);
};

export const transformDataForChart = (data, chartType) => {
  try {
    switch (chartType) {
      case 'bar':
      case 'line':
        return data.map(entry => ({
          index: entry[Object.keys(entry)[0]],
          ...Object.keys(entry).slice(1).reduce((acc, key) => ({
            ...acc,
            [key]: parseFloat(entry[key]) || 0
          }), {})
        }));
      case 'pie':
        return data.map(entry => ({
          id: entry[Object.keys(entry)[0]],
          value: parseFloat(entry[Object.keys(entry)[1]]) || 0,
        }));
      case 'radar':
        const keys = Object.keys(data[0]).slice(1);
        return keys.map(key => ({
          id: key,
          data: data.map(d => ({
            axis: d[Object.keys(d)[0]],
            value: parseFloat(d[key]) || 0,
          }))
        }));
      default:
        return data;
    }
  } catch (error) {
    console.error("Error transforming data for chart:", error);
    return null;
  }
};
