import React, { useContext } from "react"

import { GlobalContext } from "../../context/GlobalContext"
import BrynsonTabs from "../../shared/BrynsonTabs"

import HistoryQuarterlyAudits from "./HistoryQuarterlyAudits"
import LatestQuarterlyAudits from "./LatestQuarterlyAudits"
import QuarterlyDetailsAudits from "./QuarterlyDetailsAudits"

export default function QuarterlyAudits() {
  const { currentUserData } = useContext(GlobalContext)
  const about = currentUserData?.currentUser?.currentCompany?.about

  return (
    <>
    <div className="p-8">
      <BrynsonTabs
        tabs={[
          {
            label: "Latest Quarterly Audits",
            icon: null
          },
          {
            label: "Details",
            icon: null
          },
          {
            label: "Audit History",
            icon: null
          }
        ]}
        className="">
        <div className="flex h-[80dvh] flex-col">
          <LatestQuarterlyAudits />
        </div>
        <div className="flex h-[80dvh] flex-col">
          <QuarterlyDetailsAudits />
        </div>
        <div className="flex h-[80dvh] flex-col">
          <HistoryQuarterlyAudits />
        </div>
      </BrynsonTabs>
    </div>
    <div className="h-full bg-blight block">
    </div>

    </>
  )
}
