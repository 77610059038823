import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_INFORMATION_MUTATION, UPDATE_INFORMATION_MUTATION } from "../queries/InformationsMutations";

export const InformationsContext = createContext(null);

const InformationProvider = ({ children, ...props }) => {
  const [, createInformation] = useMutation(CREATE_INFORMATION_MUTATION)
  const [, updateInformation] = useMutation(UPDATE_INFORMATION_MUTATION)

  return(
    <InformationsContext.Provider value={{createInformation, updateInformation}}>
      {children}
    </InformationsContext.Provider>
  );
}

export default InformationProvider;
