export const PROPERTY_SEO_DATA_QUERY = `
  query propertySeoData($id:ID!){
    propertySeoData(id:$id) {
      id
      name
      semrushData {
        id
        keyword
        data
      }
    }
  }
`

const MediaFields = `#graphql
  fragment MediaFields on Medium {
    id
    mediumType
    mediumableType
    mediumableId
    matterportUrl
    importedUrl
    medium
    feedDisabled
    name
    caption
    rank
  }
`

export const PROPERTY_QUERY = `#graphql
  ${MediaFields}

  query Property($id:ID!){
    property(id:$id) {
      id
      name
      latitude
      longitude
      identificationType
      rentalType
      dayLightSaving
      concession {
        id
        value
        term
        dateStart
        dateEnd
        descriptionHeader
        descriptionBody
        descriptionFooter
      }
      floorplansOptions {
        value
        name
      }
      concessionColumns {
        accessor
        Header
        type
      }
      images {
        id
        image
        caption
        rank
      }
      media {
        ...MediaFields
      }
      fee {
        id
        prorateType
        lateType
        latePercent
        lateMinFee
        lateFeePerDay
        nonRefundableHoldFee
        adminFee
        applicationFee
        brokerFee
      }
      feeColumns {
        accessor
        Header
        type
      }
      deposit {
        id
        depositType
        amountType
        valueRange
        description
        percentRefundable
        portionRefundable
      }
      depositColumns {
        accessor
        Header
        type
      }
      floorplanColumns {
        accessor
        Header
        type
      }
      unitColumns {
        accessor
        Header
        type
      }
      petPolicyColumns {
        accessor
        Header
        type
      }
      propertyDirectoryColumns {
        accessor
        Header
        type
      }
      petPolicyTypes {
        name
        value
      }
    	amenitiesRecords {
        id
        amenityId
      }
      petPolicies {
        id
        propertyId
        petType
        count
        weight
        weightLimitNotes
        monthRentMin
        monthRentMax
        oneTimeFee
        size
        aggressiveAllowed
        largeAllowed
        description
        comment
        depositMin
        depositMax
        feeMin
        feeMax
        restrictions
        petCare
        otherTerms
      }
      floorplans {
        id
        name
        beds
        baths
        sqFtMin
        rentMin
        rentMax
        displayActive
        maxNumberMarket
        updatedAt
        media {
          ...MediaFields
        }
        units {
          id
          number
          name
        }
        concession {
          id
        }
        fee {
          id
        }
        deposit {
          id
        }
        amenities {
          id
          name
          rank
        }
        amenitiesRecords {
          id
          amenityId
        }
      }
      informationColumns {
        accessor
        Header
        type
      }
      accountColumns {
        accessor
        Header
        type
      }
      paymentColumns {
        accessor
        Header
        type
      }
      information {
        id
        propertyId
        structureType
        unitCount
        numberOfAcres
        yearBuilt
        yearRemodeled
        acquisitionDate
        propertyAvailabilityUrl
        shortDescription
        longDescription
      }
      units {
        id
        name
        number
        rent
        leaseTerm
        sqFt
        media {
          ...MediaFields
        }
        isAvailable
        isComingSoon
        isReady
        isDisplayed
        isRemoved
        concession {
          id
        }
        availability {
          id
        }
        amenities {
          id
          name
          rank
        }
        amenitiesRecords {
          id
          amenityId
        }
        floorplan {
          id
          name
          rentMin
        }
      }
    }
  }
`

export const PROPERTIES_QUERY = `
  query {
    properties {
      id
      name
      address
      city
      latitude
      longitude
      identificationType
      rentalType
      dayLightSaving
      state {
        id
        abbreviation
        name
      }
      feeds {
        id
        name
        enabled
        names
      }
    	amenitiesRecords {
        id
        amenityId
      }
      concession {
        id
      }
      fee {
        id
      }
      deposit {
        id
      }
      information {
        id
      }
      account {
        id
      }
      payment {
        id
      },
      units{
        id
      },
      fullAddressWithFallback
      zip
      unitCount
      pmsUsed
      crmUsed
      helloDataPropertyId
      helloDataPropertyName
      floorPlanCount
      promotional
    }
  }
`

export const CREATE_PROPERTY_MUTATION = `
  mutation CreatePropertyMutation($companyId: ID!, $address: String, $name: String, $city: String,
                                  $stateId: Int, $zip: String, $unitCount: Int, $pmsUsed: String,
                                  $crmUsed: String, $promotional: String,
                                  $identificationType: String, $rentalType: String,
                                  $dayLightSaving: Boolean,
                                  $latitude: Float, $longitude: Float, $helloDataPropertyId: String, $helloDataPropertyName: String) {
    createPropertyMutation(companyId: $companyId, address: $address, name: $name, city: $city,
                           stateId: $stateId, zip: $zip, unitCount: $unitCount, pmsUsed: $pmsUsed, crmUsed: $crmUsed,
                           promotional: $promotional,
                           identificationType: $identificationType,
                           rentalType: $rentalType,
                           dayLightSaving: $dayLightSaving,
                           latitude: $latitude,
                          longitude: $longitude,
                          helloDataPropertyId: $helloDataPropertyId,
                          helloDataPropertyName: $helloDataPropertyName,
                        ) {
      id
      name
      address
      city
      identificationType
      rentalType
      dayLightSaving
      latitude
      longitude
      state {
        id
        abbreviation
        name
      }
      zip
      unitCount
      pmsUsed
      crmUsed
      promotional
      helloDataPropertyId
      helloDataPropertyName
    }
  }
`

export const EDIT_PROPERTY_MUTATION = `
  mutation UpdatePropertyMutation($id: ID!, $companyId: ID!, $address: String, $name: String, $city: String,
                        $stateId: Int, $zip: String, $unitCount: Int, $pmsUsed: String,
                        $crmUsed: String, $promotional: String,
                        $identificationType: String, $rentalType: String,
                        $dayLightSaving: Boolean,
                        $latitude: Float,
                        $longitude: Float,
                        $helloDataPropertyId: String,
                        $helloDataPropertyName: String,
                        ) {
    updatePropertyMutation(id: $id, companyId: $companyId, address: $address, name: $name, city: $city,
                 stateId: $stateId, zip: $zip, unitCount: $unitCount, pmsUsed: $pmsUsed, crmUsed: $crmUsed, promotional: $promotional,
                 identificationType: $identificationType,
                 rentalType: $rentalType,
                 dayLightSaving: $dayLightSaving,
                 latitude: $latitude, longitude: $longitude, helloDataPropertyId: $helloDataPropertyId, helloDataPropertyName: $helloDataPropertyName,) {
      id
      name
      address
      city
      identificationType
      helloDataPropertyId
      helloDataPropertyName
      rentalType
      dayLightSaving
      latitude
      longitude
      state {
        id
        abbreviation
        name
      }
      zip
      unitCount
      pmsUsed
      crmUsed
      promotional
    }
  }
`

export const DELETE_PROPERTY_MUTATION = `
  mutation DeletePropertyMutation($id: ID!) {
    deletePropertyMutation(id: $id) {
      result
      errors
    }
  }
`

export const UPLOAD_PROPERTIES_MUTATION = `
  mutation UploadPropertyMutation($properties: [PropertyUpload!]!) {
    uploadPropertyMutation (properties: $properties) {
      result
    }
  }
`
