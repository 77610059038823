import React, { useEffect } from "react"
import PropertiesDirectoryProvider from "../../context/PropertiesDirectoryContext"
import PropertyDirectoryNew from "../../shared/PropertyDirectoryNew"
import PropertyDirectoryEdit from "../../shared/PropertyDirectoryEdit"
import PaymentEdit from "../../shared/PaymentEdit"

export default function PropertyDirectoryInfo({ params, page }) {
  return (
    <PropertiesDirectoryProvider>
      {page === "new" &&
        <PropertyDirectoryNew params={params} />
      }
      {page === "edit" &&
        <PropertyDirectoryEdit params={params} />
      }
    </PropertiesDirectoryProvider>
  )
}
