import React, { useEffect } from "react"
import PetPoliciesProvider from "../../context/PetPoliciesContext"
import PetPolicyShow from "../../shared/PetPolicyShow"
import PetPolicyEdit from "../../shared/PetPolicyEdit"
import PetPolicyNew from "../../shared/PetPolicyNew"

export default function PetPolicy({ params, page }) {
  return (
    <PetPoliciesProvider>
      {page === "show" &&
        <PetPolicyShow params={params} />
      }
      {page === "new" &&
        <PetPolicyNew params={params} />
      }
      {page === "edit" &&
        <PetPolicyEdit params={params} />
      }
    </PetPoliciesProvider>
  )
}
