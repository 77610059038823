import React, { useState, useEffect } from "react"

const Homepage = () => {

  return (
    <h1 className="home-description">ILS MANAGEMENT&trade; FOR THE MULTIFAMILY INDUSTRY</h1>
  )
}

export default Homepage
