import { CSVBoxButton } from "@csvbox/react"
import React, { useContext, useState } from "react"

import { FloorplansContext } from "../context/FloorplansContext"
import { GlobalContext } from "../context/GlobalContext"
import BrynsonSquareButton from "../shared/BrynsonSquareButton"

import SlideInAlert from "./SlideInAlert"

export default function FloorplanUpload({ buttonStyle, buttonSize, buttonClassName, propertyId, setLoading, style }) {
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [importSuccess, setImportSuccess] = useState(false)
  const { uploadFloorplan } = useContext(FloorplansContext)
  const { currentUserData, reloadProperty, reloadProperties } = useContext(GlobalContext)
  const columns = [
    {
      accessor: "Property Name",
      accessor_value: "propertyName",
      kind: "string"
    },
    {
      accessor: "Unit Type Name",
      accessor_value: "name",
      kind: "string"
    },
    {
      accessor: "Square Feet",
      accessor_value: "sqFtMin",
      kind: "int"
    },
    {
      accessor: "Bedrooms",
      accessor_value: "beds",
      kind: "int"
    },
    {
      accessor: "Bathrooms",
      accessor_value: "baths",
      kind: "int"
    },
    {
      accessor: "Cats Allowed?",
      accessor_value: "catsAllowed",
      kind: "string"
    },
    {
      accessor: "Dogs Allowed?",
      accessor_value: "dogsAllowed",
      kind: "string"
    },
    {
      accessor: "Amenities",
      accessor_value: "amenities",
      kind: "string"
    },
    {
      accessor: "Application Fee",
      accessor_value: "applicationFee",
      kind: "float"
    },
    {
      accessor: "Deposit",
      accessor_value: "depositAmount",
      kind: "float"
    },
    {
      accessor: "Market Rent",
      accessor_value: "rentOptionSelected",
      kind: "string"
    },
    {
      accessor: "Marketing Title",
      accessor_value: "marketingTitle",
      kind: "string"
    },
    {
      accessor: "Marketing Description",
      accessor_value: "marketingDescription",
      kind: "string"
    },
    {
      accessor: "Youtube Url",
      accessor_value: "availabilityLink",
      kind: "string"
    }
  ]

  const fetchFloorplans = (floorplanInput) => {
    const floorplanValues = {}
    floorplanValues["companyId"] = parseInt(currentUserData?.currentUser?.currentCompany?.id)
    floorplanValues["propertyId"] = parseInt(propertyId)
    columns.forEach((item) => {
      if (floorplanInput[item.accessor]) {
        if (item.kind == "float") {
          floorplanValues[item.accessor_value] = parseFloat(floorplanInput[item.accessor].replace(",", ""))
        } else if (item.kind == "int") {
          floorplanValues[item.accessor_value] = parseInt(floorplanInput[item.accessor])
        } else {
          floorplanValues[item.accessor_value] = floorplanInput[item.accessor]
        }
      } else {
        floorplanValues[item.accessor_value] = null
      }
    })
    return floorplanValues
  }

  const handleFloorplanImport = (result, data) => {
    const floorplansForUpload = data.rows.map((uploadedFloorplan) => fetchFloorplans(uploadedFloorplan))
    if (result) {
      uploadFloorplan({ floorplans: floorplansForUpload, page: window.location.href }).then((res) => {
        if (res?.error) {
          setShowAlert(true)
          setAlertContent(`Error importing floorplansForUpload: ${res.error} Please check your data and try again.`)
        } else {
          if (res?.data?.uploadFloorplanMutation?.background) {
            alert("Check back in a few minutes.")
          } else {
            propertyId ? reloadProperty(parseInt(propertyId)) : reloadProperties()
          }
        }
      })
    } else {
      console.log("Upload fail")
    }
  }

  return (
    <div style={style}>
      <CSVBoxButton
        licenseKey="eNSSkYbKBXXBqTUypvUhWzzje7xmzm"
        onImport={handleFloorplanImport}
        render={(launch) => (
          <BrynsonSquareButton
            type={buttonStyle}
            size={buttonSize}
            label="Bulk Upload Floorplans"
            fullWidth={false}
            onClick={launch}
            className={buttonClassName}
          />
        )}>
        Import
      </CSVBoxButton>
      <SlideInAlert visible={showAlert} setVisible={setShowAlert}>
        {alertContent} {importSuccess && <a onClick={() => (window.property.href = "/portal/clients")}>View</a>}
      </SlideInAlert>
    </div>
  )
}
