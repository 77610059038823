import { Label, Select, Table } from "flowbite-react"
import React, { useState, useEffect } from "react"
import { HiArrowUp, HiArrowDown } from "react-icons/hi"

export function EstimatedUnitMix({ properties = [] }) {
  const [groupBy, setGroupBy] = useState("floorplan")
  const [expanded, setExpanded] = useState(-1) // -1 means no expanded row
  const [property, setProperty] = useState(properties[0])

  const propertiesMap = properties.reduce((map, property) => {
    map[property.id] = property
    return map
  }, {})

  useEffect(() => {
    setProperty(properties[0])
  }, [properties])

  function summary(units = []) {
    const count = units.length
    const floors = units.map((unit) => unit.floor).filter(Boolean)
    const minFloor = Math.min(...floors)
    const maxFloor = Math.max(...floors)
    const ask_price_psf =
      units.filter((unit) => unit.sqft).reduce((acc, unit) => acc + unit.price / unit.sqft, 0) / count
    const eff_price_psf =
      units.filter((unit) => unit.sqft).reduce((acc, unit) => acc + unit.effective_price / unit.sqft, 0) / count
    return {
      listings: count,
      days_on_market: (units.reduce((acc, unit) => acc + unit.days_on_market, 0) / count).toFixed(0),
      sf: (units.reduce((acc, unit) => acc + unit.sqft, 0) / count).toFixed(0),
      floor:
        isFinite(minFloor) && isFinite(maxFloor)
          ? minFloor === maxFloor
            ? minFloor
            : `${minFloor} - ${maxFloor}`
          : "-",
      ask_price: $(units.reduce((acc, unit) => acc + unit.price, 0) / count, 0),
      ask_price_psf: ask_price_psf > 0 ? $(ask_price_psf, 2) : "-",
      eff_price: $(units.reduce((acc, unit) => acc + unit.effective_price, 0) / count, 0),
      eff_price_psf: eff_price_psf > 0 ? $(eff_price_psf, 2) : "-"
    }
  }

  const availability = property?.building_availability || []
  const beds = Object.groupBy(availability, (unit) => unit.bed)
  const floorplans = Object.groupBy(availability, (unit) => unit.floorplan_name)

  const listings_percents = Object.entries(groupBy === "floorplan" ? floorplans : beds).reduce(
    (acc, [key, units]) => ({
      ...acc,
      [key]: availability.length > 0 ? +((units.length / availability.length) * 100).toFixed(2) : 0
    }),
    {}
  )

  const est_total_units = Object.entries(listings_percents).reduce(
    (acc, [key, percent]) => ({
      ...acc,
      [key]: +((property.number_units * percent) / 100).toFixed(0)
    }),
    {}
  )

  return (
    <div className="mt-8 overflow-x-auto rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Estimated Unit Mix</p>
          <p className="text-xs font-medium text-gray-500">
            Analyze rents, availabilities and time on market by floorplan or type for your property and its comps.
          </p>
        </div>
        <div className="flex items-center space-x-8">
          <div className="flex items-center">
            <Label className="m-0 mr-2">Group By</Label>
            <Select value={groupBy} onChange={(e) => setGroupBy(e.target.value)} className="min-w-[8rem] font-medium">
              <option value="bed/bath">Bed/Bath</option>
              <option value="floorplan">Floorplan</option>
            </Select>
          </div>
          <div className="flex items-center">
            <Label className="m-0 mr-2">Property</Label>
            <Select
              value={property?.id}
              onChange={(e) => setProperty(propertiesMap[e.target.value])}
              className="min-w-[15rem] font-medium">
              {properties.map((property, index) => (
                <option key={index} value={property.id}>
                  {property.building_name}
                </option>
              ))}
            </Select>
          </div>
        </div>
      </div>
      <Table className="w-full overflow-x-scroll">
        <Table.Head>
          <Table.HeadCell></Table.HeadCell>
          <Table.HeadCell>Bed/Bath</Table.HeadCell>
          <Table.HeadCell>Floorplan</Table.HeadCell>
          <Table.HeadCell># Listings</Table.HeadCell>
          <Table.HeadCell># % of Listings</Table.HeadCell>
          <Table.HeadCell>Est. Total Units</Table.HeadCell>
          <Table.HeadCell>Days on Mkt</Table.HeadCell>
          <Table.HeadCell>Leased Date</Table.HeadCell>
          <Table.HeadCell>SF</Table.HeadCell>
          <Table.HeadCell>Floor</Table.HeadCell>
          <Table.HeadCell>Ask Rent</Table.HeadCell>
          <Table.HeadCell>Ask Rent PSF</Table.HeadCell>
          <Table.HeadCell>Eff Rent</Table.HeadCell>
          <Table.HeadCell>Eff Rent PSF</Table.HeadCell>
        </Table.Head>
        <Table.Body className="border-t text-sm">
          {Object.entries(groupBy === "floorplan" ? floorplans : beds).map(([key, units], index) => {
            const data = summary(units)
            return (
              <React.Fragment key={key}>
                <Table.Row key={index} id={key} className="border-b font-medium text-gray-800 hover:bg-blue-100">
                  <Table.Cell className="select-none px-4 py-2 align-middle">
                    <div className="flex items-center">
                      {expanded === index ? (
                        <HiArrowUp
                          className="mr-2 h-3 w-3 cursor-pointer text-blue-500"
                          onClick={() => setExpanded(-1)}
                        />
                      ) : (
                        <HiArrowDown
                          className="mr-2 h-3 w-3 cursor-pointer hover:text-blue-500"
                          onClick={() => setExpanded(index)}
                        />
                      )}
                      {property.building_name}
                    </div>
                  </Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">
                    {units[0].bed} BR / {units[0].bath} BA
                  </Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">{units[0].floorplan_name}</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">{data.listings}</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">
                    {listings_percents[key] > 0 ? `${listings_percents[key]}%` : "-"}
                  </Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">{est_total_units[key]}</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">{data.days_on_market}</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">-</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">{data.sf}</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">{data.floor}</Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">
                    {data.ask_price != null && data.ask_price[0] != null && !isNaN(parseFloat(data.ask_price[0]))
                      ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                          parseFloat(data.ask_price[0])
                        )
                      : "N/A"}
                  </Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">
                    {data.ask_price_psf != null &&
                    data.ask_price_psf[0] != null &&
                    !isNaN(parseFloat(data.ask_price_psf[0]))
                      ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                          parseFloat(data.ask_price_psf[0])
                        )
                      : "N/A"}
                  </Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">
                    {data.eff_price != null && data.eff_price[0] != null && !isNaN(parseFloat(data.eff_price[0]))
                      ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                          parseFloat(data.eff_price[0])
                        )
                      : "N/A"}
                  </Table.Cell>
                  <Table.Cell className="px-4 py-2 text-center align-middle">
                    {data.eff_price_psf != null &&
                    data.eff_price_psf[0] != null &&
                    !isNaN(parseFloat(data.eff_price_psf[0]))
                      ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                          parseFloat(data.eff_price_psf[0])
                        )
                      : "N/A"}
                  </Table.Cell>
                </Table.Row>
                <>
                  {expanded === index &&
                    units.map((unit) => (
                      <Table.Row
                        key={unit.id}
                        className="border-b bg-gray-50 font-medium text-gray-800 hover:bg-blue-100">
                        <Table.Cell className="py-2 pl-14 align-middle">{property.building_name}</Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.bed} BR / {unit.bath} BA
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">{unit.floorplan_name}</Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">-</Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">-</Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">-</Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">{unit.days_on_market}</Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.exit_market || "Active"}
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.sqft ? unit.sqft : "-"}
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.floor ? unit.floor : "-"}
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.price != null
                            ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(unit.price)
                            : "-"}
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.price != null && unit.sqft > 0
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2
                              }).format(unit.price / unit.sqft)
                            : "-"}
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.effective_price != null
                            ? new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(
                                unit.effective_price
                              )
                            : "-"}
                        </Table.Cell>
                        <Table.Cell className="px-4 py-2 text-center align-middle">
                          {unit.effective_price != null && unit.sqft > 0
                            ? new Intl.NumberFormat("en-US", {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 2
                              }).format(unit.effective_price / unit.sqft)
                            : "-"}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                </>
              </React.Fragment>
            )
          })}
          <Table.Row className="border-b font-semibold text-gray-800 hover:bg-blue-100">
            <Table.Cell className="px-4 py-2 align-middle">Totals/Averages</Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle"></Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle"></Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">{availability.length}</Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle"></Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {Object.values(est_total_units).reduce((acc, total) => acc + total, 0)}
            </Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {availability.length
                ? (availability.reduce((acc, unit) => acc + unit.days_on_market, 0) / availability.length).toFixed(0)
                : "-"}
            </Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle"></Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {availability.length
                ? (availability.reduce((acc, unit) => acc + unit.sqft, 0) / availability.length).toFixed(0)
                : "-"}
            </Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle"></Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {availability.length
                ? $(availability.reduce((acc, unit) => acc + unit.price, 0) / availability.length, 0)
                : "-"}
            </Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {availability.length && availability.every((unit) => unit.sqft)
                ? $(availability.reduce((acc, unit) => acc + unit.price / unit.sqft, 0) / availability.length, 2)
                : "-"}
            </Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {availability.length
                ? $(availability.reduce((acc, unit) => acc + unit.effective_price, 0) / availability.length, 0)
                : "-"}
            </Table.Cell>
            <Table.Cell className="px-4 py-2 text-center align-middle">
              {availability.length && availability.every((unit) => unit.sqft)
                ? $(
                    availability.reduce((acc, unit) => acc + unit.effective_price / unit.sqft, 0) / availability.length,
                    2
                  )
                : "-"}
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  )
}
