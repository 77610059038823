const toCurrency = (numberString) => {
  if (!numberString) return numberString
  return new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol' }).format(numberString)
}

export const propertyColumnsToDisplay = [
  {
    accessor: "name",
    Header: "Property Name",
    type: "text",
    className: "full-width font-medium"
  },
  {
    accessor: "fullAddressWithFallback",
    Header: "Address",
    type: "text",
    className: "full-width"
  },
  {
    accessor: "unitCount",
    Header: "Unit Count",
    type: "number"
  },
  {
    accessor: "pmsUsed",
    Header: "PMS Used",
    type: "text"
  },
  {
    accessor: "crmUsed",
    Header: "CRM Used",
    type: "text"
  },
]


export const propertyColumnsToUpload = [
  {
    accessor: "name",
    Header: "Property Name",
    type: "text",
  },
  {
    accessor: "address",
    Header: "Street Address",
    type: "text"
  },
  {
    accessor: "city",
    Header: "City",
    type: "text"
  },
  {
    accessor: "state",
    Header: "State",
    type: "string",
    accessor_value: "stateId",
    Cell: props => props.value ? props.value.abbreviation : ''
  },
  {
    accessor: "zip",
    Header: "Zipcode",
    type: "text"
  },
  {
    accessor: "unitCount",
    Header: "Unit Count",
    type: "number"
  },
  {
    accessor: "pmsUsed",
    Header: "PMS Used",
    type: "text"
  },
  {
    accessor: "crmUsed",
    Header: "CRM Used",
    type: "text"
  },
  {accessor: "currentMonthOccupancyPercentage", Header: "currentMonthOccupancyPercentage", type: "number"},
  {accessor: "askingRentPerSf", Header: "askingRentPerSF", type: "number"},
  {accessor: "currentApartmentsComRate", Header: "currentApartmentsComRate", type: "number"},
  {accessor: "currentZillowRate", Header: "currentZillowRate", type: "number"},
  {accessor: "currentRentComRate", Header: "currentRentComRate", type: "number"},
  {accessor: "currentApartmentListRate", Header: "currentApartmentListRate", type: "number"},
  {accessor: "currentZumperRate", Header: "currentZumperRate", type: "number"},
  {accessor: "currentRealtorPackage", Header: "currentRealtorPackage", type: "number"},
  {accessor: "current3dApartmentsRate", Header: "current3dApartmentsRate", type: "number"},
  {accessor: "currentRealtorRate", Header: "currentRealtorRate", type: "number"},
  {accessor: "currentRentComPackage", Header: "currentRentComPackage", type: "text"},
  {accessor: "currentZillowPackage", Header: "currentZillowPackage", type: "text"},
  {accessor: "currentZumperPackage", Header: "currentZumperPackage", type: "text"},
  {accessor: "currentRentablePackage", Header: "currentRentablePackage", type: "text"},
  {accessor: "current3dApartmentsPackage", Header: "current3dApartmentsPackage", type: "text"},
  {accessor: "currentApartmentsComPackage", Header: "currentApartmentsComPackage", type: "text"},
  {
    accessor: "year",
    Header: "Year",
    type: "text"
  },
  {
    accessor: "month",
    Header: "Month",
    type: "text"
  },
]

export const propertyColumnsCurrent = [
  {
    accessor: "name",
    Header: "Property Name",
    type: "text",
  },
  {
    accessor: "address",
    Header: "Street Address",
    type: "text"
  },
  {
    accessor: "state",
    Header: "State",
    type: "string",
    accessor_value: "stateId",
    Cell: props => props.value ? props.value.abbreviation : ''
  },
  {
    accessor: "city",
    Header: "City",
    type: "text"
  },
  {
    accessor: "zip",
    Header: "Zipcode",
    type: "text"
  },
  {
    accessor: "unitCount",
    Header: "Unit Count",
    type: "number"
  },
  {
    accessor: "pmsUsed",
    Header: "PMS Used",
    type: "text"
  },
  {
    accessor: "crmUsed",
    Header: "CRM Used",
    type: "text"
  },
  {
    accessor: "promotional",
    Header: "Promotional",
    type: "text"
  },
  {
    accessor: "latitude",
    Header: "Longitude",
    type: "decimal"
  },
  {
    accessor: "longitude",
    Header: "Longitude",
    type: "decimal"
  },
  {
    accessor: "identificationType",
    Header: "Identification Type",
    type: "text"
  },
  {
    accessor: "rentalType",
    Header: "Rental Type",
    type: "text"
  },
  {
    accessor: "helloDataPropertyId",
    Header: "HelloDataProperty",
    type: "text"
  },
  {
    accessor: "helloDataPropertyName",
    Header: "HelloDataPropertyName",
    type: "text"
  },
  {
    accessor: "dayLightSaving",
    Header: "Day Light Saving",
    type: "boolean"
  },
]
