import { Table, Dropdown } from "flowbite-react"
import _ from "lodash"
import React, { useState } from "react"
import { useQuery } from "urql"

import { DCAA_QUERY } from "../../queries/DcaasMutations"

export default function DCAA() {
  const pathname = window.location.pathname

  const id = pathname.split("/").pop()

  const [{ data: dcaasData }] = useQuery({
    query: DCAA_QUERY,
    variables: { dcaaId: id }
  })

  const [selectedFloorPlanSource, setSelectedFloorPlanSource] = useState("")

  const dcaa = dcaasData?.dcaa
  const fieldStructures = dcaa?.fieldStructures

  const renderArray = (arr) => (
    <div>
      {arr.map((item, index) => (
        <div key={index} className="flex w-full flex-col border-b border-bgray">
          <div className="px-3">{item}</div>
        </div>
      ))}
    </div>
  )

  const fields = [
    "initialAuditCompletionDate",
    "auditorName",
    "quarterlyAuditCompletionDate",
    "address",
    "website",
    "directPhoneNumber",
    "email",
    "propertyDescription",
    "unitCount",
    "totalBuildings",
    "numberOfStories",
    "applicationFee",
    "adminFee",
    "storageFee",
    "rentSpecials",
    "offerDuration"
  ]

  const renderFloorPlans = () => (
    <div className="m-3 flex w-full flex-col">
      <div className="bg-bgray font-bold">Floor Plans:</div>
      <div className="pl-3">
        {dcaa?.floorPlans && (
          <div className="m-3 overflow-x-auto">
            <Dropdown label={dcaa?.floorPlans[selectedFloorPlanSource]?.source_name || "Source"} inline>
              {dcaa?.floorPlans &&
                Object.keys(dcaa?.floorPlans).map((sourceKey) => {
                  const source = dcaa.floorPlans[sourceKey]
                  return (
                    <Dropdown.Item
                      key={sourceKey}
                      value={sourceKey}
                      onClick={() => setSelectedFloorPlanSource(sourceKey)}>
                      {source["source_name"]}
                    </Dropdown.Item>
                  )
                })}
            </Dropdown>
            <Table>
              <Table.Head>
                <Table.HeadCell>Floorplan name</Table.HeadCell>
                <Table.HeadCell>Beds</Table.HeadCell>
                <Table.HeadCell>Baths</Table.HeadCell>
                <Table.HeadCell>Sq feet</Table.HeadCell>
                <Table.HeadCell>Price</Table.HeadCell>
                <Table.HeadCell>Units</Table.HeadCell>
                <Table.HeadCell>Units Available</Table.HeadCell>
              </Table.Head>
              <Table.Body>
                {dcaa?.floorPlans &&
                  dcaa?.floorPlans[selectedFloorPlanSource]?.floor_plans?.map((floorplan, index) => (
                    <Table.Row key={index}>
                      <Table.Cell>{floorplan["floorplan_name"]}</Table.Cell>
                      <Table.Cell>{floorplan["beds"]}</Table.Cell>
                      <Table.Cell>{floorplan["baths"]}</Table.Cell>
                      <Table.Cell>{floorplan["sq_feet"]}</Table.Cell>
                      <Table.Cell>{floorplan["price"]}</Table.Cell>
                      <Table.Cell>{floorplan["units"]}</Table.Cell>
                      <Table.Cell>{floorplan["units_available"]}</Table.Cell>
                    </Table.Row>
                  ))}
              </Table.Body>
            </Table>
          </div>
        )}
      </div>
    </div>
  )

  const fieldStructureAttributes = (fieldName) => {
    const fieldStructure = fieldStructures?.[_.snakeCase(fieldName)]
    let keys = fieldStructure ? Object.keys(fieldStructure) : []
    keys = keys.filter((key) => key !== "keys")
    return keys
  }

  const renderObject = (fieldName, title) => {
    const fieldStructure = fieldStructures?.[_.snakeCase(fieldName)]

    return (
      <div className="m-3 flex w-full flex-col">
        <div className="bg-bgray font-bold">{title || _.startCase(fieldName)}</div>
        <div className="pl-3">
          {fieldStructureAttributes(fieldName).map((key) => (
            <div key={key} className="flex w-full flex-row border-b border-bgray">
              <div className="basis-1/3 font-bold">{_.startCase(key)}:</div>
              <div className="pl-3">
                {fieldStructure?.[key]?.type === "boolean"
                  ? dcaa?.[fieldName]?.[key] === "1" || dcaa?.[fieldName]?.[key] === true
                    ? "Yes"
                    : ""
                  : dcaa?.[fieldName]?.[key]}
              </div>
              {fieldStructure?.[key]?.with_description && (
                <div className="px-3">{dcaa?.[fieldName]?.[`${key}_description`]}</div>
              )}
            </div>
          ))}
        </div>
      </div>
    )
  }

  const renderField = (value, fieldName) => (
    <div key={fieldName} className="flex w-full flex-row border-b border-bgray">
      <div className="basis-1/4 font-bold">{_.startCase(fieldName)}:</div>
      <div className="pl-3">{value ? value : ""}</div>
    </div>
  )

  const renderFields = () => fields.map((field) => renderField(dcaa?.[field], field))

  const renderArrayField = (fieldName) =>
    dcaa?.[fieldName] && (
      <div className="m-3 flex w-full flex-col">
        <div className="bg-bgray font-bold">{_.startCase(fieldName)}:</div>
        <div className="pl-3">{dcaa?.[fieldName] && renderArray(dcaa?.[fieldName])}</div>
      </div>
    )

  const renderSubTitle = (title) => <h1 className="border-b border-bgray text-xl font-semibold">{title}</h1>

  return (
    <div className="p-8">
      {renderSubTitle("Onboarding Checklist for Internet Listing Services (inclusive of GMB)")}
      {dcaa?.property && renderField(dcaa?.property?.name, "property")}
      {renderFields()}
      <div className="columns-2 gap-2">
        {renderObject("leaseTerms", "Lease Terms")}
        {renderObject("officeHours", "Office Hours")}
        {renderObject("utilitiesIncluded", "Utilities Included")}
        {renderObject("includedUtilities", "Included Utilities")}
        {renderObject("feedInformation", "Feed Information")}
      </div>
      {renderSubTitle("Amenities")}
      <div className="columns-2 gap-2">
        {renderObject("petPolicy", "Pet Policy")}
        {renderObject("parking", "Parking")}
      </div>
      {renderFloorPlans()}
      {renderArrayField("photos")}
      {renderArrayField("videos")}
      {renderArrayField("matterports")}
      {renderArrayField("propertyMedias")}
      {renderArrayField("custom")}
      <div className="columns-2 gap-2">
        {renderObject("ilsMedia", "ILS Media")}
        {renderObject("features", "Features")}
        {renderObject("services", "Services")}
        {renderObject("interior", "Interior")}
        {renderObject("fitnessAndRecreation", "Fitness and Recreation")}
        {renderObject("outdoorSpaces", "Outdoor Spaces")}
        {renderObject("kitchen", "Kitchen")}
        {renderObject("studentFeatures", "Student Features")}
        {renderObject("living", "Living")}
      </div>
      <div className="flex w-full flex-row border-b border-bgray">
        <div className="basis-1/4 font-bold">Other Features:</div>
        <div className="pl-3">{dcaa?.otherFeatures}</div>
      </div>
      <div className="flex w-full flex-row border-b border-bgray">
        <div className="basis-1/4 font-bold">Stand Out:</div>
        <div className="pl-3">{dcaa?.standOut}</div>
      </div>
      <div className="flex w-full flex-row border-b border-bgray">
        <div className="basis-1/4 font-bold">Nearby Facilities:</div>
        <div className="pl-3">{dcaa?.nearbyFacilities}</div>
      </div>
    </div>
  )
}
