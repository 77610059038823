import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["multiselect"]

  removeItem = (e) => {
    const id = $(e.target).closest("span").attr("data-id")
    const allItems = [...this.multiselectTarget.options]
    const selectItem = allItems.find((option) => option.value == id)
    selectItem.selected = false

    document.getElementById(`setup_options_insurance_ids_${id}`).checked = false

    $(e.target)
      .closest("span")
      .fadeOut(function () {
        $(this).remove()
      })
  }
}
