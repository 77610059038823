export function getHistoryDates(subject, startDate = null, endDate = null) {
  let dates = []

  if (startDate && endDate) {
    if (new Date(startDate) > new Date(endDate)) {
      throw new Error("startDate must be before endDate")
    }

    let currentDate = new Date(endDate)
    while (currentDate >= new Date(startDate)) {
      dates.unshift(currentDate.toISOString().split("T")[0])
      currentDate.setDate(currentDate.getDate() - 1)
    }
  } else {
    let maxToDate = new Date("2000-01-01T00:00:00")

    if (subject && subject["building_availability"] && Array.isArray(subject["building_availability"])) {
      subject["building_availability"].forEach((item) => {
        if (item.history && Array.isArray(item.history)) {
          item.history.forEach((hist) => {
            const toDate = new Date(hist.to_date)
            if (toDate > maxToDate) {
              maxToDate = toDate
            }
          })
        }
      })
    }

    maxToDate = maxToDate > new Date() ? new Date() : maxToDate

    for (let i = 0; i < 10; i++) {
      const date = new Date(maxToDate)
      date.setDate(date.getDate() - i * 3)
      dates.unshift(date.toISOString().split("T")[0])
    }
  }

  return dates
}

function calculateAverageRent(rentByDate, dates) {
  return dates.map((date) => {
    const rentData = rentByDate[date]
    const avgRent = rentData ? (rentData.total / rentData.count).toFixed(1) : 0
    return { x: date, y: parseFloat(avgRent) }
  })
}

function getCombinedHistory(subject, comparables) {
  const combinedHistory = []

  if (subject && subject["building_availability"]) {
    combinedHistory.push(...subject["building_availability"])
  }

  if (comparables) {
    comparables.forEach((comp) => {
      if (comp["building_availability"]) {
        combinedHistory.push(...comp["building_availability"])
      }
    })
  }

  return combinedHistory
}

function calculateMedian(prices) {
  if (prices.length === 0) return 0
  const mid = Math.floor(prices.length / 2)
  if (prices.length % 2 === 0) {
    return (prices[mid - 1] + prices[mid]) / 2
  } else {
    return prices[mid]
  }
}

function calculateAverage(numbers) {
  if (numbers.length === 0) return 0
  return numbers.reduce((sum, num) => sum + num, 0) / numbers.length
}

function getAvgRentData(subject, comparables, rentType, startDate, endDate) {
  const dates = getHistoryDates(subject, startDate, endDate)
  const rentData = {
    asking_rent: (item) => item.price,
    effective_rent: (item) => item.effective_price,
    concession_rent: (item) => item.price - item.effective_price
  }

  const collectRentData = (property, dates, rentType) => {
    const rentByDate = {}
    if (property && property["building_availability"] && Array.isArray(property["building_availability"])) {
      property["building_availability"].forEach((item) => {
        if (item.history && Array.isArray(item.history) && item.sqft) {
          item.history.forEach((hist) => {
            const fromDate = new Date(hist.from_date)
            const toDate = new Date(hist.to_date)
            dates.forEach((date) => {
              const currentDate = new Date(date)
              if (currentDate >= fromDate && currentDate <= toDate) {
                if (!rentByDate[date]) {
                  rentByDate[date] = { total: 0, count: 0 }
                }
                rentByDate[date].total += rentData[rentType](hist)
                rentByDate[date].count++
              }
            })
          })
        }
      })
    }
    return rentByDate
  }

  const combinedHistory = getCombinedHistory(subject, comparables)

  const subjectData = calculateAverageRent(collectRentData(subject, dates, rentType), dates)

  const medianData = dates.map((date) => {
    const prices = []
    combinedHistory.forEach((item) => {
      item.history.forEach((hist) => {
        const fromDate = new Date(hist.from_date)
        const toDate = new Date(hist.to_date)
        const currentDate = new Date(date)
        if (currentDate >= fromDate && currentDate <= toDate) {
          prices.push(rentData[rentType](hist))
        }
      })
    })
    const median = calculateMedian(prices)
    return { x: date, y: median }
  })

  const unitData50 = dates.map((date) => {
    const currentDate = new Date(date)
    const relevantData = combinedHistory
      .filter((item) => {
        const fromDate = new Date(item.from_date)
        const toDate = new Date(item.to_date)
        return currentDate >= fromDate && currentDate <= toDate
      })
      .map((item) =>
        rentData[rentType](
          item.history.find((hist) => {
            const histFromDate = new Date(hist.from_date)
            const histToDate = new Date(hist.to_date)
            return currentDate >= histFromDate && currentDate <= histToDate
          })
        )
      )

    const sortedData = relevantData.sort((a, b) => b - a)
    const topPercent = sortedData.slice(0, Math.ceil(sortedData.length * 0.5))
    const average = calculateAverage(topPercent)
    return { x: date, y: average }
  })

  const unitData80 = dates.map((date) => {
    const currentDate = new Date(date)
    const relevantData = combinedHistory
      .filter((item) => {
        const fromDate = new Date(item.from_date)
        const toDate = new Date(item.to_date)
        return currentDate >= fromDate && currentDate <= toDate
      })
      .map((item) =>
        rentData[rentType](
          item.history.find((hist) => {
            const histFromDate = new Date(hist.from_date)
            const histToDate = new Date(hist.to_date)
            return currentDate >= histFromDate && currentDate <= histToDate
          })
        )
      )

    const sortedData = relevantData.sort((a, b) => b - a)
    const topPercent = sortedData.slice(0, Math.ceil(sortedData.length * 0.8))
    const average = calculateAverage(topPercent)
    return { x: date, y: average }
  })

  const unitData90 = dates.map((date) => {
    const currentDate = new Date(date)
    const relevantData = combinedHistory
      .filter((item) => {
        const fromDate = new Date(item.from_date)
        const toDate = new Date(item.to_date)
        return currentDate >= fromDate && currentDate <= toDate
      })
      .map((item) =>
        rentData[rentType](
          item.history.find((hist) => {
            const histFromDate = new Date(hist.from_date)
            const histToDate = new Date(hist.to_date)
            return currentDate >= histFromDate && currentDate <= histToDate
          })
        )
      )

    const sortedData = relevantData.sort((a, b) => b - a)
    const topPercent = sortedData.slice(0, Math.ceil(sortedData.length * 0.9))
    const average = calculateAverage(topPercent)
    return { x: date, y: average }
  })

  const result = [
    { id: subject.building_name, data: subjectData },
    { id: "Median Price", data: medianData },
    { id: "50% of Units", data: unitData50 },
    { id: "80% of Units", data: unitData80 },
    { id: "90% of Units", data: unitData90 }
  ]

  return result.reverse()
}

export function getAvgAskingRentPsfByDate(subject, comparables, startDate = null, endDate = null) {
  return getAvgRentData(subject, comparables, "asking_rent", startDate, endDate)
}

export function getAvgEffectRentPsfByDate(subject, comparables, startDate = null, endDate = null) {
  return getAvgRentData(subject, comparables, "effective_rent", startDate, endDate)
}

export function getAvgConcessionRentPsfByDate(subject, comparables, startDate = null, endDate = null) {
  return getAvgRentData(subject, comparables, "concession_rent", startDate, endDate)
}

export function getAvgAskingRentByDate(subject, comparables, startDate = null, endDate = null) {
  return getAvgRentData(subject, comparables, "asking_rent", startDate, endDate)
}

export function getAvgEffectRentByDate(subject, comparables, startDate = null, endDate = null) {
  return getAvgRentData(subject, comparables, "effective_rent", startDate, endDate)
}

export function getAvgConcessionRentByDate(subject, comparables, startDate = null, endDate = null) {
  return getAvgRentData(subject, comparables, "concession_rent", startDate, endDate)
}
