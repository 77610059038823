import React, { useEffect } from "react"
import UnitShow from "../../shared/UnitShow"
import UnitsProvider from "../../context/UnitsContext"
import UnitNew from "../../shared/UnitNew"
import UnitEdit from "../../shared/UnitEdit"

export default function Floorplan({ params, page }) {
  return (
    <UnitsProvider>
      {page === "show" &&
        <UnitShow params={params} />
      }
      {page === "new" &&
        <UnitNew params={params} />
      }
      {page === "edit" &&
        <UnitEdit params={params} />
      }
    </UnitsProvider>
  )
}
