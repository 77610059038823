import React from "react"

import { Amenities } from "./Amenities"
import { Finishes } from "./Finishes"

export function AmenitiesFinishes({ properties = [] }) {
  return (
    <>
      <div className="my-8 overflow-hidden rounded-md border">
        <div className="flex items-center justify-between p-4">
          <div>
            <p className="mb-1 text-xl font-semibold">Finishes & Amenities</p>
            <p className="text-xs font-medium text-gray-500">
              Compare amenities and finish levels across your competition to improve market positioning.
            </p>
          </div>
        </div>
        <Finishes properties={properties} />
        <Amenities properties={properties} />
      </div>
    </>
  )
}
