import clsx from "clsx"
import { Table, Tooltip } from "flowbite-react"
import React from "react"
import { HiOutlineInformationCircle } from "react-icons/hi"

export function Fees({ properties = [] }) {
  const formatsFees = (properties) => {
    const fees = []
    properties.forEach((property) => {
      const fee = {
        property: property.building_name,
        application_fee: property.application_fee ? property.application_fee : 0,
        admin_fee: property.admin_fee ? property.admin_fee : 0,
        storage_fee: property.storage_fee ? property.storage_fee : 0,
        pet_rent: ((property.cats_monthly_rent ?? 0) + (property.dogs_monthly_rent ?? 0)) / 2,
        pet_fee: ((property.cats_one_time_fee ?? 0) + (property.dogs_one_time_fee ?? 0)) / 2,
        pet_deposit: ((property.cats_deposit ?? 0) + (property.dogs_deposit ?? 0)) / 2,
        covered_parking: property.parking_covered ? property.parking_covered : 0,
        garage_parking: property.parking_garage ? property.parking_garage : 0,
        surface_lot_parking: property.parking_surface_lot ? property.parking_surface_lot : 0
      }
      fees.push(fee)
    })
    return fees
  }

  const fees = formatsFees(properties)
  return (
    <div className="mb-8 overflow-hidden rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Fees</p>
          <p className="text-xs font-medium text-gray-500">
            Compare fees charged by your subject property to what the market is charging.
          </p>
        </div>
      </div>
      <FeesTable fees={fees} />
    </div>
  )
}

export function FeesTable({ fees = [] }) {
  function v(value) {
    return typeof value === "number" ? value : 0
  }

  function $(value, fixed = 0) {
    const processedValue = typeof value === "number" ? value : parseFloat(value)

    return typeof processedValue === "number" ? `$${fixed > 0 ? processedValue.toFixed(fixed) : processedValue}` : ""
  }

  const subject = fees.find((fee) => fee.property)
  const totalFees = fees.reduce(
    (acc, fee) => {
      acc.application_fee += v(fee.application_fee)
      acc.admin_fee += v(fee.admin_fee)
      acc.storage_fee += v(fee.storage_fee)
      acc.pet_rent += v(fee.pet_rent)
      acc.pet_fee += v(fee.pet_fee)
      acc.pet_deposit += v(fee.pet_deposit)
      acc.covered_parking += v(fee.covered_parking)
      acc.garage_parking += v(fee.garage_parking)
      acc.surface_lot_parking += v(fee.surface_lot_parking)
      return acc
    },
    {
      application_fee: 0,
      admin_fee: 0,
      storage_fee: 0,
      pet_rent: 0,
      pet_fee: 0,
      pet_deposit: 0,
      covered_parking: 0,
      garage_parking: 0,
      surface_lot_parking: 0
    }
  )

  const count = fees.length
  const average = {
    application_fee: count > 0 ? totalFees.application_fee / count : 0,
    admin_fee: count > 0 ? totalFees.admin_fee / count : 0,
    storage_fee: count > 0 ? totalFees.storage_fee / count : 0,
    pet_rent: count > 0 ? totalFees.pet_rent / count : 0,
    pet_fee: count > 0 ? totalFees.pet_fee / count : 0,
    pet_deposit: count > 0 ? totalFees.pet_deposit / count : 0,
    covered_parking: count > 0 ? totalFees.covered_parking / count : 0,
    garage_parking: count > 0 ? totalFees.garage_parking / count : 0,
    surface_lot_parking: count > 0 ? totalFees.surface_lot_parking / count : 0
  }

  return (
    <Table>
      <Table.Head>
        <Table.HeadCell />
        <Table.HeadCell>Application Fee</Table.HeadCell>
        <Table.HeadCell>Admin Fee</Table.HeadCell>
        <Table.HeadCell>Storage Fee</Table.HeadCell>
        <Table.HeadCell>Pet Rent</Table.HeadCell>
        <Table.HeadCell>Pet Fee</Table.HeadCell>
        <Table.HeadCell>Pet Deposit</Table.HeadCell>
        <Table.HeadCell>Covered Parking</Table.HeadCell>
        <Table.HeadCell>Garage Parking</Table.HeadCell>
        <Table.HeadCell>Surface Lot Parking</Table.HeadCell>
      </Table.Head>
      <Table.Body className="border-t text-sm">
        {fees.map((fee, index) => (
          <Table.Row
            key={index}
            className={clsx(
              "border-b hover:bg-blue-100",
              index == 0 ? "bg-blue-50 font-semibold text-blue-800" : "font-medium text-gray-800"
            )}>
            <Table.Cell
              title={fee.property}
              className="max-w-[10rem] overflow-hidden text-ellipsis whitespace-nowrap p-0 py-2 pl-4">
              {fee.property}
            </Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.application_fee)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.admin_fee)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.storage_fee)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.pet_rent)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.pet_fee)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.pet_deposit)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.covered_parking)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.garage_parking)}</Table.Cell>
            <Table.Cell className="p-0 text-center align-middle">{$(fee.surface_lot_parking)}</Table.Cell>
          </Table.Row>
        ))}
        <Table.Row className="border-t bg-gray-50 font-semibold text-gray-800">
          <Table.Cell className="flex items-center p-0 py-1.5 pl-4">
            Comp Average
            <Tooltip className="normal-case" content={`Does not include ${subject?.property}`} placeholder="bottom">
              <HiOutlineInformationCircle className="ml-1 mt-0.5 h-3 w-3 text-gray-700" />
            </Tooltip>
          </Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.application_fee))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.admin_fee))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.storage_fee))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.pet_rent))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.pet_fee))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.pet_deposit))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.covered_parking))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.garage_parking))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle">{$(parseInt(average.surface_lot_parking))}</Table.Cell>
          <Table.Cell className="p-0 text-center align-middle"></Table.Cell>
        </Table.Row>
      </Table.Body>
    </Table>
  )
}
