import React, { createContext } from "react"
import { useMutation } from "urql"

import {
  CREATE_FLOORPLAN_MUTATION,
  UPDATE_FLOORPLAN_MUTATION,
  DELETE_FLOORPLAN_MUTATION,
  UPLOAD_FLOORPLAN_MUTATION
} from "../queries/FloorplansMutations"

export const FloorplansContext = createContext(null)

const FloorplansProvider = ({ children, ...props }) => {
  const [, createFloorplan] = useMutation(CREATE_FLOORPLAN_MUTATION)
  const [, updateFloorplan] = useMutation(UPDATE_FLOORPLAN_MUTATION)
  const [, deleteFloorplan] = useMutation(DELETE_FLOORPLAN_MUTATION)
  const [, uploadFloorplan] = useMutation(UPLOAD_FLOORPLAN_MUTATION)

  return (
    <FloorplansContext.Provider value={{ createFloorplan, updateFloorplan, deleteFloorplan, uploadFloorplan }}>
      {children}
    </FloorplansContext.Provider>
  )
}

export default FloorplansProvider
