import React from "react"
import styled from "styled-components"

const Label = styled.p`
  font-family: Lato;
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 24px;
  letter-spacing: 4%;
  color: #55778a;
  margin-bottom: 8px;
  text-transform: uppercase;
`

export default function GreyLabel({ content }) {
  return <Label>{content}</Label>
}
