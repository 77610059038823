import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["toggler"]

  connect() {
    if (this.hasTogglerTarget) {
      this.tagItems()
      this.hideBottomRows()
    }
  }

  toggle = (e) => {
    e.preventDefault()
    if (this.togglerTarget.dataset.state == "collapsed") {
      $(this.element.querySelectorAll(".not-top-row")).fadeIn()
      this.togglerTarget.dataset.state = "expanded"
      this.element.querySelector(".itembox").querySelector("span").textContent = "Show Less"
      this.togglerTarget.querySelector("i").classList.remove("icon-add")
      this.togglerTarget.querySelector("i").classList.add("icon-minus")
    } else {
      $(this.element.querySelectorAll(".not-top-row")).fadeOut()
      this.togglerTarget.dataset.state = "collapsed"
      this.togglerTarget.querySelector("span").textContent = "Show All"
      this.togglerTarget.querySelector("i").classList.remove("icon-minus")
      this.togglerTarget.querySelector("i").classList.add("icon-add")
    }
  }

  tagItems = () => {
    const topRowOffset = this.element.querySelectorAll(".itembox")[0].offsetTop

    this.element.querySelectorAll(".itembox").forEach((box) => {
      if (box.offsetTop > topRowOffset) {
        box.classList.add("not-top-row")
      }
    })
  }

  hideBottomRows = () => {
    $(this.element.querySelectorAll(".not-top-row")).fadeOut()
    this.togglerTarget.dataset.state = "collapsed"
  }
}
