/* eslint-disable react/no-unescaped-entities */

import * as dayjs from "dayjs"
import { Breadcrumb, Button, TextInput } from "flowbite-react"
import React, { useEffect, useState } from "react"
import { HiInformationCircle, HiSearch } from "react-icons/hi"

import LoadingButton from "../../shared/LoadingButton"

import { useFetch, fetchPropertyDetail, fetchPropertyComparables } from "./CompAnalysisReportsNew"
import { Property } from "./Property"
import { RentCompsTable } from "./RentCompsTable"
export const MIN_DATE = "06/17/2023"

export function formatDate(date) {
  return dayjs(date).format("MM/DD/YYYY")
}

const MAX_COMPARABLES = 5
const HELLO_DATA_PROPERTY_SEARCH_ENDPOINT = "/hello_data/search"
const HELLO_DATA_API_KEY = "964da45d-8f6b-4e0f-8049-9d9aa80ab963"

async function updateReport({ id, name, subject, comparables }) {
  const token = document.querySelector('meta[name="csrf-token"]').content

  const res = await fetch(`/hello_data/reports/${id}`, {
    method: "PUT",
    headers: {
      "X-CSRF-Token": token,
      "content-type": "application/json"
    },
    body: JSON.stringify({
      name,
      subject,
      comparables
    })
  })
  return await res.json()
}

export function SearchResultList({
  show = false,
  error = null,
  results = [],
  isLoading = false,
  onSelectResult = () => {}
}) {
  return show ? (
    <div className="absolute inset-x-0 top-12 z-10 w-full divide-y overflow-hidden rounded-md border bg-white shadow-md">
      {isLoading ? (
        <p className="px-4 py-3 text-sm font-medium text-gray-600">Loading...</p>
      ) : error ? (
        <p className="px-4 py-3 text-sm font-medium text-red-600">Network error</p>
      ) : results?.length ? (
        results.map((result) => <SearchResult key={result.id} result={result} onSelect={onSelectResult} />)
      ) : null}
    </div>
  ) : null
}

export function SearchResult({ result = {}, onSelect = () => {} }) {
  return (
    <div
      onClick={() => onSelect(result)}
      className="flex cursor-pointer space-y-0.5 px-4 py-2 transition hover:bg-blue-50">
      <div className="flex-1">
        <p className="text-sm font-semibold text-gray-800">{result.building_name}</p>
        <p className="text-xs font-medium text-gray-700">
          {result.street_address}, {result.city}, {result.state}, {result.zip_code}
        </p>
        <div className="flex items-center space-x-2 py-1">
          {result.year_built ? (
            <p className="rounded-md border px-2.5 py-0.5 text-xs font-medium text-gray-700">
              Built in {result.year_built}
            </p>
          ) : null}
          {result.number_units ? (
            <p className="rounded-md border px-2.5 py-0.5 text-xs font-medium text-gray-700">
              {result.number_units} Units
            </p>
          ) : null}
        </div>
      </div>
      <div className="space-y-0.5">
        {result.street_address_alias?.length > 0
          ? result.street_address_alias.slice(0, 4).map((alias, index) => (
              <p key={index} className="text-right text-xxs">
                {alias}
              </p>
            ))
          : null}
      </div>
    </div>
  )
}

export default function CompAnalysisReportsEdit() {
  const [search1, setSearch1] = useState("") // search for the subject
  const [search2, setSearch2] = useState("") // search for the comps
  const [properties, setProperties] = useState([])
  const [subjectProperty, setSubjectProperty] = useState(null)
  const [selectedProperties, setSelectedProperties] = useState([])
  const [comparables, setComparables] = useState([])
  const [reportName, setReportName] = useState("New report")
  const [reportId, setReportId] = useState("")
  const [loading, setLoading] = useState(false)
  const [loadingFetchProperty, setLoadingFetchProperty] = useState(false)
  const csrfToken = window.document.querySelector('[name="csrf-token"]').getAttribute("content")

  const fetchReportData = async () => {
    const path = window.location.pathname
    const match = path.match(/\/portal\/comp_analysis_reports_edit\/(\d+)/)
    const reportId = match ? match[1] : null
    const res = await fetch(`/hello_data/reports/${reportId}.json`)
    const data = await res.json()
    if (data?.data?.subject && data?.data?.comparables) {
      const subject = data.data.subject
      setSubjectProperty(subject)
      setComparables(data.data.comparables)
      setSelectedProperties(data.data.comparables)
      setProperties([subject, ...data.data.comparables])
      setReportName(data.name)
      setReportId(data.id)
    }
  }

  const {
    data: results1,
    error: error1,
    isLoading: isLoading1
  } = useFetch({
    url: `${HELLO_DATA_PROPERTY_SEARCH_ENDPOINT}?q=${search1}`,
    enabled: Boolean(search1.trim()),
    options: {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    }
  })

  const {
    data: results2,
    error: error2,
    isLoading: isLoading2
  } = useFetch({
    url: `${HELLO_DATA_PROPERTY_SEARCH_ENDPOINT}?q=${search2}`,
    enabled: Boolean(search2.trim()),
    options: {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    }
  })

  function updatePropertiesSimilarityScores(comparables) {
    if (selectedProperties.length && selectedProperties.length > 0) {
      const updatedProperties = selectedProperties.map((property) => {
        const comparable = comparables.find((comparable) => comparable.id === property.id)

        if (!comparable) {
          return property
        }

        property.data.similarity_score = comparable.similarity_score
        return new Property(property.data, { similarity_score: comparable.similarity_score })
      })

      setSelectedProperties(updatedProperties)
    }
  }

  async function handleUpdateReport() {
    try {
      setLoading(true)
      const res = await updateReport({
        id: reportId,
        name: reportName,
        subject: subjectProperty,
        comparables: selectedProperties
      })
      if (res.id) {
        setLoading(false)
        window.location.href = `/portal/comp_analysis_reports/${res.id}`
      }
    } catch (e) {
      console.log(e)
    }
  }

  async function onSelectSubject(result = {}) {
    setSearch1("")

    try {
      const data = await fetchPropertyDetail(result.id)
      const property = new Property(data, { isSubject: true })

      setSubjectProperty(property)
    } catch (e) {
      console.log(e)
    }
  }

  async function onSelectProperty(result = {}) {
    setLoadingFetchProperty(true)
    setSearch2("")

    if (properties.length && properties.find((property) => property.id === result.id)) {
      return
    }

    if (!subjectProperty) {
      return
    }

    try {
      const data = await fetchPropertyDetail(result.id)
      const { comparables = [] } = await fetchPropertyComparables({
        topN: 1,
        selected_ids: [result.id],
        subject: subjectProperty
      })
      setSelectedProperties([
        ...selectedProperties,
        new Property(data, {
          isSubject: false,
          similarity_score: comparables[0]?.similarity_score
        })
      ])
      setLoadingFetchProperty(false)
    } catch (e) {
      console.log(e)
    }
  }

  function onRemoveProperty(property) {
    if (property.isSubject) {
      setSubjectProperty(null)
    }

    if (selectedProperties.length) {
      setSelectedProperties(selectedProperties.filter((item) => item.id !== property.id))
    }
  }
  useEffect(() => {
    if (subjectProperty) {
      setProperties([subjectProperty, ...selectedProperties])
    } else {
      setProperties(selectedProperties)
    }
  }, [subjectProperty, selectedProperties])

  useEffect(() => {
    async function onSuggestComps() {
      if (subjectProperty && selectedProperties.length && selectedProperties.length > 0) {
        const { comparables = [] } = await fetchPropertyComparables({
          topN: selectedProperties.length,
          selected_ids: selectedProperties.map((property) => property.id),
          subject: subjectProperty.data
        })

        setComparables(comparables)
      }
    }
    onSuggestComps()
  }, [subjectProperty])

  useEffect(() => {
    updatePropertiesSimilarityScores(comparables)
  }, [comparables])

  useEffect(() => {
    const fetchData = async () => {
      await fetchReportData()
    }
    fetchData()
  }, [])

  return (
    <div className="py-6">
      <Breadcrumb className="px-5 py-3">
        <Breadcrumb.Item className="font-semibold" href="/portal/comp_analysis_reports">
          Comp Analysis Report
        </Breadcrumb.Item>
        <Breadcrumb.Item>Edit Report</Breadcrumb.Item>
      </Breadcrumb>

      <div className="mb-4 flex items-center px-8">
        <h1 className="flex-1 text-xl font-semibold">Edit Property Report: {reportName}</h1>
      </div>
      <div className="px-8">
        {!subjectProperty ? (
          <div className="space-y-2">
            <h2 className="mt-3 text-lg font-medium">Property Name or Address:</h2>
            <div className="relative max-w-lg">
              <TextInput
                icon={HiSearch}
                value={search1}
                className="lg:max-w-lg"
                placeholder="Enter an address or building name"
                onChange={(e) => setSearch1(e.target.value)}
              />
              <SearchResultList
                show={Boolean(search1.trim()) && (isLoading1 || results1?.length)}
                error={error1}
                results={results1}
                isLoading={isLoading1}
                onSelectResult={onSelectSubject}
              />
            </div>
          </div>
        ) : null}

        {properties.length ? (
          <RentCompsTable className="mt-4" properties={properties} removeable={true} onRemove={onRemoveProperty} />
        ) : null}

        <div className="space-y-2">
          {subjectProperty && (
            <div className="mt-4 flex items-center justify-between">
              <h2 className="mt-3 text-lg font-medium">Rent Comps</h2>
            </div>
          )}

          {subjectProperty ? (
            <>
              {!loadingFetchProperty ? (
                <div className="relative">
                  <TextInput
                    icon={HiSearch}
                    value={search2}
                    className="lg:max-w-lg"
                    placeholder="Enter an address or building name"
                    disabled={comparables.length >= MAX_COMPARABLES}
                    onChange={(e) => setSearch2(e.target.value)}
                  />
                  <SearchResultList
                    show={Boolean(search2.trim()) && (isLoading2 || results2?.length)}
                    error={error2}
                    results={results2}
                    isLoading={isLoading2}
                    onSelectResult={onSelectProperty}
                  />
                </div>
              ) : (
                <LoadingButton />
              )}
            </>
          ) : null}
        </div>
      </div>
      {subjectProperty && (
        <div className="mt-8 px-8">
          <div className="flex items-center">
            <HiInformationCircle className="mr-2" />
            <p className="text-sm font-medium text-gray-800">
              Once you've selected your comparable properties, click Generate Report below to save your choices and view
              the detailed analysis.
            </p>
          </div>
          <div className="mt-4 flex items-center justify-end space-x-4">
            {!loading ? <Button onClick={handleUpdateReport}>Update Report</Button> : <LoadingButton />}
          </div>
        </div>
      )}
    </div>
  )
}
