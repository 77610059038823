import React, { useEffect } from "react"
import AvailabilityProvider from "../../context/AvailabilitiesContext"
import AvailabilityNew from "../../shared/AvailabilityNew"
import AvailabilityEdit from "../../shared/AvailabilityEdit"

export default function Concession({ params, page }) {
  return (
    <AvailabilityProvider>
      {page === "new" &&
        <AvailabilityNew params={params} />
      }
      {page === "edit" &&
        <AvailabilityEdit params={params} />
      }
    </AvailabilityProvider>
  )
}
