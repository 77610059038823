import React, { useState, useContext } from 'react';
import { GlobalContext } from "../context/GlobalContext"

const ChatWidget = (props) => {

  // disabling for now in favor of intercom chat
  // main entry point will be Ask Paul dedicated page
  return null

  if (props.currentPath === "/portal/paul" || props.currentPath === "/portal/support") {
    return null;
  }

  const { currentUserData } = useContext(GlobalContext)

  const fullName = currentUserData?.currentUser?.name;
  const firstName = fullName?.split(' ')[0];

  const [isOpen, setIsOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false); // New state for loading

  const toggleChat = () => setIsOpen(!isOpen);

  const handleInputChange = (e) => setInputMessage(e.target.value);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    // Add user message to chat
    setMessages([...messages, { text: inputMessage, sender: 'user' }]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const token = document.querySelector('meta[name="csrf-token"]').content;

      // Send message to API
      const response = await fetch('/chats/ask', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': token
        },
        body: JSON.stringify({ question: inputMessage, compact_mode: true }),
      });
      const data = await response.json();
      // Add API response to chat
      setMessages(prevMessages => [...prevMessages, { text: data.message, sender: 'bot' }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, { text: 'Sorry, there was an error processing your request.', sender: 'bot' }]);
    } finally {
        setIsLoading(false); // Set loading to false
    }
  };

  return (
    <div className="fixed bottom-4 right-4">
      {!isOpen && (
        <button
          onClick={toggleChat}
          className="text-white rounded-full p-3 shadow-lg hover:bg-blue-600"
          style={{ backgroundColor: 'rgb(20, 49, 86)' }}
        >
          <div className="flex flex-row">
            <span className="ml-2 font-bold"> Ask Paul</span>
            <span className="text-xxs font-medium ml-1 mr-2 text text-bgold">BETA</span>
            <svg className="w-6 h-6" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 9.75a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375m-13.5 3.01c0 1.6 1.123 2.994 2.707 3.227 1.087.16 2.185.283 3.293.369V21l4.184-4.183a1.14 1.14 0 0 1 .778-.332 48.294 48.294 0 0 0 5.83-.498c1.585-.233 2.708-1.626 2.708-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"></path>
            </svg>
          </div>
        </button>
      )}
      {isOpen && (
        <div className="bg-white rounded-lg shadow-xl w-80 min-h-96 h-full flex flex-col">
          <div className="text-white p-4 rounded-t-lg flex justify-between items-center" style={{ backgroundColor: 'rgb(20, 49, 86)' }}>
            <h3 className="font-bold text-white">Paul</h3>
            <button onClick={toggleChat}>
              <svg className="w-6 h-6 hover:text-blue-600" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"></path>
              </svg>
            </button>
          </div>
          <div className="flex-grow overflow-y-auto px-4 pt-4 pb-8 font-medium">
            {messages.length === 0 ? (
              <div className="text-gray-500">Hi {firstName}! Ask me a question about your Brynsights data, I'm here to help. Here are some examples of questions:<br/>
              <ul className="list-disc ml-5 mt-2">
                <li>How many properties are on each ILS?</li>
                <li>Last quarter how many leads did I get from each ILS?</li>
                <li>How much have I spent on each ILS in the last 6 months?</li>
              </ul>
              </div>
            ) : (
              messages.map((message, index) => (
                <div
                  key={index}
                  className={`mb-2 p-2 rounded ${
                    message.sender === 'user' ? 'bg-blight ml-auto' : 'bg-gray-50'
                  } max-w-[70%]`}
                >
                  {message.text}
                </div>
              ))
            )}
            {isLoading && (
    <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <style>
      {`
        .spinner_b2T7 {
          animation: spinner_xe7Q 0.8s linear infinite;
        }
        .spinner_YRVV {
          animation-delay: -0.65s;
        }
        .spinner_c9oY {
          animation-delay: -0.5s;
        }
        @keyframes spinner_xe7Q {
          93.75%, 100% { r: 3px; }
          46.875% { r: 0.2px; }
        }
      `}
    </style>
    <circle className="spinner_b2T7" cx="4" cy="12" r="3" />
    <circle className="spinner_b2T7 spinner_YRVV" cx="12" cy="12" r="3" />
    <circle className="spinner_b2T7 spinner_c9oY" cx="20" cy="12" r="3" />
  </svg>            )}
          </div>
          <form onSubmit={handleSubmit} className="p-4 border-t">
            <div className="flex">
              <input
                type="text"
                value={inputMessage}
                onChange={handleInputChange}
                placeholder="Type a question..."
                className="flex-grow border rounded-l-lg p-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <button
                type="submit"
                className="text-white rounded-r-lg p-2 hover:bg-blue-600"
                style={{ backgroundColor: 'rgb(20, 49, 86)' }}
              >
                <svg className="w-5 h-5" data-slot="icon" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"></path>
                </svg>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default ChatWidget;