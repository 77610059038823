// from https://angelos.dev/2019/09/render-react-components-from-rails-views/

export default ({ components, renderComponent, onBeforeMount = () => null, loadEventName = "DOMContentLoaded" }) => {
  const mountComponentNode = (node) => {
    const componentAttr = node.dataset.reactComponent
    const component = components[componentAttr]

    if (!component) {
      console.warn(`${componentAttr}: not registered`)
      return
    }

    const propsAttr = node.dataset.reactProps

    if (!propsAttr) {
      console.warn(`${componentAttr}: missing props`)
    }

    const props = propsAttr ? JSON.parse(propsAttr) : {}

    renderComponent(component, props, node)
  }

  const mountComponentNodes = (nodes) => {
    nodes.forEach(mountComponentNode)
  }

  const mountComponents = () => {
    const componentNodes = document.querySelectorAll("[data-react-component]")
    mountComponentNodes(componentNodes)
  }

  document.addEventListener(loadEventName, () => {
    onBeforeMount()
    mountComponents()
  })
}
