export const GEOFENCING_CAMPAINGS_QUERY = `
  query {
    geofencingCampaigns {
      id
      geofencingDetailsId
      clicks
      costPerWalkIn
      cpc
      cpm
      ctr
      id
      impressions
      name
      totalCost
      walkIns
    }
  }
`

export const GEOFENCING_PERFORMANCE_QUERY = `
  query {
    geofencingPerformance {
      id
      geofencingDetailsId
      impressions
      cpm
      views
      clicks
      ctr
      cpc
      total
      walkIns
      costPerWalkIn
    }
  }
`

export const GEOFENCING_TV_CAMPAINGS_QUERY = `
  query {
    geofencingTvCampaigns {
      id
      geofencingDetailsId
      name
      views
      cpm
      completeRate
      muteRate
      pauseRate
      inStreamPreRoll
      inStreamMidRoll
      totalCost
      walkIns
      costPerWalkIn
    }
  }
`

export const GEOFENCING_DETAILS_QUERY = `
  query {
    geofencingDetails {
      id
      filename
      importDate
    }
  }
`

export const GEOFENCING_GEOFENCE_PERFORMANCES_QUERY = `
  query {
    geofencingGeofencePerformances {
      id
      geofencingDetailsId
      name
      campaign
      impressions
      clicks
      ctr
      totalCost
      cpc
      viewthroughWalkIns
      clickthroughWalkIns
      walkIns
      costPerWalkIn
    }
  }
`

export const GEOFENCING_GEOFENCE_AD_PERFORMANCES_QUERY = `
  query {
    geofencingGeofenceAdPerformances {
      id
      geofencingDetailsId
      name
      impressions
      clicks
      ctr
      walkIns
      preview
    }
  }
`
