import React, { useEffect } from "react"
import DepositProvider from "../../context/DepositsContext"
import DepositNew from "../../shared/DepositNew"
import DepositEdit from "../../shared/DepositEdit"

export default function Deposit({ params, page }) {
  return (
    <DepositProvider>
      {page === "new" &&
        <DepositNew params={params} />
      }
      {page === "edit" &&
        <DepositEdit params={params} />
      }
    </DepositProvider>
  )
}
