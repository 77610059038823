import React, { useEffect } from "react"
import AccountsProvider from "../../context/AccountsContext"
import AccountNew from "../../shared/AccountNew"
import AccountEdit from "../../shared/AccountEdit"

export default function Account({ params, page }) {
  return (
    <AccountsProvider>
      {page === "new" &&
        <AccountNew params={params} />
      }
      {page === "edit" &&
        <AccountEdit params={params} />
      }
    </AccountsProvider>
  )
}
