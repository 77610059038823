import React from "react"
const consumer_fusion = require("../../../../../public/samples/consumer_fusion.png")

export default function ConsumerFusion() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Consumer Fusion</h1>
			<p className="text-lg font-medium">Integrate with your reputation management provider</p>
			<div className="flex justify-center align-center mt-8">
				<img src={consumer_fusion} />
			</div>
		</div>
	)
}