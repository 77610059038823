import React, { useState } from "react"
import CheckBox from "./CheckBox"

export default function CheckBoxExtended({ inputId, name, handleClick, id, checked }) {
  const [currentChecked, setCurrentChecked] = useState(checked)
  return (
    <CheckBox
      id={id}
      inputId={inputId}
      name={name}
      checked={currentChecked}
      handleClick={() => {
        setCurrentChecked(!currentChecked)
        if (handleClick) {
          handleClick(!currentChecked)
        }
      }} />
  )
}
