import { Accordion, Table } from "flowbite-react"
import React from "react"
import { useQuery } from "urql"

import Loading from "../../shared/Loading"
import BrynsonTooltip from "../../shared/BrynsonTooltip"
import { InformationCircleIcon } from '@heroicons/react/24/outline'

const PROPERTIES_QUERY = `
  query {
    properties {
      id
      name
      city
      state {
        name
        abbreviation
      }
      semrushData(order: "id DESC") {
        id
        keyword
        semrushType
        data
      }
    }
  }
`

export default function SEOAudits() {
  const [{ data: propertiesData, fetching }, propertyReload] = useQuery({
    query: PROPERTIES_QUERY,
    requestPolicy: "network-only"
  })

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold">Who's Searching What</h1>
      <p className="text-lg font-medium">See how people are searching for apartments in your geographies.</p>
      <p className="mt-2 font-normal">For each property, we audit the top results and relevant data for the following queries, which are consistently the top searches:</p>
      <div className="w-1/2">
        <ul className="mt-2 font-normal list-disc ml-4 grid grid-cols-2 gap-1 text-bgold">
          <li>"[city] [state] apartments"</li>
          <li>"1 bedroom apartments [city] [state]"</li>
          <li>"1 bdr apartments [city] [state]"</li>
          <li>"studio apartments[city] [state]"</li>
          <li>"1 bedroom apartments in [city] [state]"</li>
          <li>"2 bedroom apartments in [city] [state]"</li>
          <li>"pet friendly apartments in [city] [state]"</li>
          <li>"luxury apartments in [city] [state]"</li>
        </ul>
      </div>
      <p className="mt-2 font-normal">If any of these don't show up in the first section for the property, it means the search volume was too low for us to have data, so they're not queries worth targeting.</p>
      <div className="mt-8 flex justify-center">
        {propertiesData ? (
          <Accordion collapseAll className="w-full">
            {propertiesData.properties.map((property, index) => (
              <Accordion.Panel key={index}>
                <Accordion.Title>{property.name}</Accordion.Title>
                <Accordion.Content>
                  <h2 className="mb-2 text-lg font-semibold">Top websites for most popular rental intent searches.</h2>
                  <p className="mb-2 text-base font-semibold text-grey">We check the most common search queries in your locality, and show the top results.</p>
                  <p className="mb-4 text-base font-semibold text-grey">The top 3 results get 70% of the clicks, so you want to make sure your property is listed on those platforms.</p>
                  <div className="grid grid-cols-2 gap-4">
                    {property.semrushData
                      .filter((x) => x.semrushType === "phrase_organic")
                      .map((semrushData, semrushDataIndex) =>
                        semrushData.data && semrushData.data.length > 0 && (
                          <div className="pb-4" key={semrushDataIndex}>
                            <h2 className="font-semibold text-black">"{semrushData.keyword}"</h2>
                            <ul>
                              {semrushData.data.map((data, dataIndex) => (
                                <li key={dataIndex}>
                                  <a href={data.url}>{data.url}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        )
                      )}
                  </div>
                  <hr className="my-8 border-b border-bgray"/>
                  {property.semrushData
                    .filter(x => x.semrushType === "phrase_paid")
                    .flatMap(x => x.data)
                    .length > 0 && (
                    <div className="mt-6 w-full text-black">
                      <h2 className="mb-2 text-lg font-semibold">What is the cost to target these keywords?</h2>
                      <p className="mb-4 text-base font-semibold text-grey">For those common queries, see if anything has high volume and low CPC. If so, it may be worth targeting those in your PPC campaigns.</p>
                      <Table hoverable={true} className="text-md mt-4 text-black">
                        <Table.Head>
                          <Table.HeadCell>Keyword</Table.HeadCell>
                          <Table.HeadCell>
                            <div className="flex items-center">
                              Volume <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`volume-${property.id}`} />
                            </div>
                          </Table.HeadCell>
                          <Table.HeadCell>
                            <div className="flex items-center">
                              Cost per Click <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`cpc-${property.id}`} />
                            </div>

                          </Table.HeadCell>
                          <Table.HeadCell>
                            <div className="flex items-center">
                              Competition <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`comp-${property.id}`} />
                            </div>
                          </Table.HeadCell>
                          <Table.HeadCell>
                            <BrynsonTooltip
                              id={`volume-${property.id}`}
                              place="top"
                              render={() => (
                               <div className="max-w-[700px]">
                                <p className="text-lg font-medium">Search volume is the <span className="font-semibold">average number</span> of times a specific search query is entered on Google per month.</p>
                               </div>
                              )}
                            />
                            <BrynsonTooltip
                              id={`cpc-${property.id}`}
                              place="top"
                              render={() => (
                               <div className="max-w-[700px]">
                                <p className="text-lg font-medium">"CPC" is Cost-per-click. That’s the average price advertisers pay for a user to click on an ad triggered by the keyword in Google Ads.</p>
                                <p className="text-lg font-medium">Cost per click is calculated by dividing the total cost of an ad by the total number of clicks.</p>
                               </div>
                              )}
                            />
                            <BrynsonTooltip
                              id={`comp-${property.id}`}
                              place="top"
                              render={() => (
                               <div className="max-w-[700px]">
                                <p className="text-lg font-medium">"Competition" is Competitive Density. It is the level of competition in Google Ads measured from 0 to 1. This number represents how many advertisers compete for this particular keyword in paid search. The closer the score is to 1, the higher the number of advertisers currently bidding on this keyword. Therefore, the harder it would be to stand out in the search results.</p>
                                <p className="text-lg font-medium"><span className="font-semibold"> Above 0.80:</span> These keywords are highly competitive among advertisers and therefore likely send traffic to paid results. You could infer that if a keyword has a high density of advertisers, the search has some intent on making a transaction. </p>
                                <p className="text-lg font-medium"><span className="font-semibold">From 0.60-0.80:</span> Keywords with an average density of advertisers. You could experiment with advertising on these keywords if your organic efforts don’t work out, but it won’t be a quick win on any of these keywords, either.</p>
                                <p className="text-lg font-medium"><span className="font-semibold">Below 0.60:</span> Keywords with the lowest density of advertisers. This could either mean that they are under the radar of most competitors that advertise in your niche, or they are simply not profitable to advertise on..</p>

                               </div>
                              )}
                            />
                          </Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                          {property.semrushData
                            .filter(x => x.semrushType === "phrase_paid")
                            .flatMap(x => x.data)
                            .map((row, rowIndex) => (
                              <Table.Row key={rowIndex} data={row}>
                                <Table.Cell>{row.keyword}</Table.Cell>
                                <Table.Cell>{row.search_volume}</Table.Cell>
                                <Table.Cell>${row.cpc}</Table.Cell>
                                <Table.Cell>{row.competition}</Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </div>
                  )}
                  <hr className="my-8 border-b border-bgray"/>
                  <div className="mt-6 w-full text-black">
                    <h2 className="mb-2 text-lg font-semibold">What are your leads searching for?</h2>
                    <p className="mb-2 text-base font-semibold text-grey">For the top result in the first section, we checked what else your leads are searching for in Google</p>
                    <p className="mb-4 text-base font-semibold text-grey">If anything has high volume and low CPC, it may be worth targeting those in your PPC campaigns.</p>
                    <Table hoverable={true} className="text-md mt-4 text-black">
                      <Table.Head>
                        <Table.HeadCell>Keyword</Table.HeadCell>
                        <Table.HeadCell>
                          <div className="flex items-center">
                            Search Volume <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`volume-${property.id}`} />
                          </div>
                        </Table.HeadCell>
                        <Table.HeadCell><div className="flex items-center"> Competition <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={property.id} /></div></Table.HeadCell>
                        <BrynsonTooltip
                          id={property.id}
                          place="top"
                          render={() => (
                           <div className="max-w-[700px]">
                            <p className="text-lg font-medium">"Competition" is Competitive Density. It is the level of competition in Google Ads measured from 0 to 1. This number represents how many advertisers compete for this particular keyword in paid search. The closer the score is to 1, the higher the number of advertisers currently bidding on this keyword. Therefore, the harder it would be to stand out in the search results.</p>
                            <p className="text-lg font-medium"><span className="font-semibold"> Above 0.80:</span> These keywords are highly competitive among advertisers and therefore likely send traffic to paid results. You could infer that if a keyword has a high density of advertisers, the search has some intent on making a transaction. </p>
                            <p className="text-lg font-medium"><span className="font-semibold">From 0.60-0.80:</span> Keywords with an average density of advertisers. You could experiment with advertising on these keywords if your organic efforts don’t work out, but it won’t be a quick win on any of these keywords, either.</p>
                            <p className="text-lg font-medium"><span className="font-semibold">Below 0.60:</span> Keywords with the lowest density of advertisers. This could either mean that they are under the radar of most competitors that advertise in your niche, or they are simply not profitable to advertise on..</p>

                           </div>
                          )}
                        />
                        <BrynsonTooltip
                          id={`volume-${property.id}`}
                          place="top"
                          render={() => (
                           <div className="max-w-[700px]">
                            <p className="text-lg font-medium">Search volume is the <span className="font-semibold">average number</span> of times a specific search query is entered on Google per month.</p>
                           </div>
                          )}
                        />
                      </Table.Head>
                      <Table.Body className="divide-y">
                        {property.semrushData
                          .filter((x) => x.semrushType === "subfolder_organic")
                          .flatMap((x) => x.data)
                          .map((row, rowIndex) => (
                            <Table.Row key={rowIndex} data={row}>
                              <Table.Cell>{row.keyword}</Table.Cell>
                              <Table.Cell>{row.search_volume}</Table.Cell>
                              <Table.Cell>{row.competition}</Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>
                  <hr className="my-8 border-b border-bgray"/>
                  {property.semrushData && property.semrushData.some(x => x.semrushType === "related" && x.data && x.data.length > 0) && (
                    <div className="mt-6 w-full text-black">
                      <h2 className="mb-2 text-lg font-semibold">What are other phrases to target in PPC campaigns?</h2>
                      <p className="mb-2 text-base font-semibold text-grey">We looked for other search phrases that may have low competition to target.</p>
                      <Table hoverable={true} className="text-md mt-4 text-black">
                        <Table.Head>
                          <Table.HeadCell>Keyword</Table.HeadCell>
                          <Table.HeadCell>
                            <div className="flex items-center">
                              Volume <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`volume-${property.id}`} />
                            </div>
                          </Table.HeadCell>
                          <Table.HeadCell>
                            <div className="flex items-center">
                              Cost per Click <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`cpc-${property.id}`} />
                            </div>

                          </Table.HeadCell>
                          <Table.HeadCell>
                            <div className="flex items-center">
                              Competition <InformationCircleIcon className="h-6 w-6 ml-2" data-tooltip-id={`comp-${property.id}`} />
                            </div>
                          </Table.HeadCell>
                          <Table.HeadCell>
                            <BrynsonTooltip
                              id={`volume-${property.id}`}
                              place="top"
                              render={() => (
                               <div className="max-w-[700px]">
                                <p className="text-lg font-medium">Search volume is the <span className="font-semibold">average number</span> of times a specific search query is entered on Google per month.</p>
                               </div>
                              )}
                            />
                            <BrynsonTooltip
                              id={`cpc-${property.id}`}
                              place="top"
                              render={() => (
                               <div className="max-w-[700px]">
                                <p className="text-lg font-medium">"CPC" is Cost-per-click. That’s the average price advertisers pay for a user to click on an ad triggered by the keyword in Google Ads.</p>
                                <p className="text-lg font-medium">Cost per click is calculated by dividing the total cost of an ad by the total number of clicks.</p>
                               </div>
                              )}
                            />
                            <BrynsonTooltip
                              id={`comp-${property.id}`}
                              place="top"
                              render={() => (
                               <div className="max-w-[700px]">
                                <p className="text-lg font-medium">"Competition" is Competitive Density. It is the level of competition in Google Ads measured from 0 to 1. This number represents how many advertisers compete for this particular keyword in paid search. The closer the score is to 1, the higher the number of advertisers currently bidding on this keyword. Therefore, the harder it would be to stand out in the search results.</p>
                                <p className="text-lg font-medium"><span className="font-semibold"> Above 0.80:</span> These keywords are highly competitive among advertisers and therefore likely send traffic to paid results. You could infer that if a keyword has a high density of advertisers, the search has some intent on making a transaction. </p>
                                <p className="text-lg font-medium"><span className="font-semibold">From 0.60-0.80:</span> Keywords with an average density of advertisers. You could experiment with advertising on these keywords if your organic efforts don’t work out, but it won’t be a quick win on any of these keywords, either.</p>
                                <p className="text-lg font-medium"><span className="font-semibold">Below 0.60:</span> Keywords with the lowest density of advertisers. This could either mean that they are under the radar of most competitors that advertise in your niche, or they are simply not profitable to advertise on..</p>

                               </div>
                              )}
                            />
                          </Table.HeadCell>
                        </Table.Head>
                          <Table.Body className="divide-y">
                            {property.semrushData
                              .filter(x => x.semrushType === "related")
                              .flatMap(x => x.data)
                              .map((row, rowIndex) => (
                                <Table.Row key={rowIndex} data={row}>
                                  <Table.Cell>{row.keyword}</Table.Cell>
                                  <Table.Cell>{row.search_volume}</Table.Cell>
                                  <Table.Cell>${row.cpc}</Table.Cell>
                                  <Table.Cell>{row.competition}</Table.Cell>
                                </Table.Row>
                              ))}
                          </Table.Body>
                      </Table>
                    </div>
                  )}
                  {/* <div className="mt-6 w-full px-4 text-black">
                    <h2 className="font-semibold text-black">What are the top paid keywords targeting your leads:</h2>
                    <Table hoverable={true} className="text-md mt-4 text-black">
                      <Table.Head>
                        <Table.HeadCell>Keyword</Table.HeadCell>
                        <Table.HeadCell>Search Volume</Table.HeadCell>
                        <Table.HeadCell>Competition</Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y">
                        {property.semrushData
                          .filter((x) => x.semrushType === "subfolder_adwords")
                          .flatMap((x) => x.data)
                          .map((row, rowIndex) => (
                            <Table.Row key={rowIndex} data={row}>
                              <Table.Cell>{row.keyword}</Table.Cell>
                              <Table.Cell>{row.search_volume}</Table.Cell>
                              <Table.Cell>{row.competition}</Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>*/}
                  <hr className="my-8 border-b border-bgray"/>
                  {property.semrushData
                    .filter(x => x.semrushType === "destinations")
                    .flatMap(x => x.data)
                    .length > 0 && (
                    <div className="mt-6 w-full text-black">
                      <h2 className="font-semibold text-black mb-2">What other sites do your leads visit:</h2>

                      <Table hoverable={true} className="text-md mt-4 text-black">
                        <Table.Head>
                          <Table.HeadCell>Site</Table.HeadCell>
                          <Table.HeadCell>Traffic</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                          {property.semrushData
                            .filter(x => x.semrushType === "destinations")
                            .flatMap(x => x.data)
                            .map((row, rowIndex) => (
                              <Table.Row key={rowIndex} data={row}>
                                <Table.Cell>{row.to_target}</Table.Cell>
                                <Table.Cell>{row.traffic}</Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </div>
                  )}

                {property.semrushData && property.semrushData.some(x => x.semrushType === "rank" && x.data && x.data.length > 0) && (
                  <>
                    <hr className="my-8 border-b border-bgray"/>
                    <div className="mt-6 w-full text-black">
                      <h2 className="mb-2 text-lg font-semibold">Where are your leads starting their search?</h2>
                      <p className="mb-2 text-base font-semibold text-grey">While your leads end up on Apartments.com or Zillow, they may start somewhere else.</p>
                      <p className="mb-4 text-base font-semibold text-grey">If any of these have an advertising program, they may be worth considering.</p>
                      <Table hoverable={true} className="text-md mt-4 text-black">
                        <Table.Head>
                          <Table.HeadCell>Channel Type</Table.HeadCell>
                          <Table.HeadCell>Traffic</Table.HeadCell>
                          <Table.HeadCell>Website</Table.HeadCell>
                          <Table.HeadCell>Traffic Type</Table.HeadCell>
                          <Table.HeadCell>Traffic Share</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                          {property.semrushData
                            .filter((x) => x.semrushType === "rank")
                            .flatMap((x) => x.data)
                            .map((row, rowIndex) => (
                              <Table.Row key={rowIndex} data={row}>
                                <Table.Cell>{row.channel}</Table.Cell>
                                <Table.Cell>{row.traffic}</Table.Cell>
                                <Table.Cell>{row.from_target}</Table.Cell>
                                <Table.Cell>{row.traffic_type}</Table.Cell>
                                <Table.Cell>{row.traffic_share}</Table.Cell>
                              </Table.Row>
                            ))}
                        </Table.Body>
                      </Table>
                    </div>
                  </>
                )}

                  <hr className="my-8 border-b border-bgray"/>
                  <div className="mt-6 w-full text-black">
                    <h2 className="mb-2 text-lg font-semibold">Where do your leads spend most of their time?</h2>
                    <p className="mb-2 text-base font-semibold text-grey">When they're not apartment hunting, your leads also spend time on these platforms.</p>
                    <p className="mb-4 text-base font-semibold text-grey">If any of these have an advertising program, they may be worth considering.</p>
                    <Table hoverable={true} className="text-md mt-4 text-black">
                      <Table.Head>
                        <Table.HeadCell>Target</Table.HeadCell>
                        <Table.HeadCell>Categories</Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y">
                        {property.semrushData
                          .filter((x) => x.semrushType === "audience")
                          .flatMap((x) => x.data)
                          .map((row, rowIndex) => (
                            <Table.Row key={rowIndex} data={row}>
                              <Table.Cell>{row.target}</Table.Cell>
                              <Table.Cell>{row.categories}</Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>
                  <hr className="my-8 border-b border-bgray"/>
                  <div className="mt-6 w-full text-black">
                    <h2 className="mb-2 text-lg font-semibold">
                      What social media platforms do your leads spend time on?
                    </h2>
                    <p className="mb-4 text-base font-semibold text-grey">Social platforms all have dedicated, highly targeted advertisting programs that may be worth considering.</p>
                    <Table hoverable={true} className="text-md mt-4 text-black">
                      <Table.Head>
                        <Table.HeadCell>Name</Table.HeadCell>
                      </Table.Head>
                      <Table.Body className="divide-y">
                        {property.semrushData
                          .filter((x) => x.semrushType === "social_media")
                          .flatMap((x) => x.data)
                          .slice(0, 10)
                          .map((row, rowIndex) => (
                            <Table.Row key={rowIndex} data={row}>
                              <Table.Cell>{row.social_name}</Table.Cell>
                            </Table.Row>
                          ))}
                      </Table.Body>
                    </Table>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            ))}
          </Accordion>
        ) : (
          <Loading />
        )}
      </div>
    </div>
  )
}
