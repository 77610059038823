import React, { useContext } from "react"
import PropertiesProvider from "../../context/PropertiesContext"
import { GlobalContext } from "../../context/GlobalContext"
import PropertyShowDirectory from "../../shared/PropertyShowDirectory"

export default function PropertyDirectory({ practice, practiceUser }) {
  const { currentUserData } = useContext(GlobalContext)

  const olympus_directory = "https://docs.google.com/spreadsheets/d/e/2PACX-1vQLmL-0m7SEonFTOiXnBTo2Fg2pGJhlXSrWlBdYCGIRmwr5MGqXdBPRWOO-dSLKRg/pubhtml?widget=true&headers=false"
  const bruns_directory = "https://docs.google.com/spreadsheets/d/e/2PACX-1vSPKtmRq29oGfa-PankV6inUIwQTwNi5_hNGuzIafqbuW0AVcIYOSqSp_O_6npnpw/pubhtml?widget=true&headers=false"

  function renderDirectory() {

    return <PropertyShowDirectory practice={practice} practiceUser={practiceUser} />;

    const about = currentUserData?.currentUser?.currentCompany?.about;

    if (about === "Olympus Property") {
      return (
        <iframe
          src={olympus_directory}
          frameBorder="0"
          width="100%"
          height="900"
        />
      );
    } else if (about === "Bruns Realty Group") {
      return (
        <iframe
          src={bruns_directory}
          frameBorder="0"
          width="100%"
          height="900"
        />
      );
    } else {
      return <PropertyShowDirectory practice={practice} practiceUser={practiceUser} />;
    }
  }



  return (
    <PropertiesProvider>
      <div className="p-8">
        <h1 className="font-semibold text-xl">Property Overview</h1>
        <div className="w-full">
          {renderDirectory()}
        </div>
      </div>
    </PropertiesProvider>
  )
}