import React from "react";
import BrynsonTabs from "../../shared/BrynsonTabs";

export default function SpecialProperty({ about }) {
  const dcaaUrls = {
    'Bruns Realty Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTOwLLGhCyLBa4ugHFWlpFEaAspArRbsYio5TZCmimd0xEjnPk9wc0-LS2ACY7Nj-dJRaBjutDVQOmZ/pubhtml?widget=true&amp;headers=false",
    'AMBO Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSx8fY7fqBXifFjt5Tg63DZ0mD_Ag4sJMlqjxpjaK3a1Jr1_h_rv3ep0Pr32hYVgcXY-ZefwaZchSKu/pubhtml?widget=true&amp;headers=false",
    'Aberly Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRTW4z2AgpSeB0pF378r9oSAAKl0yGIye92HuOU-JlKvnZiCLy5FDs2q-LykuTHCkmkbi5g35GLhDGL/pubhtml?widget=true&amp;headers=false",
    'Bellrock Real Estate Partners LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSfz0bWCAAWD4ZdAVWU1rWfiC7Gxxft6LDm8-jz976vG4909cH_JwegW3kd6mUdBDMbCvRkxbMQT7i7/pubhtml?widget=true&amp;headers=false",
    'Chelsea Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSaEKYk1FXwcRomrSwz-7Wb99EBBG-pbdgm6PdX4nxDKGh3W15bxrN_m3Mhg62wqA/pubhtml?widget=true&amp;headers=false",
    'Dawn Homes Management Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQRyhydR2l5vSEKsPxy93myO4PvUdlZrkwbGCJ34fkAXdRiNhfWqoJMmCiKaiE7ZjbFBNkiSAK_TN6K/pubhtml?widget=true&amp;headers=false",
    'Finning Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRvZ8HeQWk8nEhZXmiLDoByKIwO810EzpyH-zaaU94F2D56tnfPTMRnWOSNHRAn-XkIUiLtZxqB-Gw1/pubhtml?widget=true&amp;headers=false",
    'Goldfarb Properties': "https://docs.google.com/spreadsheets/d/e/2PACX-1vS0CNMJ4K1H60y6nc1VIIV5N6EzivT5ev2PscLi4hQ06_BgXs5mGRhICP4cZ-OJmxkvHH-LXw-c7I43/pubhtml?widget=true&amp;headers=false",
    'Judge Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vR4pS5dSOYP1gz5bmkRpdmP_aC9X8LP8lPz8oW8EDtjTUBKtR1MFTfQOADjlF3DJADKNo2TyWbpCbuO/pubhtml?widget=true&amp;headers=false",
    'M3 Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQFMpVQ7_jKdCSM4U6NMKrRwYYf549hBZx7a_Ng-O-UbInZZGRZEnnAfL712Swhl4KTeHVP3FKgAMNU/pubhtml?widget=true&amp;headers=false",
    'Olive Tree Management': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRbs17wzomjorDOUksBOej4NctSZJRd0J4LXkGg22hmbtZaJWfdYYWvmQYNHERB2XiXj50stPjaStj3/pubhtml?widget=true&amp;headers=false",
    'Omni Development': "https://docs.google.com/spreadsheets/d/e/2PACX-1vTIv6ojwUhM84fAVhqJd03cwuPFClc7eda-PDyNDx6NR95QjrVLFuY3I3VgdYWHLrrOnB3veBhHir4x/pubhtml?widget=true&amp;headers=false",
    'REM Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRdvTruwfkAuxnKwQQaeX_CJk95NZuu9BN4CSSVS3RSuyWJujS9AsQ6-t6jRiOEYTaHaH6xsiu-UF79/pubhtml?widget=true&amp;headers=false",
    'Skytian Capital': "https://docs.google.com/spreadsheets/d/e/2PACX-1vROx24jePta82mmtaIxYr1T7f8x_7soZtYFzkqTTcxQd70UKcQuDYYLzrWUnjIoC4V26uCU6z7iEnNq/pubhtml?widget=true&amp;headers=false",
    'Tri City Rentals': "https://docs.google.com/spreadsheets/d/e/2PACX-1vSiRppYV-CAHA94SN56oabuWQ3v6IllKAHTtZTTYrcy9lksCtDJ-n5q0u8vjMAZORB1VA1CMbHpwOex/pubhtml?widget=true&amp;headers=false",
    'WPHS Venture Partners': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ0TkWK5ptCjcbZTJNhDER3hAq_2-Y8AYFs53uff4s2TDiD1dJJcfmfNW6N0gZdX2IjjikHl2JryF0a/pubhtml?widget=true&amp;headers=false",
    'Westmere Village': "https://docs.google.com/spreadsheets/d/e/2PACX-1vT4m-1LqcZW3o5BlhBPQo_Ls-7Rba4srGq72-bc7upkVq5QN3mEl7MkLUuV-HkYaw2iARjZfTluDrha/pubhtml?widget=true&amp;headers=false",
    'PMC Demo': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRl2clob7JxsxoVCKI22_vUzkTM1siX3ICoOFsojxae245k7npkswsbqJNDZMK0G7rzJp0U4OrX9r7O/pubhtml?widget=true&amp;headers=false",
    'The Sterling Group': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQM6aOLJCGHFgpkpEUe8Z8QjAygE0H_R3bK7pwnKlricevXkhNbnlip3b0lLNhAL7Y7-ub8BFpCJAku/pubhtml?widget=true&amp;headers=false",
    'Velo Residential': "https://docs.google.com/spreadsheets/d/e/2PACX-1vQezJAl3gn675W1Owommf6U-Abpu8JGPLJm3LaTXNzkDyi57OIWNB4yJHh7jtXjx2-lWrMo7X_r5unJ/pubhtml?widget=true&amp;headers=false",
    'Prime Management LLC': "https://docs.google.com/spreadsheets/d/e/2PACX-1vRT9XU_2F-EGd0GGWip1d2KAIHJTERbNPXEyhx_ZBMKmtigKhJ9y7oCqfv9E_1t-KCuFUkCAT2eLths/pubhtml?widget=true&amp;headers=false"
  };


  const olympusUrls = {
    West: "https://docs.google.com/spreadsheets/d/e/2PACX-1vRX_hQ6bwKIyXA0MFxBSKdzTuOJfl9TkR0H8wDGedxakFQpbkHnYMaBEYow1NLI7fX4XkF7geY4ZTSQ/pubhtml?widget=true&amp;headers=false",
    Central: "https://docs.google.com/spreadsheets/d/e/2PACX-1vTMaCdcR7ihBIEo_k7J9d3Uz-IUgkOp5q8-4uDAui8edmud5lhTSEUKY4QoSbmvsW4sFzK8eZFN1bK4/pubhtml?widget=true&amp;headers=false",
    East: "https://docs.google.com/spreadsheets/d/e/2PACX-1vQg3GmAlTHVAGEbKstCYlExAuYOs60ho_2GmmQDQo_Tuqq57tKeTuD-P2OjlKIlT88nIQBECX-P99Rf/pubhtml?widget=true&amp;headers=false"
  };

  const tabs = [
    { label: "West", url: olympusUrls.West },
    { label: "Central", url: olympusUrls.Central },
    { label: "East", url: olympusUrls.East }
  ];

  const renderIframe = (url) => (
    <iframe src={url} frameBorder="0" width="100%" height="900"></iframe>
  );

  const renderTabsForOlympus = () => (
    <BrynsonTabs className="mt-4" tabs={tabs}>
      {tabs.map((tab, index) => (
        <div key={index} label={tab.label}>
          {renderIframe(tab.url)}
        </div>
      ))}
    </BrynsonTabs>
  );

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold">Digital Curb Appeal Audits</h1>
      <div className="w-2/3">
        {about === "Olympus Property" ? (
          renderTabsForOlympus()
        ) : (
          renderIframe(dcaaUrls[about] || "about not found placeholder URL")
        )}
      </div>
    </div>
  );
}
