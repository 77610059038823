import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_CONCESSION_MUTATION, UPDATE_CONCESSION_MUTATION } from "../queries/ConcessionsMutations";

export const ConcessionsContext = createContext(null);

const ConcessionsProvider = ({ children, ...props }) => {
  const [, createConcession] = useMutation(CREATE_CONCESSION_MUTATION)
  const [, updateConcession] = useMutation(UPDATE_CONCESSION_MUTATION)

  return(
    <ConcessionsContext.Provider value={{createConcession, updateConcession}}>
      {children}
    </ConcessionsContext.Provider>
  );
}

export default ConcessionsProvider;
