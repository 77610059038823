import React, { useEffect } from "react"
import FeesProvider from "../../context/FeesContext"
import FeeNew from "../../shared/FeeNew"
import FeeEdit from "../../shared/FeeEdit"

export default function Fee({ params, page }) {
  return (
    <FeesProvider>
      {page === "new" &&
        <FeeNew params={params} />
      }
      {page === "edit" &&
        <FeeEdit params={params} />
      }
    </FeesProvider>
  )
}
