import { ResponsiveBarCanvas } from "@nivo/bar"
import { Label } from "flowbite-react"
import React, { useState, useEffect } from "react"

export function FutureAvailability({ properties = [] }) {
  const currentDate = new Date()
  const [timeFrame, setTimeFrame] = useState(6)
  const [futurePeriods, setFuturePeriods] = useState(10)

  const formatData = (property) => {
    const availabilityData = calculateAvailability(property.building_availability)
    return availabilityData
  }

  const calculateAvailability = (availability, periods = futurePeriods) => {
    const periodsData = []

    for (let i = 0; i < periods; i++) {
      const from = new Date(currentDate)
      from.setDate(from.getDate() + i * timeFrame)
      const to = new Date(from)

      to.setDate(to.getDate() + timeFrame)

      const formattedDate = `${from.getMonth() + 1}/${from.getDate()} - ${to.getMonth() + 1}/${to.getDate()}`

      // calculate available units for each property
      let count = 0
      availability.forEach((item) => {
        const exitMarket = new Date(item.exit_market)
        if (exitMarket <= to) {
          count++
        }
      })

      periodsData.push({
        property: formattedDate,
        ["Available Units"]: count
      })
    }

    return periodsData
  }

  let data = formatData(properties[0])

  const datesText = "Next 60 Days"

  useEffect(() => {
    setFuturePeriods(10)
    setTimeFrame(6)
  }, [])

  return (
    <div className="mt-8 rounded-md border">
      <div className="flex items-center justify-between p-4">
        <div>
          <p className="mb-1 text-xl font-semibold">Future Availability</p>
          <p className="text-xs font-medium text-gray-500">
            Monitor the future supply of availabities across your comps for units that have issued a notice to vacate.
          </p>
        </div>
        <div className="flex items-center space-x-8">
          <div className="flex items-center">
            <Label className="m-0 mr-2">{datesText}</Label>
          </div>
        </div>
      </div>
      <div className="h-80 overflow-x-auto">
        <ResponsiveBarCanvas
          data={data}
          keys={["Available Units"]}
          valueScale={{ type: "linear" }}
          indexScale={{ type: "band", round: true }}
          indexBy="property"
          colors={{ scheme: "paired" }}
          groupMode="grouped"
          borderRadius={4}
          innerPadding="16"
          margin={{ top: 50, right: 0, bottom: 50, left: 50 }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "top-right",
              direction: "row",
              justify: false,
              translateX: -400,
              translateY: -30,
              itemWidth: 160,
              itemHeight: 20,
              itemsSpacing: 2,
              symbolSize: 20,
              itemDirection: "left-to-right"
            }
          ]}
        />
      </div>
    </div>
  )
}
