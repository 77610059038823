import React, { useContext, useEffect, useMemo, useState } from "react"
import moment from "moment-timezone"
import BreadCrumbs from "../../shared/BreadCrumbs"
import DesktopTitle1 from "../../shared/DesktopH5"
import InputField from "../../shared/InputField"
import Selectbox from "../../shared/Selectbox"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import ProfilePhoto from "./ProfilePhoto"
import { GlobalContext } from "../../context/GlobalContext"

export default function PersonalSettings(params) {
  const { currentUserData, updateCurrentUser } = useContext(GlobalContext)
  const [error, setError] = useState()
  const [loading, setLoading] = useState()
  const [data, setData] = useState()
  
  const months = moment.months().map((month, index) => ({
    label: month,
    value: index + 1,
  }));

  useEffect(() => {
    if (currentUserData) {
      setData(currentUserData.currentUser)
    }
  }, [currentUserData])
  
  const breadCrumbs = [
    { link: "/portal/dashboard", title: "dashboard" },
    { link: "/portal/settings", title: "settings" },
    { link: "/portal/settings/personal", title: "personal" },
  ]

  return (
    <div className="p-8">
      { data && <div>
        <BreadCrumbs items={breadCrumbs} />
        <DesktopTitle1 content={"Edit Personal Settings"} />
        {error && <p className="text-red-600">{error}</p>}
        <div className="flex row flex-wrap grid grid-cols-2 gap-3">
          <div className="mb-2 w-full">
            <label htmlFor="personal-settings-name">Name</label>
            <InputField
              id={'personal-settings-name'}
              placeholder='Name'
              defaultValue={data.name}
              type="text"
              onChange={(input) => {
                data.name = input.target.value
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="personal-settings-email">E-mail</label>
            <InputField
              id={'personal-settings-email'}
              placeholder='E-mail'
              defaultValue={data.email}
              type="email"
              onChange={(input) => {
                data.email = input.target.value
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="personal-settings-password">Password</label>
            <InputField
              id={'personal-settings-password'}
              placeholder='Password'
              defaultValue={data.password}
              type="password"
              onChange={(input) => {
                data.password = input.target.value
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="personal-settings-password-confirmation">Password Confirmation</label>
            <InputField
              id={'personal-settings-password-confirmation'}
              placeholder='Password Confirmation'
              defaultValue={data.password_confirmation}
              type="password"
              onChange={(input) => {
                data.password_confirmation = input.target.value
              }}
            />
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="personal-settings-birthday">Birthday</label>
            <div className="flex row flex-wrap grid grid-cols-2 gap-3">
              <div className="w-full">
                <InputField
                  id={'personal-settings-birthday'}
                  placeholder='Day'
                  defaultValue={data.birthday}
                  type="number"
                  min='1'
                  max='31'
                  step='1'
                  onChange={(input) => {
                    data.birthday = input.target.value ? parseInt(input.target.value) : null
                  }}
                />
              </div>
              <div className="w-full">
                <Selectbox
                  placeholder='Month'
                  defaultValue={data.birthdayMonth ? months.find(x => x.value === data.birthdayMonth) : null}
                  options={months}
                  onChange={(value) => {
                    data.birthdayMonth = value
                  }}
                  update={false}
                />
              </div>
            </div>
            
          </div>
          <div className="mb-2 w-full">
            <label htmlFor="personal-settings-birthday">Photo</label>
            <ProfilePhoto filestackApiKey={params.filestack_api_key} />
          </div>
        </div>
        <div className="flex justify-center items-center w-full mt-6">
          <div>
            <BrynsonSquareButton
              type="primary"
              loading={loading}
              onClick={() => {
                setLoading(true)
                updateCurrentUser(data).then(() => {
                  setLoading(false)
                })
              }}
              label="Edit personal settings"
            />
          </div>
        </div>
      </div> }
    </div>
  )
}