import React from "react"
const keyworddata = require("../../../../../public/samples/keyworddata")

export default function KeywordMonitoring() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Keyword Monitoring</h1>
			<p className="text-lg font-medium">A page to show social listening data around key words and phrases</p>
			<div className="flex justify-center align-center mt-8">
				<img src={keyworddata} />
			</div>
		</div>
	)
}