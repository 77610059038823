import React, { useEffect } from "react"
import ParksProvider from "../../context/ParksContext"
import ParkNew from "../../shared/ParkNew"
import ParkEdit from "../../shared/ParkEdit"

export default function Park({ params, page }) {
  return (
    <ParksProvider>
      {page === "new" &&
        <ParkNew params={params} />
      }
      {page === "edit" &&
        <ParkEdit params={params} />
      }
    </ParksProvider>
  )
}
