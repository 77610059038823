import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import InputField from "./InputField"
import DesktopTitle1 from "./DesktopH5"
import { FloorplansContext } from "../context/FloorplansContext"
import BrynsonSquareButton from "./BrynsonSquareButton"
import BrynsonDatePicker from "./BrynsonDatePicker"
import CheckBoxExtended from "./CheckboxExtended"
import CurrencyInput from 'react-currency-input-field';
import Loading from "./Loading"
import BreadCrumbs from "./BreadCrumbs"

export default function FloorplanNew({ params }) {
  const prefix = "$"
  const page = "floorplan"
  const { propertyData, reloadProperty } = useContext(GlobalContext)
  const { createFloorplan } = useContext(FloorplansContext)
  const [error, setError] = useState()
  const [floorplanInput, setFloorplanInput] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchCreateFloorplans = (floorplanInput) => {
    const floorplanValues = {}
    floorplanValues['propertyId'] = JSON.parse(params)["id"];
    propertyData?.property?.floorplanColumns.forEach((item) => {
      if (floorplanInput[item.accessor]) {
        floorplanValues[item.accessor] = item.type === "decimal" || item.type === "integer" ? parseInt(floorplanInput[item.accessor]) : floorplanInput[item.accessor]
        if (item.type === "float") {
          floorplanValues[item.accessor] = parseFloat(floorplanInput[item.accessor])
        }
      } else {
        floorplanValues[item.accessor_value || item.accessor] = null;
      }
    })

    return floorplanValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadProperty(parseInt(id))
    }
  }, [params])

  useMemo(() => {
    if (propertyData?.property?.floorplanColumns) {
      const floorplanValues = {}
      propertyData?.property?.floorplanColumns.map((item) => {
        floorplanValues[item.accessor] = null
      })
      setFloorplanInput(floorplanValues)

      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: "/portal/listings", title: `Property ${propertyData?.property?.name || ""}`},
        { link: `/portal/floorplans/${propertyData?.property?.id}`, title: "Floorplans"},
        { link: null, title: "New" }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData?.property?.floorplanColumns])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  const addValue = (item, value) => {
    const floorplan = {}
    floorplan[`${item.accessor}`] = value
    setFloorplanInput({ ...floorplanInput, ...floorplan })
  }

  return (
    <>
      { propertyData ?
        <div>
          <div className="p-8">
            { breadCrumbs &&
              breadCrumbHeader()
            }
            <DesktopTitle1 content="Add New Floorplan" />
            {error && <p className="text-red-600">{error}</p>}
            <div className="flex row flex-wrap grid grid-cols-2 gap-3">
              {propertyData?.property?.floorplanColumns.map((item) => (
                <div className="row flex items-center w-full" key={`floorplan-insert-${item.accessor}`}>
                  {item.type === "decimal" &&
                    <div className="mb-2 w-full">
                      <label htmlFor="property-name">{item.Header}</label>
                      <CurrencyInput
                        className="m-0 w-full rounded border border-geyser bg-white text-navy shadow-none outline-0 focus:border-teal active:border-teal px-4 py-3.5"
                        name={item.accessor}
                        placeholder={item.Header}
                        defaultValue={""}
                        decimalsLimit={2}
                        onValueChange={(value, name) => {
                          addValue(item, value)
                        }}
                        prefix={prefix}
                      />
                    </div>
                  }
                  {item.type === "boolean" &&
                    <div className="my-4 w-full">
                      <CheckBoxExtended name={item.Header} checked={false} handleClick={(value) => {
                        addValue(item, value)
                      }} />
                    </div>
                  }
                  {item.type === "date" &&
                    <div className="mb-2 w-full">
                      <label htmlFor="property-name">{item.Header}</label>
                      <BrynsonDatePicker selectedDate={""} onChange={(date) => {
                        addValue(item, date)
                      }} />
                    </div>
                  }
                  {(item.type !== "boolean" && item.type !== "decimal" && item.type !== "date") &&
                    <div className="mb-2 w-full">
                      <label htmlFor="property-name">{item.Header}</label>
                      <InputField
                        id={`property-${item.accessor}-edit`}
                        placeholder={item.Header}
                        defaultValue={""}
                        type={item.type === "integer" ? "number" : "text" }
                        onChange={(input) => {
                          addValue(item, input.target.value)
                        }}
                      />
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
          <div className="flex justify-center items-center w-full m-3">
            {propertyData?.property?.floorplanColumns &&
              <BrynsonSquareButton
                type="primary"
                testId="add-new-property-button"
                loading={currentLoading}
                onClick={() => {
                  setLoading(true)

                  const createFloorplans = fetchCreateFloorplans(floorplanInput)

                  createFloorplan(createFloorplans).then((result) => {
                    if (result?.error) {
                      setError(`Error updating property: ${result?.error}`)
                    } else {
                      window.location.href=`/portal/floorplans/${JSON.parse(params)["id"]}`
                    }
                    setTimeout(() => {
                      setLoading(false)
                    }, 1000)
                  })
                }}
                label="Add New Floorplan"
              />
            }
          </div>
        </div>
      :
        <Loading />
      }
    </>
  )
}
