import React from "react"
const reputation = require("../../../../../public/samples/reputation")

export default function ReputationManagement() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Reputation Management</h1>
			<p className="text-lg font-medium">View your reviews across Google, Facebook, Yelp etc. including patterns and changes.</p>
			<div className="flex justify-center align-center mt-8">
				<img src={reputation} />
			</div>
		</div>
	)
}