import {
  PencilSquareIcon,
  TrashIcon,
  AdjustmentsHorizontalIcon,
  DocumentIcon,
  WalletIcon,
  CurrencyDollarIcon,
  PhotoIcon
} from "@heroicons/react/24/solid"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { Tooltip } from "react-tooltip"
import styled from "styled-components"

import { FloorplansContext } from "../context/FloorplansContext"
import { GlobalContext } from "../context/GlobalContext"

import BreadCrumbs from "./BreadCrumbs"
import BrynsonSquareButton from "./BrynsonSquareButton"
import BrynsonSquareLink from "./BrynsonSquareLink"
import BrynsonTable from "./BrynsonTable"
import CheckBoxExtended from "./CheckboxExtended"
import DesktopTitle3 from "./DesktopTitle3"
import FloorplanUpload from "./FloorplanUpload"
import Loading from "./Loading"
import Modal from "./Modal"
import Sidebar from "./Sidebar"

const TableWrapper = styled.div`
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function FloorplanShow({ params }) {
  const page = "floorplan"
  const { propertyData, reloadProperty, createFloorplanAmenities, deleteFloorplanAmenities } = useContext(GlobalContext)
  const { deleteFloorplan } = useContext(FloorplansContext)
  const [showDeleteFloorplan, setShowDeleteFloorplan] = useState(false)
  const [currentLoading, setLoading] = useState(false)
  const [uploadLoading, setUploadLoading] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [showSidebar, setShowSidebar] = useState(false)
  const [selected, setSelected] = useState()
  const [breadCrumbs, setBreadCrumbs] = useState()
  const [amenities, setAmenities] = useState([])
  const [search, setSearch] = useState()

  const handleUploadLoading = (value) => {
    setUploadLoading(value)
  }

  const showHideProperty = (property) => {}

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadProperty(parseInt(id))
    }
  }, [params])

  const id = JSON.parse(params)["id"]
  const tableHeader = () => (
    <div className="column is-5 is-offset-4 table-actions">
      <div className="mr-2">
        <FloorplanUpload
          buttonStyle="white"
          propertyId={`${JSON.parse(params)["id"]}`}
          setLoading={handleUploadLoading}
        />
      </div>
      <BrynsonSquareLink
        type="primary"
        label="Add New Floorplan"
        href={`/portal/floorplans/new?id=${JSON.parse(params)["id"]}`}
      />
    </div>
  )

  const floorplanColumns = [
    {
      accessor: "name",
      Header: "Name",
      type: "text"
    },
    {
      accessor: "beds",
      Header: "Beds",
      type: "text"
    },
    {
      accessor: "baths",
      Header: "Baths",
      type: "text"
    },
    {
      accessor: "sqFtMin",
      Header: "Sq.Ft",
      type: "text"
    },
    {
      id: "actions",
      accessor: "actions",
      className: "text-center",
      Cell: (props) => (
        <div className="row flex justify-end gap-4">
          <button
            data-tooltip-id="tooltip"
            data-tooltip-content="Remove"
            className="font-medium text-bblue underline"
            onClick={() => {
              setCurrentId(props.cell.row.original.id)
              setShowDeleteFloorplan(true)
            }}>
            <TrashIcon width={24} height={24} />
          </button>
          <button
            data-tooltip-id="tooltip"
            data-tooltip-content="Edit"
            className="font-medium text-bblue underline"
            onClick={() => {
              window.location.href = `/portal/floorplans/${props.cell.row.original.id}/edit`
            }}>
            <PencilSquareIcon width={24} height={24} />
          </button>
          <button
            data-tooltip-id="tooltip"
            data-tooltip-content="Amenities"
            className="font-medium text-bblue underline"
            onClick={() => {
              const floorplan = propertyData?.property?.floorplans.find(
                (item) => item.id === props.cell.row.original.id
              )
              if (floorplan) {
                setSelected(floorplan)
                setShowSidebar(true)
              }
            }}>
            <AdjustmentsHorizontalIcon width={24} height={24} />
          </button>
          {props.cell.row.original.concession ? (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Concessions"
                className="text-bblue"
                href={`/portal/concessions/${
                  props.cell.row.original.id
                }/edit?source=Floorplan&back=floorplans&back_id=${JSON.parse(params)["id"]}`}>
                <DocumentIcon width={24} height={24} />
              </a>
            </div>
          ) : (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Concessions"
                className="text-bblue"
                href={`/portal/concessions/new?id=${
                  props.cell.row.original.id
                }&source=Floorplan&back=floorplans&back_id=${JSON.parse(params)["id"]}`}>
                <DocumentIcon width={24} height={24} />
              </a>
            </div>
          )}
          {props.cell.row.original.deposit ? (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Deposit"
                className="text-bblue"
                href={`/portal/deposits/${props.cell.row.original.id}/edit?source=Floorplan&back=floorplans&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <WalletIcon width={24} height={24} />
              </a>
            </div>
          ) : (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Deposit"
                className="text-bblue"
                href={`/portal/deposits/new?id=${props.cell.row.original.id}&source=Floorplan&back=floorplans&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <WalletIcon width={24} height={24} />
              </a>
            </div>
          )}
          {props.cell.row.original.fee ? (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Fee"
                className="text-bblue"
                href={`/portal/fees/${props.cell.row.original.id}/edit?source=Floorplan&back=floorplans&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <CurrencyDollarIcon width={24} height={24} />
              </a>
            </div>
          ) : (
            <div>
              <a
                data-tooltip-id="tooltip"
                data-tooltip-content="Fee"
                className="text-bblue"
                href={`/portal/fees/new?id=${props.cell.row.original.id}&source=Floorplan&back=floorplans&back_id=${
                  JSON.parse(params)["id"]
                }`}>
                <CurrencyDollarIcon width={24} height={24} />
              </a>
            </div>
          )}
          <div>
            <a
              data-tooltip-id="tooltip"
              data-tooltip-content="Photos"
              className="text-bblue"
              href={`/portal/media?id=${props.cell.row.original.id}&source=Floorplan`}>
              <PhotoIcon width={24} height={24} />
            </a>
          </div>
        </div>
      )
    }
  ]

  const checked = (item) => {
    const amenity = selected?.amenitiesRecords?.find((i) => parseInt(i.amenityId) === parseInt(item.id))
    return amenity ? true : false
  }

  const filterAmenities = () => {
    let amenities = selected?.amenities
    if (search && amenities) {
      amenities = amenities.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    }
    setAmenities(amenities)
  }

  useMemo(() => {
    if (propertyData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: "/portal/listings", title: `Property ${propertyData.property.name || ""}` },
        { link: null, title: page }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData])

  useEffect(() => {
    filterAmenities()
  }, [selected])

  useEffect(() => {
    filterAmenities()
  }, [search])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  return (
    <div className="p-8">
      <Tooltip id="tooltip" />
      {propertyData?.property?.floorplans && !uploadLoading ? (
        <>
          {breadCrumbs && breadCrumbHeader()}
          <TableWrapper>
            <BrynsonTable
              data={propertyData?.property?.floorplans || []}
              columns={floorplanColumns}
              countTitle={propertyData?.property?.floorplans.length > 1 ? "total floorplans" : "floorplan"}
              header={tableHeader}
              rowClick={showHideProperty}
            />
          </TableWrapper>
        </>
      ) : (
        <Loading />
      )}
      <Sidebar
        visible={showSidebar}
        header={() => (
          <h5 className="grow text-center text-lg font-bold">{selected?.name ? selected?.name : ""} Amenities</h5>
        )}
        backgroundColor="#FFF"
        iconName="close"
        closeSidebar={() => {
          setShowSidebar(false)
        }}>
        {selected && showSidebar && (
          <>
            <input
              className="focus:border-teal active:border-teal m-0 mb-4 w-full rounded border border-geyser bg-white px-3 py-2 text-navy shadow-none outline-0"
              onChange={(e) => {
                setSearch(e.target.value)
              }}
            />
            <div className="row flex grid grid-cols-2 flex-wrap">
              {amenities?.map((item) => (
                <div className="m-2" key={`amenities-${item.id}`}>
                  <CheckBoxExtended
                    name={item.name}
                    checked={checked(item)}
                    handleClick={(value) => {
                      if (value) {
                        createFloorplanAmenities({
                          floorplanId: parseInt(selected?.id),
                          amenityId: parseInt(item?.id)
                        }).then((result) => {
                          const id = JSON.parse(params)["id"]
                          reloadProperty(parseInt(id))
                        })
                      } else {
                        deleteFloorplanAmenities({
                          floorplanId: parseInt(selected?.id),
                          id: parseInt(item?.id)
                        }).then((result) => {
                          const id = JSON.parse(params)["id"]
                          reloadProperty(parseInt(id))
                        })
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          </>
        )}
      </Sidebar>
      <Modal
        open={showDeleteFloorplan}
        closeModal={() => {
          setShowDeleteFloorplan(false)
        }}
        size="small"
        header={
          <div>
            <DesktopTitle3 content="Delete Floorplan" />
          </div>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="link"
              size="medium"
              onClick={() => {
                setShowDeleteFloorplan(false)
              }}
              label="Go Back"
            />
            <BrynsonSquareButton
              type="warning"
              size="medium"
              loading={currentLoading}
              className={"flex items-center justify-center"}
              onClick={() => {
                setLoading(true)
                deleteFloorplan({ id: currentId }).then((result) => {
                  setLoading(false)
                  setShowDeleteFloorplan(false)
                  const id = JSON.parse(params)["id"]
                  reloadProperty(parseInt(id))
                })
              }}
              label="Delete floorplan"
            />
          </>
        }>
        <DesktopTitle3 content="Are you sure you want to delete this floorplan? This action can't be undone." />
      </Modal>
    </div>
  )
}
