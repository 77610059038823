import React from "react"
const leaselabs = require("../../../../../public/samples/lease_labs.png")

export default function LeaseLabs() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">LeaseLabs</h1>
			<p className="text-lg font-medium">Integrate with your leasing provider</p>
			<div className="flex justify-center align-center mt-8">
				<img src={leaselabs} />
			</div>
		</div>
	)
}