import Swiper, { Navigation, Pagination } from "swiper"
import "swiper/swiper.scss"

document.addEventListener("turbolinks:load", function () {
  if ($("body").hasClass("public index")) {
    // Slider setup
    Swiper.use([Navigation])

  }

  if ($("body").hasClass("applications new")) {
    // Slider setup
    Swiper.use([Pagination])
  }

  $(".toggle").click(function (e) {
    e.preventDefault()

    var $this = $(this)

    if ($this.next().hasClass("show")) {
      $this.removeClass("active")
      $this.next().removeClass("show")
      $this.next().slideUp(350)
    } else {
      $this.parent().parent().find("li a").removeClass("active")
      $this.parent().parent().find("li .inner").removeClass("show")
      $this.parent().parent().find("li .inner").slideUp(350)
      $this.next().toggleClass("show")
      $this.toggleClass("active")
      $this.next().slideToggle(350)
    }
  })

  $("#tabs li").on("click", function () {
    var tab = $(this).data("tab")

    $("#tabs li").removeClass("is-active")
    $(this).addClass("is-active")

    $("#tab-content .columns.sections").removeClass("is-active")
    $('.columns.sections[data-content="' + tab + '"]').addClass("is-active")
  })

  $(".show-more").on("click", function () {
    $("a.button.show-more").hide()
    $(".column.is-hidden-mobile").removeClass("is-hidden-mobile")
    $(".column.is-hidden-tablet").removeClass("is-hidden-tablet")
  })
})
