
export const ACCOUNT_QUERY = `
  query Account($id:ID!){
    account(id:$id) {
      id
      propertyId
      liveDate
      liveFlag
      lastDayBegin
      lastDayEnd
      lastAccMthEnd
      accMthEndFlag
      lastInvoiceNumber
      lastReceiptNumber
      chashReverse
      endOfYear
      accountsReceivable
      accountsPayable
      generalLedger
      currentAccountingPeriodAr
      currentAccountingPeriodAp
      taxCredit
      property {
        id
        name
      }
      columns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_ACCOUNT_MUTATION = `
  mutation CreateAccountMutation($propertyId: Int!, $liveDate: ISO8601Date, $liveFlag: String, $lastDayBegin: ISO8601Date, $lastDayEnd: ISO8601Date, $lastAccMthEnd: ISO8601Date, $accMthEndFlag: String, $lastInvoiceNumber: Int, $lastReceiptNumber: Int, $chashReverse: Float, $endOfYear: ISO8601Date, $accountsReceivable: ISO8601Date, $accountsPayable: ISO8601Date, $generalLedger: ISO8601Date, $currentAccountingPeriodAr: ISO8601Date, $currentAccountingPeriodAp: ISO8601Date, $taxCredit: Float) {
    createAccountMutation(
      propertyId: $propertyId
      liveDate: $liveDate
      liveFlag: $liveFlag
      lastDayBegin: $lastDayBegin
      lastDayEnd: $lastDayEnd
      lastAccMthEnd: $lastAccMthEnd
      accMthEndFlag: $accMthEndFlag
      lastInvoiceNumber: $lastInvoiceNumber
      lastReceiptNumber: $lastReceiptNumber
      chashReverse: $chashReverse
      endOfYear: $endOfYear
      accountsReceivable: $accountsReceivable
      accountsPayable: $accountsPayable
      generalLedger: $generalLedger
      currentAccountingPeriodAr: $currentAccountingPeriodAr
      currentAccountingPeriodAp: $currentAccountingPeriodAp
      taxCredit: $taxCredit
    ) {
      id
      propertyId
      liveDate
      liveFlag
      lastDayBegin
      lastDayEnd
      lastAccMthEnd
      accMthEndFlag
      lastInvoiceNumber
      lastReceiptNumber
      chashReverse
      endOfYear
      accountsReceivable
      accountsPayable
      generalLedger
      currentAccountingPeriodAr
      currentAccountingPeriodAp
      taxCredit
    }
  }
`

export const UPDATE_ACCOUNT_MUTATION = `
  mutation UpdateAccountMutation($id: ID!, $liveDate: ISO8601Date, $liveFlag: String, $lastDayBegin: ISO8601Date, $lastDayEnd: ISO8601Date, $lastAccMthEnd: ISO8601Date, $accMthEndFlag: String, $lastInvoiceNumber: Int, $lastReceiptNumber: Int, $chashReverse: Float, $endOfYear: ISO8601Date, $accountsReceivable: ISO8601Date, $accountsPayable: ISO8601Date, $generalLedger: ISO8601Date, $currentAccountingPeriodAr: ISO8601Date, $currentAccountingPeriodAp: ISO8601Date, $taxCredit: Float) {
    updateAccountMutation(
      id: $id
      liveDate: $liveDate
      liveFlag: $liveFlag
      lastDayBegin: $lastDayBegin
      lastDayEnd: $lastDayEnd
      lastAccMthEnd: $lastAccMthEnd
      accMthEndFlag: $accMthEndFlag
      lastInvoiceNumber: $lastInvoiceNumber
      lastReceiptNumber: $lastReceiptNumber
      chashReverse: $chashReverse
      endOfYear: $endOfYear
      accountsReceivable: $accountsReceivable
      accountsPayable: $accountsPayable
      generalLedger: $generalLedger
      currentAccountingPeriodAr: $currentAccountingPeriodAr
      currentAccountingPeriodAp: $currentAccountingPeriodAp
      taxCredit: $taxCredit
    ) {
      id
      propertyId
      liveDate
      liveFlag
      lastDayBegin
      lastDayEnd
      lastAccMthEnd
      accMthEndFlag
      lastInvoiceNumber
      lastReceiptNumber
      chashReverse
      endOfYear
      accountsReceivable
      accountsPayable
      generalLedger
      currentAccountingPeriodAr
      currentAccountingPeriodAp
      taxCredit
    }
  }
`
