import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import InputField from "./InputField"
import DesktopTitle1 from "./DesktopH5"
import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import CurrencyInput from 'react-currency-input-field';
import BrynsonDatePicker from "./BrynsonDatePicker"
import { PetPoliciesContext } from "../context/PetPoliciesContext"
import Selectbox from "./Selectbox"
import Loading from "./Loading"
import BreadCrumbs from "./BreadCrumbs"
import moment from "moment-timezone"

export default function PetPolicyEdit({ params }) {
  const prefix = "$"
  const page = "pet policy"
  const { petPolicyData, reloadPetPolicy } = useContext(GlobalContext)
  const { updatePetPolicy } = useContext(PetPoliciesContext)
  const [error, setError] = useState()
  const [petPolicyInput, setPetPolicyInput] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchCreatePetPolicy = (petPolicyInput) => {
    const PetPolicyValues = {}
    PetPolicyValues['id'] = JSON.parse(params)["id"];
    petPolicyData?.petPolicy?.columns.forEach((item) => {
      if (petPolicyInput[item.accessor]) {
        PetPolicyValues[item.accessor] = item.type === "decimal" || item.type === "integer" ? parseInt(petPolicyInput[item.accessor]) : petPolicyInput[item.accessor]
      } else {
        PetPolicyValues[item.accessor_value || item.accessor] = null;
      }
    })

    return PetPolicyValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadPetPolicy(parseInt(id))
    }
  }, [params])

  useEffect(() => {
    if (petPolicyData) {
      const petPolicyValues = {}
      petPolicyData?.petPolicy?.columns.map((item) => {
        petPolicyValues[item.accessor] = petPolicyData?.petPolicy[item.accessor]
      })
      setPetPolicyInput(petPolicyValues)
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: "/portal/listings", title: `Property ${petPolicyData?.petPolicy?.property?.name || ""}`},
        { link: `/portal/pet_policies/${petPolicyData?.petPolicy?.property?.id}`, title: "Pet Policies"},
        { link: null, title: "Edit" }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [petPolicyData])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  const addValue = (item, value) => {
    const petPolicy = {}
    petPolicy[`${item.accessor}`] = value
    setPetPolicyInput({ ...petPolicyInput, ...petPolicy })
  }

  const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  const sizeOptions = ['Huge', 'Large', 'Medium', 'Small', 'Tiny'].map((value) => ({
    value: value,
    label: value
  }))

  return (
    <>
      { petPolicyData ?
        <div className="p-8">
          { breadCrumbs &&
            breadCrumbHeader()
          }
          <DesktopTitle1 content={`Edit pet policy ${petPolicyData?.petPolicy["name"] || ""}`} />
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex row flex-wrap grid grid-cols-2 gap-3">
            {petPolicyData?.petPolicy?.columns.map((item) => (
              <div className="row flex items-center w-full" key={`pet-policy-insert-${item.accessor}`}>

                {item.accessor === "petType" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <Selectbox
                      defaultValue={petPolicyData?.petPolicy[item.accessor] ? {label: capitalize(petPolicyData?.petPolicy[item.accessor]), value: petPolicyData?.petPolicy[item.accessor]} : null}
                      options={petPolicyData?.petPolicy?.petPolicyTypes?.map((i) => ({ value: i.value, label: i.name }))}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                    />
                  </div>
                }

                {item.accessor === "size" &&
                  <div className="mb-2 w-full">
                    <label htmlFor={`pet-policy-${item.accessor}-edit`}>{item.Header}</label>
                    <Selectbox
                      id={`pet-policy-${item.accessor}-edit`}
                      defaultValue={petPolicyData?.petPolicy[item.accessor] ? {label: capitalize(petPolicyData?.petPolicy[item.accessor]), value: petPolicyData?.petPolicy[item.accessor]} : null}
                      options={sizeOptions}
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                    />
                  </div>
                }

                {item.type === "decimal" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <CurrencyInput
                      className="m-0 w-full rounded border border-geyser bg-white text-navy shadow-none outline-0 focus:border-teal active:border-teal px-4 py-3.5"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={petPolicyData?.petPolicy[item.accessor]}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                }
                {item.type === "boolean" &&
                  <div className="my-4 w-full">
                    <CheckBoxExtended name={item.Header} checked={petPolicyData?.petPolicy[item.accessor]} handleClick={(value) => {
                      addValue(item, value)
                    }} />
                  </div>
                }
                {item.type === "date" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <BrynsonDatePicker selectedDate={petPolicyData?.petPolicy[item.accessor] ? moment(petPolicyData?.petPolicy[item.accessor]).toDate() : ""} onChange={(date) => {
                      addValue(item, date)
                    }} />
                  </div>
                }
                {(item.type !== "boolean" && item.type !== "decimal" && item.type !== "date" && item.accessor !== "petType" && item.accessor !== "size") &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <InputField
                      id={`property-${item.accessor}-edit`}
                      placeholder={item.Header}
                      defaultValue={petPolicyData?.petPolicy[item.accessor]}
                      type={item.type === "integer" ? "number" : "text" }
                      onChange={(input) => {
                        addValue(item, input.target.value)
                      }}
                    />
                  </div>
                }
              </div>
            ))}
          </div>
          {petPolicyData?.petPolicy?.columns &&
            <div className="flex justify-center items-center w-full mt-6">
              <div>
                <BrynsonSquareButton
                  type="primary"
                  testId="add-new-property-button"
                  loading={currentLoading}
                  onClick={() => {
                    setLoading(true)            
                    const createPetPolicy = fetchCreatePetPolicy(petPolicyInput)
                    updatePetPolicy(createPetPolicy).then((result) => {
                      if (result?.error || result?.data?.createPetPolicy?.errors) {
                        setError(`Error updating property: ${result?.error || result?.data?.createPetPolicy?.errors}`)
                      } else {
                        window.location.href=`/portal/pet_policies/${petPolicyData?.petPolicy["propertyId"]}`
                      }
                      setTimeout(() => {
                        setLoading(false)
                      }, 1000)
                    })
                  }}
                  label="Edit pet policy"
                />
              </div>
            </div>
          }
        </div>
      :
        <Loading />
      }
    </>
  )
}
