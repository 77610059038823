import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_OFFICE_HOUR_MUTATION, UPDATE_OFFICE_HOUR_MUTATION, DELETE_OFFICE_HOUR_MUTATION } from "../queries/OfficeHoursMutations";

export const OfficeHoursContext = createContext(null);

const OfficeHoursProvider = ({ children, ...props }) => {
  const [, createOfficeHour] = useMutation(CREATE_OFFICE_HOUR_MUTATION)
  const [, updateOfficeHour] = useMutation(UPDATE_OFFICE_HOUR_MUTATION)
  const [, deleteOfficeHour] = useMutation(DELETE_OFFICE_HOUR_MUTATION)  

  return(
    <OfficeHoursContext.Provider value={{createOfficeHour, updateOfficeHour, deleteOfficeHour}}>
      {children}
    </OfficeHoursContext.Provider>
  );
}

export default OfficeHoursProvider;
