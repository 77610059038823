import moment from "moment-timezone"
import React, { useContext, useEffect, useMemo, useState } from "react"
import CurrencyInput from "react-currency-input-field"

import { AccountsContext } from "../context/AccountsContext"
import { GlobalContext } from "../context/GlobalContext"

import BreadCrumbs from "./BreadCrumbs"
import BrynsonDatePicker from "./BrynsonDatePicker"
import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import DesktopTitle1 from "./DesktopH5"
import InputField from "./InputField"
import Loading from "./Loading"
import Selectbox from "./Selectbox"

export default function AccountEdit({ params }) {
  const prefix = "$"
  const page = "account"
  const { accountData: contentData, reloadAccount: reloadCurrent } = useContext(GlobalContext)
  const { updateAccount: update } = useContext(AccountsContext)
  const [error, setError] = useState()
  const [currentInput, setCurrentInput] = useState()
  const [columns, setColumns] = useState([])
  const [currentData, setCurrentData] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchUpdate = (currentInput) => {
    const currentValues = {}
    currentValues["id"] = currentData?.id
    columns.forEach((item) => {
      if (currentInput[item.accessor]) {
        if (item.type === "integer") {
          currentValues[item.accessor] = parseInt(currentInput[item.accessor])
        } else if (item.type === "decimal") {
          currentValues[item.accessor] = parseFloat(currentInput[item.accessor])
        } else {
          currentValues[item.accessor] = currentInput[item.accessor]
        }
      } else {
        currentValues[item.accessor] = null
      }
    })

    return currentValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadCurrent(parseInt(id))
    }
  }, [params])

  const addProperty = (data) => {
    const currentValues = {}
    data?.account?.columns.map((item) => {
      currentValues[item.accessor] = data?.account[item.accessor]
    })
    setCurrentInput(currentValues)
    setCurrentData(data?.account)
    setColumns(data?.account.columns)
  }

  const addValue = (item, value) => {
    const current = {}
    current[`${item.accessor}`] = value
    setCurrentInput({ ...currentInput, ...current })
  }

  useMemo(() => {
    if (contentData) {
      addProperty(contentData)
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: "/portal/listings", title: `Property ${contentData.account.property.name || ""}` },
        { link: null, title: page }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [contentData])

  const accMthEndFlagOptions = ["Preliminary", "Changes", "Month Closed", "None"].map((value) => ({
    value: value,
    label: value
  }))

  return (
    <>
      {contentData ? (
        <div className="p-8">
          {breadCrumbs && <BreadCrumbs items={breadCrumbs} />}
          <DesktopTitle1 content={`Edit ${page}`} />
          {error && <p className="text-red-600">{error}</p>}
          <div className="row grid grid-cols-2 flex-wrap gap-3">
            {columns.map((item) => (
              <div className="row flex w-full items-center" key={`current-insert-${item.accessor}`}>
                {item.type === "decimal" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="current-name">{item.Header}</label>
                    <CurrencyInput
                      className="m-0 w-full rounded border border-geyser bg-white px-4 py-3.5 text-navy shadow-none outline-0"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={currentData[item.accessor]}
                      decimalsLimit={2}
                      onValueChange={(value) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                )}
                {item.type === "boolean" && (
                  <div className="my-4 w-full">
                    <CheckBoxExtended
                      name={item.Header}
                      checked={currentData[item.accessor]}
                      handleClick={(value) => {
                        addValue(item, value)
                      }}
                    />
                  </div>
                )}
                {item.type === "date" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="current-date">{item.Header}</label>
                    <BrynsonDatePicker
                      selectedDate={currentData[item.accessor] ? moment(currentData[item.accessor]).toDate() : ""}
                      onChange={(date) => {
                        addValue(item, date)
                      }}
                    />
                  </div>
                )}
                {item.accessor === "accMthEndFlag" && (
                  <div className="mb-2 w-full">
                    <label htmlFor="edit-account-acc-mth-end-flag">{item.Header}</label>
                    <Selectbox
                      id="edit-account-acc-mth-end-flag"
                      options={accMthEndFlagOptions}
                      defaultValue={
                        currentData[item.accessor]
                          ? { label: currentData[item.accessor], value: currentData[item.accessor] }
                          : null
                      }
                      onChange={(value) => {
                        addValue(item, value)
                      }}
                      update={false}
                    />
                  </div>
                )}
                {item.accessor !== "accMthEndFlag" &&
                  item.type !== "boolean" &&
                  item.type !== "decimal" &&
                  item.type !== "date" &&
                  item.accessor !== "petType" && (
                    <div className="mb-2 w-full">
                      <label htmlFor="current">{item.Header}</label>
                      <InputField
                        id={`current-${item.accessor}-edit`}
                        placeholder={item.Header}
                        defaultValue={currentData[item.accessor]}
                        type={item.type === "integer" ? "number" : "text"}
                        onChange={(input) => {
                          addValue(item, input.target.value)
                        }}
                      />
                    </div>
                  )}
              </div>
            ))}
          </div>
          {columns.length > 0 && (
            <div className="row flex w-full items-center justify-center gap-4">
              <div>
                <BrynsonSquareButton
                  type="primary"
                  testId="add-new-button"
                  loading={currentLoading}
                  onClick={() => {
                    setLoading(true)
                    const updateCurrent = fetchUpdate(currentInput)
                    update(updateCurrent).then((result) => {
                      if (result?.error) {
                        setError(`Error updating: ${result?.error}`)
                      }
                      setTimeout(() => {
                        setLoading(false)
                      }, 1000)
                    })
                  }}
                  label={`Edit ${page}`}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
