export const prorateTypeOptions = [
  {value: 'Standard', label: 'Standard'},
  {value: 'Annualized', label: 'Annualized'},
  {value: 'DaysInMonth', label: 'Days in month'},
]

export const leasedStatusOptions = [
  {
    label: "leased",
    value: "leased"
  },
  {
    label: "Available",
    value: "available"
  },
  {
    label: "Approved",
    value: "approved"
  },
  {
    label: "Reserved",
    value: "reserved"
  },
  {
    label: "Not ready",
    value: "not_ready"
  },
  {
    label: "On notice",
    value: "on_notice"
  },
  {
    label: "Leased on notice",
    value: "leased_on_notice"
  },
  {
    label: "Leased reserved",
    value: "leased_reserved"
  },
  {
    label: "other",
    value: "Other"
  },
]

export const occupancyStatusOptions = [
  {
    label: "Vacant",
    value: "vacant"
  },
  {
    label: "Occupied",
    value: "occupied"
  }
]

export const paymentMethodOptions = [
  {
    label: "Cash",
    value: "Cash"
  },
  {
    label: "Check",
    value: "Check"
  },
  {
    label: "CreditCard",
    value: "Credit Card"
  },
  {
    label: "Insurance",
    value: "Insurance"
  },
  {
    label: "CorporateBilling",
    value: "Corporate Billing"
  },
  {
    label: "PerDiem",
    value: "PerDiem"
  }
]
