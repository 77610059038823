import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_PET_POLICY_MUTATION, DELETE_PET_POLICY_MUTATION, EDIT_PET_POLICY_MUTATION } from "../queries/PetPolicyMutations";

export const PetPoliciesContext = createContext(null);

const PetPoliciesProvider = ({ children, ...props }) => {
  const [, createPetPolicy] = useMutation(CREATE_PET_POLICY_MUTATION)
  const [, updatePetPolicy] = useMutation(EDIT_PET_POLICY_MUTATION)
  const [, deletePetPolicy] = useMutation(DELETE_PET_POLICY_MUTATION)  

  return(
    <PetPoliciesContext.Provider value={{createPetPolicy, updatePetPolicy, deletePetPolicy}}>
      {children}
    </PetPoliciesContext.Provider>
  );
}

export default PetPoliciesProvider;
