import autocomplete from "autocomplete.js"
import { Controller } from "stimulus"

// Note: For Autosuggests when a single option may be selected.
// For multiple autosuggest, use the MultiAutosuggestController

export default class extends Controller {
  static targets = [
    "input",
    "result",
    "selectionName",
    "displayAfterSelection",
    "hideAfterSelection",
    "issuerName",
    "showError",
    "submit"
  ]
  static values = {
    credentialType: String,
    currentUser: String,
    issuerSelected: Boolean
  }

  connect() {
    if (this.inputTarget.value != "") {
      // Already have an input, form is being re-rendered bc of a submission validation issue,
      // hide the autosuggest and just show selection name instead
      $(this.hideAfterSelectionTarget).hide()
      $(this.displayAfterSelectionTarget).show()
    } else {
      if (this.displayAfterSelectionTarget) {
        this.displayAfterSelectionTarget.style.display = "none"
      }
    }

    this.suggestionsAmountToShow = this.data.get("matchesToShow") || 10
    this.setupAutosuggest()
    this.issuerSelectedValue = false
  }

  setupAutosuggest = () => {
    const controller = this

    autocomplete(
      `#${controller.inputTarget.id}`,
      {
        hint: false,
        clearOnSelected: false
      },
      [
        {
          source: (query, callback) => {
            const pattern = new RegExp(query, "gi")
            var temp_results = App[controller.data.get("source")].reduce(
              (a, o) => (o.validation_state == "approved" && a.push(o), a),
              []
            )
            const results = temp_results
              .filter((item) => item.long_name.toLowerCase().includes(query.toLowerCase()))
              .slice(0, controller.suggestionsAmountToShow)
              .map((item) => {
                item.highlight = item.long_name.replace(pattern, "<em>$&</em>")
                return item
              })

            callback(results)
          },
          displayKey: "name",
          templates: {
            empty: function (context, credentialTypeValue) {
              return `<button class= "no-results-btn" id="no-results-issuer" type="button" data-action="click->autosuggest#noResultsButtonClicked"><i class= "icon-add"/> Don't see the right issuer? Add one now.</a>`
            },
            suggestion: function (suggestion) {
              return suggestion.highlight
            }
          }
        }
      ]
    ).on("autocomplete:selected", function (event, suggestion, dataset, context) {
      controller.selectionNameTarget.textContent = suggestion.long_name
      if (document.getElementById("add_license_modal").classList.contains("is-active")) {
        this.credentialTypeValue = "License"
      }
      if (document.getElementById("add_certificate_modal").classList.contains("is-active")) {
        this.credentialTypeValue = "Certificate"
      }
      if (document.getElementById("add_membership_modal").classList.contains("is-active")) {
        this.credentialTypeValue = "Membership"
      }
      if (document.getElementById("add_degree_modal").classList.contains("is-active")) {
        this.credentialTypeValue = "Degree"
      }
      var inputFieldID = `${this.credentialTypeValue}IssuerID`
      $("#" + inputFieldID).val(suggestion.id)

      $(`#hideAfter${this.credentialTypeValue}IssuerSelect`).fadeOut(() => {
        $(`#displayAfter${this.credentialTypeValue}IssuerSelect`).fadeIn()
      })
      setTimeout(function () {
        controller.formIsChanged()
      }, 1000)
    })
  }

  noResultsButtonClicked = () => {
    var temp_name = this.inputTarget.value
    var displaySelected = `displaySelected${this.credentialTypeValue}Issuer`
    var inputFieldID = `${this.credentialTypeValue}IssuerID`
    var displayAfterSelected = `displayAfter${this.credentialTypeValue}IssuerSelect`
    var hideAfterSelected = `hideAfter${this.credentialTypeValue}IssuerSelect`
    var curr_kind = this.credentialTypeValue.toLowerCase()
    $.ajax({
      url: "/issuers_requests",
      data: {
        issuer: {
          name: this.inputTarget.value,
          long_name: this.inputTarget.value,
          kind: curr_kind,
          creator_id: this.currentUserValue
        }
      },
      dataType: "script",
      method: "post",
      success: function (data) {
        $("input#" + inputFieldID).val(data)
        document.getElementById(displaySelected).innerHTML = temp_name
        $("#" + hideAfterSelected).fadeOut(() => {
          $("#" + displayAfterSelected).fadeIn()
        })
      }
    })
    setTimeout(() => {
      this.formIsChanged()
    }, 1000)
  }

  displayIssuerInputs = () => {
    document.getElementById(`displaySelected${this.credentialTypeValue}Issuer`).innerHTML = ""
    document.getElementById(`${this.credentialTypeValue}_issuer_id`).value = ""
    document.getElementById(`${this.credentialTypeValue}_issuer_id`).innerHTML = ""
    $(`#displayAfter${this.credentialTypeValue}IssuerSelect`).fadeOut(() => {
      $(`#hideAfter${this.credentialTypeValue}IssuerSelect`).fadeIn()
    })
    this.disableSubmitButton()
  }

  disableSubmitButton = () => {
    this.submitTarget.disabled = true
    this.issuerSelectedValue = false
  }

  enableSubmitButton = () => {
    this.submitTarget.disabled = false
    this.issuerSelectedValue = true
  }

  formIsChanged() {
    if (this.credentialTypeValue == "License") {
      if (document.getElementById(`LicenseNumber`).value == "") {
        this.disableSubmitButton()
      } else {
        this.enableSubmitButton()
      }
    } else {
      const certificateNotUploaded =
        document.getElementById(`displayAfter${this.credentialTypeValue}Select`).style.cssText == "display: none;" ||
        document.getElementById(`displayAfter${this.credentialTypeValue}IssuerSelect`).style.cssText == "display: none;"
      const documentNotUploaded =
        window.getComputedStyle(document.getElementById(`certificateUploaded`), null).display === "none"
      const issuerNotUploaded = document.getElementById(`displaySelectedCertificateIssuer`).textContent.length === 0

      if (certificateNotUploaded || documentNotUploaded || issuerNotUploaded) {
        this.disableSubmitButton()
      } else {
        this.enableSubmitButton()
      }
    }
  }

  noSelectError() {
    if (
      (this.inputTarget.value != "" && $(`input#${this.credentialTypeValue}IssuerID`).val.length < 2) ||
      this.issuerSelectedValue == false ||
      document.getElementById(`displayAfter${this.credentialTypeValue}Select`).style.cssText == "display: none;"
    ) {
      this.showErrorTarget.classList.remove("hidden")
      this.disableSubmitButton()
    }
  }
}
