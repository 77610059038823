import clsx from "clsx"
import React, { useEffect, useState } from "react"

import { UnitCollection } from "../../CompAnalysisComponents/UnitCollection"
export function UnitsSubject({ properties = [], className = "", filter = { startDate: null, endDate: null } }) {
  const [data, setData] = useState(null)

  const getAvailables = (data) => UnitCollection.filterAvailableUnits(data, filter)

  const getLeased = (data) => UnitCollection.filterLeasedUnits(data, filter)

  const getDaysOnMarket = (data) => {
    let units = data.filter((unit) => unit.days_on_market > 0)
    if (!units || units.length === 0) return 0
    const total = units.reduce((acc, unit) => {
      if (unit.price) {
        return acc + unit.price
      }
      return acc
    }, 0)
    return (total / units.length).toFixed(0)
  }

  const getAskingPrice = (data) => {
    let units = data.filter((unit) => unit.price > 0)
    if (!units || units.length === 0) return 0
    const total = units.reduce((acc, unit) => acc + (unit.price || 0), 0)
    return (total / units.length).toFixed(1)
  }

  const getAskingPricePsf = (data) => {
    let units = data.filter((unit) => unit.price > 0 && unit.sqft > 0)
    if (!units || units.length === 0) return 0

    const total = units.reduce((acc, unit) => {
      const pricePsf = unit.price && unit.sqft ? (unit.price / unit.sqft).toFixed(2) : 0
      const roundedPricePsf = Math.round(parseFloat(pricePsf) * 10) / 10
      return acc + roundedPricePsf
    }, 0)
    return (total / units.length).toFixed(1)
  }

  const getEffectivePrice = (data) => {
    let units = data.filter((unit) => unit.effective_price > 0)
    if (!units || units.length === 0) return 0
    const total = units.reduce((acc, unit) => acc + (unit.effective_price || 0), 0)
    return units.length > 0 ? (total / units.length).toFixed(1) : 0
  }

  const getEffectivePricePsf = (data) => {
    let units = data.filter((unit) => unit.effective_price > 0 && unit.sqft > 0)
    if (!units || units.length === 0) return 0

    const total = units.reduce((acc, unit) => {
      const pricePsf = unit.effective_price && unit.sqft ? unit.effective_price / unit.sqft : 0
      const roundedPricePsf = Math.round(pricePsf * 10) / 10
      return acc + roundedPricePsf
    }, 0)

    return units.length > 0 ? (total / units.length).toFixed(1) : 0
  }

  useEffect(() => {
    const property = properties && properties.length > 0 ? properties[0] : null
    if (property) {
      const units = property.building_availability
      const subjectData = {
        listings: {
          available: getAvailables(units).length,
          leased: getLeased(units).length
        },
        daysOnMarket: {
          available: getDaysOnMarket(getAvailables(units)),
          leased: getDaysOnMarket(getLeased(units))
        },
        askingRent: {
          available: getAskingPrice(getAvailables(units)),
          leased: getAskingPrice(getLeased(units))
        },
        askingRentPsf: {
          available: getAskingPricePsf(getAvailables(units)),
          leased: getAskingPricePsf(getLeased(units))
        },
        effectiveRent: {
          available: getEffectivePrice(getAvailables(units)),
          leased: getEffectivePrice(getLeased(units))
        },
        effectiveRentPsf: {
          available: getEffectivePricePsf(getAvailables(units)),
          leased: getEffectivePricePsf(getLeased(units))
        }
      }
      setData(subjectData)
    }
  }, [properties])
  return (
    <>
      {data && (
        <div className={clsx("flex flex-wrap items-center justify-around", className)}>
          {[
            { title: "Listings", available: data.listings.available, leased: data.listings.leased },
            { title: "Days on Market", available: data.daysOnMarket.available, leased: data.daysOnMarket.leased },
            { title: "Asking Rent", available: `$${data.askingRent.available}`, leased: `$${data.askingRent.leased}` },
            {
              title: "Asking Rent PSF",
              available: `$${data.askingRentPsf.available}`,
              leased: `$${data.askingRentPsf.leased}`
            },
            {
              title: "Effective Rent",
              available: `$${data.effectiveRent.available}`,
              leased: `$${data.effectiveRent.leased}`
            },
            {
              title: "Effective Rent PSF",
              available: `$${data.effectiveRentPsf.available}`,
              leased: `$${data.effectiveRentPsf.leased}`
            }
          ].map((card, index) => (
            <div key={index} className={clsx("max-w-1/7 mb-4 flex-1", index < 5 && "pr-2")}>
              <div className="h-full rounded border bg-white shadow-none ">
                <div className="p-4">
                  <div className="font-semibold">{card.title}</div>
                </div>
                <div className="border-t border-gray-200"></div>
                <div className="flex items-center justify-between p-4">
                  <div>
                    <div className="text-sm text-gray-500">Available</div>
                    <div className="font-semibold">{card.available}</div>
                  </div>
                  <div>
                    <div className="text-sm text-gray-500">Leased</div>
                    <div className="font-semibold">{card.leased}</div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
