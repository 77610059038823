import React, { useEffect } from "react"
import { useQuery } from "urql"
import { PROPERTIES_QUERY } from "../../queries/PropertiesMutations"
import Loading from "../../shared/Loading"
import { EMBEDDED_DOCUMENTS_QUERY } from "../../queries/EmbeddedDocuments"

export default function QuarterlyDetailsAudits() {

  const [{ data: propertiesData, fetching: propertiesFetching }] = useQuery({
    query: PROPERTIES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: EmbeddedDocumentsData, fetching }] = useQuery({
    query: EMBEDDED_DOCUMENTS_QUERY,
    variables: {
      documentType: "quarterly_audits"
    }
  })

  // No need for useEffect to set a single document anymore

  if (fetching || propertiesFetching) {
    return <Loading />
  }

  return (
    <>
      {(propertiesData && EmbeddedDocumentsData?.embeddedDocuments?.length) ? (
        <div>
          {EmbeddedDocumentsData.embeddedDocuments.map((doc, index) => (
            <div key={index} className="mb-4">
              <iframe src={doc.thirdPartyLink} width="100%" height="800px" frameBorder="0" />
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  )
}
