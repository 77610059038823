export const FLOORPLAN_QUERY = `
  query Floorplan($id:ID!){
    floorplan(id:$id) {
      id
      name
      beds
      baths
      sqFtMin
      sqFtMax
      expirationDate
      dateAvailable
      totalRoomCount
      floorCount
      displayActive
      description
      notes
      rentMin
      rentMax
      effectiveRentMin
      effectiveRentMax
      leaseTermMin
      leaseTermMax
      effectiveRentMax
      adminFee
      applicationFee
      externalSource
      externalSourceId
      floorplanPartnerId
      availabilityLink
      manualOverride
      rentOptionSelected
      maxNumberMarket
      requireDeposit
      depositAmount
      depositDescription
      incomeRestricted
      propertyId
      property {
        id
        name
      }
      promotional
      images {
        id
        image
        caption
        rank
      }
      media {
        id
        mediumType
        mediumableType
        mediumableId
        matterportUrl
        importedUrl
        medium
        feedDisabled
        name
        caption
        rank
      }
      columns {
        accessor
        Header
        type
      }
      fee {
        id
        prorateType
        lateType
        latePercent
        lateMinFee
        lateFeePerDay
        nonRefundableHoldFee
        adminFee
        applicationFee
        brokerFee
      }
      feeColumns {
        accessor
        Header
        type
      }
      concession {
        id
        value
        term
        dateStart
        dateEnd
        descriptionHeader
        descriptionBody
        descriptionFooter
      }
      concessionColumns {
        accessor
        Header
        type
      }
      deposit {
        id
        depositType
        amountType
        valueRange
        description
        percentRefundable
        portionRefundable
      }
      depositColumns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_FLOORPLAN_MUTATION = `
  mutation CreateFloorplanMutation($propertyId:ID!,
    $name:String, $beds:Int, $baths:Float,
    $sqFtMin:Int, $sqFtMax:Int,$expirationDate:ISO8601Date,
    $dateAvailable: ISO8601Date, $totalRoomCount:Int,
    $floorCount:Int, $displayActive:Boolean,
    $description:String, $notes:String, $rentMin:Int,
    $rentMax:Int, $adminFee:Float, $applicationFee:Float,
    $externalSource:String, $externalSourceId:String,
    $floorplanPartnerId:Int, $availabilityLink:String,
    $manualOverride:Boolean, $rentOptionSelected:String,
    $maxNumberMarket:Int, $requireDeposit:String,
    $depositAmount:Float, $depositDescription:String,
    $incomeRestricted:Boolean, $promotional: String,
    $effectiveRentMin:Int, $effectiveRentMax:Int,
    $leaseTermMin:Int, $leaseTermMax:Int){
    createFloorplanMutation(propertyId:$propertyId,
      name:$name,beds:$beds,baths:$baths,sqFtMin:$sqFtMin,
      sqFtMax:$sqFtMax, expirationDate:$expirationDate,
      dateAvailable:$dateAvailable, totalRoomCount:$totalRoomCount,
      floorCount:$floorCount, displayActive:$displayActive,
      description:$description, notes:$notes,
      rentMin:$rentMin, rentMax:$rentMax, adminFee:$adminFee,
      effectiveRentMin:$effectiveRentMin, effectiveRentMax:$effectiveRentMax,
      leaseTermMin:$leaseTermMin, leaseTermMax:$leaseTermMax,
      applicationFee:$applicationFee, externalSource:$externalSource,
      externalSourceId:$externalSourceId, floorplanPartnerId: $floorplanPartnerId,
      availabilityLink:$availabilityLink, manualOverride:$manualOverride,
      rentOptionSelected:$rentOptionSelected,maxNumberMarket:$maxNumberMarket,
      requireDeposit:$requireDeposit, depositAmount:$depositAmount,
      depositDescription:$depositDescription, incomeRestricted:$incomeRestricted, promotional: $promotional) {
      id
      name
      beds
      baths
      sqFtMin
      sqFtMax
      expirationDate
      dateAvailable
      totalRoomCount
      floorCount
      displayActive
      description
      notes
      rentMin
      rentMax
      effectiveRentMin
      effectiveRentMax
      leaseTermMin
      leaseTermMax
      effectiveRentMax
      adminFee
      applicationFee
      externalSource
      externalSourceId
      floorplanPartnerId
      availabilityLink
      manualOverride
      rentOptionSelected
      maxNumberMarket
      requireDeposit
      depositAmount
      depositDescription
      incomeRestricted
      promotional
    }
  }
`
export const UPDATE_FLOORPLAN_MUTATION = `
  mutation UpdateFloorplanMutation($id:ID!,
    $name:String, $beds:Int, $baths:Float,
    $sqFtMin:Int, $sqFtMax:Int,$expirationDate:ISO8601Date,
    $dateAvailable: ISO8601Date, $totalRoomCount:Int,
    $floorCount:Int, $displayActive:Boolean,
    $description:String, $notes:String, $rentMin:Int,
    $rentMax:Int, $adminFee:Float, $applicationFee:Float,
    $externalSource:String, $externalSourceId:String,
    $floorplanPartnerId:Int, $availabilityLink:String,
    $manualOverride:Boolean, $rentOptionSelected:String,
    $maxNumberMarket:Int, $requireDeposit:String,
    $depositAmount:Float, $depositDescription:String,
    $incomeRestricted:Boolean, $promotional: String,
    $effectiveRentMin:Int, $effectiveRentMax:Int,
    $leaseTermMin:Int, $leaseTermMax:Int){
    updateFloorplanMutation(id:$id,
      name:$name,beds:$beds,baths:$baths,sqFtMin:$sqFtMin,
      sqFtMax:$sqFtMax, expirationDate:$expirationDate,
      dateAvailable:$dateAvailable, totalRoomCount:$totalRoomCount,
      floorCount:$floorCount, displayActive:$displayActive,
      description:$description, notes:$notes,
      rentMin:$rentMin, rentMax:$rentMax, adminFee:$adminFee,
      effectiveRentMin:$effectiveRentMin, effectiveRentMax:$effectiveRentMax,
      leaseTermMin:$leaseTermMin, leaseTermMax:$leaseTermMax,
      applicationFee:$applicationFee, externalSource:$externalSource,
      externalSourceId:$externalSourceId, floorplanPartnerId: $floorplanPartnerId,
      availabilityLink:$availabilityLink, manualOverride:$manualOverride,
      rentOptionSelected:$rentOptionSelected,maxNumberMarket:$maxNumberMarket,
      requireDeposit:$requireDeposit, depositAmount:$depositAmount,
      depositDescription:$depositDescription, incomeRestricted:$incomeRestricted, promotional: $promotional) {
      id
      name
      beds
      baths
      sqFtMin
      sqFtMax
      expirationDate
      dateAvailable
      totalRoomCount
      floorCount
      displayActive
      description
      notes
      rentMin
      rentMax
      effectiveRentMin
      effectiveRentMax
      leaseTermMin
      leaseTermMax
      adminFee
      applicationFee
      externalSource
      externalSourceId
      floorplanPartnerId
      availabilityLink
      manualOverride
      rentOptionSelected
      maxNumberMarket
      requireDeposit
      depositAmount
      depositDescription
      incomeRestricted
      promotional
    }
  }
`
export const DELETE_FLOORPLAN_MUTATION = `
  mutation DeleteFloorplanMutation($id:ID!){
    deleteFloorplanMutation(id:$id){
      result
    }
  }
`

export const UPLOAD_FLOORPLAN_MUTATION = `
  mutation UploadFloorplanMutation($floorplans: [FloorplanUpload!]!, $page:String) {
    uploadFloorplanMutation (floorplans: $floorplans, page: $page) {
      result
      background
    }
  }
`
