import React, { useEffect, useState } from "react"
import BrynsonTabs from "../../shared/BrynsonTabs"
import "@react-pdf-viewer/core/lib/styles/index.css"
import DisplayComponent from "./DisplayComponent"

export default function CompAnalysis() {
  const [initialTab, setInitialTab] = useState(0)

  useEffect(() => {
    if (window.location.hash === "#custom_reports") {
      setInitialTab(1)
    }
  }, [])

  const tabs = [
    {
      label: "CoStar Reports",
      render: () => <DisplayComponent compAnalysisType="costar" rand={Math.random()} />
    },
    {
      label: "Custom Reports",
      render: () => <DisplayComponent compAnalysisType="other" rand={Math.random()} />
    }
  ]

  return (
    <div className="p-8">
      <BrynsonTabs className="mt-4" tabs={tabs} initialActiveTab={initialTab}></BrynsonTabs>
    </div>
  )
}