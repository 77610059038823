import React, { useContext } from "react"

import { MediaContext } from "../context/MediaContext"

import FilestackPicker from "./FilestackPicker"
import Loading from "./Loading"

const MediumUpload = ({ callback, loading, fileType, mediumType, sourceId, sourceType, apikey }) => {
  const newMediumType = mediumType
  const acceptfileTypes = fileType == "image" ? ["image/jpeg", "image/png"] : ["video/mp4", "video/mov"]
  const { createMedium: create } = useContext(MediaContext)
  const handleUpload = async (fileData) => {
    const variables = {
      mediumData: JSON.stringify(fileData),
      mediumType: newMediumType,
      mediumableId: sourceId,
      mediumableType: sourceType,
      rank: 0,
      caption: ""
    }

    create(variables)
  }

  const uploadImages = (filePayloads) => {
    filePayloads.map((payload) => {
      handleUpload(payload)
    })

    callback()
  }

  return (
    <div className="w-full relative z-10">
      {loading ? (
        <Loading />
      ) : (
        <FilestackPicker
          mediumType={newMediumType}
          apikey={apikey}
          pickerOptions={{
            accept: acceptfileTypes,
            maxFiles: 10,
            storeTo: {
              location: "s3",
              access: "public"
            }
          }}
          onSuccess={(result) => {
            if (result.filesUploaded.length > 0) {
              uploadImages.bind(this, result.filesUploaded)()
            }
          }}
        />
      )}
    </div>
  )
}

export default MediumUpload
