import { TextInput } from "flowbite-react"
import React, { useContext, useMemo, useState } from "react"
import CurrencyInput from "react-currency-input-field"
import { HiSearch } from "react-icons/hi"
import styled from "styled-components"

import { GlobalContext } from "../context/GlobalContext"
import { PropertiesContext } from "../context/PropertiesContext"
import { SearchResultList, useFetch } from "../portal/comp_analysis/CompAnalysisReportsNew"
import { propertyColumnsCurrent } from "../queries/PropertiesColumns"

import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import DesktopH5 from "./DesktopH5"
import InputField from "./InputField"
import Modal from "./Modal"
import Selectbox from "./Selectbox"

const CreatePropertyForm = styled.div`
  padding-bottom: 50px;

  .row {
    margin-top: 16px;
  }
`

export default function CreatePropertyModal({ showCreateProperty, close }) {
  const [propertyInput, setPropertyInput] = useState()
  const { createProperty } = useContext(PropertiesContext)
  const { statesData, currentUserData, propertyReload } = useContext(GlobalContext)
  const columns = propertyColumnsCurrent
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)
  const [helloDataPropertyId, setHelloDataPropertyId] = useState("")
  const [search1, setSearch1] = useState("")
  const [showResultList, setShowResultList] = useState(false)
  const csrfToken = window.document.querySelector('[name="csrf-token"]').getAttribute("content")
  const {
    data: results1,
    error: error1,
    isLoading: isLoading1
  } = useFetch({
    url: `/hello_data/search?q=${search1}`,
    enabled: Boolean(search1.trim()),
    options: {
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": csrfToken
      }
    }
  })
  async function onSelectSubject(result = {}) {
    setSearch1("")

    try {
      setSearch1(result.building_name)
      setHelloDataPropertyId(result.id)
      setShowResultList(false)
    } catch (e) {
      console.log(e)
    }
  }

  const fetchCreateProperties = (propertyInput) => {
    const propertiesValues = {}
    propertiesValues["companyId"] = parseInt(currentUserData?.currentUser?.currentCompany?.id)
    columns.forEach((item) => {
      if (propertyInput[item.accessor]) {
        if (item.accessor_value === "state_id") {
          propertiesValues[item.accessor_value] = parseInt(propertyInput[item.accessor]?.id)
        } else {
          let value = null
          if (item.type === "number") {
            value = parseInt(propertyInput[item.accessor])
          } else if (item.type === "decimal") {
            value = parseFloat(propertyInput[item.accessor])
          } else {
            value = propertyInput[item.accessor]
          }
          propertiesValues[item.accessor_value || item.accessor] = value
        }
      } else {
        propertiesValues[item.accessor_value || item.accessor] = null
      }
      propertiesValues.helloDataPropertyId = helloDataPropertyId
      propertiesValues.helloDataPropertyName = search1
    })

    return propertiesValues
  }

  const addValue = (item, value) => {
    const current = {}
    current[`${item.accessor}`] = value
    setPropertyInput({ ...propertyInput, ...current })
  }

  const identificationTypeOptions = [
    "High Rise",
    "Garden Style",
    "Mid Rise",
    "Mixed Use",
    "Standard",
    "Unspecified",
    "Low Rise",
    "House for Rent",
    "Condo for Rent",
    "Walkup",
    "Coach House",
    "Townhouse",
    "Courtyard"
  ].map((value) => ({
    value: value,
    label: value
  }))

  const rentalTypeOptions = [
    "Subsidized",
    "Assisted Senior",
    "Assisted Living",
    "Market Rate",
    "Unspecified",
    "Broker",
    "Student",
    "Temporary"
  ].map((value) => ({
    value: value,
    label: value
  }))

  useMemo(() => {
    if (columns) {
      const propertiesValues = {}
      columns.map((item) => {
        propertiesValues[item.accessor] = null
      })
      setPropertyInput(propertiesValues)
    }
  }, [columns])

  return (
    <Modal
      open={showCreateProperty}
      closeModal={() => {
        close()
      }}
      header={
        <>
          <DesktopH5 content="Add new property" />
          <p className="text-base text-grey">Email or Phone required</p>
        </>
      }
      footer={
        <>
          <BrynsonSquareButton
            type="tertiary"
            onClick={() => {
              close()
            }}
            label="Go back"
          />
          <BrynsonSquareButton
            type="primary"
            testId="add-new-property-button"
            loading={loading}
            onClick={() => {
              setLoading(true)
              const invitedProperty = {
                ...propertyInput,
                propertyType: "invited",
                invitedFrom: "web_single_invite"
              }

              const createProperties = fetchCreateProperties(propertyInput)

              createProperty(createProperties).then((result) => {
                if (result?.error || result?.data?.editProperty?.errors) {
                  setError(`Error updating property: ${result?.error || result?.data?.editProperty?.errors}`)
                } else {
                  propertyReload()
                  close(true)
                }
                setLoading(false)
              })
            }}
            label="Add new property"
          />
        </>
      }>
      <CreatePropertyForm>
        {error && <p className="text-red-600">{error}</p>}
        {columns.map((item) => (
          <div className="row px-4" key={`property-insert-label-${item.accessor}`}>
            {item.type === "boolean" && (
              <div className="my-4 w-full">
                <CheckBoxExtended
                  name={item.Header}
                  handleClick={(value) => {
                    addValue(item, value)
                  }}
                />
              </div>
            )}
            {item.accessor === "identificationType" && (
              <div className="my-4 w-full">
                <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                <Selectbox
                  id={`property-${item.accessor}-edit`}
                  options={identificationTypeOptions}
                  onChange={(value) => {
                    addValue(item, value)
                  }}
                  update={false}
                />
              </div>
            )}
            {item.accessor === "rentalType" && (
              <div className="my-4 w-full">
                <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                <Selectbox
                  id={`property-${item.accessor}-edit`}
                  options={rentalTypeOptions}
                  onChange={(value) => {
                    addValue(item, value)
                  }}
                  update={false}
                />
              </div>
            )}
            {item.accessor === "state" && (
              <>
                <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                <Selectbox
                  options={statesData?.states?.map((i) => ({ value: i.id, label: i.abbreviation }))}
                  onChange={(value) => {
                    const property = {}
                    property[`${item.accessor}`] = parseInt(value)
                    setPropertyInput({ ...propertyInput, ...property })
                  }}
                />
              </>
            )}
            {item.accessor === "helloDataPropertyId" && (
              <>
                <label htmlFor="property-name">{item.Header}</label>
                <div className="relative max-w-lg">
                  <TextInput
                    icon={HiSearch}
                    value={search1}
                    className="lg:max-w-lg"
                    placeholder="Enter an address or building name"
                    onChange={(e) => {
                      setSearch1(e.target.value)
                      setShowResultList(true)
                    }}
                  />
                  <SearchResultList
                    show={Boolean(search1.trim()) && (isLoading1 || results1?.length) && showResultList}
                    error={error1}
                    results={results1}
                    isLoading={isLoading1}
                    onSelectResult={onSelectSubject}
                  />
                </div>
              </>
            )}
            {item.type === "decimal" && (
              <>
                <label htmlFor={`property-${item.accessor}-edit`}>{item.Header}</label>
                <CurrencyInput
                  className="focus:border-teal active:border-teal m-0 w-full rounded border border-geyser bg-white px-4 py-3.5 text-navy shadow-none outline-0"
                  name={item.accessor}
                  placeholder={item.Header}
                  decimalsLimit={2}
                  id={`property-${item.accessor}-edit`}
                  onValueChange={(value, name) => {
                    addValue(item, value)
                  }}
                />
              </>
            )}
            {item.type !== "boolean" &&
              item.accessor !== "state" &&
              item.accessor !== "structureType" &&
              item.accessor !== "identificationType" &&
              item.accessor !== "rentalType" &&
              item.accessor !== "helloDataPropertyId" &&
              item.accessor !== "helloDataPropertyName" &&
              item.type !== "decimal" && (
                <>
                  <label htmlFor="property-name">{item.Header}</label>
                  <InputField
                    id={`property-${item.accessor}-edit`}
                    placeholder={item.Header}
                    onChange={(input) => {
                      const property = {}
                      property[`${item.accessor}`] = input.target.value
                      setPropertyInput({ ...propertyInput, ...property })
                    }}
                  />
                </>
              )}
            {/* {item.accessor_value && statesData?.states?.length > 0 ? (
              <>
                <label htmlFor="property-name">{item.Header}</label>
                <Selectbox
                  options={statesData?.states?.map((i) => ({ value: i.id, label: i.abbreviation }))}
                  onChange={(value) => {
                    const property = {}
                    property[`${item.accessor}`] = parseInt(value)
                    setPropertyInput({ ...propertyInput, ...property })
                  }}
                />
              </>
            ) : (
              <>
                <label htmlFor="property-name">{item.Header}</label>
                <InputField
                  id={`property-${item.accessor}`}
                  placeholder={item.Header}
                  onChange={(input) => {
                    const property = {}
                    property[`${item.accessor}`] = input.target.value
                    setPropertyInput({ ...propertyInput, ...property })
                  }}
                />
              </>
            )} */}
          </div>
        ))}
      </CreatePropertyForm>
    </Modal>
  )
}
