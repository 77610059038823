import React from "react"
import styled from "styled-components"

const Spinner = styled.div`
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  @keyframes spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`

const HalfSpinner = styled.div`
  display: block;
  width: 42px;
  height: 42px;
  border: 2px solid #0d9da4;
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
`

export default function Loading() {
  return (
    <Spinner>
      <HalfSpinner />
    </Spinner>
  )
}
