import { Accordion } from "flowbite-react"
import React from "react"
import { useQuery } from "urql"

import { EMBEDDED_DOCUMENTS_QUERY } from "../queries/EmbeddedDocuments"

export default function BrynsonEmbeddedDocuments({ documentType }) {
  const [{ data: EmbeddedDocumentsData }] = useQuery({
    query: EMBEDDED_DOCUMENTS_QUERY,
    variables: {
      documentType: documentType
    }
  })

  return (
    <div className="p-8 px-0">
      {EmbeddedDocumentsData?.embeddedDocuments?.length ? (
        <Accordion collapseAll>
          {EmbeddedDocumentsData.embeddedDocuments?.map((report) => (
            <Accordion.Panel key={report.id}>
              <Accordion.Title>{report.name}</Accordion.Title>
              <Accordion.Content>
                <iframe src={report.thirdPartyLink} width="100%" height="1000" frameBorder="0"></iframe>
              </Accordion.Content>
            </Accordion.Panel>
          ))}
        </Accordion>
      ) : (
        <></>
      )}
    </div>
  )
}
