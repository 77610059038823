import React, { useContext, useEffect, useMemo, useState } from "react"
import BrynsonTable from "./BrynsonTable"
import { GlobalContext } from "../context/GlobalContext"
import Modal from "./Modal"
import styled from "styled-components"
import BrynsonSquareLink from "./BrynsonSquareLink"
import BrynsonSquareButton from "./BrynsonSquareButton"
import { PencilSquareIcon, TrashIcon, AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid"
import DesktopTitle3 from "./DesktopTitle3"
import { PetPoliciesContext } from "../context/PetPoliciesContext"
import Loading from "./Loading"
import BreadCrumbs from "./BreadCrumbs"

const TableWrapper = styled.div`
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function PetPolicyShow({ params }) {
  const page = "pet policy"
  const { propertyData, reloadProperty } = useContext(GlobalContext)
  const { deletePetPolicy } = useContext(PetPoliciesContext)
  const [showDeletePetPolicy, setShowDeletePetPolicy] = useState(false)
  const [currentLoading, setLoading] = useState(false)
  const [currentId, setCurrentId] = useState()
  const [breadCrumbs, setBreadCrumbs] = useState()

  const showHideProperty = (property) => {}

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadProperty(parseInt(id))
    }
  }, [params])

  const tableHeader = () => (
    <div className="column is-5 is-offset-4 table-actions">
      <BrynsonSquareLink
        type="primary"
        label="Add new pet policy"
        href={`/portal/pet_policies/new?id=${JSON.parse(params)["id"]}`}
      />
    </div>
  )

  const petPolicyColumns = [
    {
      accessor: "petType",
      Header: "Pet Type",
      type: "text",
    },
    {
      accessor: "count",
      Header: "Pet Count",
      type: "text",
    },
    {
      id: 'actions',
      accessor: "actions",
      className: "text-center",
      Cell: (props) => (
        <div className="flex row gap-4 justify-end">
          <button className="text-bblue font-medium underline" onClick={() => {
            setCurrentId(props.cell.row.original.id)
            setShowDeletePetPolicy(true)
          }}>
            <TrashIcon width={24} height={24} />
          </button>
          <button className="text-bblue font-medium underline" onClick={() => {
            window.location.href=`/portal/pet_policies/${props.cell.row.original.id}/edit`
          }}>
            <PencilSquareIcon width={24} height={24} />
          </button>
        </div>
      )
    },
  ]

  useMemo(() => {
    if (propertyData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: "/portal/listings", title: `Property ${propertyData.property.name || ""}`},
        { link: null, title: page }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )

  return (
    <div className="p-8">
      {propertyData?.property?.petPolicies ? (
        <>
          {breadCrumbs &&
            breadCrumbHeader()
          }
          <TableWrapper>
            <BrynsonTable
              data={propertyData?.property?.petPolicies || []}
              columns={petPolicyColumns}
              countTitle={propertyData?.property?.petPolicies.length > 1 ? "total pet policy" : "pet policy"}
              header={tableHeader}
              rowClick={showHideProperty}
            />
          </TableWrapper>
        </>
      ) : <Loading />}
      <Modal
        open={showDeletePetPolicy}
        closeModal={() => {
          setShowDeletePetPolicy(false)
        }}
        size="small"
        header={
          <div>
            <DesktopTitle3 content="Delete Pet Policy" />
          </div>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="link"
              size="medium"
              onClick={() => {
                setShowDeletePetPolicy(false)
              }}
              label="Go Back"
            />
            <BrynsonSquareButton
              type="warning"
              size="medium"
              loading={currentLoading}
              className={'flex justify-center items-center'}
              onClick={() => {
                setLoading(true)
                deletePetPolicy({ id: currentId }).then((result) => {
                  setLoading(false)
                  setShowDeletePetPolicy(false)
                  const id = JSON.parse(params)["id"]
                  reloadProperty(parseInt(id))
                })
              }}
              label="Delete pet policy"
            />
          </>
        }>
        <DesktopTitle3 content="Are you sure you want to delete this pet policy? This action can't be undone." />
      </Modal>
    </div>
  )
}
