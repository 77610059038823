import NiceModal, { useModal } from "@ebay/nice-modal-react"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import ReactModal from "react-modal"
import styled from "styled-components"

import BrynsonSquareButton from "./BrynsonSquareButton"
import DesktopH5 from "./DesktopH5"
import DesktopTitle3 from "./DesktopTitle3"

const ModalContent = styled.div`
  margin: ${(props) => props.contentMargin};
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(80vh - ${(props) => props.headerHeight}px - ${(props) => props.footerHeight}px - 164px);
  @media (min-width: 640px) and (max-width: 767px) {
    max-height: calc(80vh - ${(props) => props.headerHeight}px - ${(props) => props.footerHeight}px - 104px);
  }
  @media (max-width: 767px) {
    max-height: calc(100vh - ${(props) => props.headerHeight}px - ${(props) => props.footerHeight}px - 164px);
  }
`
const Modal = ({
  closeModal,
  open,
  children,
  header,
  footer,
  hideFooter,
  handleClick,
  size = "large",
  wide = false,
  contentMargin = "32px 32px 140px 32px"
}) => {
  const [dimension, updateDimension] = useState({ width: 0, height: 0 })

  useEffect(() => {
    updateDimension({
      ...dimension,
      width: window.innerWidth,
      height: window.innerHeight
    })
  }, [window.innerWidth])

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: size === "large" || wide ? "60vw" : "40vw",
      padding: "0",
      borderColor: "#E4EFF6",
      height: size === "large" ? "80vh" : "fit-content",
      overflow: "none"
    },
    overlay: { zIndex: 9999, backgroundColor: "rgba(0,0,0,0.6)" }
  }

  const mobileCustomStyle = {
    content: {
      top: "0",
      left: "0",
      right: "auto",
      bottom: "auto",
      marginRight: "0",
      transform: "none",
      inset: "none",
      width: "100vw",
      padding: "0",
      borderColor: "#E4EFF6",
      height: size === "small" ? "60vh" : "100vh",
      overflow: "none",
      position: "fixed",
      overflowY: "scroll"
    },
    overlay: { zIndex: 9999, backgroundColor: "rgba(0,0,0,0.6)" }
  }

  const [headerHeight, setHeaderHeight] = useState(0)
  const [headerEl, setHeaderEl] = useState()

  const [footerHeight, setFooterHeight] = useState(0)
  const [footerEl, setFooterEl] = useState()

  useEffect(() => {
    setHeaderHeight(headerEl?.clientHeight)
    setFooterHeight(footerEl?.clientHeight)
  }, [headerEl, footerEl])

  const mdBreakpoint = 767
  return (
    <div>
      <ReactModal
        isOpen={open}
        onRequestClose={closeModal}
        style={dimension?.width < mdBreakpoint ? mobileCustomStyle : customStyles}
        ariaHideApp={false}>
        <div>
          <div className="CloseIcon absolute right-4 top-3 hover:cursor-pointer">
            <i className="icon-close text-lg font-semibold text-navy" onClick={closeModal}></i>
          </div>
          {header && (
            <div className="ModalHeader p-8 pb-0">
              <div
                ref={(el) => {
                  setHeaderEl(el)
                }}>
                {header}
              </div>
            </div>
          )}
          <ModalContent
            headerHeight={headerHeight}
            footerHeight={footerHeight}
            contentMargin={dimension?.width < mdBreakpoint ? "32px" : contentMargin}>
            {children}
          </ModalContent>
          <div
            ref={(el) => {
              setFooterEl(el)
            }}>
            {!hideFooter && (
              <>
                {footer ? (
                  <div
                    className={`absolute inset-x-0 bottom-0 flex justify-between border-t border-geyser bg-white p-6 ${
                      size === "large" ? "md:fixed" : "md:absolute"
                    }`}>
                    <div className="flex w-full items-center justify-between">{footer}</div>
                  </div>
                ) : (
                  <div
                    style={{
                      paddingBottom: size === "large" && dimension?.width < mdBreakpoint ? "72px" : "24px"
                    }}
                    className={`absolute inset-x-0 bottom-0 flex justify-between border-t border-geyser bg-white p-6 ${
                      size === "large" ? "md:fixed" : "md:absolute"
                    }`}>
                    <div className="flex w-full items-center justify-between">
                      <BrynsonSquareButton type="primary" label="Done" onClick={handleClick} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  )
}

Modal.propTypes = {
  closeModal: PropTypes.func,
  open: PropTypes.bool,
  children: PropTypes.node,
  header: PropTypes.node,
  footer: PropTypes.node,
  hideFooter: PropTypes.bool,
  handleClick: PropTypes.func,
  size: PropTypes.oneOf(["large", "small"]),
  wide: PropTypes.bool,
  contentMargin: PropTypes.string
}

export default Modal

export const SaveOrCancelFooter = ({ onSave, onCancel, loading }) => (
  <>
    <BrynsonSquareButton type="tertiary" onClick={() => onCancel()} label="Cancel" />
    <BrynsonSquareButton type="primary" loading={loading} onClick={() => onSave()} label="Save" />
  </>
)

export const ConfirmCancelModal = NiceModal.create(({ loading }) => {
  const modal = useModal()

  return (
    <Modal
      open={modal.visible}
      closeModal={() => modal.hide()}
      size="small"
      header={
        <div>
          <DesktopH5 content="Cancel booking" />
        </div>
      }
      footer={
        <>
          <BrynsonSquareButton type="tertiary" onClick={() => modal.hide()} label="Go back" />
          <BrynsonSquareButton
            type="warning"
            loading={loading}
            onClick={() => modal.resolve()}
            label="Cancel booking"
          />
        </>
      }>
      <DesktopTitle3 content={"Are you sure you want to cancel your booking?"} />
      <p className="help-text">This booking will immediately be cancelled and the owner will be notified.</p>
    </Modal>
  )
})
