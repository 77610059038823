import React from "react"
const soci = require("../../../../../public/samples/soci.png")

export default function Soci() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Soci</h1>
			<p className="text-lg font-medium">Integrate with your marketing delivery provider</p>
			<div className="flex justify-center align-center mt-8">
				<img src={soci} />
			</div>
		</div>
	)
}