import React, { forwardRef } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  width: 100%;
  textarea {
    border: 1px solid #c8d6df;
    background: white;
    padding: 14px 16px;
    width: 100%;
    margin: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0.25rem;
    height: auto;
    font-size: 16px;
  }
`

const TextAreaField = (props, ref) => (
  <Wrapper>
    <textarea ref={ref} {...props} />
  </Wrapper>
)

export default forwardRef(TextAreaField)
