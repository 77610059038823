import React from "react"

const BreadCrumbs = ({ items }) => {
  return (
    <nav className="w-full rounded-md mb-3">
      <ol className="list-reset flex">
        {items.map((item, index) => (
          <li className="flex flex-row" key={`item-breadcrumb-${index}`}>
            <div>
              {item.link ?
                <a href={item.link} className="text-navy font-medium capitalize">{item.title}</a>
              :
                <span className="capitalize">{item.title}</span>
              }
            </div>
            {items.length -1 !== index &&
              <div>
                <span className="mx-2 text-neutral">/</span>
              </div>
            }
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default BreadCrumbs
