import React from "react"
import { CiCircleList } from "react-icons/ci"
import { TfiDashboard } from "react-icons/tfi"

import BrynsonEmbeddedDocuments from "../../shared/BrynsonEmbeddedDocuments"
import BrynsonTabs from "../../shared/BrynsonTabs"

import LeadTrackings from "./LeadTrackings"

export default function Conversions() {
  const tabs = [
    {
      label: "Summary",
    },
    {
      label: "CoStar",
    },
    {
      label: "Zillow"
    },
    {
      label: "Rent."
    },
    {
      label: "Zumper"
    },
    {
      label: "Apartment List"
    },
    {
      label: "Rentable"
    }
  ]

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold mb-2">ILS Performance Data</h1>
      <BrynsonTabs className="mt-4" tabs={tabs}>
        <BrynsonEmbeddedDocuments documentType="ils_conversion" />
        <>Coming Soon</>
        <>Coming Soon</>
        <>Coming Soon</>
        <>Coming Soon</>
        <>Coming Soon</>
      </BrynsonTabs>
    </div>
  )
}
