export const UPCOMING_INTEGRATIONS_QUERY = () => `
query {
  upcomingIntegrations {
      id
      name
      description
      category
      logoUrl
    }
  }
`
