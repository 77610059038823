/**
 * Filestack-react is having a bug on container id
 * this component here is for making it work
 */
import { client as filestack } from "filestack-react"
import React, { useEffect } from "react"

const usePicker = (_ref) => {
  const { apikey, mediumType } = _ref
  const _ref$pickerOptions = _ref.pickerOptions
  const pickerOptions = _ref$pickerOptions === undefined ? {} : _ref$pickerOptions

  const _ref$clientOptions = _ref.clientOptions
  const clientOptions = _ref$clientOptions === undefined ? {} : _ref$clientOptions
  const _ref$onSuccess = _ref.onSuccess
  // eslint-disable-next-line no-console
  const onSuccess = _ref$onSuccess === undefined ? console.log : _ref$onSuccess
  const _ref$onError = _ref.onError
  // eslint-disable-next-line no-console
  const onError = _ref$onError === undefined ? console.error : _ref$onError

  const _onError = (error) => {
    onError(error)
  }
  const _onSuccess = (result) => {
    onSuccess(result)
  }
  const rootId = "asset-root"
  const containerId = "asset-container"
  const picker = filestack.Filestack(apikey, clientOptions).picker({
    ...pickerOptions,
    rootId,
    container: `#${containerId}`,
    onUploadDone: _onSuccess
  })

  useEffect(() => {
    picker.open().then().catch(_onError)
    return () => {
      if (picker) {
        picker.close()
      }
    }
  }, [mediumType])
  return {
    containerId
  }
}

const FilestackPicker = (_ref) => {
  const { apikey, pickerOptions, clientOptions, onSuccess, onError, mediumType } = _ref
  const _usePicker = usePicker({
    mediumType,
    apikey,
    pickerOptions: {
      ...pickerOptions,
      displayMode: filestack.PickerDisplayMode.inline
    },
    clientOptions,
    onSuccess,
    onError
  })
  const { containerId } = _usePicker
  return <div id={containerId} style={{ height: "319px", width: "100%" }} data-testid="picker-inline" />
}

export default FilestackPicker
