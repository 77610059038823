import { clsx } from "clsx"
import React from "react"

import { UnitCollection } from "./UnitCollection"

export function FloorplansCard({ floorplan = {}, index = 0 }) {
  const colors = ["border-bblue", "border-bblue", "border-bblue", "border-bblue"]
  return (
    <div className="col-span-1 rounded-md border">
      <div className={clsx("border-b-4", colors[index % colors.length])}>
        <div className="flex items-center justify-between p-4">
          <p className="text-xl font-semibold text-gray-800">{floorplan.name}</p>
          <p className="text-sm font-semibold text-gray-400">{floorplan.desc}</p>
        </div>
      </div>
      <div className="space-y-1.5 p-4 pb-5 text-sm">
        {floorplan.data.map((item, index) => (
          <div key={index} className="flex items-center justify-between">
            <div className="font-medium text-gray-700">{item.label}</div>
            <div className="font-mono font-semibold tracking-widest text-gray-800">{item.value}</div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default function FloorplansCards({ properties = [] }) {
  const units = UnitCollection.fromProperties(properties)
  const groupedByFloorplan = units.groupByFloorplan()

  return (
    <div className="my-8 grid grid-cols-4 gap-4">
      {Object.entries(groupedByFloorplan).map(([bed, unitCollection], index) => (
        <FloorplansCard key={bed} floorplan={unitCollection.toFloorplan()} index={index} />
      ))}
    </div>
  )
}
