export const MEDIUM_QUERY = `
  query Medium($mediumableId:ID!, $mediumableType: String!) {
    medium(mediumableId:$mediumableId, mediumableType:$mediumableType) {
      id
      caption
      rank
      mediumType
      matterportUrl
      importedUrl
    }
  }
`

export const CREATE_MEDIUM_MUTATION = `
  mutation CreateMediumMutation($mediumData: String!, $mediumableId: ID!, $mediumableType: String!, $caption: String, $rank: Int, $mediumType: String!, $matterportUrl: String) {
    createMediumMutation(mediumData: $mediumData, mediumableId: $mediumableId, mediumableType: $mediumableType, caption: $caption, rank: $rank, mediumType: $mediumType, matterportUrl: $matterportUrl) {
      errors
      success
      medium {
        id
        caption
        rank
        mediumType
        mediumableId
        mediumableType
        matterportUrl
      }
    }
  }
`

export const UPDATE_MEDIUM_MUTATION = `
  mutation UpdateMediumMutation($id: ID!, $caption: String, $rank: Int, $mediumType: String!, $name: String, $feedDisabled: Boolean) {
    updateMediumMutation(id: $id, caption: $caption, rank: $rank, mediumType: $mediumType, name: $name, feedDisabled: $feedDisabled) {
      errors
      success
      medium {
        id
        caption
        rank
        name
        feedDisabled
        mediumType
        mediumableId
        mediumableType
        matterportUrl
      }
    }
  }
`

export const BULK_UPDATE_MEDIA_MUTATION = `
  mutation BulkUpdateMediaMutation($mediaIds: [ID!]!, $caption: String, $rank: Int, $mediumType: String, $name: String, $feedDisabled: Boolean, $mediumableType: String, $mediumableId: ID, $matterportUrl: String) {
    bulkUpdateMediaMutation(mediaIds: $mediaIds, caption: $caption, rank: $rank, mediumType: $mediumType, name: $name, feedDisabled: $feedDisabled, mediumableType: $mediumableType, matterportUrl: $matterportUrl, mediumableId: $mediumableId) {
      errors
      success
    }
  }
`

export const DELETE_MEDIUM_MUTATION = `
  mutation DeleteMediumMutation($id: ID!) {
    deleteMediumMutation(id: $id) {
      result
      errors
    }
  }
`

export const SORT_MEDIA_MUTATION = `
  mutation SortMediaMutation($mediaIds: [ID!]!) {
    sortMediaMutation(mediaIds: $mediaIds) {
      errors
      success
    }
  }
`

export const UPDATE_MEDIUM_TYPE_FEED_MUTATION = `
  mutation UpdateMediumTypeFeedMutation($mediumType: String!, $mediumFeedableType: String!, $mediumFeedableId: ID!, $enabled: Boolean!) {
    updateMediumTypeFeedMutation(mediumType: $mediumType, mediumFeedableType: $mediumFeedableType, mediumFeedableId: $mediumFeedableId, enabled: $enabled) {
      errors
      success
      result
    }
  }
`
