import React from "react"
const digible = require("../../../../../public/samples/digible.png")

export default function Digible() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Digible</h1>
			<p className="text-lg font-medium">Integrate with your marketing analytics provider</p>
			<div className="flex justify-center align-center mt-8">
				<img src={digible} />
			</div>
		</div>
	)
}