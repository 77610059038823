import Swiper, { Navigation } from "swiper"
import "swiper/swiper.scss"

document.addEventListener("turbolinks:load", function () {
  // Swipers
  Swiper.use([Navigation])

  $("[data-swiper]").each(function () {
    const name = $(this).attr("data-swiper")
    const selector = `.swiper-container[data-swiper='${name}']`
    const navSelector = `.swiper-navigation[data-swiper='${name}']`
    new Swiper(selector, {
      direction: "horizontal",
      loop: $(window).width() < 480,
      breakpoints: {
        320: {
          slidesPerView: 2,
          spaceBetween: 14
        },
        480: {
          slidesPerView: 3,
          spaceBetween: 14
        }
      },
      navigation: {
        nextEl: `${navSelector} .swiper-next`,
        prevEl: `${navSelector} .swiper-prev`
      },
      on: {
        init: function (swiper) {
          if (swiper.width >= 880) {
            $(`${navSelector} .swiper-prev`).css({ visibility: "hidden" })

            if (swiper.slides.length < 3) {
              $(`${navSelector} .swiper-next`).css({ visibility: "hidden" })
            }
          } else {
            $(navSelector).insertAfter(selector)
          }
        },
        imagesReady: function (swiper) {
          if (swiper.slides[0].querySelector(".inner")) {
            const heights = swiper.slides.map((slide) => {
              return $(".inner", slide).outerHeight()
            })

            const maxHeight = heights.reduce(function (a, b) {
              return Math.max(a, b)
            })

            swiper.slides.forEach((slide) => {
              const offset = (maxHeight - $(".inner", slide).height()) / 2
              if (offset > 0) {
                $(".inner", slide).css({ padding: `${offset}px 15px ${offset}px 15px` })
              }
            })
          }
        },
        slideChangeTransitionStart: function (swiper) {
          if (swiper.width >= 480) {
            if (swiper.activeIndex == 0) {
              $(`${navSelector} .swiper-prev`).css({ visibility: "hidden" })
              $(`${navSelector} .swiper-next`).css({ visibility: "visible" })
            } else if (swiper.activeIndex == swiper.slides.length - 2) {
              $(`${navSelector} .swiper-prev`).css({ visibility: "visible" })
              $(`${navSelector} .swiper-next`).css({ visibility: "hidden" })
            } else {
              $(`${navSelector} .swiper-prev`).css({ visibility: "visible" })
              $(`${navSelector} .swiper-next`).css({ visibility: "visible" })
            }
          }
        }
      }
    })
  })
})
