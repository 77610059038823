import PropTypes from "prop-types"
import React from "react"
import { Tooltip } from "react-tooltip"
import "react-tooltip/dist/react-tooltip.css"

const BrynsonTooltip = (props) => (
  <Tooltip
    style={{
      "--rt-color-dark": "var(--color-navy)",
      fontSize: "11px",
      fontWeight: "400",
      zIndex: "9999",
      textTransform: "none"
    }}
    {...props}
  />
)

BrynsonTooltip.propTypes = {
  id: PropTypes.string,
  anchorSelect: PropTypes.string,
  render: PropTypes.func,
  place: PropTypes.oneOf(["top", "right", "bottom", "left"]),
  noArrow: PropTypes.bool,
  isOpen: PropTypes.bool
}

export default BrynsonTooltip
