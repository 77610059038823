export const CREATE_AVAILABILITY_MUTATION = `
  mutation CreateAvailabilityMutation($unitId: Int!, $vacateDate: ISO8601Date, $vacancyClass: String, $madeReadyDate: ISO8601Date, $unitAvailabilityUrl: String) {
    createAvailabilityMutation(
      unitId: $unitId
      vacateDate: $vacateDate
      vacancyClass: $vacancyClass
      madeReadyDate: $madeReadyDate
      unitAvailabilityUrl: $unitAvailabilityUrl
    ) {
      id
      unitId
      vacateDate
      vacancyClass
      madeReadyDate
      unitAvailabilityUrl
    }
  }
`

export const UPDATE_AVAILABILITY_MUTATION = `
  mutation UpdateAvailabilityMutation($id: ID!, $vacateDate: ISO8601Date, $vacancyClass: String, $madeReadyDate: ISO8601Date, $unitAvailabilityUrl: String) {
    updateAvailabilityMutation(
      id: $id
      vacateDate: $vacateDate
      vacancyClass: $vacancyClass
      madeReadyDate: $madeReadyDate
      unitAvailabilityUrl: $unitAvailabilityUrl
    ) {
      id
      unitId
      vacateDate
      vacancyClass
      madeReadyDate
      unitAvailabilityUrl
    }
  }
`
