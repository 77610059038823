import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_ACCOUNT_MUTATION, UPDATE_ACCOUNT_MUTATION } from "../queries/AccountsMutations";

export const AccountsContext = createContext(null);

const AccountsProvider = ({ children, ...props }) => {
  const [, createAccount] = useMutation(CREATE_ACCOUNT_MUTATION)
  const [, updateAccount] = useMutation(UPDATE_ACCOUNT_MUTATION)

  return(
    <AccountsContext.Provider value={{createAccount, updateAccount}}>
      {children}
    </AccountsContext.Provider>
  );
}

export default AccountsProvider;
