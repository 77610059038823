import clsx from "clsx"
import React from "react"

export default function DropdownCaret({ expanded }) {
  return (
    <i
      className={clsx("ml-2 inline-block h-2 w-2 rotate-45", {
        "mt-0.5 border-l-2 border-t-2 border-navy": !expanded,
        "mb-0.5 border-b-2 border-r-2 border-teal": expanded
      })}
    />
  )
}
