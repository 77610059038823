import React, { createContext } from "react"
import { useMutation } from "urql"

import { CREATE_PROPERTY_DIRECTORY_MUTATION, PROPERTIES_DIRECTORY_QUERY, UPDATE_PROPERTY_DIRECTORY_MUTATION } from "../queries/PropertiesDirectoryMutations"
import { CREATE_PROPERTY_DIRECTORY_INFO_MUTATION, UPDATE_PROPERTY_DIRECTORY_INFO_MUTATION } from "../queries/PropertiesDirectoryInfoMutations"

export const ILSRatesContext = createContext(null)

const ILSRates = ({ children, ...props }) => {
  const [, queryPropertiesDirectory] = useMutation(PROPERTIES_DIRECTORY_QUERY)
  const [, createPropertyDirectory] = useMutation(CREATE_PROPERTY_DIRECTORY_MUTATION)
  const [, updatePropertyDirectory] = useMutation(UPDATE_PROPERTY_DIRECTORY_MUTATION)
  const [, createPropertyDirectoryInfo] = useMutation(CREATE_PROPERTY_DIRECTORY_INFO_MUTATION)
  const [, updatePropertyDirectoryInfo] = useMutation(UPDATE_PROPERTY_DIRECTORY_INFO_MUTATION)
  return (
    <ILSRatesContext.Provider value={{ createPropertyDirectory, updatePropertyDirectory, queryPropertiesDirectory, createPropertyDirectoryInfo, updatePropertyDirectoryInfo }}>
      {children}
    </ILSRatesContext.Provider>
  )
}

export default ILSRates
