import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["field", "error", "button"]
  static values = {
    allCredentials: Array
  }

  connect() {
    this.change()
    this.buttonTarget.disabled = true
  }

  change() {
    const newCredential = this.fieldTarget.value.replace(/\s+/g, "-").toLowerCase()
    if (this.allCredentialsValue.includes(newCredential)) {
      this.fieldTarget.classList.add("is-danger")
      this.errorTarget.classList.remove("hidden")
      this.buttonTarget.classList.add("hidden")
    } else if (this.fieldTarget.value == "") {
      this.buttonTarget.disabled = true
      this.fieldTarget.classList.remove("is-danger")
      this.errorTarget.classList.add("hidden")
      this.buttonTarget.classList.remove("hidden")
    } else {
      this.fieldTarget.classList.remove("is-danger")
      this.errorTarget.classList.add("hidden")
      this.buttonTarget.classList.remove("hidden")
      this.buttonTarget.disabled = false
    }
  }
}
