import React from "react"

import MediaProvider from "../../context/MediaContext"
import MediaIndex from "../../shared/MediaIndex"

export default function Media({ params, page, filestack_api_key }) {
  return (
    <MediaProvider>
      {page === "index" && <MediaIndex params={params} filestackApiKey={filestack_api_key} />}
    </MediaProvider>
  )
}
