import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    $("input", this.element).keyup(function () {
      this.value = this.value.replace(/[^0-9\.]/g, "")
      if (this.value) {
        $(this).parent().next().find("input").focus()

        if (!$(this).parent().next().length) {
          $(this).closest("form").find("button").click()
        }
      }
    })

    $("input:first", this.element).focus()
  }
}
