import clsx from "clsx"
import { Table } from "flowbite-react"
import React, { useState } from "react"

function $(value, fixed = -1) {
  const processedValue = typeof value === "number" ? value : parseFloat(value)
  return typeof processedValue === "number" ? `$${fixed >= 0 ? processedValue.toFixed(fixed) : processedValue}` : ""
}

export default function WeeklyMarketActivityUnitsTable({ units = [] }) {
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  const renderPagination = () => {
    const pageNumbers = []
    const maxButtons = 7
    const halfMaxButtons = Math.floor(maxButtons / 2)

    // Calculate the position where the ellipsis should appear
    let startPage = Math.max(1, currentPage - halfMaxButtons)
    let endPage = Math.min(startPage + maxButtons - 1, totalPages)

    // If the current page is close to the beginning, adjust endPage
    if (currentPage <= halfMaxButtons) {
      endPage = Math.min(maxButtons, totalPages)
    }

    // If the current page is close to the end, adjust startPage
    if (currentPage >= totalPages - halfMaxButtons) {
      startPage = Math.max(totalPages - maxButtons + 1, 1)
    }

    pageNumbers.push(
      <button
        key="prev"
        onClick={() => {
          if (currentPage > 1) {
            handlePageChange(currentPage - 1)
          }
        }}
        className={clsx(
          "m-1 rounded px-2 py-1",
          currentPage > 1 ? "cursor-pointer bg-gray-100 text-gray-600" : "cursor-not-allowed bg-gray-200 text-gray-400"
        )}>
        &lt;
      </button>
    )

    if (startPage > 1) {
      pageNumbers.push(
        <button
          key="first"
          onClick={() => handlePageChange(1)}
          className="m-1 rounded bg-gray-100 px-2 py-1 text-gray-600">
          1
        </button>
      )
      if (startPage > 2) {
        pageNumbers.push(
          <div key="ellipsis-start" className="m-1 px-2 py-1">
            ...
          </div>
        )
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={clsx("m-1 rounded px-2 py-1", i === currentPage ? "bg-blue-400" : "bg-gray-100 text-gray-600")}>
          {i}
        </button>
      )
    }

    if (endPage < totalPages - 1) {
      pageNumbers.push(
        <div key="ellipsis-end" className="m-1 px-2 py-1">
          ...
        </div>
      )
    }

    if (endPage < totalPages) {
      pageNumbers.push(
        <button
          key="last"
          onClick={() => handlePageChange(totalPages)}
          className="m-1 rounded bg-gray-100 px-2 py-1 text-gray-600">
          {totalPages}
        </button>
      )
    }

    pageNumbers.push(
      <button
        key="next"
        onClick={() => {
          if (currentPage < totalPages) {
            handlePageChange(currentPage + 1)
          }
        }}
        className={clsx(
          "m-1 rounded px-2 py-1",
          currentPage < totalPages
            ? "cursor-pointer bg-gray-100 text-gray-600"
            : "cursor-not-allowed bg-gray-200 text-gray-400"
        )}>
        &gt;
      </button>
    )

    return <div className="flex justify-center">{pageNumbers}</div>
  }

  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 5
  const totalPages = Math.ceil(units.length / itemsPerPage)
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  const currentUnits = units.slice(indexOfFirstItem, indexOfLastItem)
  return (
    <div className="overflow-x-auto">
      <Table>
        <Table.Head>
          <Table.HeadCell>Property Name</Table.HeadCell>
          <Table.HeadCell>Floorplan</Table.HeadCell>
          <Table.HeadCell>Unit</Table.HeadCell>
          <Table.HeadCell>Floor</Table.HeadCell>
          <Table.HeadCell>Bed</Table.HeadCell>
          <Table.HeadCell>Bath</Table.HeadCell>
          <Table.HeadCell>SF</Table.HeadCell>
          <Table.HeadCell>Available On</Table.HeadCell>
          <Table.HeadCell>Last Ask Price</Table.HeadCell>
          <Table.HeadCell>Ask PSF</Table.HeadCell>
          <Table.HeadCell>Last Eff Price</Table.HeadCell>
          <Table.HeadCell>Eff PSF</Table.HeadCell>
          <Table.HeadCell>On Mkt Date</Table.HeadCell>
          <Table.HeadCell>Leased Date</Table.HeadCell>
          <Table.HeadCell>Days on Mkt</Table.HeadCell>
        </Table.Head>
        <Table.Body className="border-t text-sm">
          {currentUnits.map((unit) => (
            <Table.Row key={unit.id} className="border-b font-medium text-gray-800 hover:bg-blue-100">
              <Table.Cell className="p-2 text-center align-left">{unit.property}</Table.Cell>
              <Table.Cell className="p-2 text-center align-left">{unit.floorplan_name}</Table.Cell>
              <Table.Cell className="p-2 text-center align-left">{unit.unit_name}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">{unit.floor}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">{unit.bed}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">{unit.bath}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">{unit.sqft}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">{unit.created_on || unit.availability}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">{unit.price ? $(unit.price) : null}</Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">
                {unit.sqft ? $(unit.price / unit.sqft, 2) : null}
              </Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">
                {unit.effective_price ? $(unit.effective_price) : null}
              </Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">
                {unit.sqft ? $(unit.effective_price / unit.sqft, 2) : null}
              </Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">
                {unit.enter_market ? unit.enter_market : "-"}
              </Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">
                {unit.exit_market ? unit.exit_market : "Active"}
              </Table.Cell>
              <Table.Cell className="p-2 text-center align-middle">
                {unit.days_on_market ? unit.days_on_market : "-"}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      {renderPagination()}
    </div>
  )
}
