import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_DEPOSIT_MUTATION, UPDATE_DEPOSIT_MUTATION } from "../queries/DepositsMutations";

export const DepositsContext = createContext(null);

const DepositProvider = ({ children, ...props }) => {
  const [, createDeposit] = useMutation(CREATE_DEPOSIT_MUTATION)
  const [, updateDeposit] = useMutation(UPDATE_DEPOSIT_MUTATION)

  return(
    <DepositsContext.Provider value={{createDeposit, updateDeposit}}>
      {children}
    </DepositsContext.Provider>
  );
}

export default DepositProvider;
