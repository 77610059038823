import React, { useContext, useEffect, useState } from "react"
import { useQuery } from "urql"

import { GlobalContext } from "../../context/GlobalContext"
import { UPCOMING_INTEGRATIONS_QUERY } from "../../queries/UpcomingIntegrations"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import BrynsonTabs from "../../shared/BrynsonTabs"
import DesktopH5 from "../../shared/DesktopH5"
import DesktopTitle3 from "../../shared/DesktopTitle3"
import Modal from "../../shared/Modal"

import IntegrationsDemo from "./IntegrationsDemo"

export default function Integrations() {
  const { updateUpcomingIntegration } = useContext(GlobalContext)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [selected, setSelected] = useState(null)

  const [{ data: upcomingIntegrationsData }] = useQuery({
    query: UPCOMING_INTEGRATIONS_QUERY()
  })

  const upcomingIntegrations = (category) =>
    upcomingIntegrationsData?.upcomingIntegrations.filter((integration) => integration.category === category)

  const pms = () => upcomingIntegrations("Property Management System")
  const resident = () => upcomingIntegrations("Resident Services")
  const advertising = () => upcomingIntegrations("Advertising")
  const biDataAnalytics = () => upcomingIntegrations("BI & Data Analytics")
  const ils = () => upcomingIntegrations("ILS")
  const crm = () => upcomingIntegrations("CRM")
  const leasingAi = () => upcomingIntegrations("Leasing AI")
  const marketingSoftware = () => upcomingIntegrations("Marketing Software")
  const reputationManagement = () => upcomingIntegrations("Reputation Management")
  const other = () => upcomingIntegrations("Other")

  const tabs = [
    {
      label: "PMS"
    },
    {
      label: "Advertising"
    },
    {
      label: "BI & Data"
    },
    {
      label: "CRM"
    },
    {
      label: "ILS"
    },
    {
      label: "Leasing AI"
    },
    {
      label: "Marketing"
    },
    {
      label: "Rep Management"
    },
    {
      label: "Resident Services"
    },
    {
      label: "Other"
    }
  ]

  const integrationTab = (list) => (
    <div className="grid grid-cols-4 gap-4">
      {list?.map((integration) => (
        <div key={`${integration.id}-integration`} className="flex flex-col border p-8 text-center shadow">
          <img src={integration.logoUrl} className="h-28 w-auto object-contain" />
          <p className="mt-4 text-lg font-semibold">{integration.name}</p>
          <p className="mb-4 text-lg font-medium">{integration.description}</p>
          <BrynsonSquareButton
            label="Connect Now"
            className="mt-auto"
            onClick={() => {
              setShow(true)
              setSelected(integration)
            }}
          />
        </div>
      ))}
    </div>
  )

  return (
    <div className="p-8">
      <h1 className="text-xl font-semibold">Integrations Directory</h1>
      <p className="text-lg font-medium">Find an integration to connect with and supercharge your Brynsights.</p>
      <div className="align-center mt-8 flex justify-center">
        <BrynsonTabs className="mt-4" tabs={tabs}>
          <>
            {upcomingIntegrationsData?.upcomingIntegrations?.length > 0 ? (
              <>
                {integrationTab(pms())}
              </>
            ) : (
              <IntegrationsDemo />
            )}


          </>

          <>{integrationTab(advertising())}</>
          <>{integrationTab(biDataAnalytics())}</>
          <>{integrationTab(crm())}</>
          <>{integrationTab(ils())}</>
          <>{integrationTab(leasingAi())}</>
          <>{integrationTab(marketingSoftware())}</>
          <>{integrationTab(reputationManagement())}</>
          <>{integrationTab(resident())}</>
          <>{integrationTab(other())}</>
        </BrynsonTabs>
      </div>

      <Modal
        open={show}
        closeModal={() => {
          setShow(false)
        }}
        size="small"
        header={
          <div>
            <DesktopH5 content="Integration Coming Soon" />
          </div>
        }
        footer={
          <>
            <BrynsonSquareButton
              type="link"
              size="medium"
              onClick={() => {
                setShow(false)
              }}
              label="Go Back"
            />
            <BrynsonSquareButton
              type="primary"
              size="medium"
              loading={loading}
              className={"flex items-center justify-center"}
              onClick={() => {
                setLoading(true)
                updateUpcomingIntegration({
                  id: parseInt(selected?.id)
                }).then((result) => {
                  setTimeout(() => {
                    setLoading(false)
                    setShow(false)
                  }, 2000)
                })
              }}
              label="Request"
            />
          </>
        }>
        <DesktopTitle3 content="At Brynson, we're constantly adding new providers and integrations. You can help us prioritize by voting for this one below." />
        <DesktopTitle3 content={selected?.name} />
        <DesktopTitle3 content={selected?.description} />
      </Modal>
    </div>
  )
}
