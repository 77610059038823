import React, { useRef, useContext, useState } from "react"

import { GlobalContext } from "../../context/GlobalContext"
import ILSRatesProvider from "../../context/ILSRatesContext"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"
import ILSRatesTab from "./ILSRatesTab"
import BrynsonTabs from "../../shared/BrynsonTabs"
import Loading from "../../shared/Loading"

const ILS_TYPES = ['Apartment List', 'CoStar',  'CoStar Standard', 'CoStar Value', 'Rent.com', 'Zillow', 'Zumper']
const tabs = ILS_TYPES.map(x => ({label: x, icon: null}))

export default function ILSRates() {
  const [loading, setLoading] = useState(false)
  const { ilsRatesData: data, ilsRatesReload: reexecuteQuery, currentUserData } = useContext(GlobalContext)
  const showActions = currentUserData?.currentUser?.email?.endsWith('@brynson.com')
  const fileInputRef = useRef(null);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      setLoading(true);
      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await fetch('/ils_rates/import', {
          method: 'POST',
          body: formData,
          headers: {
            "Accept": "application/json",
            "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').content,
          },
        });

        if (response.ok) {
          reexecuteQuery();
        } else {
          const error = await response.json();
          alert(error.error);
        }
      } catch (error) {
        console.log('Error importing file:', error);
      } finally {
        fileInputRef.current.value = null;
        setLoading(false);
      }
    }
  };

  // Filter tabs based on the existence of their type in the data
  const filteredTabs = tabs.filter(tab => data?.brynsonRates?.some(rate => rate.ilsType === tab.label));

  return (
    <ILSRatesProvider>
      <div className="p-8">
        <h1 className="font-semibold text-xl mb-6">ILS Rates</h1>
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          accept=".csv"
          onChange={handleFileChange}
        />
        {showActions &&
          <div className="text-right mb-4">
            <BrynsonSquareButton
              label="Import ILS Rates"
              fullWidth={false}
              onClick={handleClick}
              loading={loading}
            />
          </div>
        }
        {filteredTabs.length > 0 ? (
          <div>
            <BrynsonTabs
              tabs={filteredTabs}
              className="">
              {filteredTabs.map((tab, index) => (
                <ILSRatesTab rate={data.brynsonRates.find(x => x.ilsType === tab.label)} key={index} />
              ))}
            </BrynsonTabs>
          </div>
        ) : (
          <Loading />
        )}
      </div>
    </ILSRatesProvider>
  )
}
