import { Controller } from "stimulus"
export default class extends Controller {
  connect() {}

  newPageUrl = (event) => {
    const value = this.data.get("page")

    var url = property.href
    var separator = url.indexOf("?") === -1 ? "?" : "&"
    var param = separator + "page="
    var newUrl
    if (separator == "&") {
      var regex = new RegExp(param + "[^&]*")
      newUrl = url.replace(regex, "")
    } else {
      newUrl = url
    }
    event.preventDefault()

    var newParam = param + value
    newUrl += newParam

    history.pushState({ turbolinks: true, url: newUrl }, "", newUrl)
  }
}
