import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["countryCode"]

  connect() {
    setTimeout(() => {
      document.getElementById("flag-container").addEventListener("click", function () {
        let countryCode = document.getElementById("selected-dial-code").innerHTML
        $("#prefix_input").val(countryCode)
      })
    }, 1000)
  }
}
