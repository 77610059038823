import React, { createContext } from "react";
import { useMutation } from "urql"
import { CREATE_PROPERTY_MUTATION, DELETE_PROPERTY_MUTATION, EDIT_PROPERTY_MUTATION, UPLOAD_PROPERTIES_MUTATION } from "../queries/PropertiesMutations";

export const PropertiesContext = createContext(null);

const PropertiesProvider = ({ children, ...props }) => {
  const [, createProperty] = useMutation(CREATE_PROPERTY_MUTATION)
  const [, editProperty] = useMutation(EDIT_PROPERTY_MUTATION)
  const [, deleteProperty] = useMutation(DELETE_PROPERTY_MUTATION)
  const [, uploadProperties] = useMutation(UPLOAD_PROPERTIES_MUTATION)
  
  return(
    <PropertiesContext.Provider value={{createProperty, editProperty, deleteProperty, uploadProperties}}>
      {children}
    </PropertiesContext.Provider>
  );
}

export default PropertiesProvider;
