export const CREATE_PROPERTY_DIRECTORY_INFO_MUTATION = `
  mutation CreatePropertyDirectoryInfoMutation(
    $propertyId:Int!,
    $propertyDirectoryId:Int!,
    $currentMonthOccupancyPercentage:Float,
    $askingRentPerSf:Float,
    $currentCostarRate:Float,
    $currentZillowRate:Float,
    $currentRentComRate:Float,
    $currentApartmentListRate:Float,
    $currentZumperRate:Float,
    $currentRentableRate:Float,
    $brynsonIlsManagementFee:Float,
    $brynsonCostarRate:Float,
    $brynsonZillowRate:Float,
    $brynsonRentRate:Float,
    $brynsonApartmentListRate:Float,
    $brynsonZumperRate:Float,
    $brynsonRentableRate:Float,
    $brynsonRealtorRate:Float,
    $brynson3dApartmentsRate:Float,
    $current3dApartmentsRate:Float,
    $currentRealtorRate:Float,
    $currentCostarPackage:String,
    $currentZillowPackage:String,
    $currentRentComPackage:String,
    $apartmentListPackage:String,
    $currentZumperPackage:String,
    $currentRentablePackage:String,
    $current3dApartmentsPackage:String,
    $currentRealtorPackage:String,
    $costarPropertyTaxes:Float,
    $zillowPropertyTaxes:Float,
    $rentPropertyTaxes:Float,
    $apartmentListPropertyTaxes:Float,
    $zumperPropertyTaxes:Float,
    $rentablePropertyTaxes:Float,
    $realtorPropertyTaxes:Float,
    $currentRentSocialPackage:String,
    $brynsonRentSocialRate:Float,
    $rentSocialPropertyTaxes:Float,
    $apartmentListPplFee:Float,
    $zillowPplFee:Float,
    $apartmentListPlatformFee:Float,
    $ownershipGroup:String,
    $region:String,
    $apartmentListLeases:Float,
    $apartmentListPplTotal:Float,
    $zillowLeases:Float,
    $zillowPplTotal:Float,
    $costarChanges:String,
    $zillowChanges:String,
    $rentComChanges:String,
    $apartmentListChanges:String,
    $zumperChanges:String,
    $rentableChanges:String,
    $realtorChanges:String
  ) {
    createPropertyDirectoryInfoMutation(
      propertyId: $propertyId,
      propertyDirectoryId: $propertyDirectoryId,
      currentMonthOccupancyPercentage: $currentMonthOccupancyPercentage,
      askingRentPerSf: $askingRentPerSf,
      currentCostarRate: $currentCostarRate,
      currentZillowRate: $currentZillowRate,
      currentRentComRate: $currentRentComRate,
      currentApartmentListRate: $currentApartmentListRate,
      currentZumperRate: $currentZumperRate,
      currentRentableRate: $currentRentableRate,
      brynsonIlsManagementFee: $brynsonIlsManagementFee,
      brynsonCostarRate: $brynsonCostarRate,
      brynsonZillowRate: $brynsonZillowRate,
      brynsonRentRate: $brynsonRentRate,
      brynsonApartmentListRate: $brynsonApartmentListRate,
      brynsonZumperRate: $brynsonZumperRate,
      brynsonRentableRate: $brynsonRentableRate,
      brynsonRealtorRate: $brynsonRealtorRate,
      brynson3dApartmentsRate: $brynson3dApartmentsRate,
      current3dApartmentsRate: $current3dApartmentsRate,
      costarPropertyTaxes: $costarPropertyTaxes,
      currentRealtorRate: $currentRealtorRate,
      currentCostarPackage: $currentCostarPackage,
      currentZillowPackage: $currentZillowPackage,
      currentRentComPackage: $currentRentComPackage,
      apartmentListPackage: $apartmentListPackage,
      currentZumperPackage: $currentZumperPackage,
      currentRentablePackage: $currentRentablePackage,
      current3dApartmentsPackage: $current3dApartmentsPackage,
      currentRealtorPackage: $currentRealtorPackage,
      zillowPropertyTaxes: $zillowPropertyTaxes,
      rentPropertyTaxes: $rentPropertyTaxes,
      apartmentListPropertyTaxes: $apartmentListPropertyTaxes,
      zumperPropertyTaxes: $zumperPropertyTaxes,
      rentablePropertyTaxes: $rentablePropertyTaxes,
      realtorPropertyTaxes: $realtorPropertyTaxes,
      currentRentSocialPackage: $currentRentSocialPackage,
      brynsonRentSocialRate: $brynsonRentSocialRate,
      rentSocialPropertyTaxes: $rentSocialPropertyTaxes,
      apartmentListPplFee: $apartmentListPplFee,
      zillowPplFee: $zillowPplFee,
      apartmentListPlatformFee: $apartmentListPlatformFee,
      ownershipGroup: $ownershipGroup,
      region: $region,
      apartmentListLeases: $apartmentListLeases,
      apartmentListPplTotal: $apartmentListPplTotal,
      zillowLeases: $zillowLeases,
      zillowPplTotal: $zillowPplTotal,
      costarChanges: $costarChanges,
      zillowChanges: $zillowChanges,
      rentComChanges: $rentComChanges,
      apartmentListChanges: $apartmentListChanges,
      zumperChanges: $zumperChanges,
      rentableChanges: $rentableChanges,
      realtorChanges: $realtorChanges
    ) {
      id
      currentMonthOccupancyPercentage
      askingRentPerSf
      currentCostarRate
      currentZillowRate
      currentRentComRate
      currentApartmentListRate
      currentZumperRate
      currentRentableRate
      brynsonIlsManagementFee
      brynsonCostarRate
      brynsonZillowRate
      brynsonRentRate
      brynsonApartmentListRate
      brynsonZumperRate
      brynsonRentableRate
      brynsonRealtorRate
      brynson3dApartmentsRate
      current3dApartmentsRate
      currentRealtorRate
      currentCostarPackage
      currentZillowPackage
      currentRentComPackage
      apartmentListPackage
      currentZumperPackage
      currentRentablePackage
      current3dApartmentsPackage
      currentRealtorPackage
      costarPropertyTaxes
      zillowPropertyTaxes
      rentPropertyTaxes
      apartmentListPropertyTaxes
      zumperPropertyTaxes
      rentablePropertyTaxes
      realtorPropertyTaxes
      currentRentSocialPackage
      brynsonRentSocialRate
      rentSocialPropertyTaxes
      apartmentListPplFee
      zillowPplFee
      apartmentListPlatformFee
      ownershipGroup
      region
      propertyId
      propertyDirectoryId
      apartmentListLeases
      apartmentListPplTotal
      zillowLeases
      zillowPplTotal
      costarChanges
      zillowChanges
      rentComChanges
      apartmentListChanges
      zumperChanges
      rentableChanges
      realtorChanges
    }
  }
`

export const UPDATE_PROPERTY_DIRECTORY_INFO_MUTATION = `
  mutation UpdatePropertyDirectoryInfoMutation($id:ID!,
  $currentMonthOccupancyPercentage:Float,
  $askingRentPerSf:Float,
  $currentCostarRate:Float,
  $currentZillowRate:Float,
  $currentRentComRate:Float,
  $currentApartmentListRate:Float,
  $currentZumperRate:Float,
  $currentRentableRate:Float,
  $brynsonIlsManagementFee:Float,
  $brynsonCostarRate:Float,
  $brynsonZillowRate:Float,
  $brynsonRentRate:Float,
  $brynsonApartmentListRate:Float,
  $brynsonZumperRate:Float,
  $brynsonRentableRate:Float,
  $brynsonRealtorRate:Float,
  $brynson3dApartmentsRate:Float,
  $current3dApartmentsRate:Float,
  $currentRealtorRate:Float,
  $currentCostarPackage:String,
  $currentZillowPackage:String,
  $currentRentComPackage:String,
  $apartmentListPackage:String,
  $currentZumperPackage:String,
  $currentRentablePackage:String,
  $current3dApartmentsPackage:String,
  $currentRealtorPackage:String,
  $costarPropertyTaxes:Float,
  $zillowPropertyTaxes:Float,
  $rentPropertyTaxes:Float,
  $apartmentListPropertyTaxes:Float,
  $zumperPropertyTaxes:Float,
  $rentablePropertyTaxes:Float,
  $realtorPropertyTaxes:Float,
  $currentRentSocialPackage:String,
  $brynsonRentSocialRate:Float,
  $rentSocialPropertyTaxes:Float,
  $apartmentListPplFee:Float,
  $zillowPplFee:Float,
  $apartmentListPlatformFee:Float,
  $ownershipGroup:String,
  $region:String,
  $apartmentListLeases:Float,
  $apartmentListPplTotal:Float,
  $zillowLeases:Float,
  $zillowPplTotal:Float,
  $costarChanges:String,
  $zillowChanges:String,
  $rentComChanges:String,
  $apartmentListChanges:String,
  $zumperChanges:String,
  $rentableChanges:String,
  $realtorChanges:String){
    updatePropertyDirectoryInfoMutation(id: $id,
    currentMonthOccupancyPercentage: $currentMonthOccupancyPercentage,
    costarPropertyTaxes: $costarPropertyTaxes,
    askingRentPerSf: $askingRentPerSf,
    currentCostarRate: $currentCostarRate,
    currentZillowRate: $currentZillowRate,
    currentRentComRate: $currentRentComRate,
    currentApartmentListRate: $currentApartmentListRate,
    currentZumperRate: $currentZumperRate,
    currentRentableRate: $currentRentableRate,
    brynsonIlsManagementFee: $brynsonIlsManagementFee,
    brynsonCostarRate: $brynsonCostarRate,
    brynsonZillowRate: $brynsonZillowRate,
    brynsonRentRate: $brynsonRentRate,
    brynsonApartmentListRate: $brynsonApartmentListRate,
    brynsonZumperRate: $brynsonZumperRate,
    brynsonRentableRate: $brynsonRentableRate,
    brynsonRealtorRate: $brynsonRealtorRate,
    brynson3dApartmentsRate: $brynson3dApartmentsRate,
    current3dApartmentsRate: $current3dApartmentsRate,
    currentRealtorRate: $currentRealtorRate,
    currentCostarPackage: $currentCostarPackage,
    currentZillowPackage: $currentZillowPackage,
    currentRentComPackage: $currentRentComPackage,
    apartmentListPackage: $apartmentListPackage,
    currentZumperPackage: $currentZumperPackage,
    currentRentablePackage: $currentRentablePackage,
    current3dApartmentsPackage: $current3dApartmentsPackage,
    currentRealtorPackage: $currentRealtorPackage,
    zillowPropertyTaxes: $zillowPropertyTaxes,
    rentPropertyTaxes: $rentPropertyTaxes,
    apartmentListPropertyTaxes: $apartmentListPropertyTaxes,
    zumperPropertyTaxes: $zumperPropertyTaxes,
    rentablePropertyTaxes: $rentablePropertyTaxes,
    realtorPropertyTaxes: $realtorPropertyTaxes,
    currentRentSocialPackage: $currentRentSocialPackage,
    brynsonRentSocialRate: $brynsonRentSocialRate,
    rentSocialPropertyTaxes: $rentSocialPropertyTaxes,
    apartmentListPplFee: $apartmentListPplFee,
    zillowPplFee: $zillowPplFee,
    apartmentListPlatformFee: $apartmentListPlatformFee,
    ownershipGroup: $ownershipGroup,
    region: $region,
    apartmentListLeases: $apartmentListLeases,
    apartmentListPplTotal: $apartmentListPplTotal,
    zillowLeases: $zillowLeases,
    zillowPplTotal: $zillowPplTotal,
    costarChanges: $costarChanges,
    zillowChanges: $zillowChanges,
    rentComChanges: $rentComChanges,
    apartmentListChanges: $apartmentListChanges,
    zumperChanges: $zumperChanges,
    rentableChanges: $rentableChanges,
    realtorChanges: $realtorChanges){
      id
      currentMonthOccupancyPercentage
      askingRentPerSf
      currentCostarRate
      currentZillowRate
      currentRentComRate
      currentApartmentListRate
      currentZumperRate
      currentRentableRate
      brynsonIlsManagementFee
      brynsonCostarRate
      brynsonZillowRate
      brynsonRentRate
      brynsonApartmentListRate
      brynsonZumperRate
      brynsonRentableRate
      brynsonRealtorRate
      brynson3dApartmentsRate
      current3dApartmentsRate
      currentRealtorRate
      currentCostarPackage
      currentZillowPackage
      currentRentComPackage
      apartmentListPackage
      currentZumperPackage
      currentRentablePackage
      current3dApartmentsPackage
      currentRealtorPackage
      costarPropertyTaxes
      zillowPropertyTaxes
      rentPropertyTaxes
      apartmentListPropertyTaxes
      zumperPropertyTaxes
      rentablePropertyTaxes
      realtorPropertyTaxes
      currentRentSocialPackage
      brynsonRentSocialRate
      rentSocialPropertyTaxes
      apartmentListPplFee
      zillowPplFee
      apartmentListPlatformFee
      ownershipGroup
      region
      propertyId
      propertyDirectoryId
      apartmentListLeases
      apartmentListPplTotal
      zillowLeases
      zillowPplTotal
      costarChanges
      zillowChanges
      rentComChanges
      apartmentListChanges
      zumperChanges
      rentableChanges
      realtorChanges
    }
  }
`
