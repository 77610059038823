import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["star", "input", "review", "button"]

  connect() {
    var selected = false
    this.showSavedRating()
    this.buttonTarget.disabled = true
    this.hasInput()
  }

  showSavedRating = () => {
    const value = $(this.inputTarget).val()
    this.highlightStars(value)
    if (value > 0) {
      this.selected = true
    }
  }

  select = (e) => {
    const value = $(e.currentTarget).attr("data-index")
    $(this.inputTarget).val(value)
    this.highlightStars(value)
    this.selected = true
    console.log("selected")
  }

  highlight = (e) => {
    const value = $(e.currentTarget).attr("data-index")
    this.highlightStars(value)
  }

  highlightStars = (n) => {
    $(this.element)
      .find("i[data-index=1]")
      .toggleClass("icon-rating-filled", n >= 1)
    $(this.element)
      .find("i[data-index=2]")
      .toggleClass("icon-rating-filled", n >= 2)
    $(this.element)
      .find("i[data-index=3]")
      .toggleClass("icon-rating-filled", n >= 3)
    $(this.element)
      .find("i[data-index=4]")
      .toggleClass("icon-rating-filled", n >= 4)
    $(this.element)
      .find("i[data-index=5]")
      .toggleClass("icon-rating-filled", n >= 5)
    $(this.element)
      .find("i[data-index=1]")
      .toggleClass("icon-rating", n <= 0)
    $(this.element)
      .find("i[data-index=2]")
      .toggleClass("icon-rating", n <= 1)
    $(this.element)
      .find("i[data-index=3]")
      .toggleClass("icon-rating", n <= 2)
    $(this.element)
      .find("i[data-index=4]")
      .toggleClass("icon-rating", n <= 3)
    $(this.element)
      .find("i[data-index=5]")
      .toggleClass("icon-rating", n <= 4)
  }

  revert = () => {
    this.showSavedRating()
  }

  hasInput = () => {
    console.log(this.reviewTarget.value.trim().length)
    if (this.reviewTarget.value != "" && this.reviewTarget.value.trim().length > 0 && this.selected) {
      this.buttonTarget.disabled = false
    } else {
      this.buttonTarget.disabled = true
    }
  }
}
