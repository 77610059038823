import clsx from "clsx"
import { Table } from "flowbite-react"
import React from "react"
import { HiStar, HiLink, HiOfficeBuilding, HiTrash } from "react-icons/hi"

export function RentCompsTable({
  className = "",
  properties = [],
  metric = "asking",
  sortable = false,
  removeable = false,
  onRemove = () => {}
}) {

  function $(value) {
    return value ? `$${value}` : ""
  }

  return (
    <Table className={clsx("border", className)}>
      <Table.Head className="border-b">
        <Table.HeadCell className="w-10 min-w-[300px]" />
        <Table.HeadCell className="text-center" sortable={sortable}>Similarity %</Table.HeadCell>
        <Table.HeadCell className="text-center" sortable={sortable}>Leased %</Table.HeadCell>
        <Table.HeadCell className="text-center" sortable={sortable}>Exposure %</Table.HeadCell>
        <Table.HeadCell className="text-center" sortable={sortable}>Year Built</Table.HeadCell>
        <Table.HeadCell className="text-center" sortable={sortable}># Units</Table.HeadCell>
        <Table.HeadCell className="text-center" sortable={sortable}>Stories</Table.HeadCell>
        <Table.HeadCell className="w-10" />
        <Table.HeadCell className="w-14">{metric === "asking" ? "Ask" : "Eff"} Rent</Table.HeadCell>
        <Table.HeadCell className="w-14">SF</Table.HeadCell>
        <Table.HeadCell className="w-14">PSF</Table.HeadCell>
        {removeable && <Table.HeadCell className="w-14" />}
      </Table.Head>
      <Table.Body className="text-sm">
        {properties.map((property, index) => (
          <Table.Row
            key={property.id}
            className={clsx(
              "h-28 border-b last-of-type:border-b-0",
              property.isSubject ? "bg-blue-50 font-semibold hover:bg-blue-100" : "font-medium hover:bg-gray-50"
            )}>
            <Table.Cell className="max-w-sm p-0">
              <div className="no-wrap relative flex">
                <div className="flex h-28 items-center justify-center px-4">
                  <div
                    className={clsx(
                      "flex h-6 w-6 items-center justify-center rounded-full",
                      property.isSubject ? "bg-bgold" : "bg-bblue"
                    )}>
                    {index === 0 ? (
                      <HiStar className="h-4 w-4 text-white" />
                    ) : (
                      <span className="font-semibold text-white">{Math.max(index, 1)}</span>
                    )}
                  </div>
                </div>

                {/* <div className="mr-4 h-28 min-w-[10rem] max-w-[170px]">
                  <img alt={property.building_name} src={property.image} className="h-28 object-cover" />
                </div> */}
                <div className="mt-2">
                  <div className="font-bold text-gray-800">
                    {property.building_name}
                    {property.similarity_score?.distance_miles ? (
                      <span className="ml-1 text-sm font-medium">
                        ({property.similarity_score.distance_miles.toFixed(2)} mi)
                      </span>
                    ) : null}
                  </div>
                  <div className=" font-medium text-gray-700">
                    {property.street_address}, {property.city}, {property.zip_code}
                  </div>
                  {property.management_company ? (
                    <div className="mb-2 mt-1 flex items-center text-xs">
                      <HiOfficeBuilding className="mr-0.5 h-4 w-4" />
                      <span className=" text-gray-700">{property.management_company}</span>
                    </div>
                  ) : null}
                  <div className="mt-1 flex items-center space-x-3">
                    {property.building_website ? (
                      <a
                        href={property.building_website}
                        target="_blank"
                        rel="noreferrer"
                        className="flex h-5 w-5 cursor-pointer items-center justify-center rounded-[4px] border border-blue-500 shadow-sm">
                        <HiLink className="h-3 w-3 text-blue-500" />
                      </a>
                    ) : null}
                  </div>
                </div>
              </div>
            </Table.Cell>
            <Table.Cell className="text-center align-middle font-semibold text-green-500">
              {property.similarity_percent}
            </Table.Cell>
            <Table.Cell className="text-center align-middle text-gray-800">{property.leased_percent}</Table.Cell>
            <Table.Cell className="text-center align-middle text-gray-800">{property.exposure_percent}</Table.Cell>
            <Table.Cell className="text-center align-middle text-gray-800">{property.year_built}</Table.Cell>
            <Table.Cell className="text-center align-middle text-gray-800">{property.number_units}</Table.Cell>
            <Table.Cell className="text-center align-middle text-gray-800">
              {property.number_stories || property.number_stories_prediction}
            </Table.Cell>
            <Table.Cell colSpan={4} className="p-0">
              <Table className={clsx("text-gray-800", property.isSubject ? "font-semibold" : "font-medium")}>
                <Table.Body className="border-l text-xs">
                  {property.bed_options?.map((option) => (
                    <tr key={option.name} className="flex h-7 border-b last-of-type:border-0 hover:bg-gray-100">
                      <td className="flex flex-1 items-center pl-4">{option.name}</td>
                      <td className="flex flex-1 items-center">
                        {metric === "asking" ? $(option.ask_price) : $(option.eff_price)}
                      </td>
                      <td className="flex flex-1 items-center pl-4">{option.sqft ? option.sqft : ""}</td>
                      <td className="flex flex-1 items-center justify-center">
                        {metric === "asking" ? $(option.ask_price_psf) : $(option.eff_price_psf)}
                      </td>
                    </tr>
                  ))}
                </Table.Body>
              </Table>
            </Table.Cell>
            {removeable && index > 0 && (
              <Table.Cell className="p-0 align-middle">
                <div className="flex items-center justify-center">
                  <HiTrash onClick={() => onRemove(property, index)} className="h-4 w-4 cursor-pointer text-red-500" />
                </div>
              </Table.Cell>
            )}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  )
}
