export class UnitCollection {
  constructor(units) {
    this.units = units
  }

  push(unit) {
    this.units.push(unit)
  }

  // Name
  get name() {
    return this.units[0]["property"]
  }

  // Listings
  get length() {
    return this.units.length
  }

  get propertyIsSubject() {
    return Boolean(this.units[0]["property_is_subject"])
  }

  // avg days on Market
  get avgDaysOnMarket() {
    const totalDays = this.units.reduce((acc, unit) => acc + unit["days_on_market"], 0)
    return this.length ? this.getMathRound(totalDays) : "-"
  }

  // avg asking rent
  get avgAskingRent() {
    const totalRent = this.units.reduce((acc, unit) => acc + unit["price"], 0)
    return this.length ? +this.getMathRound(totalRent).toFixed(0) : "-"
  }

  // avg effective rent
  get avgEffectiveRent() {
    const totalRent = this.units.reduce((acc, unit) => acc + unit["effective_price"], 0)
    return this.length ? +(totalRent / this.length).toFixed(0) : "-"
  }

  // avg SQFT
  get avgSQFT() {
    const totalSQFT = this.units.reduce((acc, unit) => acc + unit["sqft"], 0)

    return this.length ? this.getMathRound(totalSQFT) : "-"
  }

  // avg asking rent PSF
  get avgAskingRentPSF() {
    if (this.avgSQFT === 0 || this.avgSQFT === "-") return "-"
    return parseFloat((this.avgAskingRent / this.avgSQFT).toFixed(2))
  }

  // avg effective rent PSF
  get avgEffectiveRentPSF() {
    if (this.avgSQFT === 0 || this.avgSQFT === "-") return "-"
    return parseFloat((this.avgEffectiveRent / this.avgSQFT).toFixed(2))
  }

  get available() {
    return this.units.filter((unit) => unit["exit_market"] !== null).length
  }
  getMathRound(item) {
    if (this.length > 0) {
      return Math.round(item / this.length)
    } else {
      return 0
    }
  }
  toRent() {
    return {
      name: this.name,
      property: this.name,
      propertyIsSubject: this.propertyIsSubject,
      listings: this.length,
      available: this.available,
      avgDaysOnMkt: this.avgDaysOnMarket,
      avgAskRent: this.avgAskingRent,
      avgEffRent: this.avgEffectiveRent,
      avgSF: this.avgSQFT,
      avgAskRentPSF: this.avgAskingRentPSF,
      avgEffRentPSF: this.avgEffectiveRentPSF,
      trend: 0.0,
      getAvgAskingRentPsfByDate: this.avgAskingRentPsfByDate
    }
  }

  toFloorplan() {
    return {
      name: this.units[0]["bed"] === 0 ? "Studio" : this.units[0]["bed"] + " Beds",
      desc: "Trailing 1 month",
      data: [
        { label: "Listings", value: this.length },
        { label: "Days on Market", value: this.avgDaysOnMarket },
        { label: "Asking Rent", value: `$${this.avgAskingRent}` },
        { label: "Asking Rent PSF", value: `$${this.avgAskingRentPSF}` },
        { label: "Effective Rent", value: `$${this.avgEffectiveRent}` },
        { label: "Effective Rent PSF", value: `$${this.avgEffectiveRentPSF}` }
      ]
    }
  }

  groupByFloorplan() {
    return this.units.reduce((acc, unit) => {
      const floorplan = unit["bed"]
      if (!acc[floorplan]) {
        acc[floorplan] = new UnitCollection([])
      }
      acc[floorplan].push(unit)
      return acc
    }, {})
  }

  groupByProperty() {
    return this.units.reduce((acc, unit) => {
      const property = unit["property_id"]
      if (!acc[property]) {
        acc[property] = new UnitCollection([])
      }
      acc[property].push(unit)
      return acc
    }, {})
  }

  groupByPropertyAndFloorplan() {
    return this.units.reduce((acc, unit) => {
      const property = unit["property"]
      const floorplan = unit["bed"]
      if (!acc[property]) {
        acc[property] = new UnitCollection([])
      }
      if (!acc[property][floorplan]) {
        acc[property][floorplan] = new UnitCollection([])
      }
      acc[property][floorplan].push(unit)
      return acc
    }, {})
  }

  static filterData(data = [], filter = { startDate: null, endDate: null }) {
    const { startDate, endDate } = filter
    let dataReault = data
    if (startDate && endDate) {
      dataReault = dataReault.filter((m) => {
        let from_date = new Date(m.from_date)
        let to_date = new Date(m.to_date)
        return from_date <= endDate && to_date >= startDate
      })
    }
    return dataReault
  }
  static filterRentUnits(data = [], filter = { startDate: null, endDate: null }) {
    let availableUnits = data
    const { startDate, endDate } = filter
    if (startDate && endDate) {
      availableUnits = data.filter((unit) => {
        const history = Array.isArray(unit.history) ? unit.history : []
        return history.some((historyItem) => {
          if (historyItem.from_date && historyItem.to_date) {
            const from_date = new Date(historyItem.from_date)
            const to_date = new Date(historyItem.to_date)
            return to_date >= startDate && from_date <= endDate
          }
          return false
        })
      })
    }
    return availableUnits
  }

  static filterAvailableUnits(data = [], filter = { startDate: null, endDate: null }) {
    let availableUnits = data
    const { startDate, endDate } = filter
    if (startDate && endDate) {
      availableUnits = data.filter((unit) => {
        const history = Array.isArray(unit.history) ? unit.history : []
        return history.some((historyItem) => {
          if (historyItem.from_date && historyItem.to_date) {
            const from_date = new Date(historyItem.from_date)
            const to_date = new Date(historyItem.to_date)
            return to_date >= startDate && from_date <= endDate
          }
          return false
        })
      })
    } else {
      availableUnits = data.filter((unit) => {
        if (
          (unit.availability && unit.exit_market && new Date(unit.availability) >= new Date(unit.exit_market)) ||
          (unit.availability && !unit.exit_market)
        ) {
          return true
        }
        return false
      })
    }
    return availableUnits
  }

  static filterLeasedUnits(data = [], filter = { startDate: null, endDate: null }) {
    let dataReault = data
    const { startDate, endDate } = filter
    const today = new Date()
    if (startDate && endDate) {
      dataReault = data.filter((unit) => {
        if (unit.created_on && new Date(unit.created_on) > startDate) {
          const rentHistory = this.filterData(unit.history, filter)
          if (rentHistory.length > 0) {
            return true
          } else {
            return false
          }
        } else {
          return false
        }
      })
    } else {
      dataReault = data.filter((unit) => {
        if (!unit.availability || new Date(unit.availability) > today) {
          return true
        }
        return false
      })
    }
    return dataReault
  }

  static filterPropertiesByDateRange(properties, filter = { startDate: null, endDate: null }) {
    const { startDate, endDate } = filter
    if (startDate && endDate && properties) {
      if (Array.isArray(properties)) {
        return properties.map((property) => {
          const filteredUnits = this.filterRentUnits(property.building_availability, filter)

          return { ...property, building_availability: filteredUnits }
        })
      } else if (typeof properties === "object" && properties !== null) {
        const filteredUnits = this.filterRentUnits(properties.building_availability, filter)
        return { ...properties, building_availability: filteredUnits }
      }
    } else {
      return properties
    }
  }

  static fromProperties(properties) {
    const units = properties.reduce(
      (result, property, index) =>
        result.concat(
          property["building_availability"].map((unit) => ({
            ...unit,
            property: property["building_name"],
            property_id: property["id"],
            property_is_subject: index === 0
          }))
        ),
      []
    )
    return new UnitCollection(units)
  }
}
