import React from "react"
const marketdata = require("../../../../../public/samples/paid_ads")

export default function PaidAds() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">Paid Ads Data</h1>
			<p className="text-lg font-medium">Analytics on your ongoing paid ad campaigns.</p>
			<div className="flex justify-center align-center mt-8">
				<img src={marketdata} />
			</div>
		</div>
	)
}