import React from "react"
import BrynsonSquareButton from "../../shared/BrynsonSquareButton"

const yardi = require("../../../../../public/pms/yardi.png")
const entrata = require("../../../../../public/pms/entrata.png")
const realpage = require("../../../../../public/pms/realpage.png")
const appfolio = require("../../../../../public/pms/appfolio.png")
const mri = require("../../../../../public/pms/mri.png")
const resman = require("../../../../../public/pms/resman.jpg")
const remotelandord = require("../../../../../public/pms/remotelandord.png")
const rentmanager = require("../../../../../public/pms/rentmanager.png")
const rentvine = require("../../../../../public/pms/rentvine.png")
const propertyware = require("../../../../../public/pms/propertyware.png")

export default function IntegrationsDemo() {
return (
    <>
      <div className="grid grid-cols-4 gap-4">
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={yardi} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Yardi</p>
          <p className="text-lg font-medium mb-4">Yardi is dedicated to the design, development, and support of real estate investment management and property management software.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={entrata} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Entrata</p>
          <p className="text-lg font-medium mb-4">Entrata's property management software connects all of your data & processes, allowing you to create better experiences for prospects, residents, & employees.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={realpage} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Realpage</p>
          <p className="text-lg font-medium mb-4">RealPage is a leading global provider of software and data analytics to the real estate industry. Clients use our platform to improve operating performance and increase capital returns.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={appfolio} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Appfolio</p>
          <p className="text-lg font-medium mb-4">AppFolio is the technology leader powering the future of the real estate industry.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={mri} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">MRI</p>
          <p className="text-lg font-medium mb-4">MRI Software is a leading provider of real estate software solutions that transform the way communities live, work and play. </p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={resman} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Resman</p>
          <p className="text-lg font-medium mb-4">Resman platform automates processes to help property managers manage their staff, improve occupancy rates, engage residents, and increase NOI.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={remotelandord} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Remote Landord</p>
          <p className="text-lg font-medium mb-4">The Remote Landlord System is a web-based property management system that manages both residential and commercial property, and scales well for both large and small portfolios.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={rentmanager} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Rent Manager</p>
          <p className="text-lg font-medium mb-4">Rent Manager, a flagship product of London Computer Systems, is a property management, accounting, CRM, and work order management software suite that combines all the tools your business needs into one integrated system.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={rentmanager} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Rent Manager</p>
          <p className="text-lg font-medium mb-4">Rent Manager, a flagship product of London Computer Systems, is a property management, accounting, CRM, and work order management software suite that combines all the tools your business needs into one integrated system.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={rentvine} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Rentvine</p>
          <p className="text-lg font-medium mb-4">Rentvine's Intuitive property management software saves you time and money by automating your business processes.</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
        <div className="border shadow p-8 text-center flex flex-col">
          <img src={propertyware} className="h-28 w-auto object-contain" />
          <p className="text-lg font-semibold mt-4">Propertyware</p>
          <p className="text-lg font-medium mb-4">Propertyware® is a property management solution for single-family and low-density rental units through a full suite of on-demand property management tools</p>
          <BrynsonSquareButton label="Connect Now" className="mt-auto" onClick={() => console.log('open modal')} />
        </div>
      </div>
    </>
  )
}
