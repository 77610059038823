import { Controller } from "stimulus"

const LEAVING_PAGE_MESSAGE =
  "You have made some changes that have not been saved yet. Do you want to leave this page without saving?"

export default class extends Controller {
  static targets = ["saveButton", "buttonID"]

  connect() {}

  formIsChanged(event) {
    this.setChanged("true")
    this.saveButtonTarget.classList.remove("hidden")
    this.buttonIDTarget.classList.remove("clicked")
    this.buttonIDTarget.innerHTML = "SAVE"
  }

  leavingPage(event) {
    if (this.isFormChanged()) {
      if (event.type == "turbolinks:before-visit") {
        if (!window.confirm(LEAVING_PAGE_MESSAGE)) {
          event.preventDefault()
        }
      } else {
        event.returnValue = LEAVING_PAGE_MESSAGE
        return event.returnValue
      }
    }
  }

  allowFormSubmission(event) {
    this.setChanged("false")
  }

  setChanged(changed) {
    this.data.set("changed", changed)
  }

  isFormChanged() {
    return this.data.get("changed") == "true"
  }
}
