export const CREATE_FEE_MUTATION = `
  mutation CreateFeeMutation($id:ID!, $sourceType:String!, $prorateType: String, $lateType: Float, $latePercent: Int, $lateMinFee: Float, $lateFeePerDay: Float, $nonRefundableHoldFee: Float, $adminFee: Float, $applicationFee: Float, $brokerFee: Float) {
    createFeeMutation(
      id: $id
      sourceType: $sourceType
      prorateType: $prorateType
      lateType: $lateType
      latePercent: $latePercent
      lateMinFee: $lateMinFee
      lateFeePerDay: $lateFeePerDay
      nonRefundableHoldFee: $nonRefundableHoldFee
      adminFee: $adminFee
      applicationFee: $applicationFee
      brokerFee: $brokerFee
    ) {
      result
    }
  }
`

export const UPDATE_FEE_MUTATION = `
  mutation UpdateFeeMutation($id: ID!, $prorateType: String, $lateType: Float, $latePercent: Int, $lateMinFee: Float, $lateFeePerDay: Float, $nonRefundableHoldFee: Float, $adminFee: Float, $applicationFee: Float, $brokerFee: Float) {
    updateFeeMutation(
      id: $id
      prorateType: $prorateType
      lateType: $lateType
      latePercent: $latePercent
      lateMinFee: $lateMinFee
      lateFeePerDay: $lateFeePerDay
      nonRefundableHoldFee: $nonRefundableHoldFee
      adminFee: $adminFee
      applicationFee: $applicationFee
      brokerFee: $brokerFee
    ) {
      id
      resourceableType
      resourceableId
      prorateType
      lateType
      latePercent
      lateMinFee
      lateFeePerDay
      nonRefundableHoldFee
      adminFee
      applicationFee
      brokerFee
    }
  }
`
