import React from "react"
import { useQuery } from "urql"

import { EMBEDDED_DOCUMENTS_QUERY } from "../../queries/EmbeddedDocuments"
import BrynsonEmbeddedDocuments from "../../shared/BrynsonEmbeddedDocuments"

export default function MondayReport() {
  const [{ data: mondayMorningReportEmbeddedsData }] = useQuery({
    query: EMBEDDED_DOCUMENTS_QUERY,
    variables: { documentType: "monday_morning_report" }
  })

  return (
    <div className="p-8">
      <h1 className="mb-6 text-xl font-semibold">Portfolio Intelligence Dashboards</h1>
      <div className="flex justify-center flex-col">
        <p className="text-lg font-medium mt-4 mb-2 w-[1000px] ">Brynsights can connect to your PMS and CRM system to standardize the data and create custom dashboards.</p>
        <p className="text-lg font-medium mt-2 mb-4 w-[1000px]">Below are some samples of potential BI and Portfolio Intelligence dashboards, which we can customize fully for your requirements or create new dashboards.</p>
        <p className="text-lg font-medium mt-2 mb-4 w-[1000px]">Please contact your client success manager for more details.</p>
      </div>
      {mondayMorningReportEmbeddedsData?.embeddedDocuments?.length ? (
        <BrynsonEmbeddedDocuments documentType="monday_morning_report" />
      ) : (
        <>

        </>
      )}
    </div>
  )
}
