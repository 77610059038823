import { Card, Checkbox } from "flowbite-react"
import React from "react"
import { RiMovieLine } from "react-icons/ri"

import { MatterportLogoUrl } from "./MediumCard"

function MediumInfo({ medium, className = "", onClick = () => {} }) {
  return (
    <div className={className} onClick={onClick}>
      <div className="flex">
        <span className="min-w-[3rem]">Name</span>
        <span className="font-semibold text-gray-800">{medium.name}</span>
      </div>
      <div className="flex">
        <span className="min-w-[3rem]">For</span>
        <span className="font-medium text-gray-800">
          {medium.mediumableType === "Property" ? " Current Property" : `${medium.mediumableType} ${medium.owner.name}`}
        </span>
      </div>
    </div>
  )
}

export default function MediumItem({ index = -1, medium, onClick = () => {}, onCheck = () => {} }) {
  return (
    <Card className="relative m-1 cursor-pointer" horizontal>
      <div className="flex h-28 items-center justify-center">
        {medium.mediumType === "video" ? (
          <RiMovieLine className="text-[120px]" onClick={onClick} />
        ) : (
          <img
            onClick={onClick}
            className="max-h-28 max-w-[32] rounded-lg object-cover shadow-md"
            src={medium.mediumType === "matterport" ? MatterportLogoUrl : medium.medium?.url || medium.importedUrl}
            alt={medium.caption}
          />
        )}
      </div>
      <MediumInfo medium={medium} onClick={onClick} />
      <Checkbox
        checked={medium.selected}
        onChange={(e) => onCheck(e.target.checked, index, medium)}
        className="absolute right-2 top-2 checked:bg-cyan-600"
      />
    </Card>
  )
}
