
export const PARK_QUERY = `
  query Park($id:ID!){
    park(id:$id) {
      id
      informationId
      assigned
      assignedFee
      spaceFee
      spaces
      comment
      parkingType
      information {
        id
        structureType
        property {
          id
          name
        }
      }
      columns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_PARK_MUTATION = `
  mutation CreateParkMutation($informationId: Int!, $assigned: Boolean, $assignedFee: String, $spaceFee: Float, $spaces: Int, $comment: String, $parkingType: String) {
    createParkMutation(
      informationId: $informationId
      assigned: $assigned
      assignedFee: $assignedFee
      spaceFee: $spaceFee
      spaces: $spaces
      comment: $comment
      parkingType: $parkingType
    ) {
      id
      informationId
      assigned
      assignedFee
      spaceFee
      spaces
      comment
      parkingType
    }
  }
`

export const UPDATE_PARK_MUTATION = `
  mutation UpdateParkMutation($id: ID!, $assigned: Boolean, $assignedFee: String, $spaceFee: Float, $spaces: Int, $comment: String, $parkingType: String) {
    updateParkMutation(
      id: $id
      assigned: $assigned
      assignedFee: $assignedFee
      spaceFee: $spaceFee
      spaces: $spaces
      comment: $comment
      parkingType: $parkingType
    ) {
      id
      informationId
      assigned
      assignedFee
      spaceFee
      spaces
      comment
      parkingType
    }
  }
`
