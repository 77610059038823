export const CREATE_DEPOSIT_MUTATION = `
  mutation CreateDepositMutation($id: ID!, $sourceType: String!, $depositType: String, $amountType: String, $valueRange: Float, $description: String, $percentRefundable: Int, $portionRefundable: Int) {
    createDepositMutation(
      id: $id
      sourceType: $sourceType
      depositType: $depositType
      amountType: $amountType
      valueRange: $valueRange
      description: $description
      percentRefundable: $percentRefundable
      portionRefundable: $portionRefundable
    ) {
      result
    }
  }
`

export const UPDATE_DEPOSIT_MUTATION = `
  mutation UpdateDepositMutation($id: ID!$depositType: String, $amountType: String, $valueRange: Float, $description: String, $percentRefundable: Int, $portionRefundable: Int) {
    updateDepositMutation(
      id: $id
      depositType: $depositType
      amountType: $amountType
      valueRange: $valueRange
      description: $description
      percentRefundable: $percentRefundable
      portionRefundable: $portionRefundable
    ) {
      result
    }
  }
`
