document.addEventListener("turbolinks:load", function () {
  // Tabs
  $("body").on("click", ".tabs ul li a", function (e) {
    if ($(this).attr("data-index")) {
      e.preventDefault()
      const parent = $(this).closest(".tabs")
      const tabsetName = parent.attr("data-tabs")
      const index = $(this).attr("data-index")
      const tabPanels = $(`.tab-panels[data-tabs='${tabsetName}']`)

      parent.find("li").removeClass("is-active")
      $(this).closest("li").addClass("is-active")

      tabPanels.find(".tab-panel.is-active").removeClass("is-active")
      tabPanels.find(`.tab-panel[data-index='${index}']`).addClass("is-active")
    }
  })
})
