import React, { useEffect } from "react"
import PropertiesProvider from "../../context/PropertiesContext"
import PropertyShowDirectory from "../../shared/PropertyShowDirectory"
import MediumUpload from "../../shared/MediumUpload"

export default function PropertyUpload({ practice, practiceUser }) {
  return (
    <PropertiesProvider>
      <MediumUpload />
    </PropertiesProvider>
  )
}
