import React from "react"
import styled from "styled-components"

const FooterButtons = styled.div`
  background-color: #fff;
  position: fixed;
  bottom: 0;
  margin-left: -24px;
  border-top: 1px solid #c8d6df;
  border-bottom: 1px solid #c8d6df;
  border-left: 1px solid #c8d6df;
  width: 45vw;
  max-width: 42rem;
  padding: 0;

  a {
    color: #0d9da4;
    text-align: center;
    font-family: Lato;
    font-size: 16px;
    font-weight: 700;
    border-right: 1px solid #c8d6df;
    padding: 24px;
    display: block;
    height: 100%;

    i {
      color: #0d9da4;
      font-weight: 900;
    }

    &:hover {
      background-color: #f3f8fb;
    }
  }

  p.help-text {
    font-family: Lato;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -1%;
    margin-bottom: 32px;
  }

  .columns:last-child {
    margin-bottom: 0;
  }

  .column {
    padding-bottom: 0;
  }

  @media only screen and (max-width: 768px) {
    width: 100vw;
  }
`

export default function SidebarFooter({ children }) {
  return <FooterButtons>{children}</FooterButtons>
}
