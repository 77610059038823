import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import InputField from "./InputField"
import DesktopTitle1 from "./DesktopH5"
import BrynsonSquareButton from "./BrynsonSquareButton"
import BrynsonDatePicker from "./BrynsonDatePicker"
import CheckBoxExtended from "./CheckboxExtended"
import CurrencyInput from 'react-currency-input-field'
import { PropertiesDirectoryContext } from "../context/PropertiesDirectoryContext"
import Loading from "./Loading"
import _ from "lodash"

export default function PropertyDirectory({ params }) {
  const prefix = "$"
  const page = "Property Directory"
  const { currentUserData } = useContext(GlobalContext)
  const { createPropertyDirectory: create } = useContext(PropertiesDirectoryContext)
  const [error, setError] = useState()
  const [currentInput, setCurrentInput] = useState({})
  const [columns, setColumns] = useState([])
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()
  const [properties, setProperties] = useState([])
  const [propertyIds, setPropertyIds] = useState([])

  const { propertiesData: propertiesData, propertyReload: reexecuteQuery } = useContext(GlobalContext)
  
  const checked = item => {
    return false
  }

  const fetchCreateInput = (currentInput) => {
    const currentValues = {}
    currentValues['companyId'] = currentUserData?.currentUser?.currentCompany?.id
    currentValues['propertyIds'] = propertyIds
    columns.forEach((item) => {
      if (currentInput[item.accessor]) {
        if (item.type === "integer") {
          currentValues[item.accessor] = parseInt(currentInput[item.accessor])
        } else if(item.type === "decimal") {
          currentValues[item.accessor] = parseFloat(currentInput[item.accessor])
        } else {
          currentValues[item.accessor] = currentInput[item.accessor]
        }
      } else {
        currentValues[item.accessor] = null;
      }
    })

    return currentValues
  }

  const addValue = (item, value) => {
    const current = {}
    current[`${item.accessor}`] = value
    
    setCurrentInput({ ...currentInput, ...current })
  }

  const filterProperties = search => {
    let filtered = propertiesData.properties
    if (search) {
      filtered = properties.filter((item) => item.name.toLowerCase().includes(search.toLowerCase()))
    }
    setProperties(filtered)
  }

  useMemo(() => {
    if (currentUserData) {
      setColumns(currentUserData?.currentUser?.currentCompany?.propertyDirectoryColumns)

      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: null, title: page}
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [currentUserData])

  useMemo(() => {
    if (propertiesData) {
      setProperties(propertiesData?.properties)
    }
  }, [propertiesData])

  return (
    <>
      {currentUserData && propertiesData ?
        <div className="p-8">
          {/* <BreadCrumbs items={breadCrumbs} /> */}
          <DesktopTitle1 content={`Add ${page}`} />      
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex row flex-wrap grid grid-cols-2 gap-3">
            {columns.map((item) => (
              <div className="row flex items-center w-full" key={`pet-policy-insert-${item.accessor}`}>
                {item.type === "decimal" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <CurrencyInput
                      className="m-0 w-full rounded border border-geyser bg-white text-navy shadow-none outline-0 focus:border-teal active:border-teal px-4 py-3.5"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={""}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                }
                {item.type === "boolean" &&
                  <div className="my-4 w-full">
                    <CheckBoxExtended name={item.Header} checked={false} handleClick={(value) => {
                      addValue(item, value)
                    }} />
                  </div>
                }
                {item.type === "date" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <BrynsonDatePicker selectedDate={""} onChange={(date) => {
                      addValue(item, date)
                    }} />
                  </div>
                }
                {(item.type !== "boolean" && item.type !== "decimal" && item.type !== "date") &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <InputField
                      id={`property-${item.accessor}-edit`}
                      placeholder={item.Header}
                      defaultValue={""}
                      type={item.type === "integer" ? "number" : "text" }
                      onChange={(input) => {
                        addValue(item, input.target.value)
                      }}
                    />
                  </div>
                }
              </div>
            ))}
          </div>
          {properties && (
            <div>
              <h4 className="grow text-md font-bold">Properties</h4>
              <div>
                <input
                  className="focus:border-teal active:border-teal m-0 mb-4 w-full rounded border border-geyser bg-white px-3 py-2 text-navy shadow-none outline-0"
                  onChange={(e) => {
                    filterProperties(e.target.value)
                  }}
                />
              </div>
              <div className="row flex grid grid-cols-2 flex-wrap">
                {properties.map((item) => (
                  <div className="m-2" key={`properties-${item.id}`}>
                    <CheckBoxExtended
                      name={item.name}
                      checked={() => propertyIds.includes(item.id)}
                      handleClick={(value) => {
                        const ids = propertyIds
                        if (value) {
                          ids.push(item.id)
                        } else {
                          _.pull(ids, item.id)
                        }
                        setPropertyIds(ids)
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          )}
          {columns.length > 0 &&
            <div className="flex justify-center items-center w-full">
              <div>
                <BrynsonSquareButton
                  type="primary"
                  testId="add-new-button"
                  loading={currentLoading}
                  onClick={() => {
                    setLoading(true)
                    const createCurrent = fetchCreateInput(currentInput)
                    create(createCurrent).then((result) => {
                      if (result?.error) {
                        setError(`Error updating: ${result?.error}`)
                      } else {
                        window.location.href=`/portal/property_directories/${result?.data?.createPropertyDirectoryMutation?.id}/edit`
                      }
                      setTimeout(() => {
                        setLoading(false)
                      }, 1000)
                    })
                  }}
                  label={`Add ${page}`}
                />
              </div>
            </div>
          }
        </div>
      :
        <Loading />
      }
    </>
  )
}
