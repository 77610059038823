import React, { useMemo } from 'react';
import { exportCSV, transformDataForChart } from './PaulChartUtils';
import { renderTable, renderChart } from './PaulChartRenderers.jsx';
import { ArrowDownTrayIcon } from '@heroicons/react/24/solid';

const SavedPaulChart = ({ chart }) => {
  const data = chart.data;
  const chartType = chart.chart_type;
  const chartData = useMemo(() => transformDataForChart(data, chartType), [data, chartType]);

  return (
    <div className="bg-white border border-gray-200 rounded-xl shadow-sm overflow-hidden">
      <div className="p-4 flex justify-between items-center font-montserrat text-sm">
        <button onClick={() => exportCSV(data, 'Brynsights Paul Export')} className="text-bblue flex items-center font-medium">
          Download CSV
          <ArrowDownTrayIcon className="ml-2 h-5 w-5" />
        </button>
      </div>
      <h3 className="font-semibold text-center text-lg mb-8">{chart.title}</h3>
      {!chartData && <div className="p-4 text-red-500">Chart can't be rendered with given data.</div>}
      {chartData && chartType === 'table' && renderTable(data)}
      {chartData && chartType !== 'table' && renderChart(chartType, chartData)}
    </div>
  );
};

export default SavedPaulChart;
