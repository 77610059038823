import { Viewer, Worker } from "@react-pdf-viewer/core"
import { Button } from "flowbite-react"
import React, { useState } from "react"
import { IoMdDownload } from "react-icons/io"
import { useQuery } from "urql"

import { COMP_ANALYSES_QUERY } from "../../queries/CompAnalysesMutations"
import { PROPERTIES_QUERY } from "../../queries/PropertiesMutations"
import Loading from "../../shared/Loading"
import Selectbox from "../../shared/Selectbox"

import CompAnalysisReports from "./CompAnalysisReports"

export default function DisplayComponent({ compAnalysisType, rand }) {
  const [{ data: propertiesData }] = useQuery({
    query: PROPERTIES_QUERY,
    requestPolicy: "network-only"
  })

  const [{ data: compAnalysesData }] = useQuery({
    query: COMP_ANALYSES_QUERY
  })

  const [currentPropertyId, setCurrentPropertyId] = useState(null)
  const [currentCompAnalysisId, setCurrentCompAnalysisId] = useState(null)

  const filteredCompAnalyses = () => {
    if (!compAnalysesData) return []
    const d = compAnalysesData?.compAnalyses.filter((compAnalysis) => compAnalysis.type === compAnalysisType)

    return d.filter((compAnalysis) => compAnalysis.propertyId === parseInt(currentPropertyId))
  }

  const selectedCompAnalysis = () =>
    filteredCompAnalyses()?.find((compAnalysis) => compAnalysis.id === currentCompAnalysisId)

  const selectableCompAnalyses = () =>
    filteredCompAnalyses()?.map((compAnalysis) => ({
      value: compAnalysis.id,
      label: `${compAnalysis.month}/${compAnalysis.year}`
    }))

  const createPropertySelector = () => {
    if (propertiesData) {
      return (
        <Selectbox
          key={compAnalysisType + rand}
          label="Select Property"
          random={rand}
          onChange={(value) => {
            setCurrentPropertyId(value)
          }}
          options={
            propertiesData &&
            propertiesData.properties.map((property) => ({
              value: property.id,
              label: property.name
            }))
          }
          className="mt-4"
        />
      )
    }
  }

  const createCompAnalysisSelector = () => {
    if (filteredCompAnalyses()) {
      return (
        <Selectbox
          key={compAnalysisType + rand}
          random={rand}
          label="Select Month/Year"
          options={selectableCompAnalyses()}
          onChange={(value) => {
            setCurrentCompAnalysisId(value)
          }}
        />
      )
    }
  }

  if (propertiesData && compAnalysesData) {
    return (
      <>
        {compAnalysisType !== "other" ? (
          <div>
            <div className="flex flex-col justify-center">
              <p className="mb-2 mt-8 w-[1000px] text-lg font-medium ">
                CoStar Rent Comparables Reports are generated for each of your properties on a quarterly basis and
                uploaded here.
              </p>
              <p className="mb-4 mt-2 w-[1000px] text-lg font-medium">
                Please select a property and then a month below to view.
              </p>
            </div>
            <div className="flex flex-row gap-4">
              <div className="mt-4 block w-[300px]">
                <label>Property:</label>
                {createPropertySelector()}
              </div>
              <div className="mt-4 block w-[300px]">
                <label>Month/Year:</label>
                {createCompAnalysisSelector()}
              </div>
            </div>
            <div>
              {filteredCompAnalyses && currentCompAnalysisId && (
                <div className="mt-8 flex w-full justify-center">
                  <div className="w-2/3">
                    {selectedCompAnalysis() && (
                      <div>
                        <div className="text-right">
                          <a
                            href={selectedCompAnalysis()["attachment"]}
                            download
                            target="_blank"
                            rel="noopener noreferrer">
                            <Button className="mb-4 inline-flex justify-end">
                              <IoMdDownload />
                            </Button>
                          </a>
                        </div>
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/es5/build/pdf.worker.js">
                          <Viewer fileUrl={selectedCompAnalysis()["attachment"]} />
                        </Worker>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-col justify-center">
              <p className="mb-2 mt-8 w-[1000px] text-lg  font-medium">
                Data for your custom competitive analysis reports are sourced with data from{" "}
                <a href="https://www.hellodata.ai/" className="text-bgold underline" target="new">
                  HelloData
                </a>
                . HelloData crawls 30M+ units and 3.5M properties per day from every CRE source.
              </p>
              <p className="mb-4 mt-2 w-[1000px] text-lg font-medium">
                In Brynsights, we create a report for each of your properties and four competitors. You can edit the
                report to change the comp set.
              </p>
            </div>
            <CompAnalysisReports />
          </>
        )}
      </>
    )
  } else {
    return <Loading />
  }
}
