import React, { useEffect, useMemo, useState } from "react"
import Select from "react-select"
import styled from "styled-components"

import { selectStyling } from "../../utils/utils"

const SelectboxWrapper = styled.div`
  input {
    border: 1px solid #c8d6df;
    background: white;
    padding: 14px 16px;
    width: 100%;
    margin: 0;
    outline: 0;
    box-shadow: none;
    border-radius: 0.25rem;
    height: auto;
    font-size: 16px;
  }

  input:hover,
  input:focus {
    box-shadow: 0 0 0 1px #0d9da4;
  }

  input[id*="react-select"]:hover,
  input[id*="react-select"]:focus {
    box-shadow: none;
  }

  input::placeholder {
    font-size: 16px;
    font-weight: 400;
  }
`
export default function Selectbox({ defaultValue, options, onChange, update = true, id = null, isDisabled = false }) {
  const [value, setValue] = useState(defaultValue)

  const onMenuOpen = () => {}

  useMemo(() => {
    if (update) setValue(defaultValue)
  }, [defaultValue])

  useEffect(() => {
    setValue(defaultValue)
  }, [options])

  return (
    <SelectboxWrapper>
      <Select
        options={options}
        value={value}
        isDisabled={isDisabled}
        id={id}
        onChange={(e) => {
          setValue(e)
          onChange(e.value)
        }}
        menuPortalTarget={document.body}
        className={"TimePicker"}
        classNamePrefix={"TimePicker"}
        onMenuOpen={onMenuOpen}
        styles={selectStyling()}
      />
    </SelectboxWrapper>
  )
}
