import React, { useState } from "react"

import CreatePropertyModal from "./CreatePropertyModal"
import PropertyDetail from "./PropertyDetail"
import Sidebar from "./Sidebar"
import SlideInAlert from "./SlideInAlert"

export default function PropertySidebar({
  showPropertyDetail,
  propertyToDetail,
  showCreateProperty,
  refreshScreen,
  setShowCreateProperty,
  setShowPropertyDetail,
  setPropertyToDetail
}) {
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState("")

  return (
    <div>
      <Sidebar
        visible={showPropertyDetail}
        header={() => <h5 className="grow text-center text-lg font-bold">{propertyToDetail?.name} </h5>}
        backgroundColor="#FFF"
        iconName="close"
        closeSidebar={() => {
          setShowPropertyDetail(false)
          setPropertyToDetail()
        }}>
        {propertyToDetail && (
          <PropertyDetail
            propertyId={propertyToDetail.id}
            createAppointment={() => {
              window.location.href = "/portal/calendar"
            }}
            refreshProperty={() => {
              // refreshScreen()
            }}
            propertyEdited={() => {
              setAlertContent("Property edited successfully. ")
              setShowAlert(true)
              refreshScreen()
            }}
            propertyDeleted={() => {
              setAlertContent("Property deleted successfully. ")
              setShowAlert(true)
              refreshScreen()
            }}
          />
        )}
      </Sidebar>
      {showCreateProperty && (
        <CreatePropertyModal
          showCreateProperty={showCreateProperty}
          close={(propertyAdded) => {
            setShowCreateProperty(false)
            if (propertyAdded) {
              setShowAlert(true)
              setAlertContent("Property added successfully")
              setTimeout(() => {
                setShowAlert(false)
              }, 10000)
            }
          }}
        />
      )}
      <SlideInAlert visible={showAlert} setVisible={setShowAlert}>
        {alertContent}
      </SlideInAlert>
    </div>
  )
}
