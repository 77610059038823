import React, { useEffect } from "react"
import styled from "styled-components"

const IconWrapper = styled.div`
  display: flex;
  background-color: #fff;
  border-radius: 100px;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;

  ${({ warning }) =>
    warning &&
    `
    background-color: #FCE9E9;
  `}

  i {
    color: #0d9da4;
    font-size: 32px;
    font-weight: 700;
    display: block;

    ${({ warning }) =>
      warning &&
      `
      background-color: #FCE9E9;
      color: #EB5757;
      font-size: 22px;
    `}
  }
`

const Alert = styled.div`
  font-family: Lato;
  background-color: #f3f8fb;
  box-shadow: 0px 8px 16px -4px rgba(107, 134, 153, 0.1);
  color: #0b3954;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 24px;
  position: fixed;
  max-width: 300px;
  z-index: 99;
  -webkit-transition-duration: 800ms;
  transition-duration: 800ms;
  border-radius: 4px;

  a {
    color: #0b3954;
    font-weight: 700;
    text-decoration: underline;
  }

  ${({ warning }) =>
    warning &&
    `
    background-color: #FCE9E9;
  `}

  ${({ position }) =>
    position === "bottomLeft" &&
    `
    left: 10vw;
		bottom: -20vh;
  `}

  ${({ position }) =>
    position === "bottomRight" &&
    `
    right: 10vw;
		bottom: -20vh;
  `}

  ${({ position }) =>
    position === "topRight" &&
    `
    right: 10vw;
		top: -20vh;
  `}

  ${({ position }) =>
    position === "topLeft" &&
    `
    left: 10vw;
		top: -20vh;
  `}

  ${({ visible, position }) =>
    visible &&
    (position === "bottomLeft" || position === "bottomRight") &&
    `
     transform: translateY(-25vh);
  `}

  ${({ visible, position }) =>
    visible &&
    (position === "topLeft" || position === "topRight") &&
    `
     transform: translateY(25vh);
  `}
`

export default function SlideInAlert({
  children,
  position = "bottomLeft",
  warning = false,
  visible,
  setVisible,
  timeout = 6000
}) {
  let timeoutId

  useEffect(() => {
    if (visible) {
      if (timeoutId) clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        setVisible(false)
      }, timeout)
    }
  }, [visible])

  return (
    <Alert position={position} visible={visible} warning={warning}>
      <div className="columns is-mobile">
        <div className="column is-one-fifth px-2">
          <IconWrapper warning={warning}>
            {warning ? <i className="icon-warning-icon" /> : <i className="icon-done" />}
          </IconWrapper>
        </div>
        <div className="column is-four-fifths px-2">{children}</div>
      </div>
    </Alert>
  )
}
