export const QUARTERLY_AUDITS_QUERY = (latest = false) => `
  query {
    quarterlyAudits(latest: ${latest}) {
      id
      name
      dateOccupancyUpdated
      currentOccupancy
      occupancyExposure
      totalVacantUnits
      notes
      sixtyDaysOccupancyExposure
      ownershipGroup
      projectVacantUnits
      region
      property {
        id
        name
        crmUsed
        city
        unitCount
      }
      auditSources {
        id
        name
        displayName
      }
      auditMetrics {
        id
        numberOfLeads
        costPerLead
        numberOfLeases
        costPerLease
        recommendation
        currentSpend
        currentPackage
        noLeasesCrm
        otherProducts
        auditSource {
          id
          name
        }
      }
    }
  }
`
