export const UNIT_QUERY = `
  query Unit($id:ID!){
    unit(id:$id) {
      id
      propertyId
      floorplanId
      name
      number
      rent
      leaseTerm
      sqFt
      isAvailable
      isComingSoon
      isReady
      isDisplayed
      isRemoved
      bedrooms
      bathrooms
      squareFeetMin
      squareFeetMax
      occupancyStatus
      leasedStatus
      promotional
      rentMax
      rentMin
      floorplan {
        id
        name
        media {
          id
          mediumType
          mediumableType
          mediumableId
          matterportUrl
          importedUrl
          medium
          feedDisabled
          name
          caption
          rank
        }
      }
      floorplansOptions {
        value
        name
      }
      property {
        id
        name
      }
      images {
        id
        image
        caption
        rank
      }
      media {
        id
        mediumType
        mediumableType
        mediumableId
        matterportUrl
        importedUrl
        medium
        feedDisabled
        name
        caption
        rank
      }
      columns {
        accessor
        Header
        type
      }
      concession {
        id
        value
        term
        dateStart
        dateEnd
        descriptionHeader
        descriptionBody
        descriptionFooter
      }
      concessionColumns {
        accessor
        Header
        type
      }
      availability {
        id
        unitId
        vacateDate
        vacancyClass
        madeReadyDate
        unitAvailabilityUrl
      }
      availabilityColumns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_UNIT_MUTATION = `
mutation CreateUnitMutation($propertyId:Int!, $name:String,
	$number:String, $rent:Float, $leaseTerm:Int,
	$sqFt:Int, $dateAvailable:ISO8601Date,
	$isAvailable:Boolean, $isComingSoon:Boolean,
	$isReady:Boolean, $isDisplayed:Boolean,
	$isRemoved:Boolean, $bedrooms:Int, $bathrooms:Int,
  $rentMax:Int, $rentMin:Int,
  $squareFeetMin:Int, $squareFeetMax:Int, $occupancyStatus:String, $leasedStatus:String, $promotional: String, $floorplanId: Int) {
  createUnitMutation(propertyId:$propertyId, name:$name,
  	number:$number, rent:$rent,
  	leaseTerm:$leaseTerm, sqFt:$sqFt,
    dateAvailable:$dateAvailable,
  	isAvailable:$isAvailable, isComingSoon:$isComingSoon,
  	isReady:$isReady, isDisplayed:$isDisplayed,
  	isRemoved:$isRemoved, bedrooms:$bedrooms, bathrooms:$bathrooms,
    squareFeetMin:$squareFeetMin, squareFeetMax:$squareFeetMax,
    rentMax:$rentMax, rentMin:$rentMin,
    occupancyStatus:$occupancyStatus, leasedStatus:$leasedStatus, promotional: $promotional, floorplanId: $floorplanId) {
    id
    name
    number
		rent
    leaseTerm
    sqFt
    isAvailable
    isComingSoon
    isReady
    isDisplayed
    isRemoved
    bedrooms
    bathrooms
    squareFeetMin
    squareFeetMax
    occupancyStatus
    leasedStatus
    promotional
    rentMax
    rentMin
  }
}
`
export const UPDATE_UNIT_MUTATION = `
mutation UpdateUnitMutation($id:ID!, $name:String,
	$number:String, $rent:Float, $leaseTerm:Int,
	$sqFt:Int, $dateAvailable:ISO8601Date,
	$isAvailable:Boolean, $isComingSoon:Boolean,
	$isReady:Boolean, $isDisplayed:Boolean,
	$isRemoved:Boolean, $bedrooms:Int, $bathrooms:Int,
  $rentMax:Int, $rentMin:Int,
  $squareFeetMin:Int, $squareFeetMax:Int, $occupancyStatus:String, $leasedStatus:String, $promotional: String, $floorplanId: ID) {
  updateUnitMutation(id:$id, name:$name,
  	number:$number, rent:$rent,
  	leaseTerm:$leaseTerm, sqFt:$sqFt,
    dateAvailable:$dateAvailable,
  	isAvailable:$isAvailable, isComingSoon:$isComingSoon,
  	isReady:$isReady, isDisplayed:$isDisplayed,
  	isRemoved:$isRemoved, bedrooms:$bedrooms, bathrooms:$bathrooms,
    squareFeetMin:$squareFeetMin, squareFeetMax:$squareFeetMax,
    rentMax:$rentMax, rentMin:$rentMin,
    occupancyStatus:$occupancyStatus, leasedStatus:$leasedStatus, promotional: $promotional, floorplanId: $floorplanId) {
    id
    name
    number
		rent
    leaseTerm
    sqFt
    isAvailable
    isComingSoon
    isReady
    isDisplayed
    isRemoved
    bedrooms
    bathrooms
    squareFeetMin
    squareFeetMax
    occupancyStatus
    leasedStatus
    promotional
    rentMax
    rentMin
  }
}
`
export const DELETE_UNIT_MUTATION = `
  mutation DeleteUnitMutation($id:ID!){
    deleteUnitMutation(id:$id){
      result
    }
  }
`

export const UPLOAD_UNIT_MUTATION = `
  mutation UploadUnitMutation($units: [UnitUpload!]!) {
    uploadUnitMutation (units: $units) {
      result
    }
  }
`
