export const EMBEDDED_DOCUMENTS_QUERY = `
  query EmbeddedDocuments($documentType:String!){
    embeddedDocuments(documentType: $documentType) {
      id
      name
      documentType
      thirdPartyLink
      order
      company {
        id
        about
      }
      property {
        id
        name
      }
      createdAt
      updatedAt
    }
  }
`
