import React from "react"
import styled from "styled-components"
import BrynsonTable from "../../shared/BrynsonTable"

const TableWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 12px !important;

  .table {
    width: fit-content !important;
    border-radius: 2px;

  }
  .table th {
    white-space: nowrap;
    font-size: 13px;
  }
`

export default function ILSRatesTable({ entries }) {
  const tableHeader = () => (
    <div className="column is-8 is-offset-1 table-actions">
    </div>
  )

  const columns = entries[0]?.map(entry => ({
    accessor: entry.label,
    Header: entry.label,
    type: "text"
  }));

  const data = entries.map((group, index) => {
    const result = {};
    const name = group[0].value
    group.forEach(item => {
      result.id = index;
      result.name = name
      result[item.label] = item.value;
    });
    return result;
  });


  return (
    <TableWrapper>
      <BrynsonTable
        data={data}
        columns={columns}
        countTitle={entries.length > 1 ? "total entries" : "entry"}
        header={tableHeader}
        rowClick={() => {}}
        hideFilter={true}
      />
    </TableWrapper>
  )
}