import { Accordion } from "flowbite-react"
import React from "react"
import { useQuery } from "urql"

import { IFRAME_INTEGRATION_QUERY } from "../../queries/IframeIntegration"

export default function IntegratedView({ viewId }) {

  const [{ data: data }] = useQuery({
    query: IFRAME_INTEGRATION_QUERY,
    variables: {
      id: viewId
    }
  })

  return (
    <div className="p-8">
      {data?.iframeIntegration?.iframeSource ? (
        <iframe src={data?.iframeIntegration?.iframeSource} width="100%" height="1000" frameBorder="0"></iframe>
      ) : (
        <></>
      )}
    </div>
  )
}
