if (!window.App) {
  window.App = {}
}

App.$ = $ // Allows us to use App.$ in UJS partials to get the Cash wrapper.

App.refireLoad = () => {
  const event = document.createEvent("Event")
  event.initEvent("turbolinks:load", true, true)
  document.dispatchEvent(event)
}

Turbolinks.setProgressBarDelay(100)

document.addEventListener("turbolinks:load", function () {
  if ($("body").hasClass("profile_setup licenses show") || $("body").hasClass("portal profiles credentials")) {
    var buttons = document.getElementsByClassName("close")
    for (var i = 0; i < buttons.length; i++) {
      var button = buttons[i]
      button.onclick = function () {
        $(".document-upload-error").html("")
      }
    }
  }
})

// copied from https://github.com/turbolinks/turbolinks/issues/181#issuecomment-423736911
;(function () {
  // Tell the browser not to handle scrolling when restoring via the history or when reloading
  if ("scrollRestoration" in history) history.scrollRestoration = "manual"

  var SCROLL_POSITION = "scroll-position"
  var PAGE_INVALIDATED = "page-invalidated"

  // Patch the reload method to flag that the following page load originated from the page being invalidated
  Turbolinks.BrowserAdapter.prototype.reload = function () {
    sessionStorage.setItem(PAGE_INVALIDATED, "true")
  }

  // Persist the scroll position when leaving a page
  addEventListener("beforeunload", function () {
    sessionStorage.setItem(
      SCROLL_POSITION,
      JSON.stringify({
        scrollX: scrollX,
        scrollY: scrollY
      })
    )
  })

  // When a page is fully loaded:
  // 1. Get the persisted scroll position
  // 2. If the properties match and the load did not originate from a page invalidation, scroll to the persisted position
  // 3. Remove the persisted information
  addEventListener("DOMContentLoaded", function (event) {
    var scrollPosition = JSON.parse(sessionStorage.getItem(SCROLL_POSITION))

    if (shouldScroll(scrollPosition)) {
      scrollTo(scrollPosition.scrollX, scrollPosition.scrollY)
    }

    sessionStorage.removeItem(SCROLL_POSITION)
    sessionStorage.removeItem(PAGE_INVALIDATED)
  })

  function shouldScroll(scrollPosition) {
    return (
      scrollPosition &&
      !JSON.parse(sessionStorage.getItem(PAGE_INVALIDATED))
    )
  }
})()
