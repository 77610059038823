export const COMP_ANALYSES_QUERY = `
  query {
    compAnalyses {
      id
      type
      propertyId
      year
      month
      attachment
    }
  }
`
