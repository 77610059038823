export const PET_POLICY_QUERY = `
  query PetPolicy($id:ID!){
    petPolicy(id:$id) {
      id
      propertyId
      petType
      count
      weight
      weightLimitNotes
      monthRentMin
      monthRentMax
      oneTimeFee
      size
      aggressiveAllowed
      largeAllowed
      description
      comment
      depositMin
      depositMax
      feeMin
      feeMax
      restrictions
      petCare
      otherTerms
      property {
        name
        id
      }
      petPolicyTypes{
        name
        value
      }
      columns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_PET_POLICY_MUTATION = `
  mutation CreatePetPolicyMutation($propertyId: Int!, $petType: String, $count: Int, $weight: Int, $weightLimitNotes: String, $monthRentMin: Float, $monthRentMax: Float, $oneTimeFee: Float, $size: String, $aggressiveAllowed: Boolean, $largeAllowed: Boolean, $description: String, $comment: String, $depositMin: Float, $depositMax: Float, $feeMin: Float, $feeMax: Float, $restrictions: String, $petCare: Boolean, $otherTerms: String) {
    createPetPolicyMutation(
      propertyId: $propertyId
      petType: $petType
      count: $count
      weight: $weight
      weightLimitNotes: $weightLimitNotes
      monthRentMin: $monthRentMin
      monthRentMax: $monthRentMax
      oneTimeFee: $oneTimeFee
      size: $size
      aggressiveAllowed: $aggressiveAllowed
      largeAllowed: $largeAllowed
      description: $description
      comment: $comment
      depositMin: $depositMin
      depositMax: $depositMax
      feeMin: $feeMin
      feeMax: $feeMax
      restrictions: $restrictions
      petCare: $petCare
      otherTerms: $otherTerms
    ) {
      id
      propertyId
      petType
      count
      weight
      weightLimitNotes
      monthRentMin
      monthRentMax
      oneTimeFee
      size
      aggressiveAllowed
      largeAllowed
      description
      comment
      depositMin
      depositMax
      feeMin
      feeMax
      restrictions
      petCare
      otherTerms
    }
  }
`

export const EDIT_PET_POLICY_MUTATION = `
  mutation UpdatePetPolicyMutation($id: ID!, $petType: String, $count: Int, $weight: Int, $weightLimitNotes: String, $monthRentMin: Float, $monthRentMax: Float, $oneTimeFee: Float, $size: String, $aggressiveAllowed: Boolean, $largeAllowed: Boolean, $description: String, $comment: String, $depositMin: Float, $depositMax: Float, $feeMin: Float, $feeMax: Float, $restrictions: String, $petCare: Boolean, $otherTerms: String) {
    updatePetPolicyMutation(
      id: $id
      petType: $petType
      count: $count
      weight: $weight
      weightLimitNotes: $weightLimitNotes
      monthRentMin: $monthRentMin
      monthRentMax: $monthRentMax
      oneTimeFee: $oneTimeFee
      size: $size
      aggressiveAllowed: $aggressiveAllowed
      largeAllowed: $largeAllowed
      description: $description
      comment: $comment
      depositMin: $depositMin
      depositMax: $depositMax
      feeMin: $feeMin
      feeMax: $feeMax
      restrictions: $restrictions
      petCare: $petCare
      otherTerms: $otherTerms
    ) {
      id
      propertyId
      petType
      count
      weight
      weightLimitNotes
      monthRentMin
      monthRentMax
      oneTimeFee
      size
      aggressiveAllowed
      largeAllowed
      description
      comment
      depositMin
      depositMax
      feeMin
      feeMax
      restrictions
      petCare
      otherTerms
    }
  }
`

export const DELETE_PET_POLICY_MUTATION = `
  mutation DeletePetPolicyMutation($id: ID!) {
    deletePetPolicyMutation(id: $id) {
      result
      errors
    }
  }
`
