import { Button, Label, TextInput } from "flowbite-react"
import React, { useContext, useEffect, useMemo, useState } from "react"
import { ReactSortable } from "react-sortablejs"
import styled from "styled-components"

import { GlobalContext } from "../context/GlobalContext"
import { MediaContext } from "../context/MediaContext"

import BreadCrumbs from "./BreadCrumbs"
import DesktopTitle1 from "./DesktopH5"
import Loading from "./Loading"
import MediumCard from "./Media/MediumCard"
import MediumUpload from "./MediumUpload"

export const MediaTypes = {
  photo: {
    label: "Photos",
    fileType: "image"
  },
  floorplan: {
    label: "Floorplans",
    fileType: "image"
  },
  "3d_virtual_tours": {
    label: "3D Virtual Tours",
    fileType: "image"
  },
  video: {
    label: "Videos",
    fileType: "video"
  },
  matterport: {
    label: "Matterport",
    fileType: []
  }
}

const MediaContent = styled.div`
  .media img {
    max-width: 150px;
    max-height: 100px;
  }
`

export default function MediaIndex({ params, filestackApiKey }) {
  const id = parseInt(JSON.parse(params)["id"])
  const source = JSON.parse(params)["source"] || "Property"

  const isProperty = source === "Property"
  const title = isProperty ? "Property Specific Media" : "List of Media"

  const {
    unitData,
    propertyData,
    floorplanData,
    reloadUnit,
    reloadProperty,
    reloadFloorplan,
    fetchingUnit,
    fetchingProperty,
    fetchingFloorplan
  } = useContext(GlobalContext)
  // eslint-disable-next-line no-unused-vars
  const { createMedium: create, sortMedia } = useContext(MediaContext)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState()
  const [currentData, setCurrentData] = useState()

  // eslint-disable-next-line no-unused-vars
  const [currentLoading, setLoading] = useState(false)
  const [inheritData, setInheritData] = useState()
  const [breadCrumbs, setBreadCrumbs] = useState()
  const [imageLoading, setImageLoading] = useState(false)
  const [caption, setCaption] = useState("")
  const [rank, setRank] = useState("")
  const [mediumType, setMediumType] = useState("photo")
  const [matterportUrl, setmatterportUrl] = useState("")
  const [sortedMedia, setSortedMedia] = useState([])

  const reloadCurrent = () => {
    if (source === "Property" && fetchingProperty === false) reloadProperty(id)
    if (source === "Floorplan" && fetchingFloorplan === false) reloadFloorplan(id)
    if (source === "Unit" && fetchingUnit === false) reloadUnit(id)
  }

  const addProperty = (data) => {
    setCurrentData(data?.property?.media.reverse())
  }

  const addFloorplan = (data) => {
    setCurrentData(data?.floorplan?.media.reverse())
  }

  const addUnit = (data) => {
    setCurrentData(data?.unit?.media.reverse())
  }

  const selectedMedia = () => (currentData ? currentData.filter((item) => item.mediumType === mediumType) : [])

  const getLinkFromSource = () => {
    if (source === "Property") {
      return "/portal/listings"
    } else if (source === "Floorplan") {
      return `/portal/floorplans/${floorplanData.floorplan.propertyId}`
    } else if (source === "Unit") {
      return `/portal/units/${unitData.unit.propertyId}`
    }
  }

  const getNameFromSource = () => {
    if (source === "Property") {
      return `Property ${propertyData?.property?.name || ""}`
    } else if (source === "Floorplan") {
      return `Floorplan ${floorplanData.floorplan.name || ""}`
    } else if (source === "Unit") {
      return `Unit ${unitData.unit.name || ""}`
    }
  }

  useMemo(() => {
    if (propertyData && source === "Property") {
      addProperty(propertyData)
    } else if (floorplanData && source === "Floorplan") {
      addFloorplan(floorplanData)
    } else if (unitData && source === "Unit") {
      addUnit(unitData)
      setInheritData(unitData.unit.floorplan.media)
    }

    if (propertyData || floorplanData || unitData) {
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard" },
        { link: getLinkFromSource(), title: getNameFromSource() },
        { link: null, title: isProperty ? title : "Media" }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [propertyData, floorplanData, unitData])

  useEffect(() => {
    if (params && !breadCrumbs) {
      reloadCurrent()
    }
  }, [])

  useEffect(() => {
    if (currentData) {
      setSortedMedia(selectedMedia())
    }
  }, [mediumType, currentData])

  const uploadMatterport = () => {
    create({
      mediumType: mediumType,
      mediumableId: id,
      mediumableType: source,
      mediumData: JSON.stringify({}),
      caption: caption === "" ? null : caption,
      rank: rank === "" ? null : rank,
      matterportUrl: matterportUrl === "" ? null : matterportUrl
    }).then(() => {
      setCaption("")
      setRank("")
      setImageLoading(false)
    })
  }

  const handleSortedMedia = (newState) => {
    if (newState.length === 0) return

    const newIds = newState.map((item) => item.id)
    const oldIds = sortedMedia.map((item) => item.id)

    if (newIds.toString() === oldIds.toString()) return

    const ids = newState.map((item) => item.id)
    updateCurrentDataRanks(newState)
    sortMedia({ mediaIds: ids })
  }

  const updateCurrentDataRanks = (newState) => {
    const updatedData = newState.map((item) => {
      const newItem = currentData.find((newItem) => newItem.id === item.id)
      if (newItem) {
        return { ...item, rank: newItem.rank }
      }
      return item
    })
    setCurrentData(updatedData)
  }

  const MatterportUpload = () => (
    <div className="flex w-full flex-row">
      <div className="basis-11/12">
        <Label htmlFor="matterport-url">Matterport URL</Label>
        <TextInput
          id="matterport-url"
          placeholder="Matterport URL"
          value={matterportUrl}
          type="text"
          onChange={(input) => setmatterportUrl(input.target.value)}
        />
      </div>
      <div className="flex basis-1/12 items-end justify-end">
        <Button
          color="blue"
          pill
          onClick={() => {
            uploadMatterport()
          }}>
          Upload
        </Button>
      </div>
    </div>
  )

  return (
    <>
      {breadCrumbs ? (
        <MediaContent className="p-8">
          {breadCrumbs && <BreadCrumbs items={breadCrumbs} />}
          <div className="flex items-center space-x-4">
            <DesktopTitle1 content={title} />
            {isProperty ? (
              <a
                className="mb-6 mt-1 inline-block border-blue-600 text-sm font-semibold text-blue-600"
                href={`/portal/media/all?id=${id}&source=Property`}>
                View All Media
              </a>
            ) : null}
          </div>
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex flex-row gap-3">
            <div className="basis-2/6 gap-2">
              {Object.keys(MediaTypes).map((item) => (
                <Button
                  className="mb-2"
                  key={item}
                  color={mediumType === item ? "blue" : "gray"}
                  pill
                  onClick={() => {
                    setMediumType(item)
                  }}>
                  {MediaTypes[item].label}
                </Button>
              ))}
            </div>
            <div className="flex basis-2/6 flex-col items-center">
              <div className="flex w-full flex-row">
                {mediumType === "matterport" ? (
                  <MatterportUpload />
                ) : (
                  <MediumUpload
                    id={id}
                    callback={reloadCurrent}
                    loading={imageLoading}
                    setLoading={setImageLoading}
                    fileType={MediaTypes[mediumType].fileType}
                    mediumType={mediumType}
                    sourceId={id}
                    sourceType={source}
                    apikey={filestackApiKey}
                  />
                )}
              </div>
            </div>
            <div className="basis-2/6">
            </div>
          </div>
          <div className="w-full mt-6">
            <ReactSortable
              className="mt-3 flex flex-col grid grid-cols-3 gap-3"
              list={sortedMedia}
              setList={(newState) => {
                handleSortedMedia(newState)
              }}>
              {sortedMedia &&
                sortedMedia.map((item) => (
                  <MediumCard
                    onChange={() => {
                      reloadCurrent()
                    }}
                    key={item.id}
                    drag={true}
                    item={item}
                    editable={item.mediumableType === source}
                  />
                ))}
            </ReactSortable>

            {
              // eslint-disable-next-line no-nested-ternary
              inheritData?.length > 0 && inheritData.filter((item) => item.mediumType === mediumType).length > 0 && (
                <h3 className="mt-3 p-3 text-xl font-bold">Inherited Media</h3>
              )
            }

            <div className="mt-3 flex max-w-3xl flex-col gap-3">
              {inheritData &&
                inheritData.length > 0 &&
                inheritData
                  .filter((item) => item.mediumType === mediumType)
                  .map((item) => (
                    <MediumCard
                      onChange={() => {
                        reloadCurrent()
                      }}
                      key={item.id}
                      drag={false}
                      item={item}
                      editable={item.mediumableType === source}
                    />
                  ))}
            </div>
          </div>

        </MediaContent>
      ) : (
        <Loading />
      )}
    </>
  )
}
