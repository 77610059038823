import { CSVBoxButton } from "@csvbox/react"
import React, { useContext, useState } from "react"

import { GlobalContext } from "../context/GlobalContext"
import { UnitsContext } from "../context/UnitsContext"
import BrynsonSquareButton from "../shared/BrynsonSquareButton"

import SlideInAlert from "./SlideInAlert"

export default function FloorplanUpload({ buttonStyle, buttonSize, buttonClassName, setLoading, style, propertyId }) {
  const [showAlert, setShowAlert] = useState(false)
  const [alertContent, setAlertContent] = useState("")
  const [importSuccess, setImportSuccess] = useState(false)
  const { uploadUnit } = useContext(UnitsContext)
  const { currentUserData, reloadProperty } = useContext(GlobalContext)
  const columns = [
    {
      accessor: "Unit Name",
      accessor_value: "name",
      kind: "string"
    },
    {
      accessor: "Market Rent",
      accessor_value: "rentMin",
      kind: "float"
    },
    {
      accessor: "Default Deposit",
      accessor_value: "depositAmount",
      kind: "float"
    },
    {
      accessor: "Sqft",
      accessor_value: "squareFeetMin",
      kind: "int"
    },
    {
      accessor: "Bedrooms",
      accessor_value: "bedrooms",
      kind: "int"
    },
    {
      accessor: "Bathrooms",
      accessor_value: "bathrooms",
      kind: "int"
    },
    {
      accessor: "Property Name",
      accessor_value: "propertyName",
      kind: "string"
    },
    {
      accessor: "Unit Type",
      accessor_value: "floorplanName",
      kind: "string"
    },
    {
      accessor: "Rent Status",
      accessor_value: "occupancyStatus",
      kind: "string"
    },
    {
      accessor: "Amenities",
      accessor_value: "amenities",
      kind: "string"
    }
  ]

  const fetchUnits = (unitInput) => {
    const unitValues = {}
    unitValues["companyId"] = parseInt(currentUserData?.currentUser?.currentCompany?.id)
    columns.forEach((item) => {
      if (unitInput[item.accessor]) {
        if (item.kind == "float") {
          unitValues[item.accessor_value] = parseFloat(unitInput[item.accessor].replace(",", ""))
        } else if (item.kind == "int") {
          unitValues[item.accessor_value] = parseInt(unitInput[item.accessor])
        } else {
          unitValues[item.accessor_value] = unitInput[item.accessor]
        }
      } else {
        unitValues[item.accessor_value] = null
      }
    })
    return unitValues
  }

  const handleFloorplanImport = (result, data) => {
    setLoading(true)
    const unitsForUpload = data.rows.map((uploadedFloorplan) => fetchUnits(uploadedFloorplan))
    if (result) {
      uploadUnit({ units: unitsForUpload }).then((res) => {
        if (res?.error) {
          setShowAlert(true)
          setAlertContent(`Error importing unitsForUpload: ${res.error} Please check your data and try again.`)
        } else {
          const successMsg = res?.data?.uploadUnitMutation?.result
          if (successMsg) {
            alert(successMsg)
          }
        }
        setLoading(false)
      })
    } else {
      console.log("Upload fail")
    }
  }

  // HkxmnmAHWhexvtB9zUNhNSCBuKGOYW
  return (
    <div style={style}>
      <CSVBoxButton
        licenseKey="7ZUS9QGELK7jqm1ZgbaFJy8AlOsaXs"
        onImport={handleFloorplanImport}
        render={(launch) => (
          <BrynsonSquareButton
            type={buttonStyle}
            size={buttonSize}
            label="Import Units"
            fullWidth={false}
            onClick={launch}
            className={buttonClassName}
          />
        )}>
        Import
      </CSVBoxButton>
      <SlideInAlert visible={showAlert} setVisible={setShowAlert}>
        {alertContent} {importSuccess && <a onClick={() => (window.property.href = "/portal/clients")}>View</a>}
      </SlideInAlert>
    </div>
  )
}
