import React from "react"

import FloorplansProvider from "../../context/FloorplansContext"
import FloorplanEdit from "../../shared/FloorplanEdit"
import FloorplanNew from "../../shared/FloorplanNew"
import FloorplanShow from "../../shared/FloorplanShow"

export default function Floorplan({ params, page }) {
  return (
    <FloorplansProvider>
      {page === "show" && <FloorplanShow params={params} />}
      {page === "new" && <FloorplanNew params={params} />}
      {page === "edit" && <FloorplanEdit params={params} />}
    </FloorplansProvider>
  )
}
