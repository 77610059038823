import React, { useContext, useEffect, useMemo, useState } from "react"
import { GlobalContext } from "../context/GlobalContext"
import InputField from "./InputField"
import DesktopTitle1 from "./DesktopH5"
import { FloorplansContext } from "../context/FloorplansContext"
import BrynsonSquareButton from "./BrynsonSquareButton"
import CheckBoxExtended from "./CheckboxExtended"
import CurrencyInput from 'react-currency-input-field';
import BrynsonDatePicker from "./BrynsonDatePicker"
import Loading from "./Loading"
import BreadCrumbs from "./BreadCrumbs"
import moment from "moment-timezone"

export default function FloorplanEdit({ params }) {
  const prefix = "$";
  const { floorplanData, reloadFloorplan } = useContext(GlobalContext)
  const { updateFloorplan } = useContext(FloorplansContext)
  const [error, setError] = useState()
  const [floorplanInput, setFloorplanInput] = useState()
  const [currentLoading, setLoading] = useState(false)
  const [breadCrumbs, setBreadCrumbs] = useState()

  const fetchCreateFloorplans = (floorplanInput) => {
    const floorplanValues = {}
    floorplanValues['id'] = JSON.parse(params)["id"];
    floorplanData?.floorplan?.columns.forEach((item) => {
      if (floorplanInput[item.accessor]) {
        floorplanValues[item.accessor] = item.type === "decimal" || item.type === "integer" ? parseInt(floorplanInput[item.accessor]) : floorplanInput[item.accessor]
        if (item.type === "float") {
          floorplanValues[item.accessor] = parseFloat(floorplanInput[item.accessor])
        }
      } else {
        floorplanValues[item.accessor_value || item.accessor] = null;
      }
    })

    return floorplanValues
  }

  useEffect(() => {
    if (params) {
      const id = JSON.parse(params)["id"]
      reloadFloorplan(parseInt(id))
    }
  }, [params])

  useEffect(() => {
    if (floorplanData) {
      const floorplanValues = {}
      floorplanData?.floorplan?.columns.map((item) => {
        floorplanValues[item.accessor] = floorplanData?.floorplan[item.accessor]
      })
      setFloorplanInput(floorplanValues)
      const breadCrumbs = [
        { link: "/portal/dashboard", title: "dashboard"},
        { link: "/portal/listings", title: `Property ${floorplanData?.floorplan.property?.name || ""}`},
        { link: `/portal/floorplans/${floorplanData?.floorplan?.propertyId}`, title: "Floorplans"},
        { link: null, title: "Edit" }
      ]
      setBreadCrumbs(breadCrumbs)
    }
  }, [floorplanData])

  const breadCrumbHeader = () => (
    <div className="mb-6">
      <BreadCrumbs items={breadCrumbs} />
    </div>
  )


  const addValue = (item, value) => {
    const floorplan = {}
    floorplan[`${item.accessor}`] = value
    setFloorplanInput({ ...floorplanInput, ...floorplan })
  }

  return (
    <>
      { floorplanData ?
        <div className="p-8">
          { breadCrumbs &&
            breadCrumbHeader()
          }
          <DesktopTitle1 content={`Edit floorplan ${floorplanData ? floorplanData?.floorplan["name"] : ""}`} />
          {error && <p className="text-red-600">{error}</p>}
          <div className="flex row flex-wrap grid grid-cols-2 gap-3">
            {floorplanData?.floorplan?.columns.map((item) => (
              <div className="row flex items-center w-full" key={`floorplan-insert-${item.accessor}`}>
                {item.type === "decimal" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <CurrencyInput
                      className="m-0 w-full rounded border border-geyser bg-white text-navy shadow-none outline-0 focus:border-teal active:border-teal px-4 py-3.5"
                      name={item.accessor}
                      placeholder={item.Header}
                      defaultValue={floorplanData?.floorplan[item.accessor]}
                      decimalsLimit={2}
                      onValueChange={(value, name) => {
                        addValue(item, value)
                      }}
                      prefix={prefix}
                    />
                  </div>
                }
                {item.type === "boolean" &&
                  <div className="my-4 w-full">
                    <CheckBoxExtended name={item.Header} checked={floorplanData?.floorplan[item.accessor]} handleClick={(value) => {
                      addValue(item, value)
                    }} />
                  </div>
                }
                {item.type === "date" &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <BrynsonDatePicker selectedDate={floorplanData?.floorplan[item.accessor] ? moment(floorplanData?.floorplan[item.accessor]).toDate() : ""} onChange={(date) => {
                      addValue(item, date)
                    }} />
                  </div>
                }
                {(item.type !== "boolean" && item.type !== "decimal" && item.type !== "date") &&
                  <div className="mb-2 w-full">
                    <label htmlFor="property-name">{item.Header}</label>
                    <InputField
                      id={`property-${item.accessor}-edit`}
                      placeholder={item.Header}
                      defaultValue={floorplanData?.floorplan[item.accessor]}
                      type={(item.type === "integer" || item.type === "float") ? "number" : "text" }
                      onChange={(input) => {
                        addValue(item, input.target.value)
                      }}
                    />
                  </div>
                }
              </div>
            ))}
          </div>
          {floorplanData?.floorplan?.columns &&
            <div className="flex justify-center items-center w-full mt-6">
              <div>
                <BrynsonSquareButton
                  type="primary"
                  testId="add-new-property-button"
                  loading={currentLoading}
                  onClick={() => {
                    setLoading(true)            
                    const createFloorplans = fetchCreateFloorplans(floorplanInput)
                    updateFloorplan(createFloorplans).then((result) => {
                      if (result?.error || result?.data?.createFloorplan?.errors) {
                        setError(`Error updating property: ${result?.error || result?.data?.createFloorplan?.errors}`)
                      } else {
                        window.location.href=`/portal/floorplans/${floorplanData?.floorplan["propertyId"]}`
                      }
                      setTimeout(() => {
                        setLoading(false)
                      }, 1000)
                    })
                  }}
                  label="Edit floorplan"
                />
              </div>
            </div>
          }
        </div>
      :
        <Loading />
      }
    </>
  )
}
