import React, { useEffect } from "react"
import InformationProvider from "../../context/InformationsContext"
import InformationNew from "../../shared/InformationNew"
import InformationEdit from "../../shared/InformationEdit"

export default function Information({ params, page }) {
  return (
    <InformationProvider>
      {page === "new" &&
        <InformationNew params={params} />
      }
      {page === "edit" &&
        <InformationEdit params={params} />
      }
    </InformationProvider>
  )
}
