import React from "react"
import styled from "styled-components"

const Checkbox = styled.div`
  display: inline-flex;

  &:hover {
    cursor: pointer;
  }

  input[type="checkbox"] {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  svg {
    display: inline-block;
    height: 24px;
    width: 24px;
    background: #fff;
    border: 2px #ddd solid;
    margin-right: 12px;
  }
`

export default function CheckBox({ inputId, name, handleClick, id, checked }) {
  return (
    <Checkbox
      data-test-id={id}
      onClick={() => {
        handleClick(id)
      }}>
      <input type="checkbox" id={inputId} />
      <svg
        className={`${checked ? "checkbox--active" : ""}`}
        aria-hidden="true"
        viewBox="0 0 15 11"
        style={{
          backgroundColor: checked ? "#0D9DA4" : "#fff",
          borderColor: checked ? "#0D9DA4" : "#ddd"
        }}
        fill="none">
        <path d="M1 4.5L5 9L14 1" strokeWidth="2" stroke="#fff" />
      </svg>
      {name}
    </Checkbox>
  )
}
