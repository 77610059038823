import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const queryInput = $(this.element)

    queryInput.keypress(function (e) {
      if (e.keyCode == 13) {
        e.preventDefault()
      }
    })

    queryInput.keyup(function (e) {
      if (e.keyCode == 13) {
        e.preventDefault()

        if ($.trim(queryInput.val()).length) {
          queryInput.closest("form").submit()
        }
      }
    })
  }
}
