import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_PARK_MUTATION, UPDATE_PARK_MUTATION } from "../queries/ParksMutations";

export const ParksContext = createContext(null);

const ParksProvider = ({ children, ...props }) => {
  const [, createPark] = useMutation(CREATE_PARK_MUTATION)
  const [, updatePark] = useMutation(UPDATE_PARK_MUTATION)

  return(
    <ParksContext.Provider value={{createPark, updatePark}}>
      {children}
    </ParksContext.Provider>
  );
}

export default ParksProvider;
