import React, { useEffect } from "react"
import PaymentsProvider from "../../context/PaymentsContext"
import PaymentNew from "../../shared/PaymentNew"
import PaymentEdit from "../../shared/PaymentEdit"

export default function Payment({ params, page }) {
  return (
    <PaymentsProvider>
      {page === "new" &&
        <PaymentNew params={params} />
      }
      {page === "edit" &&
        <PaymentEdit params={params} />
      }
    </PaymentsProvider>
  )
}
