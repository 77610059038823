export const INFORMATION_QUERY = `
  query Information($id:ID!){
    information(id:$id) {
      id
      propertyId
      structureType
      unitCount
      buildingCount
      numberOfAcres
      yearBuilt
      yearRemodeled
      acquisitionDate
      propertyAvailabilityUrl
      shortDescription
      longDescription
      leaseLength
      email
      phone
      property {
        id
        name
      }
      park {
        id
      }
      officeHours {
        id
        informationId
        openTime
        closeTime
        day
      }
      daysOfTheWeek {
        value
        name
      }
      parkColumns {
        accessor
        Header
        type
      }
      officeHourColumns {
        accessor
        Header
        type
      }
      columns {
        accessor
        Header
        type
      }
    }
  }
`

export const CREATE_INFORMATION_MUTATION = `
  mutation CreateInformationMutation($propertyId: Int!, $structureType: String, $unitCount: Int, $buildingCount: Int, $numberOfAcres: Int, $yearBuilt: String, $yearRemodeled: String, $acquisitionDate: ISO8601Date, $propertyAvailabilityUrl: String, $shortDescription: String, $longDescription: String, $leaseLength: String, $email: String, $phone: String) {
    createInformationMutation(
      propertyId: $propertyId
      structureType: $structureType
      unitCount: $unitCount
      buildingCount: $buildingCount
      numberOfAcres: $numberOfAcres
      yearBuilt: $yearBuilt
      yearRemodeled: $yearRemodeled
      acquisitionDate: $acquisitionDate
      propertyAvailabilityUrl: $propertyAvailabilityUrl
      shortDescription: $shortDescription
      longDescription: $longDescription
      leaseLength: $leaseLength
      email: $email
      phone: $phone
    ) {
      id
      propertyId
      structureType
      unitCount
      numberOfAcres
      yearBuilt
      yearRemodeled
      acquisitionDate
      propertyAvailabilityUrl
      shortDescription
      longDescription
      leaseLength
      email
      phone
    }
  }
`

export const UPDATE_INFORMATION_MUTATION = `
  mutation UpdateInformationMutation($id: ID!, $structureType: String, $unitCount: Int, $buildingCount: Int, $numberOfAcres: Int, $yearBuilt: String, $yearRemodeled: String, $acquisitionDate: ISO8601Date, $propertyAvailabilityUrl: String, $shortDescription: String, $longDescription: String, $leaseLength: String, $email: String, $phone: String) {
    updateInformationMutation(
      id: $id
      structureType: $structureType
      unitCount: $unitCount
      buildingCount: $buildingCount
      numberOfAcres: $numberOfAcres
      yearBuilt: $yearBuilt
      yearRemodeled: $yearRemodeled
      acquisitionDate: $acquisitionDate
      propertyAvailabilityUrl: $propertyAvailabilityUrl
      shortDescription: $shortDescription
      longDescription: $longDescription
      leaseLength: $leaseLength
      email: $email
      phone: $phone
    ) {
      id
      propertyId
      structureType
      unitCount
      buildingCount
      numberOfAcres
      yearBuilt
      yearRemodeled
      acquisitionDate
      propertyAvailabilityUrl
      shortDescription
      longDescription
      leaseLength
      email
      phone
    }
  }
`
