export const DCAAS_QUERY = `
  query {
    dcaas {
      id
      property {
        id
        name
      }
      initialAuditCompletionDate
      auditorName
      quarterlyAuditCompletionDate
      address
      website
      directPhoneNumber
      email
      propertyDescription
      unitCount
      totalBuildings
      numberOfStories
      applicationFee
      adminFee
      storageFee
      rentSpecials
      offerDuration
    }
  }
`

export const DCAA_QUERY = `
  query($dcaaId: ID!) {
    dcaa(dcaaId: $dcaaId){
      id
      property {
        id
        name
      }
      initialAuditCompletionDate
      auditorName
      quarterlyAuditCompletionDate
      address
      website
      directPhoneNumber
      email
      propertyDescription
      unitCount
      totalBuildings
      numberOfStories
      applicationFee
      adminFee
      storageFee
      rentSpecials
      offerDuration
      leaseTerms
      utilitiesIncluded
      includedUtilities
      officeHours
      feedInformation
      parking
      petPolicy
      floorPlans
      photos
      videos
      matterports
      propertyMedias
      custom
      ilsMedia
      features
      services
      interior
      fitnessAndRecreation
      outdoorSpaces
      kitchen
      studentFeatures
      living
      otherFeatures
      standOut
      nearbyFacilities
      fieldStructures
    }
  }
`
