import React, { createContext } from "react";
import { useMutation } from "urql";
import { CREATE_FEE_MUTATION, UPDATE_FEE_MUTATION } from "../queries/FeesMutations";

export const FeesContext = createContext(null);

const FeesProvider = ({ children, ...props }) => {
  const [, createFee] = useMutation(CREATE_FEE_MUTATION)
  const [, updateFee] = useMutation(UPDATE_FEE_MUTATION)

  return(
    <FeesContext.Provider value={{createFee, updateFee}}>
      {children}
    </FeesContext.Provider>
  );
}

export default FeesProvider;
