import React from "react"
const pmsdata = require("../../../../../public/samples/pmsdata")

export default function PMSData() {
	return (
		<div className="p-8">
			<h1 className="font-semibold text-xl">PMS Data Summary</h1>
			<p className="text-lg font-medium">A page to show high level stats and summaries of the data from the connected PMS platform.</p>
			<div className="flex justify-center align-center mt-8">
				<img src={pmsdata} />
			</div>
		</div>
	)
}